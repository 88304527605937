import React, { useState, useRef } from 'react'
import { ButtonHeading, ConsigneeDetailsTab, VehicleDetailsTab } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import LoadingBar from 'react-top-loading-bar';
import FuelStatement from '../FuelStatement';
import { useParams } from 'react-router-dom';

const VehicleDetails = () => {
    let { id } = useParams();
    const [activeTab, setActiveTab] = useState(1);
    const loadingBarRef = useRef(null)
    const [getData, setGetData] = useState(null)

    const tabProps = {
        tabList: constant.vehicle_DetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    }

    const handleDataFetch = (data) => {
        setGetData(data)
    }

    return (
        <div className=''>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <ButtonHeading title={getData?.vehicle_no ? getData?.vehicle_no : 'Loading...'} buttonTitle="Edit" />

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && (
                    <VehicleDetailsTab onDataFetch={handleDataFetch} loadingBarRef={loadingBarRef}  />
                )}
                {activeTab === 2 && <FuelStatement />}
            </div>

        </div>
    )
}

export default VehicleDetails;