
import React, { useState, useCallback } from 'react'
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../CropModal/croperHalper' // Helper function to crop image


const ImageCrop = (props) => {
    const [open, setOpen] = React.useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    console.log("props",props)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }, [])
    
      const showCroppedImage = useCallback(async () => {
        try {
          // const croppedImage = await getCroppedImg(
          //   props.imgsrc,
          //   croppedAreaPixels,
          //   rotation
          // )
          // setCroppedImage(croppedImage)
          // await props.setStateOfParentModal({ false: false, croppedImage: croppedImage });
          // setCroppedImage(croppedImage)
          // props.setStateOfParentModal({ false: false, croppedImage: croppedImage });
        } catch (e) {
          console.error(e)
        }
      }, [croppedAreaPixels, rotation])

      const onCloseHandle = ()=>{
        props.setCommondata({...props.commondata, croperopen:false})
      }

  return (
    <Modal show={props?.open} onHide={onCloseHandle} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Cropper
                      image={props.imgsrc}
                      crop={crop}
                      rotation={rotation}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCloseHandle}>
          Cancel
        </Button>
        <Button variant="primary"  onClick={showCroppedImage}>
          Crop Image
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageCrop;


