import { handleKeyDown } from "_services";
import { CommonInput, CommonModal, CustomSelect } from "components";
import { UserContext } from "context";
import { addTotalFrieght, editTotalFrieght } from "features";
import { errorSchema, utils } from "helper";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import StorageService from "utils/StorageServices/StorageServices";


const EditConsignorFreight = (props) => {
const {data,setEditData} = props;

  const intialTotal = {
    trip_id: "",
    freight_type: data?.freight_type,
    qty: data?.qty,
    rate: data?.rate,
    amount: data?.total_freight,
  
  };
  const dispatch = useDispatch();
  const { masterData } = useContext(UserContext);
  const [formTotal, setFormTotal] = useState(intialTotal);
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [freightTypeList, setFreightTypeList] = useState([]);
 

  useEffect(() => {
    if (masterData) {
  
      const lrFreight = masterData?.contract_rate_type_list?.map((item) => {
        return { label: item?.name, value: item?.id };
      });
    
      setFreightTypeList(lrFreight);
      setFormTotal({ ...formTotal, trip_id: props?.tripId });
    }
  }, [masterData]);

  useEffect(() => {
    if(formTotal?.freight_type!==6){
        setFormTotal({ ...formTotal, amount: Number(formTotal?.qty) * Number(formTotal.rate) });
    }
  }, [formTotal.rate, formTotal?.qty ]);

  useEffect(() => {
    if (formTotal?.freight_type!==6) {
      setFormTotal({ ...formTotal, amount: 0, qty: 0 ,rate:0});
    } 
  }, [formTotal.freight_type]);

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...formTotal,
      [name]: value,
    };
    setFormTotal(stateObj);

    if (!!formError) {
      const validationResult = await utils.checkFormError(
        { ...stateObj },
        errorSchema.addTotalFreigthSchema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const onSubmitTotal = async () => {
    const validationResult = await utils.checkFormError(
      { ...formTotal },
      errorSchema.addTotalFreigthSchema
    );
    const payload = {
      company_id: StorageService?.getCompanyId(),
      trip_id: props?.tripId,
      freight_type: formTotal?.freight_type,
      qty: parseInt(formTotal?.qty),
      rate: parseInt(formTotal?.rate),
      amount: parseInt(formTotal?.amount),
     
    };
    // try {
    //   if (validationResult === true) {
    //     setIsLoading(true);
    //     const res = await dispatch(!props?.totalFreightDetails?addTotalFrieght(payload):editTotalFrieght(payload));
    //     if (res?.payload?.responseCode === 200) {
    //       setIsLoading(false);
    //       handleCloseTotal();
    //       props.callbackTotal();
    //     }
    //   } else {
    //     setFormError(validationResult);
    //   }
    // } catch {
    //   setIsLoading(false);
    // }
  };

  const allClear = () => {
    setFormTotal({ ...intialTotal });
  };
  const handleCloseTotal = () => {
    props?.closeModal();
    allClear();
  };

  const modifyProps = {
    ...props,
    onSubmit: onSubmitTotal,
    isLoading: isLoading,
    disabled: isLoading ,
   
  };
  return (
    <div>
      <CommonModal {...modifyProps}>
        <div className="addTotalFreight_body">
          <div className="_qntSelector">
            <CustomSelect
              label="Freight Type"
              options={freightTypeList?.length ? freightTypeList : []}
              onSelect={(data) => handleOnChange("freight_type", data?.value)}
              value={freightTypeList?.find(
                (i) => i?.value === formTotal?.freight_type
              )}
              focus={!!formError?.freight_type}
              error={formError.freight_type}
            />
          </div>

          {formTotal?.freight_type !== 6 ? (
            <div className="_clmsFlex">
              <div className="clmDual_fx">
                <CommonInput
                  type="text"
                  name="qty"
                  value={Number(formTotal.qty)}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, Number(value))
                  }
                  label={
                    freightTypeList.length
                      ? freightTypeList?.find(
                          (i) => i?.value === formTotal?.freight_type
                        )?.label
                      : "Qty"
                  }
                  onKeyDown={handleKeyDown}
                  focus={!!formError?.qty}
                  error={formError.qty}
                />
              </div>
              <div className="clmDual_fx">
                <CommonInput
                  type="text"
                  label="Rate"
                  name="rate"
                  value={Number(formTotal.rate)}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, Number(value))
                  }
                  onKeyDown={handleKeyDown}
                  focus={!!formError?.rate}
                  error={formError.rate}
                />
              </div>
            </div>
          ) : (
            <div className="">
              <CommonInput
                type="text"
                onChange={({ target: { name, value } }) =>
                  handleOnChange(name, value)
                }
                label={
                  freightTypeList.length
                    ? freightTypeList?.find(
                        (i) => i?.value === formTotal?.freight_type
                      ).label
                    : "Qty"
                }
                placeholder="Enter Freight"
                value={formTotal?.amount}
                name="amount"
                onKeyDown={handleKeyDown}
                focus={!!formError?.amount}
                error={formError.amount}
              />
            </div>
          )}

          <div className="_amtBaar">
            <p>Amount</p>
            <p>{`Rs. ${formTotal?.amount ? formTotal?.amount : 0}`}</p>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default EditConsignorFreight;
