import { API, axiosInstance } from "lib";

export const updateLrProcess = (data = {}) => {
  return axiosInstance
    .post(API.TRIP_SERVIES + '/apk_update_lr_process', data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const podReceivedData = (data={}) => {
  return axiosInstance
    .post(API.TRIP_SERVIES + 'apk_pod_received' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const podStatusUpdateData = (data={}) => {
  return axiosInstance
    .post(API.TRIP_SERVIES + 'apk_pod_update' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};