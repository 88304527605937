import { CommonModal } from 'components';
import React, { useContext, useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa6';
import bidderImg from "../../../src/assets/img/bidderImg.png"
import SaveConnectModal from 'modals/SaveConnectModal';
import { UserContext } from 'context';
import AddReviewModal from 'modals/AddReviewModal';
import { FaStarHalfAlt } from 'react-icons/fa';
import { utils } from 'helper';

const ViewProfileModal = (props) => {

    let { isOpen, nextModal, closeModal, isBtnHideShow , activeTab} = props;
    const { setModalZIndex, modalZIndex, masterData } = useContext(UserContext);

    const [showWriteReview, setShowWriteReview] = useState(false);

    const propsViewProfile = {
        ...props,
        modalZIndex,
        isOpen,
    }

    const handleEventOnclick = () => {
        nextModal(isOpen);
        closeModal(false);
    }

    const handleAddReview = () => {
        setShowWriteReview(!showWriteReview)
        setModalZIndex(999)
    }

    useEffect(() => {
        if (!showWriteReview) {
            setModalZIndex('')
        }
    }, [showWriteReview])

    let reviewModal = {
        isOpen: showWriteReview,
        closeModal: (e) => setShowWriteReview(false),
        modalMainClass: "_dashCommon_Modal _smModal _rvrModal",
        modalTitle: "Write A Review",
        footerPart: true,
    }

    return (
        <>
            <CommonModal {...propsViewProfile}>
                <div className='__vwPrf_body'>
                    <div className='_flxBidr _wrtReview_bx p-0'>
                        <div className='_bidR_bxs' >
                            <div className='__bidR_usrBx'>
                                <span className='_bidR_verifyIcon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12.1999" cy="12.1999" r="8.34737" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5579 23.1158C13.0757 23.1158 14.5786 22.8168 15.9809 22.236C17.3832 21.6552 18.6573 20.8038 19.7306 19.7306C20.8038 18.6573 21.6552 17.3832 22.236 15.9809C22.8168 14.5786 23.1158 13.0757 23.1158 11.5579C23.1158 10.0401 22.8168 8.53715 22.236 7.13488C21.6552 5.73261 20.8038 4.45848 19.7306 3.38523C18.6573 2.31198 17.3832 1.46063 15.9809 0.879792C14.5786 0.298954 13.0757 -2.2617e-08 11.5579 0C8.49255 4.56772e-08 5.55275 1.2177 3.38523 3.38523C1.2177 5.55275 0 8.49255 0 11.5579C0 14.6232 1.2177 17.563 3.38523 19.7306C5.55275 21.8981 8.49255 23.1158 11.5579 23.1158ZM11.26 16.2324L17.681 8.52716L15.7085 6.88337L10.1864 13.5086L7.32899 10.65L5.51312 12.4658L9.36575 16.3185L10.3597 17.3124L11.26 16.2324Z" fill="#00934C" />
                                    </svg>
                                </span>
                                <img src={bidderImg} alt="" />

                                <div className='_ratingBx'>
                                    <FaStar />
                                    <span>4.5</span>
                                </div>
                            </div>
                            <div className='w-100'>
                                <p className='tl_txt'> {isOpen?.company ? utils.firstLetterCapital(isOpen?.company) : '-'} </p>
                                <p className='tsm_txt'>{isOpen?.city?.label}, {isOpen?.state?.label}</p>
                            </div>
                        </div>

                        <div>
                            {
                                isBtnHideShow ?
                                    <button className='_removRequestBtn'>Remove Connection</button>
                                    :
                                    <button className='_sndRequestBtn' onClick={(e) => handleEventOnclick(e)}>Send Connection Request</button>
                            }
                        </div>
                    </div>

                    <div className='_cntDetail_fx' style={{ marginTop: "30px" }}>
                        <div className='_cntInner_bxs'>
                            <span>208</span>
                            <p>Views</p>
                        </div>
                        <div className='_cntInner_bxs'>
                            <span>208</span>
                            <p>Connection</p>
                        </div>
                        <div className='_cntInner_bxs'>
                            <span>{isOpen?.user_count ? isOpen?.user_count : 0}</span>
                            <p>Users</p>
                        </div>
                        <div className='_cntInner_bxs'>
                            <span>208</span>
                            <p>Loads</p>
                        </div>
                        <div className='_cntInner_bxs'>
                            <span>208</span>
                            <p>Active Loads</p>
                        </div>
                    </div>

                    <ul class="nav nav-tabs" id="myConnectionTab" role="tablist" style={{ marginTop: '26px' }}>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="about-tab" data-bs-toggle="tab" data-bs-target="#about-tab-pane" type="button" role="tab" aria-controls="about-tab-pane" aria-selected="true"><span>About</span></button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review-tab-pane" type="button" role="tab" aria-controls="review-tab-pane" aria-selected="false"><span>Reviews 5</span></button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="about-tab-pane" role="tabpanel" aria-labelledby="about-tab" tabindex="0">
                            <div className='_dtOtr_txtBx' style={{ marginTop: '20px' }}>

                                {activeTab === 1 && <div>
                                    <h4>Rating</h4>

                                    <div className='_starGrp_bx' onClick={() => handleAddReview()}>
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                    </div>
                                </div>}

                                <div>
                                    <h4>About us</h4>
                                    <p>With years of trucking experience, i provide reliable and customer-centric transportation services that prioritize safety and efficiency.</p>
                                    <span className='_joinDa_yr'>Joined 2 years Ago</span>
                                </div>

                                <div>
                                    <h4>Operating states (15)</h4>

                                    <div className='_derCr_blx'>
                                        <span><div className='__insRt'>Goa</div></span>
                                        <span><div className='__insRt'>Madhya Pradesh</div></span>
                                        <span><div className='__insRt'>Andhra Pradesh</div></span>
                                        <span><div className='__insRt'>Delhi</div></span>
                                        <span><div className='__insRt'>Punjab</div></span>
                                        <a href="#">+9 more</a>
                                    </div>
                                </div>

                                <div>
                                    <h4>Operating routes (24)</h4>

                                    <div className='_derCr_blx'>
                                        <span><div className='__insRt'>Indore &gt; Delhi</div></span>
                                        <span><div className='__insRt'>Delhi &gt; Indore</div></span>
                                        <span><div className='__insRt'>Bhopal &gt; Indore</div></span>
                                        <a href="#">+9 more</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="review-tab-pane" role="tabpanel" aria-labelledby="review-tab" tabindex="0">
                            <div className='_rvBx_lstMain'>

                                <div className='_rvBx_lst'>
                                    <div className='d-flex gap-2'>
                                        <div className='_rvBd_imgBx'>
                                            <img src={bidderImg} alt="" />
                                        </div>
                                        <div className='_rv_bdrDetail'>
                                            <h5>Yash Road Carriers</h5>
                                            <p>Indore, Madhya Pradesh</p>

                                            <div className='_starFillGrp_bx'>
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStarHalfAlt />

                                                <p style={{ marginBottom: '0px', marginLeft: '6px' }}>2 Days Ago</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='_rv_comments'>
                                        <p>
                                            With years of trucking experience, i provide reliable and customer-centric transportation services that prioritize safety and efficiency. i provide reliable and customer-centric transportation services that prioritize safety and efficiency.i provide reliable and.
                                        </p>
                                    </div>
                                </div>

                                <div className='_rvBx_lst'>
                                    <div className='d-flex gap-2'>
                                        <div className='_rvBd_imgBx'>
                                            <img src={bidderImg} alt="" />
                                        </div>
                                        <div className='_rv_bdrDetail'>
                                            <h5>Yash Road Carriers</h5>
                                            <p>Indore, Madhya Pradesh</p>

                                            <div className='_starFillGrp_bx'>
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />

                                                <p style={{ marginBottom: '0px', marginLeft: '6px' }}>2 Days Ago</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='_rv_comments'>
                                        <p>
                                            With years of trucking experience, i provide reliable and customer-centric transportation services that prioritize safety and efficiency. i provide reliable and customer-centric transportation services that prioritize safety and efficiency.i provide reliable and.
                                        </p>
                                    </div>
                                </div>

                                <div className='_rvBx_lst'>
                                    <div className='d-flex gap-2'>
                                        <div className='_rvBd_imgBx'>
                                            <img src={bidderImg} alt="" />
                                        </div>
                                        <div className='_rv_bdrDetail'>
                                            <h5>Yash Road Carriers</h5>
                                            <p>Indore, Madhya Pradesh</p>

                                            <div className='_starFillGrp_bx'>
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />

                                                <p style={{ marginBottom: '0px', marginLeft: '6px' }}>2 Days Ago</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='_rv_comments'>
                                        <p>
                                            With years of trucking experience, i provide reliable and customer-centric transportation services that prioritize safety and efficiency. i provide reliable and customer-centric transportation services that prioritize safety and efficiency.i provide reliable and.
                                        </p>
                                    </div>
                                </div>

                                <div className='_rvBx_lst'>
                                    <div className='d-flex gap-2'>
                                        <div className='_rvBd_imgBx'>
                                            <img src={bidderImg} alt="" />
                                        </div>
                                        <div className='_rv_bdrDetail'>
                                            <h5>Yash Road Carriers</h5>
                                            <p>Indore, Madhya Pradesh</p>

                                            <div className='_starFillGrp_bx'>
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />

                                                <p style={{ marginBottom: '0px', marginLeft: '6px' }}>2 Days Ago</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='_rv_comments'>
                                        <p>
                                            With years of trucking experience, i provide reliable and customer-centric transportation services that prioritize safety and efficiency. i provide reliable and customer-centric transportation services that prioritize safety and efficiency.i provide reliable and.
                                        </p>
                                    </div>
                                </div>

                                <div className='_rvBx_lst'>
                                    <div className='d-flex gap-2'>
                                        <div className='_rvBd_imgBx'>
                                            <img src={bidderImg} alt="" />
                                        </div>
                                        <div className='_rv_bdrDetail'>
                                            <h5>Yash Road Carriers</h5>
                                            <p>Indore, Madhya Pradesh</p>

                                            <div className='_starFillGrp_bx'>
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />
                                                <FaStar />

                                                <p style={{ marginBottom: '0px', marginLeft: '6px' }}>2 Days Ago</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='_rv_comments'>
                                        <p>
                                            With years of trucking experience, i provide reliable and customer-centric transportation services that prioritize safety and efficiency. i provide reliable and customer-centric transportation services that prioritize safety and efficiency.i provide reliable and.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </CommonModal>
            {/* <div className='offBack_drop'></div> */}

            {showWriteReview
                && (
                    <AddReviewModal {...reviewModal} />
                )
            }
        </>
    )
}

export default ViewProfileModal