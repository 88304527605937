import React, { useState, useEffect } from 'react';
import { ButtonHeading, SupplierDetailsTab } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import Ledger from '../Ledger';
import { useLocation } from 'react-router-dom';

const SupplierDetails = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tabFromQuery = parseInt(queryParams.get('tab')) || 1;

    const [activeTab, setActiveTab] = useState(tabFromQuery);
    const [supplierData, setSupplierData] = useState(null)

    useEffect(() => {
        setActiveTab(tabFromQuery);
    }, [tabFromQuery]);

    const tabProps = {
        tabList: constant.supplierDetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab,
    };

    const handleSupplierData = (data) =>{
        setSupplierData(data)
    }

    return (
        <div className=''>
            <ButtonHeading title={supplierData?.name} buttonTitle="Edit" />

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && <SupplierDetailsTab supplierData={handleSupplierData} />}
                {activeTab === 2 && 'test 2'}
                {activeTab === 3 && 'test 3'}
                {activeTab === 4 && <Ledger type={1} />}
            </div>
        </div>
    );
};

export default SupplierDetails;
