
import React from 'react'
import { usePlacesWidget } from "react-google-autocomplete"
import CommonInput from '../CommonInput';

const MapInput = ({ label, handleInputChange, value,handlInputChange }) => {

    

    const { ref: materialRef } = usePlacesWidget({
        apiKey: "AIzaSyAkWxbuO-maU16USeyELd3UP0hPLITP3Ec",
        onPlaceSelected: (place) => {
           
            handleInputChange(place);
        },
        options: {
            types: ['(cities)'],
            componentRestrictions: { country: 'in' },
        },
    });

    const handleinput = (e) => {
        e.preventDefault();
        handleInputChange(e.target.value);
    };

    return (
        <>
        
        <div className="anvCustom_floatInput form-floating ">
      <input
        className="form-control"
        ref={materialRef}
        value={value}
        onChange={(e)=>handlInputChange(e.target.value)}
      />
      <label htmlFor="start_kms">
        {" "}
        <span>{label}</span>{" "}
      </label>
    </div>

    
        </>

    )
}

export default MapInput