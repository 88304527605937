


import { handleKeyDown, lrListofTrip } from "_services";
import { CommonInput, CommonModal, CustomSelect } from "components";
import { UserContext } from "context";
import { contractRateApi } from "features";
import { Checkbox } from "pages/User/AddUser";
import React, { useEffect, useState, useCallback, useMemo } from "react";

const LrFreightModal = (props) => {
  const { separateLr, listOfFreight, setListOfFreight, formValue, ownerShip, isOpen } = props;
  const { masterData } = React.useContext(UserContext);

  const filteredArray1 = useMemo(() => {
    return separateLr.map((item) => ({
      consignor_id: item[0]?.consignor_Newid,
      lr_list: item?.filter((lr) => lr.selected).map((lr) => lr?._id),
    }));
  }, [separateLr]);

  const fetchContractRates = useCallback(async () => {
    try {
      const res = await contractRateApi({
        company_id: formValue.company_id,
        lorry_type_id: formValue?.lorry_type_id,
        vehicle_type_id: formValue?.vehicle_type_id,
        vehicle_ownership: ownerShip,
        vehicle_id: formValue?.vehicle_id,
        supplier_id: formValue?.supplier_id,
        select_consignor_lr: filteredArray1,
      });

      if (res?.responseCode === 200) {
       
        const updatedData = res?.data?.final_lr_list.map((consignor) => 
          {
            const isHighestFreight = consignor.contract.highest_freight === 1;
            const highestFreight = isHighestFreight
    ? Math.max(...consignor.lr_list.map(lr => lr.contract_rates.total_freight))
    : 0;

   
            return {
              ...consignor,
              lr_list: consignor.lr_list.map((lr) => ({
                ...lr,
                contract_rates: {
                  ...lr.contract_rates,
                  qty: 0,
                  selected: isHighestFreight && lr.contract_rates.total_freight === highestFreight ? true:false,
                },
                lr_freight: {
                  ...lr.lr_freight,
                  selected: true,
                },
              })),
            }
          });

        setListOfFreight(updatedData);
      }
    } catch (error) {
      console.error("Error fetching contract rates", error);
    }
  }, [filteredArray1, formValue, ownerShip, setListOfFreight]);

  useEffect(() => {
    if (separateLr || formValue || ownerShip) {
      fetchContractRates();
    }
  }, [separateLr, formValue, ownerShip, fetchContractRates]);


  const handleContractRateChange = useCallback(
    (consignorIndex, lrIndex, field, value, chargeable_on) => {
      setListOfFreight((prevList) => {
        const updatedData = [...prevList];
        const targetList = updatedData[consignorIndex].lr_list[lrIndex];
        const targetRate = chargeable_on === 2 ? targetList.contract_rates : targetList.lr_freight;
        
        if (field === "freight_type") {
          targetRate.qty = 0;
          targetRate.rate = 0;
          targetRate.amount = 0;
        }
        targetRate[field] = value;
        
        return updatedData;
      });
    },
    [setListOfFreight]
  );

  const getFreightLabel = useCallback((freightType) => {
    switch (freightType) {
      case 1:
        return "Kot";
      case 2:
        return "Invoice";
      case 3:
        return "Kg";
      case 4:
        return "Qty";
      case 5:
        return "KMs";
      case 6:
        return "Flat";
      default:
        return "Qty";
    }
  }, []);

  const getFreightValue = useCallback((chargeableOn, contractRates, lrFreight) => {
    return chargeableOn === 2 ? (contractRates?.qty ==0||contractRates?.qty ==null)?"":contractRates?.qty : (lrFreight?.qty==0|| lrFreight?.qty==null)?"":lrFreight?.qty;
  }, []);

  const isFreightDisabled = useCallback((chargeableOn, contractRates, lrFreight) => {
    return chargeableOn === 2 ? contractRates?.freight_type === 6 : lrFreight?.freight_type === 6;
  }, []);

  const isEmpty = obj => Object.keys(obj).length === 0;

  function calculateFreight(lr, item) {
    if (lr?.contract?.chargeable_on === 2) {
      if (!item?.contract_rates?.freight_type) {
        return 0;
      }else{
        if (item?.contract_rates?.freight_type !== 6) {
          return item?.contract_rates?.qty * item?.contract_rates?.rate;
        } else {
          return item?.contract_rates?.total_freight;
        }
      }
      
    } else {
      if (item?.lr_freight?.freight_type !== 6) {
        return item?.lr_freight?.qty * item?.lr_freight?.rate;
      } else {
        return item?.lr_freight?.total_freight;
      }
    }
  }

  const shouldDisableCheckbox = (item, consignorIndex, lr, listOfFreight) => {
    const isChargeableOn = lr?.contract?.chargeable_on === 2;
    const isHighestFreight = lr.contract.highest_freight === 1;
    
    if (isChargeableOn) {
      if(isHighestFreight){
return true
      }else{
        const selectedCount = listOfFreight[consignorIndex].lr_list.filter(
          (lr) => lr.contract_rates.selected
        ).length;
        return selectedCount <= 1 && item?.contract_rates?.selected;
      }
      
    } else {
      const selectedCount = listOfFreight[consignorIndex].lr_list.filter(
        (lr) => lr.lr_freight.selected
      ).length;
      return selectedCount <= 1 && item?.lr_freight?.selected;
    }
  };

  return (
    <CommonModal {...props}>
      <div className="_lrFreightBody">
        {listOfFreight.length?
        listOfFreight?.map((lr, consignorIndex) => (
          <div className="_lrxAll" key={`fr${consignorIndex}`}>
            <h4 className="lrfHead">{lr?.lr_list[0]?.consignor_id?.name}</h4>
            {lr?.lr_list?.map((item, lrIndex) => (
              <div className="_lrx_flx" key={`fr${lrIndex}`}>
                <div className=" fright_size">
                <CommonInput
                  type="text"
                  label="Contract Description"
                  value={item?.contract_id?.name}
                  disabled
                />
              </div>
              <div className=" fright_size">
                <CommonInput
                  type="text"
                  label="Route"
                  value={`${item?.from_location} - ${item?.to_location}`}
                  disabled
                />
                </div>
                <div className=" fright_size">
                <CommonInput
                  type="text"
                  label="Invoice Number"
                  value={item?.invoice_number}
                  disabled
                />
                </div>
                <div className=" md_size">
                <CustomSelect
                  label="Freight Type"
                  value={{
                    label: lr?.contract?.rate_type.find(
                      (i) => i?.id === (lr?.contract?.chargeable_on === 2 ? item?.contract_rates?.freight_type : item?.lr_freight?.freight_type)
                    )?.name,
                    value: lr?.contract?.rate_type.find(
                      (i) => i?.id === (lr?.contract?.chargeable_on === 2 ? item?.contract_rates?.freight_type : item?.lr_freight?.freight_type)
                    )?.id,
                  }}
                  options={lr?.contract?.rate_type.map((v) => ({
                    label: v.name,
                    value: v.id,
                  }))}
                  onSelect={(data) => handleContractRateChange(consignorIndex, lrIndex, "freight_type", data?.value,lr?.contract?.chargeable_on)}
                />
                </div>
                <div className=" md_size">
                <CommonInput
                  type="text"
                  label={getFreightLabel(lr?.contract?.chargeable_on === 2 ? item?.contract_rates?.freight_type : item?.lr_freight?.freight_type)}
                  value={getFreightValue(lr?.contract?.chargeable_on, item?.contract_rates, item?.lr_freight)}
                  // disabled={isFreightDisabled(lr?.contract?.chargeable_on, item?.contract_rates, item?.lr_freight)}
                  disabled={
                    isFreightDisabled(lr?.contract?.chargeable_on, item?.contract_rates, item?.lr_freight)
                  }
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleContractRateChange(consignorIndex, lrIndex, "qty", Number(e.target?.value),lr?.contract?.chargeable_on)}
                />
                </div>
                <div className=" md_size">
                <CommonInput
                  type="text"
                  label="Rate"
                  value={lr?.contract?.chargeable_on === 2 ?  (item?.contract_rates?.rate ==0 || item?.contract_rates?.rate == null) ? "":item?.contract_rates?.rate : (item?.lr_freight?.rate == 0 || item?.lr_freight?.rate == "undefined")?"":item?.lr_freight?.rate}
                  // disabled={isFreightDisabled(lr?.contract?.chargeable_on, item?.contract_rates, item?.lr_freight)}
                  disabled={
                    isFreightDisabled(lr?.contract?.chargeable_on, item?.contract_rates, item?.lr_freight)
                  }
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleContractRateChange(consignorIndex, lrIndex, "rate", Number(e.target?.value),lr?.contract?.chargeable_on)}
                />
                </div>
                <div className=" md_size">
               { lr?.contract?.show_amount === 1 &&  <CommonInput
                  type="text"
                  label="Amount"
                  value={
                    calculateFreight(lr, item)
                  }
                  disabled={       
                      lr?.contract?.edit_amount == 1? !isFreightDisabled(lr?.contract?.chargeable_on, item?.contract_rates, item?.lr_freight):true 
                  }
                  // disabled={!isFreightDisabled(lr?.contract?.chargeable_on, item?.contract_rates, item?.lr_freight)}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleContractRateChange(consignorIndex, lrIndex, "total_freight", Number(e.target?.value),lr?.contract?.chargeable_on)}
                />}
                </div>
                <div className="smCheck">
                <Checkbox
                  type="checkbox"
                  id={item?._id}
                  isChecked={lr?.contract?.chargeable_on === 2?item?.contract_rates?.selected:item?.lr_freight?.selected}
                  // isDisabled={
                  //   lr?.contract?.chargeable_on === 2?listOfFreight[consignorIndex].lr_list.filter(
                  //     (lr) => lr.contract_rates.selected
                  //   ).length <= 1 && item?.contract_rates?.selected:listOfFreight[consignorIndex].lr_list.filter(
                  //     (lr) => lr.lr_freight.selected
                  //   ).length <= 1 && item?.lr_freight?.selected
                    
                  // }

                  isDisabled={shouldDisableCheckbox(item, consignorIndex, lr, listOfFreight)}
                  handleClick={(e) => handleContractRateChange(consignorIndex, lrIndex, "selected", e.target.checked,lr?.contract?.chargeable_on)}
                />
                </div>
              </div>
            ))}
          </div>
        )):
        <>Loadding..</>
        }
      </div>
    </CommonModal>
  );
};

export default LrFreightModal;
