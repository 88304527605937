import { getCommonDummyLrData } from '_services';
import { CustomTableNew } from 'components/CustomTable';
import { UserContext } from 'context';
import { API, axiosInstance } from 'lib';
import moment from 'moment';
import React, { useEffect, useState, useContext, useMemo } from 'react';

const Custom = () => {
    const [columns, setColumns] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [filterableColumns, setFilterableColumns] = useState([]);
    const [isMasterFilter, setIsMasterFilter] = useState(true);
    const [error, setError] = useState(true);
    const [loading, setLoading] = useState(true);

    const transformData = (data) => {
        return data.map(item => ({
            lr_number: item.lr_number,
            trip_id: item.trip_id.trip_number,
            plan_id: item.plan_id.indent_number,
            invoice_date: moment(item.invoice_date).format('DD MMM YYYY, hh:mm A'),
            dispatch_date: moment(item.trip_id.dispatch_date).format('DD MMM YYYY, hh:mm A'),
            consignor_id: item.consignor_id.name,
            branch_id: item.branch_id.branch,
            from_location: item.from_location,
            to_location: item.to_location,
            invoice_number: item.invoice_number,
            sap_invoice_number: item.sap_invoice_number,
            gst_number: item.gst_number,
            po_number: item.po_number,
            mlr_number: item.mlr_number,
            lr_status: item.lr_status,
            pod_status: item.pod_status,
            created_by: item.created_by,
            eway_bill_number: item.eway_bill_number,
            eway_bill_date: moment(item.eway_bill_date).format('DD MMM YYYY, hh:mm A'),
            eway_expiry_date: moment(item.eway_expiry_date).format('DD MMM YYYY, hh:mm A'),
            eway_bill_status: item.eway_bill_status,
            total_qty: item.total_qty,
            total_kot: item.total_kot,
            total_weight: item.total_weight
        }));
    };

    const getData = async () => {
        let postData = {}
        try {
            // let response = await getCommonDummyLrData(postData);
            let response = {
                "lr_columns": [
                    {
                        "Header": "LR No",
                        "accessor": "lr_number",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "Trip No",
                        "accessor": "trip_id",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "Indent No",
                        "accessor": "plan_id",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "LR Date",
                        "accessor": "invoice_date",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "Dispatch Date",
                        "accessor": "dispatch_date",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "Party",
                        "accessor": "consignor_id",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "Branch",
                        "accessor": "branch_id",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "From Station",
                        "accessor": "from_location",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "To Station",
                        "accessor": "to_location",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "Invoice No",
                        "accessor": "invoice_number",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "SAP Invoice No",
                        "accessor": "sap_invoice_number",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "GST No",
                        "accessor": "gst_number",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "PO No",
                        "accessor": "po_number",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "MLR No",
                        "accessor": "mlr_number",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "Status",
                        "accessor": "lr_status",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "POD Status",
                        "accessor": "pod_status",
                        "view": 1,
                        "lock": 0
                    },
                    {
                        "Header": "Added By",
                        "accessor": "created_by",
                        "view": 1,
                        "lock": 1
                    },
                    {
                        "Header": "Eway Bill Number",
                        "accessor": "eway_bill_number",
                        "view": 1,
                        "lock": 1
                    },
                    {
                        "Header": "Eway Bill Start Date",
                        "accessor": "eway_bill_date",
                        "view": 1,
                        "lock": 1
                    },
                    {
                        "Header": "Eway Bill Expiry Date",
                        "accessor": "eway_expiry_date",
                        "view": 1,
                        "lock": 1
                    },
                    {
                        "Header": "Eway Bill Status",
                        "accessor": "eway_bill_status",
                        "view": 1,
                        "lock": 1
                    },
                    {
                        "Header": "Total QTY",
                        "accessor": "total_qty",
                        "view": 1,
                        "lock": 1
                    },
                    {
                        "Header": "Total KOT",
                        "accessor": "total_kot",
                        "view": 1,
                        "lock": 1
                    },
                    {
                        "Header": "Total Weight",
                        "accessor": "total_weight",
                        "view": 1,
                        "lock": 1
                    }
                ],
                "lr_list": [
                    {
                        "_id": "6672c2e8a1a89c08196e6da7",
                        "branch_id": {
                            "_id": "666ab2e693a15ef0aa4d6f46",
                            "branch": "Indore"
                        },
                        "trip_id": {
                            "_id": "6672bf7410b043b1bc9d8b56",
                            "trip_number": "123456789",
                            "dispatch_date": "2024-06-19T00:00:00.000Z"
                        },
                        "plan_id": {
                            "_id": "6672bf7410b043b1bc9d8b56",
                            "indent_number": "1111111"
                        },
                        "invoice_date": "2024-06-19T00:00:00.000Z",
                        "invoice_time": "",
                        "consignor_id": {
                            "_id": "6672bf7410b043b1bc9d8b56",
                            "name": "Parle1"
                        },
                        "from_location": "Indore",
                        "from_state": "Madhya Pradesh",
                        "to_location": "Dewas",
                        "to_state": "Madhya Pradesh",
                        "unit_id": {
                            "_id": "6672bf7410b043b1bc9d8b58",
                            "name": "Parle Transport1",
                            "unit_code": "CR11-2024"
                        },
                        "consignee_id": {
                            "_id": "6672c271a1a89c08196e6d11",
                            "name": "Teat1",
                            "consignee_code": "CN11-2024"
                        },
                        "invoice_number": "525252552422",
                        "sap_invoice_number": "1683515",
                        "gst_number": "51684616",
                        "gst_invoice_number": "51686461",
                        "po_number": "564684",
                        "mlr_number": "B1-2024-10",
                        "eway_bill_number": null,
                        "eway_bill_date": "2024-06-19T00:00:00.000Z",
                        "eway_expiry_date": "2024-06-19T00:00:00.000Z",
                        "eway_bill_status": 0,
                        "remarks": "",
                        "is_deleted": 0,
                        "lr_number": "B1-2024-10",
                        "lr_status": 1,
                        "pod_status": 1,
                        "company_id": "666ab2e693a15ef0aa4d6f43",
                        "created_by": "666ab28d93a15ef0aa4d6f09",
                        "updated_by": null,
                        "lr_material_list": [
                            {
                                "name": "Britania",
                                "material_code": "",
                                "hsn_code": null,
                                "material_category_id": null,
                                "qty": 10,
                                "qty_type": 1,
                                "weight": 25,
                                "weight_type": 1,
                                "kot": 3,
                                "amount": null
                            }
                        ],
                        "freight_type": 1,
                        "total_qty": 10,
                        "total_kot": 10,
                        "total_weight": 10,
                        "rate": 10,
                        "total_amount": 10,
                        "lr_next_process": "intransit",
                        "lr_process": [
                            {
                                "type": "pending",
                                "status": 1,
                                "name": "Pending",
                                "date": "2024-06-19T00:00:00.000Z",
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "intransit",
                                "status": 0,
                                "name": "Intransit",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "party_point",
                                "status": 0,
                                "name": "Party Point",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "delivered",
                                "status": 0,
                                "name": "Delivered",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "bill_raised",
                                "status": 0,
                                "name": "Bill Raised",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            }
                        ],
                        "createdAt": "2024-06-19T11:37:12.281Z",
                        "updatedAt": "2024-06-19T11:37:12.281Z"
                    },
                    {
                        "_id": "6672c2e8a1a89c08196e6da7",
                        "branch_id": {
                            "_id": "666ab2e693a15ef0aa4d6f46",
                            "branch": "Bhopal"
                        },
                        "trip_id": {
                            "_id": "6672bf7410b043b1bc9d8b56",
                            "trip_number": "654987654",
                            "dispatch_date": "2024-06-19T00:00:00.000Z"
                        },
                        "plan_id": {
                            "_id": "6672bf7410b043b1bc9d8b56",
                            "indent_number": "22222222"
                        },
                        "invoice_date": "2024-06-19T00:00:00.000Z",
                        "invoice_time": "",
                        "consignor_id": {
                            "_id": "6672bf7410b043b1bc9d8b56",
                            "name": "Parle2"
                        },
                        "from_location": "Mumbai",
                        "from_state": "Maharashtra",
                        "to_location": "Chennai",
                        "to_state": "Tamil Nadu",
                        "unit_id": {
                            "_id": "6672bf7410b043b1bc9d8b58",
                            "name": "Parle Transport2",
                            "unit_code": "CR12-2024"
                        },
                        "consignee_id": {
                            "_id": "6672c271a1a89c08196e6d11",
                            "name": "Teat2",
                            "consignee_code": "CN12-2024"
                        },
                        "invoice_number": "8934648486",
                        "sap_invoice_number": "56467456",
                        "gst_number": "46456456456",
                        "gst_invoice_number": "46456456",
                        "po_number": "5646456456",
                        "mlr_number": "B1-2024-10",
                        "eway_bill_number": null,
                        "eway_bill_date": "2024-06-19T00:00:00.000Z",
                        "eway_expiry_date": "2024-06-19T00:00:00.000Z",
                        "eway_bill_status": 0,
                        "remarks": "",
                        "is_deleted": 0,
                        "lr_number": "B1-2024-10",
                        "lr_status": 1,
                        "pod_status": 1,
                        "company_id": "666ab2e693a15ef0aa4d6f43",
                        "created_by": "666ab28d93a15ef0aa4d6f09",
                        "updated_by": null,
                        "lr_material_list": [
                            {
                                "name": "Britania",
                                "material_code": "",
                                "hsn_code": null,
                                "material_category_id": null,
                                "qty": 10,
                                "qty_type": 1,
                                "weight": 25,
                                "weight_type": 1,
                                "kot": 3,
                                "amount": null
                            }
                        ],
                        "freight_type": 1,
                        "total_qty": 20,
                        "total_kot": 20,
                        "total_weight": 20,
                        "rate": 20,
                        "total_amount": 20,
                        "lr_next_process": "intransit",
                        "lr_process": [
                            {
                                "type": "pending",
                                "status": 1,
                                "name": "Pending",
                                "date": "2024-06-19T00:00:00.000Z",
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "intransit",
                                "status": 0,
                                "name": "Intransit",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "party_point",
                                "status": 0,
                                "name": "Party Point",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "delivered",
                                "status": 0,
                                "name": "Delivered",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "bill_raised",
                                "status": 0,
                                "name": "Bill Raised",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            }
                        ],
                        "createdAt": "2024-06-19T11:37:12.281Z",
                        "updatedAt": "2024-06-19T11:37:12.281Z"
                    },
                    {
                        "_id": "6672c2e8a1a89c08196e6da7",
                        "branch_id": {
                            "_id": "666ab2e693a15ef0aa4d6f46",
                            "branch": "Dewas"
                        },
                        "trip_id": {
                            "_id": "6672bf7410b043b1bc9d8b56",
                            "trip_number": "5464654",
                            "dispatch_date": "2024-06-19T00:00:00.000Z"
                        },
                        "plan_id": {
                            "_id": "6672bf7410b043b1bc9d8b56",
                            "indent_number": "963852741"
                        },
                        "invoice_date": "2024-06-19T00:00:00.000Z",
                        "invoice_time": "",
                        "consignor_id": {
                            "_id": "6672bf7410b043b1bc9d8b56",
                            "name": "Parle3"
                        },
                        "from_location": "Jalgaon",
                        "from_state": "Maharashtra",
                        "to_location": "Madhurai",
                        "to_state": "Tamil Nadu",
                        "unit_id": {
                            "_id": "6672bf7410b043b1bc9d8b58",
                            "name": "Parle Transport3",
                            "unit_code": "CR13-2024"
                        },
                        "consignee_id": {
                            "_id": "6672c271a1a89c08196e6d11",
                            "name": "Teat3",
                            "consignee_code": "CN13-2024"
                        },
                        "invoice_number": "86434456",
                        "sap_invoice_number": "78975645646",
                        "gst_number": "456456456",
                        "gst_invoice_number": "4567676456",
                        "po_number": "456467686",
                        "mlr_number": "B1-2024-10",
                        "eway_bill_number": null,
                        "eway_bill_date": "2024-06-19T00:00:00.000Z",
                        "eway_expiry_date": "2024-06-19T00:00:00.000Z",
                        "eway_bill_status": 0,
                        "remarks": "",
                        "is_deleted": 0,
                        "lr_number": "B1-2024-10",
                        "lr_status": 1,
                        "pod_status": 1,
                        "company_id": "666ab2e693a15ef0aa4d6f43",
                        "created_by": "666ab28d93a15ef0aa4d6f09",
                        "updated_by": null,
                        "lr_material_list": [
                            {
                                "name": "Britania",
                                "material_code": "",
                                "hsn_code": null,
                                "material_category_id": null,
                                "qty": 10,
                                "qty_type": 1,
                                "weight": 25,
                                "weight_type": 1,
                                "kot": 3,
                                "amount": null
                            }
                        ],
                        "freight_type": 1,
                        "total_qty": 30,
                        "total_kot": 30,
                        "total_weight": 30,
                        "rate": 30,
                        "total_amount": 30,
                        "lr_next_process": "intransit",
                        "lr_process": [
                            {
                                "type": "pending",
                                "status": 1,
                                "name": "Pending",
                                "date": "2024-06-19T00:00:00.000Z",
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "intransit",
                                "status": 0,
                                "name": "Intransit",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "party_point",
                                "status": 0,
                                "name": "Party Point",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "delivered",
                                "status": 0,
                                "name": "Delivered",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            },
                            {
                                "type": "bill_raised",
                                "status": 0,
                                "name": "Bill Raised",
                                "date": null,
                                "vehicle_no": "",
                                "vehicle_ownership": "",
                                "lorry_type": ""
                            }
                        ],
                        "createdAt": "2024-06-19T11:37:12.281Z",
                        "updatedAt": "2024-06-19T11:37:12.281Z"
                    }
                ]
            }
            setColumns(response.lr_columns);
            setApiData(transformData(response.lr_list));
            setFilterableColumns(["lr_number", "plan_id"]);
            setIsMasterFilter(true);
            // setFilterableColumns(response.filterableColumns);
            // setIsMasterFilter(response.isMasterFilter);
        } catch (error) {
            console.error('Error fetching master data:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {

        getData()
        // let responseData = {
        //     "columns": [
        //         { "Header": "ID", "accessor": "id" },
        //         { "Header": "Name", "accessor": "name" },
        //         { "Header": "Age", "accessor": "age" },
        //         { "Header": "Location", "accessor": "location" }
        //     ],
        //     "data": [
        //         { "id": 1, "name": "John Doe", "age": 28, "location": "New York" },
        //         { "id": 2, "name": "Jane Smith", "age": 32, "location": "San Francisco" },
        //         { "id": 3, "name": "Michael Johnson", "age": 25, "location": "Chicago" },
        //         { "id": 4, "name": "Emily Davis", "age": 30, "location": "Los Angeles" }
        //     ],
        //     "filterableColumns": ["name", "age", "location"],
        //     "isMasterFilter": true
        // }

        // setColumns(responseData.columns);
        // setApiData(responseData.data);
        // setFilterableColumns(responseData.filterableColumns);
        // setIsMasterFilter(responseData.isMasterFilter);


    }, [])

    return (
        <div className='container-fluid'>
            <CustomTableNew columns={columns} data={apiData} filterableColumns={filterableColumns} isMasterFilter={isMasterFilter} />
        </div>
    );
};

export default Custom;
