import { API, axiosInstance } from "lib";


export const addIndentApi = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_INDENT , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getMaterialApi = (data = {}) => {
    return axiosInstance
      .post(API.MATERIAL_LIST , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };

  export const indentListApi = (data) => {
    return axiosInstance
      .post(API.INDENT_LIST , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  }



  export const indentCountApi = (data) => {
    return axiosInstance
      .post(API.INDENT_COUNT , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  }


 