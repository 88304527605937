import { CommonInput, CommonModal, CustomSelect } from 'components'
import moment from 'moment';
import { Checkbox } from 'pages/User/AddUser';
import React from 'react'
import StorageService from 'utils/StorageServices/StorageServices';

const MakePaymentModal = (props) => {

    const initiFrom = {
        category: "",
        sub_category: "",
        expense_category: "",
        amount: "",
        txn_date: moment(new Date()).format("YYYY-MM-DD"),
        account_category: "",
        account_sub_category: "",
        account_id: "",
        description: "",
        vendor_type: "",
        vendor_account: "",
        lr_id: "",
        trip_id: props?.tripId,
        branch_id: "",
        company_id: StorageService.getCompanyId(),
        vehicle_id: "",
        file: [],
      };

    let propsMakePayment = {
        ...props
    };

    const arryOptions = [
        { label: 'Arjun', value: "01" },
        { label: 'Anvit', value: "02" },
    ]


    return (
        <>
            <CommonModal {...propsMakePayment}>
                <div className="addFreight_body">
                    <div className='add_inrFr pt-3'>
                        <div className='_allDflx'>
                            <div>
                                <CommonInput
                                    label="Amount"
                                />
                            </div>

                            <div>
                                <CommonInput
                                    label="Transaction Date"
                                    type="date"
                                />
                            </div>

                            <div>
                                <CommonInput
                                    label="Transaction ID"
                                />
                            </div>

                            <div>
                                <div className="_payMode_hed">
                                    <h4>Payment Mode</h4>
                                    <span className='_baseSpan _bspDisable'>Cash</span>
                                    <span className='_baseSpan '>Bank Transfer</span>
                                </div>
                            </div>

                            <div>
                                <CustomSelect
                                    label="Select Account"
                                    options={arryOptions}
                                />
                            </div>

                            <div>
                                <Checkbox
                                    name="Select Supplier Bank Account"
                                    id='test'
                                    index='test'
                                    isChecked={true}
                                />

                                <div className='mt-2'>
                                    <CustomSelect
                                        label="Select Supplier Account"
                                        options={arryOptions}
                                    />
                                </div>
                            </div>

                            <div className="_attatchmentBxs">
                                <h4>Attachment</h4>

                                <div className="_athBxs">
                                    <input type="file" id="_fileUpload" />
                                    <label htmlFor="_fileUpload">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="74"
                                            height="74"
                                            viewBox="0 0 74 74"
                                            fill="none"
                                        >
                                            <rect
                                                x="0.496242"
                                                y="0.496242"
                                                width="72.4514"
                                                height="72.4514"
                                                rx="3.4737"
                                                fill="#E1EDFF"
                                                stroke="#0066FF"
                                                strokeWidth="0.992484"
                                            />
                                            <path
                                                opacity="0.8"
                                                d="M34.9154 44.1094V28.782H37.965V44.1094H34.9154ZM28.7822 37.9649V34.9153H44.1096V37.9649H28.7822Z"
                                                fill="#0066FF"
                                            />
                                        </svg>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonModal>
        </>
    )
}

export default MakePaymentModal