import React, { useState, useRef } from 'react';
import { ButtonHeading } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import UnitDetailsTab from 'components/MastersDetailsTabs/UnitDetailsTabs/UnitDetailsTab';
import LoadingBar from 'react-top-loading-bar';

const UnitDetails = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [detailsData, setDetailsData] = useState({});
    const loadingBarRef = useRef(null); // Create a ref for the loading bar

    const handleDetailsData = (data) => {
        setDetailsData(data);
    };

    const tabProps = {
        tabList: constant.unitDetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    };

    return (
        <div className=''>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                loaderSpeed={1000}
                shadow={true}
            />
            <ButtonHeading title={detailsData?.name} buttonTitle="Edit" />

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && (
                    <UnitDetailsTab
                        onDataFetch={handleDetailsData}
                        loadingBarRef={loadingBarRef} // Pass the ref to the child component
                    />
                )}
            </div>
        </div>
    );
}

export default UnitDetails;
