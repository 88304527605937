import { createChartOfAccount, getDropDownChartOfAccount } from '_services/accountingServices/accountingServices'
import { CommonCheckbox, CommonInput, CommonModal } from 'components'
import CustomSelectAccount from 'components/CustomSelect/CustomSelectAccount'
import { UserContext } from 'context'
import { errorSchema, utils } from 'helper'
import React, { useContext, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import StorageService from "utils/StorageServices/StorageServices";

const formObj = {
    company_id: StorageService.getCompanyId(),
    category_id: '',
    sub_category_id: '',
    name: '',
    code: '',
    desc: '',
    is_attachment: 0,
    parant_account_value: 0,
    parent_account_id: '',
}

const AddChartAccountModal = (props) => {

    const { masterData, cities, states } = useContext(UserContext)
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [dropDownAccountType, setDropDownAccountType] = useState([])

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addEditChartOfAccountSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async () => {
        try {
            let object = {
                ...formValue,
            }
            const res = await createChartOfAccount(object);
            if (!!res) {
                setFormValue({});
                setLoading(false);
                toast.success('Account Created Successfully');
                props.closeModal(); // Close the modal here
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }
    
    const handleClick = async (e) => {
        console.log("hello formVAlue", formValue)
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.addEditChartOfAccountSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            apiCallingFunction()
            setFormError("");
        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    };
    
    // Pass closeModal as a prop to CommonModal
    const propsChartOfAccount = {
        ...props,
        onSubmit: handleClick,
        closeModal: props.closeModal 
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const onhandleSelect = async (item, name) => {
        // console.log('Selected Option:', item.option);
        // console.log('Selected Sub-Category:', item.sub);
        const stateObj = {
            ...formValue,
            category_id: item.option.value,
            sub_category_id: item.sub.subValue,
            parent_account_id: item.option.value,
        };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addEditChartOfAccountSchema);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }

    };

    const checkboxHandler = (event, name) => {
        let value = event.target.checked ? 1 : 0;
        setFormValue((s) => ({ ...s, [name]: value }))
    }


    const callDropdownChartOfAccount = async () => {
        try {
            let data = { company_id: StorageService.getCompanyId() }
            const res = await getDropDownChartOfAccount(data);
            if (!!res) {
                setDropDownAccountType(res?.data?.chart_of_account);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        callDropdownChartOfAccount()
    }, [props])

    return (
        <>
            <CommonModal {...propsChartOfAccount}>
                <div className="addLr_body">
                    <div className='_allDflx'>
                        <div>
                            <CustomSelectAccount
                                options={dropDownAccountType?.length > 0 ? dropDownAccountType?.map((el) => {
                                    return {
                                        label: el?.name,
                                        value: el?.id,
                                        sub_categories: el?.sub_category?.map(subCat => ({
                                            subLabel: subCat?.name,
                                            subValue: subCat?.id
                                        }))
                                    };
                                }) : []}
                                name="category_id"
                                flotingLabel="Account Type"
                                value={formValue?.category_id}
                                error={formError["category_id.label"]}
                                onSelect={(data) => onhandleSelect(data, 'category_id')}
                                placeholder="Select Account Type"
                            />
                        </div>

                        <div>
                            <CommonInput
                                type="text"
                                placeholder="Account Name"
                                label="Account Name *"
                                name='name'
                                value={formValue.name}
                                focus={!!(typeof formError === "object" && formError?.name)}
                                error={!!(typeof formError === "object") ? formError?.name : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div>
                            <CommonCheckbox
                                className="_smallLab"
                                name="Make this a sub-account"
                                id="parant_account_value"
                                isChecked={formValue?.parant_account_value === 1}
                                handleClick={(e) => checkboxHandler(e, 'parant_account_value')}
                            />
                            {
                                formValue?.parant_account_value === 1 && (
                                    <div className='mt-2'>
                                        <CommonInput
                                            type="text"
                                            placeholder="Parent Account"
                                            label="Parent Account *"
                                            name='parent_account_id'
                                            value={formValue.parent_account_id}
                                            focus={!!(typeof formError === "object" && formError?.parent_account_id)}
                                            error={!!(typeof formError === "object") ? formError?.parent_account_id : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, value)
                                            }
                                        />
                                    </div>
                                )
                            }
                        </div>

                        <div>
                            <CommonInput
                                type="text"
                                placeholder="Account Code"
                                label="Account Code * "
                                name='code'
                                value={formValue.code}
                                focus={!!(typeof formError === "object" && formError?.code)}
                                error={!!(typeof formError === "object") ? formError?.code : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div>
                            <CommonInput
                                type="text"
                                placeholder="Description"
                                label="Description *"
                                name='desc'
                                value={formValue.desc}
                                focus={!!(typeof formError === "object" && formError?.desc)}
                                error={!!(typeof formError === "object") ? formError?.desc : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div>
                            <CommonCheckbox
                                className="_smallLab"
                                name="Attachment Mandatory"
                                id="is_attachment"
                                isChecked={formValue?.is_attachment === 1}
                                handleClick={(e) => checkboxHandler(e, 'is_attachment')}
                            />
                        </div>
                    </div>
                </div>
            </CommonModal>
        </>
    )
}

export default AddChartAccountModal