import React from 'react'

const CommonCanvas = ({
    isOpen,
    closeModal,
    modalTitle = "",
    submitTitle = "",
    cancleTitle = "",
    children,
    isLoadding = false,
    onSubmit,
    disabled = false,
    modalMainClass,
    headerPart = false,
    footerPart = false,
    modalZIndex,
    cancelButtonTitle='Cancel'
}) => {
  return (
    <div>
    <div
      className={`offcanvas offcanvas-end common_offCanvas ${isOpen ? 'show' : ''}`}
      tabIndex={-1}
      style={{ visibility: isOpen ? 'visible' : 'hidden' }}
    >
      {isOpen &&

        (<>
          <div className="offcanvas-header">
            <h5 className="offcanvas-title"> {modalTitle} </h5>
            <button type="button" className="btn-close" onClick={closeModal}></button>
          </div>

          {children}
          {!footerPart?<div className='offcanvas-footer'>
                <button className=' _submitOn_modal' disabled={disabled} onClick={onSubmit}>{submitTitle}</button>
                
              </div>:
              <div className='offcanvas-footer'>
                <button className=' _submitOn_modal ' disabled={disabled} onClick={()=>onSubmit(1)}>Approval</button>
                <button className=' _submitOn_modal ' disabled={disabled} onClick={()=>onSubmit(0)}>Save</button>
              </div>}
        </>
        )
      }
    </div>
    <div className={isOpen ? 'offBack_drop' : ''}></div>
  </div>
  )
}

export default CommonCanvas