import React, { useContext, useEffect, useRef, useState } from 'react'
import { getLedgerStatementData } from '_services/accountsServices/ledgerStatementServices';
import { ButtonHeading, CommonDateRange, CommonInput, NavTabs } from 'components'
import { UserContext } from 'context';
import { utils } from 'helper';
import { useParams } from 'react-router-dom'
import StorageService from 'utils/StorageServices/StorageServices';
import LoadingBar from 'react-top-loading-bar';
import moment from 'moment';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const Ledger = (props) => {
    let runing_balance = 0;
    let { id } = useParams();
    const loadingBarRef = useRef(null);
    let { type } = props;
    const { masterData, cities, states } = useContext(UserContext)
    const [ledgerStatementData, setLedgerStatementData] = useState([]);
    const [accountDetails, setAccountDetails] = useState({});
    const [openingBalance, setOpeningBalance] = useState(0)
    const [selectedRange, setSelectedRange] = useState({
        startDate: moment(new Date()).subtract(32, "days"),
        endDate: moment(new Date()),
    });

    const itemsPerPage = 100;
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState({
        limit: 100,
        offset: 1,
        // status: 1,
        // start_date: new Date(),
        // end_date: new Date()
    });

    const getDataLedgerStatement = async (idd) => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                ...filter,
                start_date: moment(selectedRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(selectedRange.endDate).format("YYYY-MM-DD"),
                company_id: StorageService.getCompanyId(),
                vendor_type: type,
                vendor_id: idd,
            }
            const res = await getLedgerStatementData(object);
            if (res?.responseCode == 200) {
                setAccountDetails(res?.data?.account_detail);
                setLedgerStatementData(res?.data?.account_statement);
                setOpeningBalance(res?.data?.opening_balance)
                setTotalItems(res?.data?.total_record);
            }
        } catch (error) {
            throw error;
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    useEffect(() => {
        getDataLedgerStatement(id);
    }, [id, filter, selectedRange])

    const handleApply = (newRange) => {
        setSelectedRange(newRange);
        setFilter((s) => ({ ...s, offset: 1 }))
    };


    const handlePageChange = (page) => {
        setFilter((s) => ({ ...s, offset: page }))
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li
                    key={i}
                    className={`page-item ${i === filter.offset ? "active" : ""}`}
                >
                    <a className="page-link _avPointer" onClick={(e) => handlePageChange(i)}>
                        {i}
                    </a>
                </li>
            );
        }

        return (
            <ul className="pagination ">
                <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
                    <a
                        className="page-link _avPointer"
                        onClick={() => handlePageChange(filter?.offset - 1)}
                    >
                        <IoChevronBack />
                    </a>
                </li>
                {pages}
                <li
                    className={`page-item ${filter?.offset === totalPages ? "disabled" : ""
                        }`}
                >
                    <a
                        className="page-link _avPointer"
                        onClick={() => handlePageChange(filter?.offset + 1)}
                    >
                        <IoChevronForward />
                    </a>
                </li>
            </ul>
        );
    };

    const getDate = (date) => {
        if (date) {
            const today = new Date(date);
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        }
    }

    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <div>
                <div className='_rangeDate_bx'>
                    <div className='_fleX_inds'>
                        <div className='_divForth'>
                            <CommonDateRange
                                initialStartDate={selectedRange.startDate}
                                initialEndDate={selectedRange.endDate}
                                onApply={handleApply}
                            />
                        </div>
                    </div>
                </div>

                <div className='_ledgerOtr'>
                    <div className='ledger_details_main'>
                        <div className="ledger_details_div">
                            <div className="ledger_address">
                                <div>To</div>

                                <div className='ledger_company'> {StorageService?.getCompName()} </div>

                                <div className="legder_iner_address">
                                    {accountDetails?.address_line1 && accountDetails?.address_line1} {accountDetails?.city?.value && accountDetails?.city?.value} {accountDetails?.state?.label && accountDetails?.state?.label}
                                </div>
                            </div>

                            <div className="ledger_expense" >
                                <div className="expense_date" >
                                    <div className="expence_title" > Statement of Accounts </div>
                                    <span> {moment(selectedRange.startDate).format("YYYY-MM-DD")} To {moment(selectedRange.endDate).format("YYYY-MM-DD")} </span>
                                </div>
                                <div >
                                    <div className="tableForTripdetails" >
                                        <table className="table">
                                            <thead>
                                                <tr className='_headTr'>
                                                    <th>Account Summary</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="modal_td">Opening Balance</td>
                                                    <td align='right' className="modal_td">
                                                        <span className={(Math.sign(openingBalance) === 1) ? 'green_Cls' : (Math.sign(openingBalance) === -1 ? 'red_Cls' : '')}>
                                                            {openingBalance}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="modal_td">Invoiced Amount</td>
                                                    <td align='right' className="modal_td"> 0 </td>
                                                </tr>
                                                <tr>
                                                    <td className="modal_td">Payment Received</td>
                                                    <td align='right' className="modal_td"> 0 </td>
                                                </tr>
                                                <tr>
                                                    <td className="modal_td">Balance Due</td>
                                                    <td align='right' className="modal_td"> 0 </td>
                                                </tr>
                                                <tr className="total_value"></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ledger_table_div">
                            <div className='tabFix '>
                                <div className='container_table table-responsive tabMin_hgt'>
                                    <table role='table'>
                                        <thead className='table_head_style background'>
                                            <tr>
                                                <th></th>
                                                <th><div className="dFsx">Date</div></th>
                                                <th><div className="dFsx">Voucher</div></th>
                                                <th><div className="dFsx">Category</div></th>
                                                <th><div className="dFsx">Transaction No</div></th>
                                                <th><div className="dFsx">Description</div></th>
                                                <th><div className="dFsx">Credit</div></th>
                                                <th><div className="dFsx">Debit</div></th>
                                                <th><div className="dFsx">Balance</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ledgerStatementData?.length > 0 ? ledgerStatementData?.map((el, ind) => {
                                                    runing_balance += el?.credit_amount - el?.debit_amount
                                                    return (
                                                        <tr key={ind}>
                                                            <td></td>
                                                            <td>
                                                                {el?.date ? <div className="dFsx2">
                                                                    <div> {getDate(el?.date)} </div>
                                                                    <span>{utils?.formatTime(el?.date)}</span>
                                                                </div> :
                                                                    <div className="dFsx2">
                                                                        <div> - </div>
                                                                        <span> - </span>
                                                                    </div>
                                                                }
                                                            </td>

                                                            <td>
                                                                <div className="dFsx2">
                                                                    <span className='voucher'> {el?.voucher_no ? el?.voucher_no : '-'} </span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dFsx2">
                                                                    {el?.expense_category?.name ? el?.expense_category?.name : '-'}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="dFsx2">
                                                                    {el?.transaction_no ? el?.transaction_no : '-'}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="dFsx2">
                                                                    {el?.opening_balance ? el?.opening_balance : (el?.opening_balance === 0 ? 0 : el?.description ? el?.description : '-')}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="dFsx2">
                                                                    {el?.credit_amount ? el?.credit_amount : '-'}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="dFsx2">
                                                                    {el?.debit_amount ? el?.debit_amount : '-'}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="dFsx2">
                                                                    {runing_balance ? runing_balance : 0}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) : (
                                                    <tr>
                                                        <td></td>
                                                        <td className='p-2'> No data </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='paginationFt_avt '>
                            {renderPagination()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ledger