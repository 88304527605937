import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, axiosInstance } from 'lib';

export const addroleCreate = createAsyncThunk(
    'add Role',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ADD_ROLE, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addUser = createAsyncThunk(
    'add User',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ADD_USER, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const getUserDetail = (data = {}) => {
    return axiosInstance
        .post(
            API.USER_DETAILS, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                // Handle network errors, such as a failed fetch request
                console.error('Network error:', error.message);
            } else {
                // Handle other errors, such as a bad response
                console.error('Error:', error.message);
            }
        });
  };


  export const editUser = createAsyncThunk(
    'edit User',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.EDIT_USER, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);
  
