import { fetchBranchDetailsData } from '_services/accountsServices/branchServices';
import { CommonInput } from 'components';
import { UserContext } from 'context';
import { utils } from 'helper';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import StorageService from "utils/StorageServices/StorageServices";

const BranchDetailsTab = ({ onDataFetch }) => {

    let { id } = useParams();
    const { masterData } = useContext(UserContext)
    const [detailsData, setDetailsData] = useState({})
    const [loading, setLoading] = useState(false);
    const companyId = StorageService.getCompanyId();

    const getDataBranchDetails = async (id_1, id_2) => {
        try {
            let object = {
                branch_id: id_1,
                company_id: id_2,
            }
            const res = await fetchBranchDetailsData(object);
            if (res?.responseCode == 200) {
                setDetailsData(res?.data?.branch)
                onDataFetch(res?.data?.branch)
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // throw error;
        }
    }

    useEffect(() => {
        if (id) {
            setLoading(true);
            getDataBranchDetails(id, companyId);
        }
    }, [id])

    return (
        <div>
            {
                loading ? <div className='p-5'> Loading... </div> : !detailsData ? (
                    <div className='p-5'> No Data Found </div>) : (
                    <div className='_addContainer2 '>
                        <div className='_ptyDetail_bx'>
                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'>
                                    Other Details
                                </div>
                                <div className='_inrPrt_flx'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            placeholder="Branch Name"
                                            label="Branch Name *"
                                            name='name'
                                            value={detailsData?.branch}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Branch Code"
                                            placeholder="Branch Code"
                                            value={detailsData?.branch_code}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Mobile Number"
                                            placeholder="Mobile Number"
                                            value={detailsData?.contact_no}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Alternate Mobile Number"
                                            placeholder="Alternate Mobile Number"
                                            value={detailsData?.alt_contact_no}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Email ID"
                                            placeholder="Email ID"
                                            value={detailsData?.email}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Remark"
                                            placeholder="Remark"
                                            value={detailsData?.remark}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="inrPrt_bxs ">
                                <div className='_inrPrt_head'> Address </div>
                                <div className='_inrPrt_flx _min_h_auto'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Address Line 1"
                                            placeholder="Address Line 1"
                                            value={detailsData?.address_line1}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="State"
                                            placeholder="State"
                                            value={detailsData?.state?.label}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="City"
                                            placeholder="City"
                                            value={detailsData?.city?.label}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="PIN Code"
                                            placeholder="PIN Code"
                                            value={detailsData?.pincode}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'>Tax Info</div>
                                <div className='_inrPrt_flx'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="GST Type"
                                            placeholder="GST Type"
                                            value={masterData?.gst_type_list?.filter((el) => (el?.id == detailsData?.gst_type))[0]?.name}
                                            disabled
                                        />
                                    </div>

                                    {
                                        detailsData?.gst_no ? (
                                            <div className='_divForth'>
                                                <CommonInput
                                                    type="text"
                                                    label="GST Number"
                                                    placeholder="GST Number"
                                                    value={detailsData?.gst_no}
                                                    disabled
                                                />
                                            </div>
                                        ) : <></>
                                    }

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="State State"
                                            placeholder="State State"
                                            value={detailsData?.gst_state?.label}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default BranchDetailsTab