import { Images } from "constants";
import React from "react";

const Loading = () => {
    return <>
    <div className="loaderBg">
            <img className="_loadWidth" src={Images.LOADINGTRUCK} alt="" />
        </div>
    </>
};

export default Loading;
