import { getCompanyList, getDashbordData } from "_services";
import { UserContext } from "context";
import { CreateCompany } from "modals";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import StorageService from "utils/StorageServices/StorageServices";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Compcanvas = ({ handleCompanyName, setOpen }) => {
  const { setShowCreateCompanyModal, userData,setGlobCompanyId } = React.useContext(UserContext);
const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const company_id = StorageService.getCompanyId();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [company, setCompany] = useState();

  useEffect(() => {
    setCompany(company_id);
  }, [company_id]);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = show ? "hidden" : "auto";
  }, [show]);

  useEffect(() => {
    setFilteredItems(userData?.user_detail?.company_list);
  }, [userData]);

  // Debounced function to handle the search input change
  const debouncedHandleSearch = useMemo(
    () =>
      debounce((term) => {
        if (term === "") {
          setFilteredItems(userData?.user_detail?.company_list);
        } else {
          const filtered = userData?.user_detail?.company_list.filter((item) =>
            item?.company_id?.company.toLowerCase().includes(term.toLowerCase())
          );
          setFilteredItems(filtered);
        }
      }, 300),
    [userData?.user_detail?.company_list]
  );

  const handleSearch = useCallback(
    (event) => {
      const term = event.target.value;
      setSearchTerm(term);
      debouncedHandleSearch(term);
    },
    [debouncedHandleSearch]
  );

  const handlechangeComany = (item) => {
    StorageService.setCompanyId(item?.company_id?._id);
    handleCompanyName(item?.company_id?._id);
    setCompany(item?.company_id?._id);
    setGlobCompanyId(item?.company_id?._id)
    navigate("/dashboard")
    toast.success(`Change Into Company " ${item.company_id.company.toUpperCase()} "`);
  };

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex={-1}
      data-bs-backdrop="false"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="adminlist">
        <div className="adjBackdrop "></div>
        <div className="sideContent">
          <div className="upperSc_head">
            <div>
              <h2>Company List</h2>
            </div>
            <div className="manageBox">
              <div className="settingsLink">
                <button
                  type="button"
                  className="ofAdd_compBtn"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => setShowCreateCompanyModal(true)}
                >
                  Add Company
                </button>
              </div>
              <div className="crossBtn">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => setOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      d="M8.98062 0.210388C8.91617 0.145811 8.83962 0.0945774 8.75535 0.0596208C8.67108 0.0246643 8.58075 0.00667089 8.48951 0.00667089C8.39828 0.00667089 8.30794 0.0246643 8.22367 0.0596208C8.1394 0.0945774 8.06285 0.145811 7.99841 0.210388L4.59202 3.60981L1.18563 0.203422C1.12114 0.138929 1.04458 0.0877708 0.960312 0.0528676C0.876048 0.0179643 0.785734 6.79542e-10 0.694528 0C0.603321 -6.79543e-10 0.513007 0.0179643 0.428743 0.0528676C0.344479 0.0877708 0.267915 0.138929 0.203422 0.203422C0.138929 0.267915 0.0877708 0.344479 0.0528676 0.428743C0.0179643 0.513007 -6.79542e-10 0.603321 0 0.694528C6.79543e-10 0.785734 0.0179643 0.876048 0.0528676 0.960312C0.0877708 1.04458 0.138929 1.12114 0.203422 1.18563L3.60981 4.59202L0.203422 7.99841C0.138929 8.0629 0.0877708 8.13947 0.0528676 8.22373C0.0179643 8.30799 0 8.39831 0 8.48951C0 8.58072 0.0179643 8.67103 0.0528676 8.7553C0.0877708 8.83956 0.138929 8.91613 0.203422 8.98062C0.267915 9.04511 0.344479 9.09627 0.428743 9.13117C0.513007 9.16608 0.603321 9.18404 0.694528 9.18404C0.785734 9.18404 0.876048 9.16608 0.960312 9.13117C1.04458 9.09627 1.12114 9.04511 1.18563 8.98062L4.59202 5.57423L7.99841 8.98062C8.0629 9.04511 8.13947 9.09627 8.22373 9.13117C8.30799 9.16608 8.39831 9.18404 8.48951 9.18404C8.58072 9.18404 8.67103 9.16608 8.7553 9.13117C8.83956 9.09627 8.91613 9.04511 8.98062 8.98062C9.04511 8.91613 9.09627 8.83956 9.13117 8.7553C9.16608 8.67103 9.18404 8.58072 9.18404 8.48951C9.18404 8.39831 9.16608 8.30799 9.13117 8.22373C9.09627 8.13947 9.04511 8.0629 8.98062 7.99841L5.57423 4.59202L8.98062 1.18563C9.24533 0.920923 9.24533 0.475097 8.98062 0.210388Z"
                      fill=""
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="mainSc_content">
            <div className="msc_inputContain">
              <input
                type="text"
                placeholder="Search Organisation"
                value={searchTerm}
                onChange={handleSearch}
              />
              <i className="fa-solid fa-magnifying-glass"></i>
            </div>
            <div className="orgList_box">
              <ul>
                {filteredItems &&
                  filteredItems?.map((item) => {
                    return (
                      <li key={item?.company_id?._id}>
                        <button
                          className="orgList_btn"
                          type="button"
                          onClick={() =>
                            handlechangeComany(item)
                          }
                        >
                          <div className="companyAndselectIcon">
                            <div className="roleAndIcon">
                              <span className="organization-thumbnail ml-1 mr-3 rounded border-dashed align-self-start">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                  className="text-muted organization-icon"
                                >
                                  <path d="M388.1 6.5H123.9c-50.2 0-91 40.8-91 91v316.8c0 50.2 40.8 91 91 91h68.2c.9.1 1.8.2 2.7.2h122.4c.9 0 1.8-.1 2.7-.2h68.2c50.2 0 91-40.8 91-91V97.5c0-50.2-40.8-91-91-91zm-91.9 456.8h-80.4v-63.6c0-22.2 18-40.2 40.2-40.2 22.2 0 40.2 18 40.2 40.2v63.6zm140.9-49c0 27-22 49-49 49h-49.9v-63.6c0-45.3-36.9-82.2-82.2-82.2-45.3 0-82.2 36.9-82.2 82.2v63.6h-49.9c-27 0-49-22-49-49V97.5c0-27 22-49 49-49h264.2c27 0 49 22 49 49v316.8z"></path>
                                  <circle cx="193.7" cy="129" r="21"></circle>
                                  <circle cx="318.7" cy="129" r="21"></circle>
                                  <circle cx="193.3" cy="228" r="21"></circle>
                                  <circle cx="318.3" cy="228" r="21"></circle>
                                </svg>
                              </span>
                              <div className="company_role_vk">
                                <div className="align-top d-inline-block text-truncate w-75">
                                  {item?.company_id?.company}
                                </div>
                                <span>{item?.role_id?.role_name}</span>
                              </div>
                            </div>
                            {company === item?.company_id?._id && (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                  className="icon-sm float-right selected-org-icon rounded-circle float-right text-white"
                                >
                                  <path d="M503.4 141.1c-5.6-5.6-12.9-8.6-20.8-8.6s-15.3 3.1-20.8 8.6L162.1 440.7 50.2 328.8c-11.5-11.5-30.2-11.5-41.6 0-11.5 11.5-11.5 30.2 0 41.6l132.8 132.9c5.6 5.5 13 8.5 20.8 8.5 7.9 0 15.5-3.2 20.9-8.7l320.4-320.4c11.4-11.5 11.4-30.2-.1-41.6z"></path>
                                </svg>
                              </div>
                            )}
                          </div>
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compcanvas;
