import React, { useEffect, useState } from 'react'
import { errorSchema, utils } from 'helper'
import { CommonInput, CommonModal, CustomSelect } from 'components'
import StorageService from 'utils/StorageServices/StorageServices'
import { fetchConsignorDetailsData } from '_services/accountsServices/consignorServices'
import { addNewConnections } from '_services'
import toast from 'react-hot-toast';

const formObj = {
    company_id: StorageService.getCompanyId(),
    name: '',
}

const AddNewConnectionModal = (props) => {
    let {isOpen, closeModal} = props;
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addNewConnection);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async () => {
        try {
            const res = await addNewConnections(formValue);
            if (!!res) {
                closeModal(true);
                setFormValue({});
                setLoading(false);
                toast.success('Created Successfully');
            } else {
                setLoading(false);
                toast.error(`Error !!`);
            }
        } catch (error) {
            toast.error(`${error?.response?.data?.responseMessage}`);
            setLoading(false);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.addNewConnection);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            apiCallingFunction();
            setFormError("");
        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    }

    const propsLrModalInhance = {
        ...props,
        isLoadding: loading,
        disabled: loading,
        onSubmit: (e) => handleSubmit(e),
    }

    useEffect(() => {
        setFormValue((s) => ({ ...s, company_id: StorageService.getCompanyId() }))
    }, [StorageService.getCompanyId()])
    
    return (
        <div>
            <CommonModal {...propsLrModalInhance}>
                <div className='addLr_body'>
                    <CommonInput
                        type="text"
                        label="Name"
                        placeholder="Name "
                        name="name"
                        value={formValue.name}
                        focus={!!(typeof formError === "object" && formError?.name)}
                        error={!!(typeof formError === "object") ? formError?.name : ""}
                        onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                        }
                    />
                </div>
            </CommonModal>
            <div className='offBack_drop'></div>
        </div>
    )
}

export default AddNewConnectionModal
