import React, { useContext, useEffect, useState, useRef } from 'react'
import { fetchOwnDriverDetailsData } from '_services/accountsServices/ownDriverServices';
import { getSupplierDetailsData } from '_services/accountsServices/supplierServices';
import { CommonInput } from 'components';
import { UserContext } from 'context';
import { utils } from 'helper';
import { useParams } from 'react-router-dom';
import StorageService from "utils/StorageServices/StorageServices";
import LoadingBar from 'react-top-loading-bar';

const DriverDetailsTab = () => {

    let { id } = useParams();
    const loadingBarRef = useRef(null);
    const { masterData, setIsDriverName } = useContext(UserContext)
    const [detailsData, setDetailsData] = useState({})
    const [loading, setLoading] = useState(false);

    const getDataDriverDetails = async (id_1) => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                company_id: StorageService.getCompanyId(),
                driver_id: id_1,
            }
            const res = await fetchOwnDriverDetailsData(object);
            if (res?.responseCode == 200) {
                setDetailsData(res?.data?.driver)
                setIsDriverName(res?.data?.driver?.name)
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    useEffect(() => {
        if (id) {
            setLoading(true);
            getDataDriverDetails(id);
        }
    }, [id])



    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            {
                loading ? <div className='p-5'> Loading... </div> : (
                    detailsData ? (
                        < div className='_addContainer2 '>
                            <div className='_ptyDetail_bx'>
                                <div className="inrPrt_bxs">
                                    <div className='_inrPrt_head'>Basic Details</div>
                                    <div className='_inrPrt_flx'>
                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                placeholder="Driver Name"
                                                label="Driver Name *"
                                                name='name'
                                                value={detailsData?.name}
                                                disabled
                                            />
                                        </div>

                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="Mobile Number"
                                                placeholder="Mobile Number"
                                                value={detailsData?.contact_no}
                                                disabled
                                            />
                                        </div>

                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="Alternate Mobile Number"
                                                placeholder="Alternate Mobile Number"
                                                value={detailsData?.alt_contact_no}
                                                disabled
                                            />
                                        </div>

                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="Opening Balance"
                                                placeholder="Opening Balance"
                                                value={detailsData?.opening_balance}
                                                disabled
                                            />
                                        </div>
                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="As of Date"
                                                placeholder="As of Date"
                                                value={utils.formateDateCalander(detailsData?.as_of_date)}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="inrPrt_bxs ">
                                    <div className='_inrPrt_head'> Address </div>
                                    <div className='_inrPrt_flx _min_h_auto'>
                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="Address Line 1"
                                                placeholder="Address Line 1"
                                                value={detailsData?.address_line1}
                                                disabled
                                            />
                                        </div>

                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="State"
                                                placeholder="State"
                                                value={detailsData?.state?.label}
                                                disabled
                                            />
                                        </div>

                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="City"
                                                placeholder="City"
                                                value={detailsData?.city?.label}
                                                disabled
                                            />
                                        </div>

                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="PIN Code"
                                                placeholder="PIN Code"
                                                value={detailsData?.pincode}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="inrPrt_bxs">
                                    <div className='_inrPrt_head'> Other Details </div>
                                    <div className='_inrPrt_flx'>
                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="License Number"
                                                placeholder="License Number"
                                                value={detailsData?.license_number}
                                                disabled
                                            />
                                        </div>
                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="Licence Expiry"
                                                placeholder="Licence Expiry"
                                                value={utils.formateDateCalander(detailsData?.licence_expiry)}
                                                disabled
                                            />
                                        </div>
                                        <div className='_divForth'>
                                            <CommonInput
                                                type="text"
                                                label="Aadhar Number"
                                                placeholder="Aadhar Number"
                                                value={detailsData?.aadhar_no}
                                                disabled
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div> No Data found </div>
                    )
                )
            }
        </div >
    )
}

export default DriverDetailsTab