import React, { useContext, useEffect, useState } from "react";
import { AddButton, CommonInput, CustomSelect } from "components";
import { constant } from "constants/constant";
import AddMapLocationModal from "modals/AddMapLocationModal";
import { UserContext } from "context";
import StorageService from "utils/StorageServices/StorageServices";
import { errorSchema, utils } from "helper";
import { State } from "country-state-city";
import { handleKeyDown } from "_services";
import { getZoneDropdownListData } from "_services/dropdownServices/dropdownService";
import {
  createAddressBook,
  getPartyAssociateApi,
} from "_services/accountsServices/addressBookService";
import toast from "react-hot-toast";

const AddAddressBook = (props) => {
  const initi = {
    name: "",
    category: "",
    associate_party_id: "",
    contact_no: "",
    email_id: "",
    address_line1: "",
    address_line2: "",
    state: {},
    city: {},
    pincode: "",
    code: "",
    zone_id: "667c05a3775fedebe24581ba",
    company_id: StorageService.getCompanyId(),
  };

  let { isOpen, onClose } = props;
  const { masterData, cities, states } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(1);
  const [showGoogleMapModal, setShowGoogleMapModal] = useState(false);
  const [form, setForm] = useState(initi);
  const [formError, setFormError] = useState("");
  const [stateValue, setStateValue] = useState();
  const [partyCategory, setPartyCategory] = useState([]);
  const [allZoneList, setAllZoneList] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partyAssociateList, setPartyAssociateList] = useState([]);

  useEffect(() => {
    const list = masterData?.addressbook_category_list.map((item) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    });

    if (list.length) {
      setPartyCategory(list);
      setForm({ ...form, category: list[0].value });
    }
  }, [masterData]);

  useEffect(() => {
    if (form?.category) {
      getPartyAssociate(form?.category);
    }
  }, [form.category]);

  useEffect(() => {
    if (stateValue) {
      handleOnChange("state", {
        label: stateValue?.name,
        value: stateValue?.isoCode,
      });
    }
  }, [stateValue]);

  useEffect(() => {
    getZoneList();
  }, [StorageService.getCompanyId()]);

  useEffect(() => {
    if (partyAssociateList.length) {
      setForm({
        ...form,
        associate_party_id: partyAssociateList[0]._id,
      });
    }
  }, [partyAssociateList]);

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...form,
      [name]: value,
    };

    setForm(stateObj);

    if (!!formError) {
      const validationResult = await utils.checkFormError(
        { ...stateObj },
        errorSchema.addAddressBook
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleCityChange = (data) => {
    handleOnChange("city", {
      label: data?.label,
      value: data?.name,
    });
    const stateDetails = State.getStateByCodeAndCountry(
      data?.stateCode,
      data?.countryCode
    );
    setStateValue(stateDetails);
  };

  const getPartyAssociate = async (category) => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        category,
        limit: "",
        offset: "",
        search: "",
      };
      const res = await getPartyAssociateApi(object);
      if (res?.responseCode == 200) {
        const list = res?.data?.list?.map((el) => {
          return {
            ...el,
            label: el?.name,
            value: el?._id,
          };
        });
        setPartyAssociateList(list);
      }
    } catch (error) {
      throw error;
    }
  };

  const getZoneList = async () => {
    try {
      let object = { company_id: StorageService.getCompanyId() };
      const res = await getZoneDropdownListData(object);
      if (res) {
        const list = res?.data?.zone_list.map((el) => {
          return {
            ...el,
            label: el?.name,
            value: el?._id,
          };
        });
        setAllZoneList(list);
      }
    } catch (error) {
      throw error;
    }
  };

  const handleClick = async (e, action) => {
    e.preventDefault();
    const validationResult = await utils?.checkFormError(
      form,
      errorSchema.addAddressBook
    );

    if (validationResult == true) {
      setLoading(true);
      await apiCallingFunction(action);
      setFormError("");
    } else {
      setFormError(validationResult);
      setLoading(false);
    }
  };

  const handleClickContinue = async (e) => {
    e.preventDefault();

    const validationResult = await utils.checkFormError(
      { ...form },
      errorSchema.addAddressBookContinue
    );

    if (validationResult == true) {
      setActiveTab(2);
    } else {
      setFormError(validationResult);
    }
  };

  const changeActiveTab = async (event, value) => {
    event.preventDefault();
    if (value > activeTab) {
      const validationResult = await utils.checkFormError(
        form,
        errorSchema.addAddressBookContinue
      );
      if (validationResult === true) {
        setActiveTab(value);
      } else {
        setFormError(validationResult);
      }
    } else {
      setActiveTab(value);
    }
  };

  const apiCallingFunction = async (action) => {
    try {
      let object = {
        ...form,
      };

      const res = await createAddressBook(object);
      if (!!res) {
        setForm({});
        setLoading(false);
        toast.success("Address Created Successfully");
        if (action == "add") {
          onClose(); 
          clearAll();
        } else {
          setActiveTab(1);
          clearAll();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const callBackDataMap = (data) => {
  
    const { address_components: addressComponents } = data;
    const { city, state, postalCode ,country} =
      parseAddressComponents(addressComponents);
  
    if (city || state || postalCode || country) {
      setForm({
        ...form,
        city: city,
        state: state,
        address_line1: ` ${city?.label}, ${state?.label} ${postalCode}, ${country}`,
        pincode: postalCode,
      });
    }
  };

  const parseAddressComponents = (addressComponents) => {
    let city = "";
    let state = "";
    let postalCode = "";
    let country="";

    addressComponents?.map((component) => {
    
      if (component.types.includes("locality")) {
        city = { label: component.long_name, value: component?.short_name };
      } else if (component.types.includes("administrative_area_level_1")) {
        state = { label: component.long_name, value: component?.short_name };
      } else if (component.types.includes("postal_code")) {
        postalCode = component.long_name;
      }else if (component.types.includes("country")) {
        country = component.long_name;
      }
    });

    return { city, state, postalCode,country };
  };

  const clearAll = () => {
    setForm({
      ...form,
      name: "",
      category: 1,
      associate_party_id: "",
      contact_no: "",
      email_id: "",
      address_line1: "",
      address_line2: "",
      state: {},
      city: {},
      pincode: "",
      code: "",
      zone_id: "",
      company_id: StorageService.getCompanyId(),
    });
  };

  let gmapLocate = {
    isOpen: showGoogleMapModal,
    closeModal: (e) => setShowGoogleMapModal(false),
    modalMainClass: "_dashCommon_Modal _mdModal",
    modalTitle: "Select on Map",
    submitTitle: "Add Location",
    callBackDataMap: callBackDataMap,
  };

  return (
    <>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title"> Add Address </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => onClose(!isOpen)}
        ></button>
      </div>

      <div className="offcanvas-body">
        <div className="pr_procssBx">
          <div className="pr_procssBx_grid">
            <div className="singleDiv">
              <CommonInput
                type="text"
                placeholder="Address Name"
                label="Address Name"
                labelRedStar={true}
                name="name"
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name, value);
                }}
                value={form?.name}
                focus={!!(typeof formError === "object" && formError?.name)}
                error={!!(typeof formError === "object") ? formError?.name : ""}
              />
            </div>

            <div className="singleDiv">
              <CustomSelect
                // placeholder="Party"
                labelRedStar={true}
                label="Category"
                options={partyCategory}
                onSelect={(data) => {
                  handleOnChange("category", data.value);
                }}
                value={
                  form?.category
                    ? partyCategory?.find((i) => i.value == form?.category)
                    : { label: "", value: "" }
                }
                focus={!!(typeof formError === "object" && formError?.category)}
                error={
                  !!(typeof formError === "object") ? formError?.category : ""
                }
              />
            </div>

            <div className="singleDiv">
              <CustomSelect
                placeholder="All Party"
                label="Associate Party"
                options={partyAssociateList}
                onSelect={(data) => {
                  handleOnChange("associate_party_id", data.value);
                }}
                value={
                  form?.associate_party_id
                    ? partyAssociateList?.find(
                        (i) => i.value == form?.associate_party_id
                      )
                    : { label: "", value: "" }
                }
                focus={
                  !!(
                    typeof formError === "object" &&
                    formError?.associate_party_id
                  )
                }
                error={
                  !!(typeof formError === "object")
                    ? formError?.associate_party_id
                    : ""
                }
              />
            </div>
          </div>
        </div>

        <div className="addPr_flBx">
          <ul className="nav nav-tabs cstNew_tabs" id="addParty" role="tablist">
            {constant.addAddressBookTabs?.map((item, indx) => (
              <li
                className="nav-item"
                role="presentation"
                key={indx + item?.value}
              >
                <button
                  className={
                    "nav-link " + (activeTab === item?.id ? "active" : "")
                  }
                  type="button"
                  aria-selected={activeTab === item?.id}
                  onClick={(e, val) => changeActiveTab(e, item.id)}
                >
                  {item?.title}
                </button>
              </li>
            ))}
          </ul>

          {activeTab === 1 && (
            <div className="pr_procssBx" id="addPartyContent">
              <div className="pr_procssBx_grid">
                <div className="singleDiv">
                  <button
                    className="gogLocation_btn"
                    onClick={() => setShowGoogleMapModal(true)}
                  >
                    <div>
                      Select From Google <span className="valId">*</span>
                    </div>
                  </button>
                </div>

                <div className="singleDiv">
                  <div className="h4Head">Add Address</div>
                </div>

                <div className="singleDiv">
                  <CommonInput
                    type="text"
                    placeholder="Address"
                    label="Address Line 1"
                    name="address_line1"
                    value={form.address_line1}
                    focus={
                      !!(
                        typeof formError === "object" &&
                        formError?.address_line1
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.address_line1
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>

                <div className="singleDiv">
                  <CustomSelect
                    options={cities}
                    onSelect={(data) => handleCityChange(data, "city")}
                    value={form?.city}
                    placeholder="Select a city"
                    error={formError["city.label"]}
                    label="City"
                  />
                </div>

                <div className="halfDiv">
                  <CommonInput
                    type="text"
                    placeholder="State"
                    label="State"
                    name="state"
                    disabled={true}
                    value={form?.state?.label}
                    focus={
                      !!(
                        typeof formError === "object" &&
                        formError["state.label"]
                      )
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError["state.label"]
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>

                <div className="halfDiv">
                  <CommonInput
                    type="text"
                    placeholder="PIN Code"
                    label="PIN Code"
                    name="pincode"
                    maxLength={6}
                    onKeyDown={handleKeyDown}
                    value={form.pincode}
                    focus={
                      !!(typeof formError === "object" && formError?.pincode)
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.pincode
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>

                <div className="singleDiv">
                  <div className="h4Head">Other Contact</div>
                </div>

                <div className="singleDiv">
                  <CommonInput
                    type="text"
                    placeholder="Contact Number"
                    label="Contact Number"
                    name="contact_no"
                    maxLength={10}
                    onKeyDown={handleKeyDown}
                    value={form.contact_no}
                    focus={
                      !!(typeof formError === "object" && formError?.contact_no)
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.contact_no
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>

                <div className="singleDiv">
                  <CommonInput
                    type="Email"
                    placeholder="Email ID"
                    label="Email ID"
                    name="email_id"
                    value={form.email_id}
                    focus={
                      !!(typeof formError === "object" && formError?.email_id)
                    }
                    error={
                      !!(typeof formError === "object")
                        ? formError?.email_id
                        : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                </div>
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div className="pr_procssBx" id="addPartyContent">
              <div className="pr_procssBx_grid">
                <div className="singleDiv">
                  <CommonInput
                    type="text"
                    label="Code"
                    placeholder="Code"
                    name="code"
                    value={form.code}
                    focus={!!(typeof formError === "object" && formError?.code)}
                    error={
                      !!(typeof formError === "object") ? formError?.code : ""
                    }
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                  />
                  <p className="_btmInput_para">Leave Blank For Autogenerate</p>
                </div>

                <div className="singleDiv">
                  <CustomSelect
                    options={allZoneList}
                    name="zone_id"
                    flotingLabel="Zone"
                    value={form?.zone_id}
                    error={formError["zone_id"]}
                    onSelect={(data) => handleOnChange("zone_id", data?.value)}
                    placeholder="Default"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="offcanvas-footer">
        {activeTab == 2 ? (
          <React.Fragment>
            <AddButton
              onClick={(e) => handleClick(e, "add")}
              disabled={loading}
              buttonTitle={loading ? "Loading..." : "Add Address"}
              className={
                loading ? "_addNewComm_btn_disabled..." : "_addNewComm_btn"
              }
            />

            <button
              className={
                isButtonDisabled
                  ? "_addNewComm_btn_disabled"
                  : "_addNewComm_btn"
              }
              onClick={(e) => handleClick(e, "new")}
              disabled={isButtonDisabled}
            >
              Add & New
            </button>
            <AddButton
              onClick={onClose}
              buttonTitle="Cancel"
              className={
                loading ? "_addNewComm_btn_disabled..." : "_addNewComm_btn"
              }
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <AddButton
              onClick={handleClickContinue}
              buttonTitle="Continue"
              className={
                loading ? "_addNewComm_btn_disabled..." : "_addNewComm_btn"
              }
            />
            <AddButton
              onClick={onClose}
              buttonTitle="Cancel"
              className={
                loading ? "_addNewComm_btn_disabled..." : "_addNewComm_btn"
              }
            />
          </React.Fragment>
        )}
      </div>
      {showGoogleMapModal && <AddMapLocationModal {...gmapLocate} />}
    </>
  );
};

export default AddAddressBook;
