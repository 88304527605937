import React, { useContext, useEffect, useState, useRef } from 'react';
import Partydetail from '../Partydetail';
import { ButtonHeading } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import Lorryreceipt from '../Lorryreceipt';
import Invoice from '../Invoice';
import Ledger from '../Ledger';
import Contract from './Contract';
import Rate from '../Rate';
import Settings from '../Settings';
import { Unit } from '../Unit';
import { useLocation, useParams } from 'react-router-dom';
import StorageService from 'utils/StorageServices/StorageServices';
import { UserContext } from 'context';
import { fetchConsignorDetailsData } from '_services/accountsServices/consignorServices';
import LoadingBar from 'react-top-loading-bar';

const ConsignorDetails = () => {
    const location = useLocation();
    const loadingBarRef = useRef(null);
    const queryParams = new URLSearchParams(location.search);
    const tabFromQuery = parseInt(queryParams.get('tab')) || 1;
    const [activeTab, setActiveTab] = useState(1);
    let { id } = useParams();
    const { masterData, cities } = useContext(UserContext);
    let company_id = StorageService.getCompanyId();
    const [detailsData, setDetailsData] = useState({});

    const getDataConsignorDetails = async (id_1, id_2) => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                consignor_id: id_1,
                company_id: id_2,
            };
            const res = await fetchConsignorDetailsData(object);
            if (res?.responseCode == 200) {
                setDetailsData(res?.data?.consignor);
            }
        } catch (error) {
            console.error(error);
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    };

    useEffect(() => {
        if (id && company_id) {
            getDataConsignorDetails(id, company_id);
        }
    }, [id]);

    useEffect(() => {
        setActiveTab(tabFromQuery);
    }, [tabFromQuery]);

    const tabProps = {
        tabList: constant.consignorDetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab,
    };

    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <ButtonHeading title={detailsData?.name} buttonTitle="Edit" />

            <div className="addPr_flBx">
                <Tab {...tabProps} />
                {activeTab === 1 && <Partydetail detailsData={detailsData} masterData={masterData} />}
                {activeTab === 2 && <Lorryreceipt />}
                {activeTab === 3 && <Invoice />}
                {activeTab === 4 && <Ledger type={2} />}
                {activeTab === 5 && <Unit handleScrollClass="partyTabs_cls"/>}
                {activeTab === 6 && <Contract />}
                {activeTab === 7 && <Rate />}
                {activeTab === 8 && <Settings />}
            </div>
        </div>
    );
};

export default ConsignorDetails;
