import { getRoleDetail } from "_services";
import { ButtonHeading, CommonInput, CommonTextarea } from "components";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";

const ViewRole = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [roleDetails, setRoleDetails] = useState();
  const companyId = StorageService.getCompanyId();

  useEffect(() => {
    getDetailsOfRole();
  }, []);

  const getDetailsOfRole = async () => {
    const payload = {
      company_id: companyId,
      role_id: atob(id),
    };
    try {
      const resp = await getRoleDetail(payload);
      if (resp?.responseCode === 200) {
        console.log("res", resp);
        const updatedData = resp?.data?.menus.map((user, index1) => ({
            ...user,
            menus: user.menus.map((menu, index2) => ({
              ...menu,
              view: menu.permissions.view === 1 ? 1 : 0,
              edit: menu.permissions.edit === 1 ? 1 : 0,
              delete: menu.permissions.delete === 1 ? 1 : 0,
              create: menu.permissions.create === 1 ? 1 : 0,
              full_access:
                menu.permissions.view === 1 &&
                menu.permissions.edit === 1 &&
                menu.permissions.delete === 1 &&
                menu.permissions.create === 1
                  ? 1
                  : 0,
            })),
          }));
        setRoleDetails({...resp.data,menus:updatedData});
      } else {
      }
    } catch (error) {
      toast.error(error);
    }
  };

  console.log("roleDetails", roleDetails);

  return (
    <div>
      <ButtonHeading title={"View Role"} buttonTitle={"Add Role"} onClick={()=>navigate(`/setting/role/addrole`)}/>

      <div>
        <div className="_addContainer">
          <div className="_addRl_bx">
            <div className="_addRlHead">Role Details</div>

            <div className="_addRl_tableBx">
              <div className="_rlsInputs_bx">
                <div className="addRl_inputOtr">
                  <CommonInput
                    type="text"
                    placeholder="Role Name"
                    label="Role Name"
                    value={roleDetails?.role_name}
                    disabled={true}
                  />
                </div>

                <div className="addRl_inputOtr">
                  <CommonTextarea
                    placeholder="Description"
                    label="Description"
                    value={roleDetails?.description}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="_otrRl_table">
                {roleDetails?.menus?.map((category, categoryIndex) => {
                  return (
                    <div className="_mainRl_table" key={category.name}>
                      <div className="_addRl_headCheck">
                        <input
                          type="checkbox"
                          label="Dashboard"
                          id={`fullCheck_${categoryIndex}`}
                         
                          checked={category?.menus.every(
                            (i) => i.full_access === 1
                          )}
                          disabled={true}
                        />
                        <label
                          htmlFor={`fullCheck_${categoryIndex}`}
                          className="anvLabel"
                        >
                          {category?.name}
                        </label>
                      </div>

                      {category?.name === "Dashboard" ? (
                        <table>
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>View</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>Summary</td>
                              <td>
                                <input
                                  type="checkbox"
                                  id="Summary"
                                  className="_checkTbl"
                                />
                                <label htmlFor="Summary">
                                  <span style={{ visibility: "hidden" }}>
                                    Summary
                                  </span>
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td>Live Location</td>
                              <td>
                                <input
                                  type="checkbox"
                                  id="LiveLocation"
                                  className="_checkTbl"
                                />
                                <label htmlFor="LiveLocation">
                                  <span style={{ visibility: "hidden" }}>
                                    Live Location
                                  </span>
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td>Timeline</td>
                              <td>
                                <input
                                  type="checkbox"
                                  id="Timeline"
                                  className="_checkTbl"
                                />
                                <label htmlFor="Timeline">
                                  <span style={{ visibility: "hidden" }}>
                                    Timeline
                                  </span>
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        category?.category_type === 1 && (
                          <table>
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>Full Access</th>
                                <th>View</th>
                                <th>Create</th>
                                <th>Edit</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            {category?.menus.map((menus, menuIndex) => (
                              <tbody key={`menuIndex_${menuIndex}`}>
                                <tr>
                                  <td> {menus?.label}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`allSelect_${menuIndex}_${menus?.category_id._id}`}
                                      checked={
                                        menus?.permissions?.view &&
                                        menus?.permissions?.edit &&
                                        menus?.permissions?.create &&
                                        menus?.permissions?.delete
                                          ? true
                                          : false
                                      }
                                      disabled={true}
                                    />
                                    <label
                                      htmlFor={`allSelect_${menuIndex}_${menus?.category_id._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl1
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`view_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.permissions?.view}
                                      checked={
                                        menus?.permissions?.view ? true : false
                                      }
                                      disabled={true}
                                    />
                                    <label
                                      htmlFor={`view_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl2
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`create_${menuIndex}_${menus?.category_id?._id}`}
                                      checked={
                                        menus?.permissions?.create
                                          ? true
                                          : false
                                      }
                                      disabled={true}
                                    />
                                    <label
                                      htmlFor={`create_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl3
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`edit_${menuIndex}_${menus?.category_id?._id}`}
                                      checked={
                                        menus?.permissions?.edit ? true : false
                                      }
                                      disabled={true}
                                    />
                                    <label
                                      htmlFor={`edit_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl4
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`delete_${menuIndex}_${menus?.category_id?._id}`}
                                      checked={
                                        menus?.permissions?.delete ? true : false
                                      }
                                      disabled={true}
                                    />
                                    <label
                                      htmlFor={`delete_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl5
                                      </span>
                                    </label>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        )
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRole;
