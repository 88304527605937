import { Heading } from 'components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { constant } from 'constants/constant';
import { FaStar } from 'react-icons/fa6';
import bidderImg from "../../../src/assets/img/bidderImg.png"
import { AddNewConnectionModal, SaveConnectModal, ViewProfileModal } from 'modals';
import { UserContext } from 'context';
import StorageService from 'utils/StorageServices/StorageServices';
import toast from 'react-hot-toast';
import { acceptConnectionRequest, getConnectionCounts, getConnectionListData, getSuggestionListData, rejectConnectionRequest, sendConnectionRequest, withDrawalConnectionRequest } from '_services';
import CommonBox from './CommonBox';

let myConnectionTabs = [
  { id: 1, title: "All Connections", value: "all_connections", count: 0 },
  { id: 2, title: "Request", value: "request", count: 0 },
  { id: 3, title: "Pending", value: "pending", count: 0 },
  { id: 4, title: "Suggestions", value: "suggestions", count: 0 },
]

const Invitations = () => {

  const loadingBarRef = useRef(null);

  const [activeTab, setActiveTab] = useState(1);
  const [showBusinessProfile, setShowBusinessProfile] = useState(false);
  const [connectionData, setConnectionData] = useState([]);
  const [countArray, setCountArray] = useState(myConnectionTabs);
  const [loading, setLoading] = useState(false);
  const [showAddConnection, setShowAddConnection] = useState(false);
  const [showSaveConnect, setShowSaveConnect] = useState(false);
  const [isBtnHideShow, setIsBtnHideShow] = useState(false);

  const [saveConnectModalDirect, setSaveConnectModalDirect] = useState(false);

  const fetchConnectionDataList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
        type: activeTab // 1 = all connection, 2 = received, 3 = sent
      }
      const res = await getConnectionListData(object);
      if (res?.responseCode === 200) {
        setConnectionData(res?.data)
        // toast.success('Error !! ');
      }
    } catch (error) {
      toast.error('Server side api error !! ');
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  const fetchSuggestionDataList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
      }
      const res = await getSuggestionListData(object);
      if (res?.responseCode === 200) {
        setConnectionData(res?.data)
        // toast.success('Error !! ');
      }
    } catch (error) {
      toast.error('Server side api error !! ');
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  const fetchCountsData = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
      }
      const res = await getConnectionCounts(object);
      if (res?.responseCode === 200) {
        let dataArray = []
        for (let i = 0; i < myConnectionTabs?.length; i++) {
          for (let j = 0; j < Object.values(res?.data)?.length; j++) {
            if (i == j) {
              dataArray.push({ ...myConnectionTabs[i], count: Object.values(res?.data)[j] })
            }
          }
        }
        setCountArray(dataArray);
      }
    } catch (error) {
      toast.error('Server side api error !! ');
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  let commonProps = {
    isOpen: showBusinessProfile,
    onClose: (e) => setShowBusinessProfile(e)
  }

  useEffect(() => {
    if (activeTab === 4) {
      fetchSuggestionDataList();
    } else {
      fetchConnectionDataList();
    }
  }, [activeTab])

  useEffect(() => {
    fetchCountsData();
  }, [])

  useEffect(() => {
    if (!showBusinessProfile) {
      setIsBtnHideShow(false)
    }
  }, [showBusinessProfile])

  let profileModal = {
    isOpen: showBusinessProfile,
    activeTab: activeTab,
    isBtnHideShow: isBtnHideShow,
    closeModal: (e) => setShowBusinessProfile(false),
    modalMainClass: "_dashCommon_Modal _viewProfile_modal",
    modalTitle: "View Business Profile",
    nextModal: (e) => setShowSaveConnect(e)
  }

  let saveModalFunction = () => {
    if (saveConnectModalDirect) {
      setShowSaveConnect(false);
    } else {
      setShowBusinessProfile(true);
      setShowSaveConnect(false);
    }
  }

  let saveConnectModal = {
    isOpen: showSaveConnect,
    hideShowBtn: (e) => setIsBtnHideShow(e),
    closeModal: (e) => saveModalFunction(e),
    modalMainClass: " _saveConnectModal",
    modalTitle: "Save Connect to",
    submitTitle: "Send Connection Request",
    nextModal: (e) => setShowAddConnection(e),
    previousModal: (e) => setShowSaveConnect(e),
  }

  const closeAddModalFunction = (event) => {
    setShowSaveConnect(event);
    setShowAddConnection(!event);
  }

  const addConnectionModal = {
    isOpen: showAddConnection,
    closeModal: (e) => closeAddModalFunction(e),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add Connection",
    submitTitle: "Continue",
  }

  return (
    <>
      <Heading title="My Connections" />

      <div>
        <div className='addPr_flBx _indentView'>
          <ul className="nav nav-tabs cstNew_tabs px-0" id="addParty" role="tablist">
            {countArray?.map((item, indx) => (
              <li
                className="nav-item"
                role="presentation"
                key={indx + item?.value}
              >
                <button
                  className={
                    "nav-link " + (activeTab === item?.id ? "active" : "")
                  }
                  type="button"
                  aria-selected={activeTab === item?.id}
                  onClick={(e) => setActiveTab(item.id)}
                >
                  <span className='_tbTitle'>{item?.title}</span>
                  <span className='_counts'>{item?.count} </span>

                </button>
              </li>
            ))}
          </ul>

          <div className='_connectContainer'>
            {activeTab === 1 && (
              <div className='__conect_mainBg'>
                {
                  (connectionData?.length > 0) ? connectionData?.map((el, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <CommonBox
                          element={el}
                          commonProps={commonProps}
                          type={1}
                        />
                      </React.Fragment>
                    )
                  }) : (loading ? 'Loading...' : 'No Records')
                }
              </div>
            )}

            {activeTab === 2 && (
              <div className='__conect_mainBg'>
                {
                  (connectionData?.length > 0) ? connectionData?.map((el, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <CommonBox
                          element={el}
                          commonProps={commonProps}
                          type={2}
                          apiCallBack={() => { fetchCountsData(); fetchConnectionDataList(); }}
                        />
                      </React.Fragment>
                    )
                  }) : (loading ? 'Loading...' : 'No Records')
                }
              </div>
            )}

            {activeTab === 3 && (
              <div className='__conect_mainBg'>
                {
                  (connectionData?.length > 0) ? connectionData?.map((el, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <CommonBox
                          element={el}
                          commonProps={commonProps}
                          type={3}
                          apiCallBack={() => { fetchCountsData(); fetchConnectionDataList(); }}
                        />
                      </React.Fragment>
                    )
                  }) : (loading ? 'Loading...' : 'No Records')
                }
              </div>
            )}

            {activeTab === 4 && (
              <>
                <div className='__conect_mainBg'>
                  {
                    (connectionData?.length > 0) ? connectionData?.map((el, ind) => {
                      return (
                        <React.Fragment key={ind + el?.compnay}>
                          <CommonBox
                            element={el}
                            commonProps={commonProps}
                            type={4}
                            apiCallBack={() => { fetchCountsData(); fetchSuggestionDataList(); }}
                            nextModal={(e) => { setShowSaveConnect(e); setSaveConnectModalDirect(true) }}
                          />
                        </React.Fragment>
                      )
                    }) : (loading ? 'Loading...' : 'No Records')
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {showBusinessProfile
        && (
          <ViewProfileModal {...profileModal} />
        )
      }

      {showSaveConnect
        && (
          <SaveConnectModal {...saveConnectModal} />
        )
      }

      {showAddConnection
        && (
          <AddNewConnectionModal {...addConnectionModal} />
        )
      }
    </>
  )
}

export default Invitations