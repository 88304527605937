import { getAllRoutes, getAllVehicleNumber } from '_services'
import { fetchConsignorDetailsData } from '_services/accountsServices/consignorServices'
import { createContract, getContractListData, singleContractDetailsData } from '_services/accountsServices/contractServices'
import { getLorryTypeList } from '_services/accountsServices/lrServices'
import { getConsigneeDropdownListData, getConsignorDropdownListData, getContractDropdownListData, getSupplierDropdownListData, getUnitDropdownListData, getZoneDropdownListData } from '_services/dropdownServices/dropdownService'
import { CommonInput, CommonModal, CustomSelect } from 'components'
import { UserContext } from 'context'
import { errorSchema, utils } from 'helper'
import VehicleTypeModal from 'modals/VehicleTypeModal'
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import * as Yup from "yup";
import StorageService from 'utils/StorageServices/StorageServices'
import { createRate } from '_services/accountsServices/rateServices'
import { getVehicleGroupListData } from '_services/accountsServices/vehicleGroupServices'
import { RxCross2 } from "react-icons/rx";

const formObj = {
    consignor_id: {},
    contract_id: {},
    rate_type: {},
    rate: '',
    amount: '',
    vehicle_id: {},
    supplier_id: {},
    vehicle_ownership: {},
    vehicle_group_id: {},
    weight_range: {},

    from: {},
    to: {}
}

const labelObj = {
    contract_id: '',
    rate_type: '',

    from_city_1: '',
    to_city_1: '',

    from_zone_2: '',
    to_zone_2: '',

    from_unit_3: '',
    to_consignee_3: '',

    from_city_4: '',
    to_zone_4: '',

    route_wise: '',

    from_unit_5: '',
    to_city_5: '',

    from_pin_6: '',
    to_pin_6: '',
}

const AddRateModal = (props) => {

    let { isOpen, closeModal } = props

    let { id } = useParams();
    let company_id = StorageService.getCompanyId();
    const { masterData, cities, modalZIndex, setModalZIndex } = useContext(UserContext)
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [consignorList, setConsignorList] = useState([]);
    const [contractList, setContractList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [contractDetails, setContractDetails] = useState({});
    const [vehicleNumbList, setVehicleNumbList] = useState([]);
    const [vehicleTypeshow, setVehicleTypeShow] = useState(false);
    const [conditionData, setConditionData] = useState({})
    const [lorryType, setLorryType] = useState([]);
    const [labelFormValue, setLabelFormValue] = useState(labelObj)
    const [vehicleTypeLabel, setVehicleTypeLabel] = useState({ label: "", value: "" });
    const [consigneeDropdownData, setConsigneeDropdownData] = useState([])
    const [unitDropdownData, setUnitDropdownData] = useState([])
    const [routeList, setRouteList] = useState([]);
    const [vehicleGroupData, setVehicleGroupData] = useState([]);
    const [allZoneList, setAllZoneList] = useState([]);

    const createDynamicSchema = (data) => {
        return Yup.object().shape({
            // consignor_id: Yup.object().shape({
            //     label: Yup.string().required("Required"),
            // }),
            contract_id: Yup.object().shape({
                label: Yup.string().required("Required"),
            }),
            rate_type: Yup.object().shape({
                label: Yup.string().required("Required"),
            }),
            // from: Yup.object().shape({
            //     label: !data?.isRouteWise
            //         ? Yup.string().required("Required * ")
            //         : Yup.string().notRequired(),
            // }),
            // to: Yup.object().shape({
            //     label: !data?.isRouteWise
            //         ? Yup.string().required("Required * ")
            //         : Yup.string().notRequired(),
            // }),
            // route_wise: Yup.object().shape({
            //     label: data?.isRouteWise
            //         ? Yup.string().required("Required * ")
            //         : Yup.string().notRequired(),
            // }),
            vehicle_ownership: Yup.object().shape({
                label: (data?.VehicleOwnership === 2)
                    ? Yup.string().required("Required * ")
                    : Yup.string().notRequired(),
            }),
            vehicle_id: Yup.object().shape({
                label: (data?.VehicleNo === 3)
                    ? Yup.string().required("Required * ")
                    : Yup.string().notRequired(),
            }),
            supplier_id: Yup.object().shape({
                label: (data?.Supplier === 4)
                    ? Yup.string().required("Required * ")
                    : Yup.string().notRequired(),
            }),
            weight_range: Yup.object().shape({
                label: (data?.WeightRange === 5)
                    ? Yup.string().required("Required * ")
                    : Yup.string().notRequired(),
            }),
            vehicle_group_id: Yup.object().shape({
                label: (data?.VehicleGroup === 6)
                    ? Yup.string().required("Required * ")
                    : Yup.string().notRequired(),
            }),
            amount: Yup.string().when("rate_type", {
                is: (val) => val?.value === 6,
                then: () => Yup.string().required("Required *"),
                otherwise: () => Yup.string().notRequired(),
            }),
            rate: Yup.string().when("rate_type", {
                is: (val) => val?.value !== 6,
                then: () => Yup.string().required("Required *"),
                otherwise: () => Yup.string().notRequired(),
            }),
        });
    }

    const schema = createDynamicSchema(conditionData);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, schema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const onhandleSelect = async (item, name) => {
        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, schema);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }
    };

    const submitContractHandler = async () => {
        const validationResult = await utils?.checkFormError(formValue, schema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            try {
                setLoading(true)
                const object = {
                    company_id: company_id,
                    consignor_id: formValue?.consignor_id?.value,
                    contract_id: formValue?.contract_id?.value,
                    matrix_allowed: contractDetails?.matrix_allowed,
                    from: formValue?.from?.value,
                    to: formValue?.to?.value,
                    rate_type: formValue?.rate_type?.value,
                    rate: formValue?.rate ? formValue?.rate : '',
                    amount: formValue?.amount ? formValue?.amount : '',
                    transit_kms: formValue?.transit_kms,
                    transit_days: formValue?.transit_days,
                    lorry_type_id: formValue?.lorry_type_id ? formValue?.lorry_type_id : '',
                    vehicle_type_id: formValue?.vehicle_type_id ? formValue?.vehicle_type_id : '',
                    vehicle_id: formValue?.vehicle_id?._id ? formValue?.vehicle_id?._id : '',
                    vehicle_ownership: formValue?.vehicle_ownership?.value ? formValue?.vehicle_ownership?.value : 0,
                    supplier_id: formValue?.supplier_id.value ? formValue?.supplier_id?.value : '',
                    weight_range: '', // formValue?.weight_range?.label,
                    vehicle_group_id: formValue?.vehicle_group_id?.value ? formValue?.vehicle_group_id?.value : '',
                }
                const res = await createRate(object);
                if (res?.responseCode == 200) {
                    closeModal(false);
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
            }
        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    }

    const getDropdownDataConsignorList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getConsignorDropdownListData(object);
            if (res?.responseCode == 200) {
                setConsignorList(res?.data?.consignor_list)
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            // setLoading(false);
            // throw error;
        }
    }

    const getDropdownDataSupplierList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getSupplierDropdownListData(object);
            if (res?.responseCode == 200) {
                let list = res?.data?.supplier_list.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                    };
                });
                setSupplierList(list)
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            // setLoading(false);
            // throw error;
        }
    }

    const getZoneList = async () => {
        try {
            let object = { company_id: StorageService.getCompanyId() };
            const res = await getZoneDropdownListData(object);
            if (res) {
                const list = res?.data?.zone_list.map((el) => {
                    return {
                        ...el,
                        label: el?.name,
                        value: el?._id,
                    };
                });
                setAllZoneList(list);
            }
        } catch (error) {
            throw error;
        }
    };

    const getDropdownDataContractList = async (id_1, id_2) => {
        try {
            let object = {
                consignor_id: id_1,
                company_id: id_2,
                search: '',
                limit: 100,
                offset: 1,
            }
            const res = await getContractDropdownListData(object);
            if (res?.responseCode == 200) {
                let data = res?.data?.contract_list?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                        active: el?.active,
                    }
                })
                setContractList(data)
            }
        } catch (error) {
            // throw error;
        }
    }

    const getDropdownDataUnitList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getUnitDropdownListData(object);
            if (res?.responseCode == 200) {
                let list = res?.data?.unit_list?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                    }
                })
                setUnitDropdownData(list)
            }
        } catch (error) {
            // throw error;
        }
    }

    const getDropdownDataConsigneeList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getConsigneeDropdownListData(object);
            if (res?.responseCode == 200) {
                let list = res?.data?.consignee_list?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                    }
                })
                setConsigneeDropdownData(list)
            }
        } catch (error) {
            // throw error;
        }
    }

    const getRouteList = async () => {
        const res = await getAllRoutes({
            company_id: StorageService.getCompanyId(),
        });
        if (res?.responseCode === 200) {
            const list = res?.data?.route?.map((i) => {
                return {
                    ...i,
                    label: i.route_name,
                    value: i._id,
                };
            });
            setRouteList(list);
        } else {
            toast.error(res?.responseMessage);
        }
    };

    const getDataContractDetails = async (contractId) => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                consignor_id: formValue?.consignor_id?.value,
                contract_id: contractId,
            }
            const res = await singleContractDetailsData(object);
            if (res?.responseCode == 200) {
                setContractDetails(res?.data?.contract_detail)
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            // setLoading(false);
            // throw error;
        }
    }

    useEffect(() => {
        if (consignorList?.length > 0) {
            setFormValue((s) => ({
                ...s,
                consignor_id: consignorList?.filter((el) => (el?._id === id) ? el : '')?.map((element) => {
                    return {
                        label: element?.name,
                        value: element?._id,
                    }
                })[0],
            }))
        }
    }, [consignorList, id])

    useEffect(() => {
        if (contractList?.length > 0) {
            setFormValue((s) => ({
                ...s,
                contract_id: contractList?.find((el) => (el?.active === 1) ? el : ''),
            }))
            setLabelFormValue((s) => ({
                ...s,
                contract_id: contractList?.find((el) => (el?.active === 1) ? el : ''),
            }))
        }
    }, [contractList])

    useEffect(() => {
        if (formValue?.consignor_id?.value) {
            getDropdownDataContractList(formValue?.consignor_id?.value, company_id);
        }
    }, [formValue?.consignor_id])

    useEffect(() => {
        if (formValue?.contract_id?.value) {
            getDataContractDetails(formValue?.contract_id?.value);
            setFormValue((s) => ({ ...s, from: {}, to: {} }))
        }
    }, [formValue?.contract_id?.value])

    const modifyRateProps = {
        ...props,
        isLoadding: loading,
        onSubmit: submitContractHandler,
        disabled: loading,
        modalZIndex: modalZIndex,
    }

    const getVehicleList = async () => {
        const res = await getAllVehicleNumber({
            company_id: StorageService.getCompanyId(),
        });
        if (res?.responseCode === 200) {
            setVehicleNumbList(
                res?.data?.vehicle_list?.map((i) => {
                    return {
                        ...i,
                        label: i.vehicle_no,
                        value: i._id,
                    };
                })
            );
        } else {
            toast.error(res?.responseMessage);
        }
    };

    const getDataLorryTypeList = async () => {
        try {
            const res = await getLorryTypeList();
            if (res?.responseCode == 200) {
                setLorryType(res?.data?.lorry_type);
            }
        } catch (error) {
            throw error;
        }
    };

    const vehicleHandleChange = (data) => {
        setFormValue({
            ...formValue,
            vehicle_id: data,
        });

        // setVehicleTypeLabel({
        //     label: `${data?.lorry_type_id?.lorry_type} > ${data?.vehicle_type_id_1?.name}`,
        //     value: "",
        // });
    };

    const callBackVehicleType = (value) => {
        setFormValue({
            ...formValue,
            lorry_type_id: value?.activeTab?._id,
            vehicle_type_id: value?.tyre?.value,
        });
        // vehicleHandleChange(value);
        setVehicleTypeLabel({
            label: `${value?.activeTab?.lorry_type} > ${value?.tyre?.label}`,
            value: value?.tyre?.value,
        });
        setVehicleTypeShow(false);
    };

    let vehicleTypeProps = {
        isOpen: vehicleTypeshow,
        closeModal: (e) => (setVehicleTypeShow(false), setModalZIndex('')),
        headerPart: true,
        footerPart: true,
        modalMainClass: "_dashCommon_Modal _smModal",
        modalTitle: "Vehicle Type",
        submitTitle: "Save",
        isLoadding: loading,
        disabled: loading ? true : false,
        lorryType: lorryType,
        callBackVehicleType: callBackVehicleType,
    };

    const getDataVehicleGroupList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
            }
            const res = await getVehicleGroupListData(object);
            if (res?.responseCode == 200) {
                setVehicleGroupData(res?.data?.vehicle_group_list);
            }
        } catch (error) {
            console.log('errr', error)
            //   throw error;
        }
    }

    useEffect(() => {
        getZoneList();
        getRouteList();
        getVehicleList();
        getDropdownDataConsignorList();
        getDropdownDataSupplierList();
        getDropdownDataUnitList();
        getDropdownDataConsigneeList();
        getDataLorryTypeList();
        getDataVehicleGroupList();
    }, []);

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            from: { label: 'All', value: '' },
            to: { label: 'All', value: '' },
            vehicle_id: { label: 'All', value: '' },
            supplier_id: { label: 'All', value: '' },
            weight_range: { label: 'All', value: '' },
            vehicle_group_id: { label: 'All', value: '' },
            vehicle_ownership: { label: 'All', value: '' },
        }))
        setLabelFormValue((s) => ({
            ...s,
            from_city_1: { label: 'All', value: '' },
            to_city_1: { label: 'All', value: '' },

            from_zone_2: { label: 'All', value: '' },
            to_zone_2: { label: 'All', value: '' },

            from_unit_3: { label: 'All', value: '' },
            to_consignee_3: { label: 'All', value: '' },

            from_city_4: { label: 'All', value: '' },
            to_zone_4: { label: 'All', value: '' },

            route_wise: { label: 'All', value: '' },

            from_unit_5: { label: 'All', value: '' },
            to_city_5: { label: 'All', value: '' },

            from_pin_6: { label: 'All', value: '' },
            to_pin_6: { label: 'All', value: '' },
        }))
    }, [])

    useEffect(() => {
        if (contractDetails?.variable_rates?.length > 0) {
            let newObj = {}
            if (contractDetails?.matrix_allowed === 5) {
                newObj['isRouteWise'] = contractDetails?.matrix_allowed === 5 ? true : false
            }
            contractDetails?.variable_rates?.forEach(element => {
                newObj[(element?.name).replace(/\s+/g, '').replace('(KG)', '')] = element?.id
            });
            setConditionData(newObj);
        }
    }, [contractDetails?.variable_rates])

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }
    return (
        <div>
            <CommonModal {...modifyRateProps}>
                <div className='addCont_body'>

                    <div className='_rtInn_bx'>
                        <div className='_cngDetail'>
                            <div className='_dualDivs'>
                                <CommonInput
                                    type="text"
                                    label="Consignor Name *"
                                    placeholder="Consignor Name *"
                                    name="name"
                                    value={formValue?.consignor_id?.label ? formValue?.consignor_id?.label : ''}
                                    disabled
                                />
                                {/* <CustomSelect
                                    options={consignorList?.map((el) => {
                                        return {
                                            label: el?.name,
                                            value: el?._id,
                                        }
                                    })}
                                    name="consignor_id"
                                    label="Select Consignor"
                                    placeholder="Select Consignor"
                                    value={formValue?.consignor_id?.value ? formValue?.consignor_id : {}}
                                    error={formError["consignor_id.label"]}
                                    onSelect={(data) => {
                                        onhandleSelect(data, 'consignor_id');
                                        setLabelFormValue((s) => ({ ...s, contract_id: { label: 'All', value: '' }, rate_type: { label: "", value: "" } }));
                                        setFormValue((s) => ({ ...s, contract_id: null }));
                                        setContractDetails({});
                                        setConditionData({});
                                    }}
                                /> */}
                            </div>

                            <div className='_dualDivs'>
                                <CustomSelect
                                    options={contractList}
                                    name="contract_id"
                                    label="Select Contract"
                                    placeholder="Select Contract"
                                    value={labelFormValue?.contract_id ? labelFormValue?.contract_id : ''}
                                    error={formError["contract_id.label"]}
                                    onSelect={(data) => {
                                        onhandleSelect(data, 'contract_id')
                                        setConditionData({});
                                        setLabelFormValue((s) => ({
                                            ...s,
                                            contract_id: data,
                                            rate_type: { label: '', value: '' },
                                            from_city_1: { label: 'All', value: '' },
                                            to_city_1: { label: 'All', value: '' },
                                        }));
                                    }}
                                />
                            </div>

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'City-City' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...cities]}
                                            name="from"
                                            label="From City"
                                            placeholder="From City"
                                            value={labelFormValue?.from_city_1?.label ? labelFormValue?.from_city_1 : ''}
                                            error={formError["from.label"]}
                                            onSelect={(data) => {
                                                handleOnChange("from", {
                                                    label: data?.label,
                                                    value: data?.name,
                                                })
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: {
                                                        label: data?.label,
                                                        value: data?.name,
                                                    },
                                                    // to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, from: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    // to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...cities]}
                                            name="to"
                                            label="To City"
                                            placeholder="To City"
                                            value={labelFormValue?.to_city_1?.label ? labelFormValue?.to_city_1 : ''}
                                            error={formError["to.label"]}
                                            onSelect={(data) => {
                                                handleOnChange("to", {
                                                    label: data?.label,
                                                    value: data?.name,
                                                })
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    // from_city_1: { label: 'All', value: '' },
                                                    to_city_1: {
                                                        label: data?.label,
                                                        value: data?.name,
                                                    },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, to: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    // from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'Zone-Zone' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...allZoneList]}
                                            name="from"
                                            label="From Zone"
                                            placeholder="From Zone"
                                            value={labelFormValue?.from_zone_2?.label ? labelFormValue?.from_zone_2 : ''}
                                            error={formError["from.label"]}
                                            onSelect={(data) => {
                                                onhandleSelect(data, 'from')
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: data,
                                                    // to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, from: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    // to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...allZoneList]}
                                            name="to"
                                            label="To Zone"
                                            placeholder="To Zone"
                                            value={labelFormValue?.to_zone_2?.label ? labelFormValue?.to_zone_2 : ''}
                                            error={formError["to.label"]}
                                            onSelect={(data) => {
                                                onhandleSelect(data, 'to')
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    // from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: data,
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, to: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    // from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'Unit-Consignee' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...unitDropdownData]}
                                            name="from"
                                            label="From Unit"
                                            placeholder="From Unit"
                                            value={labelFormValue?.from_unit_3?.label ? labelFormValue?.from_unit_3 : ''}
                                            error={formError["from.label"]}
                                            onSelect={(data) => {
                                                onhandleSelect(data, 'from')
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: data,
                                                    // to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, from: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    // to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...consigneeDropdownData]}
                                            name="to"
                                            label="To Consignee"
                                            placeholder="To Consignee"
                                            value={labelFormValue?.to_consignee_3?.label ? labelFormValue?.to_consignee_3 : ''}
                                            error={formError["to.label"]}
                                            onSelect={(data) => {
                                                onhandleSelect(data, 'from')
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    // from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: data,
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, to: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    // from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'City-Zone' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...cities]}
                                            name="from"
                                            label="From City"
                                            placeholder="From City"
                                            value={labelFormValue?.from_city_4?.label ? labelFormValue?.from_city_4 : ''}
                                            error={formError["from.label"]}
                                            onSelect={(data) => {
                                                handleOnChange("from", {
                                                    label: data?.label,
                                                    value: data?.name,
                                                })
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: data,
                                                    // to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, from: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    // to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...allZoneList]}
                                            name="to"
                                            label="To Zone"
                                            placeholder="To Zone"
                                            value={labelFormValue?.to_zone_4?.label ? labelFormValue?.to_zone_4 : ''}
                                            error={formError["to.label"]}
                                            onSelect={(data) => {
                                                onhandleSelect(data, 'to')
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    // from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: data,
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, to: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    // from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'Route wise' && (
                                <>
                                    <div className='singleDiv'>
                                        <CustomSelect
                                            label="Route wise"
                                            placeholder="Select Route"
                                            options={[{ label: 'All', value: '' }, ...routeList]}
                                            name='route_wise'
                                            value={labelFormValue?.route_wise?.label ? labelFormValue?.route_wise : ''}
                                            error={formError["route_wise.label"]}
                                            onSelect={(data) => {
                                                handleOnChange("route_wise", {
                                                    label: data?.route_name,
                                                    value: data?._id,
                                                })
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: data,
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, route_wise: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'Unit-City' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...unitDropdownData]}
                                            name="from"
                                            label="From Unit"
                                            placeholder="From Unit"
                                            value={labelFormValue?.from_unit_5?.label ? labelFormValue?.from_unit_5 : ''}
                                            error={formError["from.label"]}
                                            onSelect={(data) => {
                                                onhandleSelect(data, 'from')
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: data,
                                                    // to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, from: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    // to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...cities]}
                                            name="to"
                                            label="To City"
                                            placeholder="To City"
                                            value={labelFormValue?.to_city_5?.label ? labelFormValue?.to_city_5 : ''}
                                            error={formError["to.label"]}
                                            onSelect={(data) => {
                                                handleOnChange("to", {
                                                    label: data?.label,
                                                    value: data?.name,
                                                })
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    // from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: data,
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, to: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    // from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'Pin-Pin' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...unitDropdownData]}
                                            name="from"
                                            label="From Pin"
                                            placeholder="From Pin"
                                            value={labelFormValue?.from_pin_6?.label ? labelFormValue?.from_pin_6 : ''}
                                            error={formError["from.label"]}
                                            onSelect={(data) => {
                                                onhandleSelect(data, 'from')
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: data,
                                                    // to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, from: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    from_pin_6: { label: 'All', value: '' },
                                                    // to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CustomSelect
                                            options={[{ label: 'All', value: '' }, ...unitDropdownData]}
                                            name="to"
                                            label="To Pin"
                                            placeholder="To Pin"
                                            value={labelFormValue?.to_pin_6?.label ? labelFormValue?.to_pin_6 : ''}
                                            error={formError["to.label"]}
                                            onSelect={(data) => {
                                                onhandleSelect(data, 'to')
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    // from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: data,
                                                }));
                                            }}
                                            isClearOption={true}
                                            isClearHandleChange={() => {
                                                setFormValue((s) => ({ ...s, to: { label: 'All', value: '' } }))
                                                setLabelFormValue((s) => ({
                                                    ...s,
                                                    from_city_1: { label: 'All', value: '' },
                                                    to_city_1: { label: 'All', value: '' },
                                                    from_zone_2: { label: 'All', value: '' },
                                                    to_zone_2: { label: 'All', value: '' },
                                                    from_unit_3: { label: 'All', value: '' },
                                                    to_consignee_3: { label: 'All', value: '' },
                                                    from_city_4: { label: 'All', value: '' },
                                                    to_zone_4: { label: 'All', value: '' },
                                                    route_wise: { label: 'All', value: '' },
                                                    from_unit_5: { label: 'All', value: '' },
                                                    to_city_5: { label: 'All', value: '' },
                                                    // from_pin_6: { label: 'All', value: '' },
                                                    to_pin_6: { label: 'All', value: '' },
                                                }));
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            <div className='_dualDivs'>
                                <CustomSelect
                                    options={contractDetails?.rate_type?.length > 0 ? contractDetails?.rate_type?.map((el) => {
                                        return {
                                            label: el?.name,
                                            value: el?.id,
                                        }
                                    }) : []}
                                    name="rate_type"
                                    label="Rate Type"
                                    placeholder="Rate type"
                                    value={labelFormValue?.rate_type}
                                    error={formError["rate_type.label"]}
                                    onSelect={(data) => {
                                        onhandleSelect(data, 'rate_type')
                                        setLabelFormValue((s) => ({
                                            ...s,
                                            rate_type: data
                                        }));
                                    }}
                                // onSelect={(data) => onhandleSelect(data, 'rate_type')}
                                />
                            </div>

                            {formValue?.rate_type?.value !== 6 && <div className='_dualDivs'>
                                <CommonInput
                                    label="Rate"
                                    placeholder="Rate"
                                    type="number"
                                    name='rate'
                                    maxLength="8"
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    onInput={maxLengthCheck}
                                    value={formValue.rate}
                                    focus={!!(typeof formError === "object" && formError?.rate)}
                                    error={!!(typeof formError === "object") ? formError?.rate : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                            </div>}

                            {formValue?.rate_type?.value === 6 && <div className='_dualDivs'>
                                <CommonInput
                                    label="Amount"
                                    placeholder="Amount"
                                    type="number"
                                    name='amount'
                                    maxLength="8"
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    onInput={maxLengthCheck}
                                    value={formValue.amount}
                                    focus={!!(typeof formError === "object" && formError?.amount)}
                                    error={!!(typeof formError === "object") ? formError?.amount : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                            </div>}

                            <div className='_dualDivs'>
                                <CommonInput
                                    label="Transit KMs"
                                    placeholder="Transit KMs"
                                    type="number"
                                    name='transit_kms'
                                    maxLength="10"
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    onInput={maxLengthCheck}
                                    value={formValue.transit_kms}
                                    focus={!!(typeof formError === "object" && formError?.transit_kms)}
                                    error={!!(typeof formError === "object") ? formError?.transit_kms : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                            </div>

                            <div className='_dualDivs'>
                                <CommonInput
                                    label="Transit Days"
                                    placeholder="Transit Days"
                                    type="number"
                                    name='transit_days'
                                    maxLength="10"
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    onInput={maxLengthCheck}
                                    value={formValue.transit_days}
                                    focus={!!(typeof formError === "object" && formError?.transit_days)}
                                    error={!!(typeof formError === "object") ? formError?.transit_days : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                            </div>
                        </div>
                        {
                            contractDetails?.variable_rates?.length > 0 ? (
                                <div>
                                    <h5 className='_cngH5_head'>Variable Rate on Fields</h5>
                                    <div className='_cngDetail'>
                                        {conditionData?.VehicleNo === 3 &&
                                            <div className='_dualDivs'>
                                                <CustomSelect
                                                    label="Vehicle Number"
                                                    placeholder="Vehicle Number"
                                                    options={[{
                                                        _id: '',
                                                        vehicle_no: 'All',
                                                        vehicle_ownership: 1,
                                                        lorry_type_id: {
                                                            _id: '',
                                                            lorry_type: ''
                                                        },
                                                        vehicle_type_id_1: {
                                                            _id: '',
                                                            name: 'All'
                                                        },
                                                        driver_id: {
                                                            _id: '',
                                                            name: 'All'
                                                        },
                                                        supplier_id: null,
                                                        vehicle_status: 1,
                                                        label: 'All',
                                                        value: ''
                                                    }, ...vehicleNumbList]}
                                                    value={formValue?.vehicle_id?.label ? formValue?.vehicle_id : ''}
                                                    error={formError["vehicle_id.label"]}
                                                    onSelect={(data) => onhandleSelect(data, 'vehicle_id')}
                                                    isClearOption={true}
                                                    isClearHandleChange={() => setFormValue((s) => ({ ...s, vehicle_id: { label: 'All', value: '' } }))}
                                                />
                                            </div>
                                        }
                                        {conditionData?.VehicleType === 1 &&
                                            <div className='_dualDivs' style={{ position: 'relative' }}>
                                                <div
                                                    className="_divForth vehicle_dots"
                                                    onClick={(e) => (setVehicleTypeShow(true), setModalZIndex(-1))}
                                                >
                                                    <CustomSelect
                                                        label="Vehicle Type"
                                                        placeholder="All"
                                                        options={[{ label: "Vehicle Number", value: "123" }]}
                                                        value={vehicleTypeLabel}
                                                        openList={false}
                                                    />
                                                </div>
                                                {vehicleTypeLabel?.label !== '' && <span onClick={() => (setFormValue((s) => ({ ...s, lorry_type_id: '', vehicle_type_id: '' })), setVehicleTypeLabel({ label: "", value: "" }))} className="clear-icon2"> <RxCross2 size={20} color='black' /> </span>}
                                            </div>
                                        }
                                        {conditionData?.VehicleOwnership === 2 &&
                                            <div className='_dualDivs'>
                                                <CustomSelect
                                                    options={[{ label: 'All', value: '' }, ...masterData?.vehicle_ownership_list?.map((el) => {
                                                        return {
                                                            label: el?.name,
                                                            value: el?.id,
                                                        }
                                                    })]}
                                                    name="vehicle_ownership"
                                                    label="Vehicle Ownership"
                                                    placeholder="Vehicle Ownership"
                                                    value={formValue?.vehicle_ownership?.label ? formValue?.vehicle_ownership : {}}
                                                    error={formError["vehicle_ownership.label"]}
                                                    onSelect={(data) => onhandleSelect(data, 'vehicle_ownership')}
                                                    isClearOption={true}
                                                    isClearHandleChange={() => setFormValue((s) => ({ ...s, vehicle_ownership: { label: 'All', value: '' } }))}
                                                />
                                            </div>
                                        }
                                        {conditionData?.Supplier === 4 &&
                                            <div className='_dualDivs'>
                                                <CustomSelect
                                                    options={[{ label: 'All', value: '' }, ...supplierList?.map((el) => {
                                                        return {
                                                            label: el?.name,
                                                            value: el?._id,
                                                        }
                                                    })]}
                                                    name="supplier_id"
                                                    label="Supplier"
                                                    placeholder="Supplier"
                                                    value={formValue?.supplier_id?.label ? formValue?.supplier_id : {}}
                                                    error={formError["supplier_id.label"]}
                                                    onSelect={(data) => onhandleSelect(data, 'supplier_id')}
                                                    isClearOption={true}
                                                    isClearHandleChange={() => setFormValue((s) => ({ ...s, supplier_id: { label: 'All', value: '' } }))}
                                                />
                                            </div>
                                        }
                                        {conditionData?.WeightRange === 5 &&
                                            <div className='_dualDivs'>
                                                <CustomSelect
                                                    options={[{ label: 'All', value: '' }, ...masterData?.material_weight_type_list?.map((el) => {
                                                        return {
                                                            label: el?.name,
                                                            value: el?.id,
                                                        }
                                                    })]}
                                                    name="weight_range"
                                                    label="Weight Range(KG)"
                                                    placeholder="Weight Range(KG)"
                                                    value={formValue?.weight_range?.label ? formValue?.weight_range : {}}
                                                    error={formError["weight_range.label"]}
                                                    onSelect={(data) => onhandleSelect(data, 'weight_range')}
                                                    isClearOption={true}
                                                    isClearHandleChange={() => setFormValue((s) => ({ ...s, weight_range: { label: 'All', value: '' } }))}
                                                />
                                            </div>
                                        }
                                        {conditionData?.VehicleGroup === 6 &&
                                            <div className='_dualDivs'>
                                                <CustomSelect
                                                    options={[{ label: 'All', value: '' }, ...vehicleGroupData?.map((el) => {
                                                        return {
                                                            label: el?.name,
                                                            value: el?._id,
                                                        }
                                                    })]}
                                                    name="vehicle_group_id"
                                                    label="Vehicle Group"
                                                    placeholder="Vehicle Group"
                                                    value={formValue?.vehicle_group_id?.label ? formValue?.vehicle_group_id : {}}
                                                    error={formError["vehicle_group_id.label"]}
                                                    onSelect={(data) => onhandleSelect(data, 'vehicle_group_id')}
                                                    isClearOption={true}
                                                    isClearHandleChange={() => setFormValue((s) => ({ ...s, vehicle_group_id: { label: 'All', value: '' } }))}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            ) : <></>
                        }
                    </div>

                </div>
            </CommonModal >
            {vehicleTypeshow && <VehicleTypeModal {...vehicleTypeProps} />}
        </div >
    )
}

export default AddRateModal
