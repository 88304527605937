import { wait } from "@testing-library/user-event/dist/utils";
import { indentListApi } from "_services";
import { ButtonHeading, CustomSelect } from "components";
import { AddIndentModal } from "modals";
import React, { useEffect, useState } from "react";
import StorageService from "utils/StorageServices/StorageServices";

const Indent = () => {
  const [showAddIndent, setShowAddIndent] = useState(false);
  const [indentList, setIndentList] = useState([]);

  useEffect(() => {
    getIndentList();
    console.log("payload")
  }, []);

  const arryOptions = [
    { label: "Arjun", value: "01" },
    { label: "Anvit", value: "02" },
  ];

  const getIndentList = async () => {
    const payload = {
      company_id: StorageService?.getCompanyId(),
      branch_id: "",
      consignor_id: "",
      vehicle_type_id: "",
      start_date: "",
      end_date: "",
      search: "",
      plan_status: 1,
      limit: 50,
      offset: 1,
    };
console.log(payload)
    try {
      const res = await indentListApi(payload);
      console.log(res);
    } catch (error) {}
  };

  return (
    <>
      <ButtonHeading
        title="Indent"
        onClick={() => setShowAddIndent(!showAddIndent)}
        buttonTitle="Add Indent"
      />

      <div className="_indentView_flx">
        <div className="indentSide_bx">
          <div className="_indtsLs_flx">
            <div className="_indtsList">
              <h5>PLAN STATUS</h5>

              <ul className="nav nav-tabs" id="myIndentTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="allPlan-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#allPlan-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="allPlan-tab-pane"
                    aria-selected="true"
                  >
                    <span>All Plans</span> <span>500</span>
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="activePln-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#activePln-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="activePln-tab-pane"
                    aria-selected="false"
                  >
                    <span>Active (Pending)</span> <span>30</span>
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="intransit-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#intransit-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="intransit-tab-pane"
                    aria-selected="false"
                  >
                    <span>Intransit</span> <span>20</span>
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="delivered-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#delivered-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="delivered-tab-pane"
                    aria-selected="false"
                  >
                    <span>Delivered</span> <span>400</span>
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="cancelled-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#cancelled-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="cancelled-tab-pane"
                    aria-selected="false"
                  >
                    <span>Cancelled</span> <span>50</span>
                  </button>
                </li>
              </ul>
              {/* <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">...</div>
                                <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">...</div>
                                <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">...</div>
                                <div className="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabindex="0">...</div>
                            </div> */}
            </div>

            <div className="_indtsList">
              <h5>BIDS 500</h5>

              <div className="_bidsLs_flx">
                <div className="_bidsList">
                  <input type="checkbox" name="" id="allBids" />
                  <label htmlFor="allBids">
                    <span>All Bids</span>
                    <span>500</span>
                  </label>
                </div>

                <div className="_bidsList">
                  <input type="checkbox" name="" id="noBids" />
                  <label htmlFor="noBids">
                    <span>No Bids</span>
                    <span>20</span>
                  </label>
                </div>

                <div className="_bidsList">
                  <input type="checkbox" name="" id="bidsRec" />
                  <label htmlFor="bidsRec">
                    <span>Bids Received</span>
                    <span>50</span>
                  </label>
                </div>

                <div className="_bidsList">
                  <input type="checkbox" name="" id="alloted" />
                  <label htmlFor="alloted">
                    <span>Alloted</span>
                    <span>20</span>
                  </label>
                </div>

                <div className="_bidsList">
                  <input type="checkbox" name="" id="accepted" />
                  <label htmlFor="accepted">
                    <span>Accepted</span>
                    <span>400</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="_indtsList">
              <h5>RESPONSES</h5>

              <div className="_bidsLs_flx">
                <div className="_bidsList">
                  <input type="checkbox" name="" id="allResponses" />
                  <label htmlFor="allResponses">
                    <span>All Responses</span>
                    <span>500</span>
                  </label>
                </div>

                <div className="_bidsList">
                  <input type="checkbox" name="" id="noResponse" />
                  <label htmlFor="noResponse">
                    <span>No Response</span>
                    <span>20</span>
                  </label>
                </div>

                <div className="_bidsList">
                  <input type="checkbox" name="" id="planNoted" />
                  <label htmlFor="planNoted">
                    <span>Plan Noted</span>
                    <span>50</span>
                  </label>
                </div>

                <div className="_bidsList">
                  <input type="checkbox" name="" id="fleetConf" />
                  <label htmlFor="fleetConf">
                    <span>Fleet Confirmed</span>
                    <span>20</span>
                  </label>
                </div>

                <div className="_bidsList">
                  <input type="checkbox" name="" id="fleetLoading" />
                  <label htmlFor="fleetLoading">
                    <span>Fleet Loading</span>
                    <span>400</span>
                  </label>
                </div>

                <div className="_bidsList">
                  <input type="checkbox" name="" id="fleetLoaded" />
                  <label htmlFor="fleetLoaded">
                    <span>Fleet Loaded</span>
                    <span>400</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="_indTabl_right">
          <div className="_indentTable">
            <table>
              <thead>
                <tr>
                  <th>Indent Date</th>
                  <th>Indent No.</th>
                  <th>Party</th>
                  <th>From Station</th>
                  <th>To Station</th>
                  <th>Branch Name</th>
                  <th>Fleet Type</th>
                  <th>Material Type</th>
                  <th>PO Date</th>
                  <th>PO No.</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <div className="_dateNtime">
                      <span className="_tblText">19 Jun 2024</span>
                      <span className="_lgtText">05:30 AM</span>
                    </div>
                  </td>

                  <td>
                    <span className="_tblText">234234</span>
                  </td>

                  <td>
                    <span className="_tblText">Parle Biscuit</span>
                  </td>

                  <td>
                    <span className="_tblText">Indore</span>
                  </td>

                  <td>
                    <span className="_tblText">Jabalpur</span>
                  </td>

                  <td>
                    <span className="_tblText">Branch Number 1</span>
                  </td>

                  <td>
                    <div className="_dateNtime">
                      <span className="_tblText">Container</span>
                      <span className="_lgtText">32Feet XL</span>
                    </div>
                  </td>

                  <td>
                    <span className="_tblText">Biscuit</span>
                  </td>

                  <td>
                    <span className="_tblText">-</span>
                  </td>

                  <td>
                    <span className="_tblText">-</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="_dateNtime">
                      <span className="_tblText">19 Jun 2024</span>
                      <span className="_lgtText">05:30 AM</span>
                    </div>
                  </td>

                  <td>
                    <span className="_tblText">234234</span>
                  </td>

                  <td>
                    <span className="_tblText">Parle Biscuit</span>
                  </td>

                  <td>
                    <span className="_tblText">Indore</span>
                  </td>

                  <td>
                    <span className="_tblText">Jabalpur</span>
                  </td>

                  <td>
                    <span className="_tblText">Branch Number 1</span>
                  </td>

                  <td>
                    <div className="_dateNtime">
                      <span className="_tblText">Container</span>
                      <span className="_lgtText">32Feet XL</span>
                    </div>
                  </td>

                  <td>
                    <span className="_tblText">Biscuit</span>
                  </td>

                  <td>
                    <span className="_tblText">-</span>
                  </td>

                  <td>
                    <span className="_tblText">-</span>
                  </td>
                </tr>

                <tr>
                  <td colSpan={10}>
                    <div className="_separateBxs">
                      <button className="_disPatch_ind">Dispatch</button>

                      <span className="activeStatus">Active</span>

                      <div>
                        <CustomSelect options={arryOptions} />
                      </div>

                      <div className="_bidBtn_fx">
                        <span className="_bidTxt">Bids</span>
                        <span className="_bidCount">0</span>
                        <span className="_bidC_para">
                          {" "}
                          <span>No Bids</span>{" "}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody>
                <tr>
                  <td>
                    <div className="_dateNtime">
                      <span className="_tblText">19 Jun 2024</span>
                      <span className="_lgtText">05:30 AM</span>
                    </div>
                  </td>

                  <td>
                    <span className="_tblText">234234</span>
                  </td>

                  <td>
                    <span className="_tblText">Parle Biscuit</span>
                  </td>

                  <td>
                    <span className="_tblText">Indore</span>
                  </td>

                  <td>
                    <span className="_tblText">Jabalpur</span>
                  </td>

                  <td>
                    <span className="_tblText">Branch Number 1</span>
                  </td>

                  <td>
                    <div className="_dateNtime">
                      <span className="_tblText">Container</span>
                      <span className="_lgtText">32Feet XL</span>
                    </div>
                  </td>

                  <td>
                    <span className="_tblText">Biscuit</span>
                  </td>

                  <td>
                    <span className="_tblText">-</span>
                  </td>

                  <td>
                    <span className="_tblText">-</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas mdOffcanvas ${
          showAddIndent ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showAddIndent ? "visible" : "hidden" }}
      >
        {showAddIndent && (
          <AddIndentModal
            isOpen={showAddIndent}
            onClose={(e) => setShowAddIndent(false)}
          />
        )}
      </div>
      <div className={showAddIndent ? "offBack_drop" : ""}></div>
    </>
  );
};

export default Indent;
