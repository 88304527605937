import React from "react";

const CommonTextarea = ({
  type = "text",
  id = "",
  rows='3',
  name = "",
  value,
  placeholder = "",
  onChange,
  label = "",
  required = false,
  disabled = false,
  error,
  ref = null,
  preAddon = false,
  postAddon = false,
  onKeyDown
}) => {
  return (
    <div className={error ? "anvCustom_floatInput form-floating anvInputError" : "anvCustom_floatInput form-floating "}>
      <textarea
        className="form-control"
        type={type}
        id={name}
        name={name}
        rows={rows}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        disabled={disabled}
        ref={ref}
        onKeyDown={onKeyDown}
      ></textarea>
      <label htmlFor="floatingInput">
        {" "}
        <span>{label}</span>{" "}
      </label>
      {error && (
        <span className="input_drop_error">
          {error}
        </span>
      )}
    </div>
  );
};

export default CommonTextarea;
