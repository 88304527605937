import React, { useState, useEffect, useRef, useContext } from 'react';
import './CustomSelect.css'; // Import the CSS file for styling
import { UserContext } from 'context';
import { RxCross2 } from "react-icons/rx";

const CustomSelectAccount = ({
    name,
    options,
    onSelect,
    placeholder,
    value,
    error,
    focus,
    error_class = "",
    id = Math.random().toString(36).substr(2, 9), // Generate a unique ID
    label,
    flotingLabel,
    disabled,
    isAddButton,
    addButtonAction,
    openList = true
}) => {
    const { masterData } = useContext(UserContext);
    const invalid = !!(focus && error);
    const valid = !!(focus && !error);
    const [searchQuery, setSearchQuery] = useState(value ? value : options[0]?.label || '');
    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (value) {
            setSelectedValue(value);
        }
        setSearchQuery('');
    }, [value]);

    useEffect(() => {
        setFilteredOptions(
            options.filter(option => {
                if (typeof searchQuery === 'string' && option?.label?.toLowerCase()?.includes(searchQuery.toLowerCase())) {
                    return true;
                }
                return option?.sub_categories?.some(sub => sub.subLabel.toLowerCase().includes(searchQuery.toLowerCase()));
            })
        );
    }, [searchQuery, options]);

    useEffect(() => {
        const handleClickOutside = event => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputChange = e => {
        setSearchQuery(e.target.value);
        setShowDropdown(true);
    };

    const handleOptionClick = (option, sub) => {
        onSelect({ option, sub });
        setSelectedValue(option);
        setSelectedSubCategory(sub);
        setSearchQuery('');
        setShowDropdown(false);
    };

    const handleResetChange = () => {
        setSelectedValue('');
        setSelectedSubCategory('');
    };

    const handleAddButtonAction = () => {
        addButtonAction(error_class);
        setShowDropdown(false);
    };

    useEffect(() => {
        const element = document?.getElementById(id);
        const handleMouseDown = e => {
            if (e?.detail > 1) {
                e?.preventDefault();
            }
        };
        if (element) {
            element.addEventListener('mousedown', handleMouseDown);
        }
        return () => {
            if (element) {
                element.removeEventListener('mousedown', handleMouseDown);
            }
        };
    }, [id]);

    return (
        <div
            className={`custom-select-container ${error ? "custom-select_error" : ""}`}
            ref={dropdownRef}
        >
            <div className="input-wrapper">
                <input
                    id={id}
                    type="text"
                    placeholder={placeholder}
                    value={selectedSubCategory?.subLabel || ''}
                    name={name}
                    onChange={handleResetChange}
                    readOnly
                    onClick={() => setShowDropdown(!showDropdown)}
                    aria-haspopup="listbox"
                    aria-expanded={showDropdown}
                    aria-invalid={invalid}
                    disabled={disabled}
                />
                {selectedValue && (
                    <span className="clear-icon" onClick={handleResetChange}>
                        <RxCross2 size={20} color='black' />
                    </span>
                )}
            </div>
            <label className='_fltLabel'>
                <span>{label ? label : flotingLabel}</span>
            </label>
            {showDropdown && openList && (
                <ul className="custom-dropdown-menu-account" role="listbox">
                    <li>
                        <div className='dropSearch_bx_account'>
                            <input
                                type="text"
                                placeholder='Search'
                                onChange={handleInputChange}
                                autoFocus
                                aria-label="Search options"
                            />
                        </div>
                    </li>
                    <li className='mapList_views'>
                        <ul>
                            {filteredOptions.length > 0 ? (
                                filteredOptions.map((option, index) => (
                                    <li key={`${option.value}_${index}`}>
                                        {label === "Vehicle Number" ? (
                                            <>
                                                <div className='anvLrc'>
                                                    <div className='_vhlNumber'>
                                                        {option.label}
                                                    </div>
                                                    <div className='_vhlOwnType'>
                                                        {`${masterData?.vehicle_ownership[option?.vehicle_ownership]} | ${option.lorry_type_id?.lorry_type} ${option.vehicle_type_id_1?.name}`}
                                                    </div>
                                                </div>
                                                <div className="_vhlStatus">
                                                    <span className="available_status"></span>
                                                    {`${masterData?.vehicle_status[option?.vehicle_status]}`}
                                                </div>
                                            </>
                                        ) : (
                                            <div className='anvLrc_account'>
                                                <span className='_anvRo_locateBx_account' readOnly>
                                                    {option.label}
                                                </span>
                                                {option.sub_categories && (
                                                    <ul>
                                                        {option.sub_categories.map((sub, subIndex) => (
                                                            <li
                                                                key={subIndex}
                                                                className={`_anvRo_locateChildBx_account 
                                                                    ${selectedValue?.label === option.label && selectedSubCategory.subLabel === sub.subLabel ? "_vhlActive_account" : ""}`}
                                                                onClick={() => handleOptionClick(option, sub)}
                                                                role="option"
                                                            >
                                                                {sub.subLabel}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                    </li>
                                ))
                            ) : (
                                <li className='_anvRo_locateBx' style={{ fontSize: '13px' }}>
                                    No Result Found
                                </li>
                            )}
                        </ul>
                    </li>
                    {isAddButton && (
                        <li className='mt-1 _lineDrp anvLrc'>
                            <button className='_addNew_cdmBtn' onClick={handleAddButtonAction}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="none">
                                    <path d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z" fill="#1956F6" />
                                </svg> Add New
                            </button>
                        </li>
                    )}
                </ul>
            )}
            {error && (
                <span className={`input_drop_error ${error_class}`}>
                    {error}
                </span>
            )}
        </div>
    );
};

export default CustomSelectAccount;
