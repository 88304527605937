import React, { useEffect, useState, useRef } from 'react'
import { ButtonHeading, CommonModal } from 'components'
import StorageService from "utils/StorageServices/StorageServices";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { changeConsigneeStatusData, deleteConsigneeData, getConsigneeListData } from '_services/accountsServices/consigneeServices';
import { AddEditConsignee } from 'modals';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import BootstrapTable from 'components/BootstrapTable';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import MastersFilterModal from 'modals/MastersFilterModal';
import LoadingBar from 'react-top-loading-bar';

const Consignee = (props) => {

    const loadingBarRef = useRef(null);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    const [consigneeList, setConsigneeList] = useState([])
    const [loading, setLoading] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const itemsPerPage = 10;
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState({
        limit: 10,
        offset: 1,
        search: '',
        status: 1
    });

    const [editConsigneeData, setEditConsigneeData] = useState(null)
    const [checkboxStates, setCheckboxStates] = useState({});

    const toggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
    };

    const getDataConsigneeList = async () => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart();
            }

            let object = {
                ...filter,
                company_id: StorageService.getCompanyId(),
            }
            const res = await getConsigneeListData(object);
            if (res?.responseCode == 200) {
                setConsigneeList(res?.data?.consignee_list);
                setTotalItems(res?.data?.total_record);
            }
        } catch (error) {
            throw error;
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    useEffect(() => {
        getDataConsigneeList();
    }, [filter, showOffcanvas])

    const deleteHandler = (item) => {
        setIsDelete(item)
    }

    const submitDeleteConsignee = async () => {
        setLoading(true);
        try {
            let object = {
                company_id: isDelete?.company_id,
                consignee_id: isDelete?._id,
            }
            const res = await deleteConsigneeData(object);
            if (res?.responseCode == 200) {
                toast.success(`${res?.responseMessage}`);
                getDataConsigneeList();
                setLoading(false);
                setIsDelete(false);
            } else {
                toast.success('Error !! ');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    let { isOpen, onClose } = props;

    let propsObject = {
        isOpen: isDelete,
        closeModal: setIsDelete,
        modalTitle: "Confirmation",
        submitTitle: "Confirm",
        cancleTitle: "Close",
        onSubmit: submitDeleteConsignee,
        isLoadding: loading,
    }

    const columns = [
        { header: "", accessor: "serial" },
        { header: "Consignee Name", accessor: "consignee_name" },
        { header: "Consignee Code", accessor: "consignee_code" },
        { header: "City", accessor: "city" },
        { header: "Zone", accessor: "zone" },
        { header: "Status", accessor: "status" },
        { header: "Actions", accessor: "action" },
    ];

    useEffect(() => {
        if (consigneeList && consigneeList.length > 0) {
            const initialStates = consigneeList.reduce((acc, item) => {
                acc[item._id] = Boolean(item?.active);
                return acc;
            }, {});
            setCheckboxStates(initialStates);
        }
    }, [consigneeList]);

    const handleCheckboxChange = (id) => {
        const newStatus = !checkboxStates[id];

        setCheckboxStates(prevStates => ({ ...prevStates, [id]: newStatus }));

        const object = {
            company_id: StorageService.getCompanyId(),
            consignee_id: id,
            active: Number(newStatus)
        };
        statusChangeofConsignee(object);
    };

    const statusChangeofConsignee = async (obj) => {
        try {
            if (obj) {
                const res = await changeConsigneeStatusData(obj);
                if (res?.responseCode === 200) {
                    toast.success(`${res?.responseMessage}`);
                } else {
                    toast.error('Error !!');
                    await getDataConsigneeList();
                }
            }
        } catch (error) {
            toast.error(error.message);
            await getDataConsigneeList();
        }
    };



    const editHandler = (supplier) => {
        setShowOffcanvas(true);
        setEditConsigneeData(supplier);
    };

    const RowListData = (data) => {

        return data?.map((item, i) => ({
            ...item,
            // serial: <>{i + 1 + filter?.limit * (filter?.page - 1)}</>,
            serial: <>
                <div className="signDropdown">
                    <button className="dropbtn11">
                        <BsThreeDotsVertical />
                    </button>
                    <div className="dropdownContent">
                        <Link to={`/account/consignee/details/${item?._id}`} className='_avPointer'
                            style={{ textDecoration: 'none' }}
                        >
                            <span className='_samAnchor'>
                                <span className='viewIcon_avt'>
                                    <AiOutlineEye />
                                </span>View
                            </span>
                        </Link>

                        <div
                            onClick={(e) => editHandler(item)}
                            className='_avPointer'
                        >
                            <span className='_samAnchor'>
                                <span className='editIcon_avt'>
                                    <FiEdit />
                                </span>
                                Edit
                            </span>
                        </div>

                        <div
                            onClick={(e) => deleteHandler(item)}
                            className='_avPointer'
                        >
                            <span className='_samAnchor'>
                                <span className='deleteIcon_avt'>
                                    <FaRegTrashCan color='red' />
                                </span>
                                Delete
                            </span>
                        </div>
                    </div>
                </div>
            </>,
            consignee_code:
                <React.Fragment>
                    {item?.consignee_code ? item?.consignee_code : '-'}
                </React.Fragment>,
            consignee_name:
                <Link to={`/account/consignee/details/${item?._id}`} style={{ textDecoration: 'none' }}>
                    {item?.name ? item?.name : '-'}
                </Link>,
            city:
                <React.Fragment>
                    {item?.city?.label ? item?.city?.label : '-'}
                </React.Fragment>,
            zone:
                <React.Fragment>
                    {item?.zone_id?.name ? item?.zone_id?.name : '-'}
                </React.Fragment>,
            status: (
                <div className="_anvSwitcherNew form-check form-switch" style={{ width: "125px" }}>
                    {console.log("Consignee item", item)}
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="actStatus"
                        id={`actStatus-${item._id}`}
                        autoComplete="off"
                        checked={checkboxStates[item._id]}
                        onChange={() => handleCheckboxChange(item._id)}
                    />
                    <span className={checkboxStates[item._id] ? "active_label_masters_status" : "disabled_label_masters_status"}>
                        {checkboxStates[item._id] ? "Active" : "Disabled"}
                    </span>
                </div>
            ),
            action: (
                <React.Fragment>
                    -
                </React.Fragment>
            ),
        }));
    };

    const handlePageChange = (page) => {
        setFilter((s) => ({ ...s, offset: page }))
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li
                    key={i}
                    className={`page-item ${i === filter.offset ? "active" : ""}`}
                >
                    <a className="page-link" onClick={(e) => handlePageChange(i)}>
                        {i}
                    </a>
                </li>
            );
        }

        return (
            <ul className="pagination _avPointer">
                <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
                    <a
                        className="page-link"
                        onClick={() => handlePageChange(filter?.offset - 1)}
                    >
                        <IoChevronBack />
                    </a>
                </li>
                {pages}
                <li
                    className={`page-item ${filter?.offset === totalPages ? "disabled" : ""
                        }`}
                >
                    <a
                        className="page-link"
                        onClick={() => handlePageChange(filter?.offset + 1)}
                    >
                        <IoChevronForward />
                    </a>
                </li>
            </ul>
        );
    };

    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <ButtonHeading
                title={"Consignee"}
                onClick={toggleOffcanvas}
                buttonTitle="Add Consignee"
                showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
                onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
                filter={filter}
                setFilter={setFilter}
            />

            {/* <div className='p-5'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">S No.</th>
                            <th scope="col">Consignee Name</th>
                            <th scope="col">Location</th>
                            <th scope="col">Created On</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            consigneeList?.length > 0 ? (
                                consigneeList?.map((el, ind) => {
                                    return (
                                        <tr key={ind + 'abc'}>
                                            <th scope="row">{ind + 1}</th>
                                            <td>
                                                <Link to={`/account/consignee/details/${el?._id}`}> {el?.name} </Link>
                                            </td>
                                            <td>{el?.city?.label ? el?.city?.label : '-'}</td>
                                            <td>{new Date(el?.createdAt).toDateString()}</td>
                                            <td>
                                                <span disabled={loading}>Edit</span> &nbsp; &nbsp;
                                                <span onClick={(e) => deleteHandler(el)}>Delete</span>
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr > <td> No data available </td> </tr>
                            )
                        }
                    </tbody>
                </table>
            </div> */}

            <div className='_tabWith_outTab'>
                <BootstrapTable
                    columns={columns}
                    data={RowListData(consigneeList?.length > 0 ? consigneeList : [])}
                    isLoading={loading}
                />

                <div className='paginationFt_avt'>
                    {renderPagination()}
                </div>
            </div>

            <div
                className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showOffcanvas ? 'visible' : 'hidden' }}
            >
                {showOffcanvas &&
                    <AddEditConsignee isOpen={showOffcanvas} onClose={(e) => setShowOffcanvas(false)} />
                }
            </div>
            <div className={showOffcanvas ? 'offBack_drop' : ''}></div>

            <div
                className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
            >
                {showFilterModal &&
                    <MastersFilterModal
                        isOpen={showFilterModal}
                        onClose={() => setShowFilterModal(false)}
                        filter={filter}
                        setFilter={setFilter}
                    />
                }
            </div>
            <div className={showFilterModal ? 'offBack_drop' : ''}></div>

            {
                isDelete?.company_id && (
                    <CommonModal {...propsObject}>
                        <p className='p-3'> Do you really want to delete {isDelete?.name} </p>
                    </CommonModal>
                )
            }
        </div>
    )
}

export default Consignee