import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FaStar } from 'react-icons/fa6';
import toast from 'react-hot-toast';
import bidderImg from "../../../src/assets/img/bidderImg.png"
import { utils } from 'helper';
import StorageService from 'utils/StorageServices/StorageServices';
import { acceptConnectionRequest, rejectConnectionRequest, sendConnectionRequest, withDrawalConnectionRequest } from '_services';
import { UserContext } from 'context';
import moment from 'moment';

const CommonBox = (props) => {

  const loadingBarRef = useRef(null);
  let { element, commonProps, type, apiCallBack, nextModal } = props;
  let { isOpen, onClose } = commonProps;
  const { connectionDetailsGlobal, setConnectionDetailsGlobal } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const rejectRequestForConnection = async (data) => {
    setLoading(true);
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
        to_company_id: data?._id
      }
      const res = await rejectConnectionRequest(object);
      if (res?.responseCode === 200) {
        apiCallBack();
        setLoading(false);
        toast.success('Reject Connection Request Successfully');
      }
    } catch (error) {
      setLoading(false);
      toast.error('Server side api error !! ');
    } finally {
      setLoading(false);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  const acceptRequestForConnection = async (data) => {
    setLoading(true);
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
        to_company_id: data?._id
      }
      const res = await acceptConnectionRequest(object);
      if (res?.responseCode === 200) {
        apiCallBack();
        setLoading(false);
        toast.success('Accept Connection Request Successfully');
      }
    } catch (error) {
      setLoading(false);
      toast.error('Server side api error !! ');
    } finally {
      setLoading(false);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  const withdrawalRequestForConnection = async (data) => {
    setLoading(true);
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        company_id: StorageService.getCompanyId(),
        to_company_id: data?._id
      }
      const res = await withDrawalConnectionRequest(object);
      if (res?.responseCode === 200) {
        apiCallBack();
        setLoading(false);
        toast.success('Withdrawal Connection Request Successfully');
      }
    } catch (error) {
      setLoading(false);
      toast.error('Server side api error !! ');
    } finally {
      setLoading(false);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  useEffect(() => {
    setConnectionDetailsGlobal(element);
  }, [props])

  const createAtDate = useMemo(() => {
    const postDate = moment(element?.createdAt).utc().local();
    const currentDate = moment();
    const displayDate =
      currentDate.diff(postDate, "days") > 1
        ? postDate.format("Do MMMM")
        : postDate.fromNow();
    if (displayDate.startsWith("in")) {
      let date = displayDate?.split("in ")[1];
      return date + " ago";
    }
    return displayDate;
  }, [element?.createdAt]);

  return (
    <div className='__inr_conectBx'>
      <div className='_bidR_bxs' onClick={(e) => onClose(element)}>
        <div className='__bidR_usrBx'>
          <span className='_bidR_verifyIcon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
            </svg>
          </span>
          <img src={bidderImg} alt="" />

          <div className='_ratingBx'>
            <FaStar />
            <span>4.5</span>
          </div>
        </div>
        <div className='w-100'>
          <p className='tl_txt'> {element?.company ? utils.firstLetterCapital(element?.company) : '-'} </p>
          <p className='tsm_txt'>{element?.city?.label}, {element?.state?.label}</p>
        </div>
      </div>

      <div className='__connDetail_main'>
        <div className='__connDetail'>
          <span>Joined</span>
          <p> {createAtDate} </p>
        </div>

        <div className='__connDetail'>
          <span>Connections</span>
          <p> {(element?.connection?.connected?.length > 0) ? element?.connection?.connected?.length : 0} </p>
        </div>

        <div className='__connDetail'>
          <span>Load posted</span>
          <p>400</p>
        </div>
      </div>

      {type === 1 && (
        <div className='_bottomBtn_bar' onClick={(e) => onClose(element)}>
          <div className='singleDiv'><button className='_viewPrf_btn'>View Profile</button></div>
        </div>
      )}

      {type === 2 && (
        <div className='_bottomBtn_bar'>
          <div className='one_3rdDiv'><button className='_rejctPrf_btn' onClick={(e) => rejectRequestForConnection(element)} disabled={loading}> {loading ? 'Loading...' : 'Reject'} </button></div>
          <div className='twiceDiv'><button className='_acceptPrf_btn' onClick={(e) => acceptRequestForConnection(element)} disabled={loading}> {loading ? 'Loading...' : 'Accept'} </button></div>
        </div>
      )}

      {type === 3 && (
        <div className='_bottomBtn_bar'>
          <div className='one_3rdDiv'><button className='_viewPrf_btn' onClick={(e) => onClose(element)} >View Profile</button></div>
          <div className='twiceDiv'><button className='_rejctPrf_btn' onClick={(e) => withdrawalRequestForConnection(element)} disabled={loading}>{loading ? 'Loading...' : 'Withdraw Request'}</button></div>
        </div>
      )}

      {type === 4 && (
        <div className='_bottomBtn_bar'>
          <div className='one_3rdDiv'><button className='_viewPrf_btn' onClick={(e) => onClose(element)}>View Profile</button></div>
          <div className='twiceDiv'><button className='_acceptPrf_btn' onClick={(e) => nextModal(element)} disabled={loading}>{loading ? 'Loading...' : 'Send Connection Request'}</button></div>
        </div>
      )}
    </div>
  )
}

export default CommonBox