
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CommonInput, CustomSelect } from 'components'
import { errorSchema, utils } from 'helper';
import { State } from "country-state-city";
import StorageService from "utils/StorageServices/StorageServices";
import { UserContext } from "context";
import debounce from 'lodash.debounce';
import toast from 'react-hot-toast';
import { createConsignor, getAllConsignorgGroup, getConsignorListData } from '_services/accountsServices/consignorServices';
import { AddButton } from 'components/common/CommonButton';
import { constant } from 'constants';
import { createConsignee } from '_services/accountsServices/consigneeServices';
import { getZoneDropdownListData } from '_services/dropdownServices/dropdownService';

const formObj = {
    company_id: StorageService.getCompanyId(),
    name: '',
    consignor_id: '',
    consignee_code: '',
    address_line1: '',
    state: {},
    city: {},
    pincode: '',
    email_id: '',
    contact_no: '',
    gst_treatment_id: {},
    gst_state: {},
    zone_id: {},
    gst_no: '',
}

const initDrop = {
    city: "",
    state: "",
};

const AddEditConsignee = (props) => {

    let { isOpen, onClose, previousModal } = props;

    const { masterData, cities, states } = useContext(UserContext)

    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [allZoneList, setAllZoneList] = useState([])
    const [accountDrop, setAccountDrop] = useState(initDrop);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredCities, setFilteredCities] = useState([]);
    const [stateValue, setStateValue] = useState({});
    const [activeTab, setActiveTab] = useState(1);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createConsignee);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async (shouldCloseModal) => {
        try {
            let object = {
                ...formValue,
                gst_treatment_id: formValue?.gst_treatment_id?.value,
                zone_id: formValue?.zone_id?.value,
                gst_state: {
                    value: formValue?.gst_state?.value,
                    label: formValue?.gst_state?.label,
                },
            }
            const res = await createConsignee(object);
            if (!!res) {
                setFormValue({});
                setLoading(false);
                if (shouldCloseModal) {
                    onClose();
                } else {
                    setActiveTab(1); // Reset tab to the first tab
                }
                toast.success('Consignor Created Successfully');
                previousModal(true);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setIsButtonDisabled(false);
        }
    }

    const handleClick = async (e) => {
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createConsignee);

        if (activeTab === 3) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(true); // Pass true to close the modal
                setFormValue((prevData) => ({
                    ...prevData,
                    company_id: StorageService.getCompanyId(),
                    name: '',
                    consignor_id: '',
                    consignee_code: '',
                    address_line1: '',
                    state: {},
                    city: {},
                    pincode: '',
                    email_id: '',
                    contact_no: '',
                    gst_treatment_id: {},
                    gst_state: {},
                    zone_id: {},
                    gst_no: '',
                }))
                setFormError("");

            } else {
                setFormError(validationResult);
                setLoading(false);
            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
        }
    };

    const handleResetFormAndSubmit = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true); // Disable the button immediately
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createConsignee);

        if (activeTab === 3) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(false);
                setFormValue((prevData) => ({
                    ...prevData,
                    company_id: StorageService.getCompanyId(),
                    name: '',
                    consignor_id: '',
                    consignee_code: '',
                    address_line1: '',
                    state: {},
                    city: {},
                    pincode: '',
                    email_id: '',
                    contact_no: '',
                    gst_treatment_id: {},
                    gst_state: {},
                    zone_id: {},
                    gst_no: '',
                }))
                setFormError("");

            } else {
                setFormError(validationResult);
                setLoading(false);
                setIsButtonDisabled(false); // Re-enable the button if validation fails
            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
            setIsButtonDisabled(false); // Re-enable the button if validation fails
        }
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const onhandleSelect = async (item, name) => {

        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createConsignee);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }

    };

    const changeActiveTab = async (event, value) => {
        event.preventDefault();
        if (value > activeTab) {
            const validationResult = await utils.checkFormError(formValue, errorSchema.createConsignee);
            if (validationResult === true) {
                setActiveTab(value);
            } else {
                // toast("Fill Required field before changing tabs.");
                setFormError(validationResult);
            }
        } else {
            setActiveTab(value);
        }
    };

    const getZoneList = async () => {
        try {
            let object = { company_id: StorageService.getCompanyId() }
            const res = await getZoneDropdownListData(object);
            if (res) {
                setAllZoneList(res?.data?.zone_list)
            }
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        getZoneList();
    }, [StorageService.getCompanyId()])

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            gst_state: states?.map((el) => {
                return {
                    label: el?.name,
                    value: el?.isoCode
                }
            })[0],
            gst_treatment_id: masterData?.gst_type_list?.map((el) => {
                return {
                    label: el?.name,
                    value: el?.id
                }
            })[0],
            zone_id: allZoneList?.map((el) => {
                return {
                    label: el?.name,
                    value: el?._id
                }
            })[0],
            company_id: StorageService.getCompanyId(),
        }));
    }, [allZoneList]);

    const closeButtonHandler = () => {
        onClose(!isOpen);
        if (previousModal) {
            previousModal(true);
        }
    }

    const handleCityChange = (data) => {
        handleOnChange("city", {
            label: data?.label,
            value: data?.name,
        })
        const stateDetails = State.getStateByCodeAndCountry(
            data?.stateCode,
            data?.countryCode
        );
        setStateValue(stateDetails)
    }

    useEffect(() => {
        if (stateValue) {
            handleOnChange("state", { label: stateValue?.name, value: stateValue?.isoCode })
        }
    }, [stateValue])

    return (
        <>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Add Consignee</h5>
                <button type="button" className="btn-close" onClick={(e) => closeButtonHandler(e)}></button>
            </div>

            <div className="offcanvas-body">

                <div className='pr_procssBx'>
                    <div className='pr_procssBx_grid'>

                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                placeholder="Consignee Name"
                                label="Consignee Name *"
                                name='name'
                                value={formValue.name}
                                focus={!!(typeof formError === "object" && formError?.name)}
                                error={!!(typeof formError === "object") ? formError?.name : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                placeholder="Consignee Code"
                                label="Consignee Code"
                                name='consignee_code'
                                value={formValue.consignee_code}
                                focus={!!(typeof formError === "object" && formError?.consignee_code)}
                                error={!!(typeof formError === "object") ? formError?.consignee_code : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                    </div>

                </div>

                <div className='addPr_flBx'>
                    <ul className="nav nav-tabs cstNew_tabs" id="addParty" role="tablist">
                        {constant.addPartyTabs?.map((item, indx) => (
                            <li
                                className="nav-item"
                                role="presentation"
                                key={indx + item?.value}
                            >
                                <button
                                    className={
                                        "nav-link " + (activeTab === item?.id ? "active" : "")
                                    }
                                    type="button"
                                    aria-selected={activeTab === item?.id}
                                    onClick={(e, val) => changeActiveTab(e, item.id)}
                                >
                                    {item?.title}
                                </button>
                            </li>
                        ))}
                    </ul>

                    {activeTab === 1 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>

                                <div className='singleDiv'>
                                    <div className="h4Head">Add Address</div>
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="Address Line 1"
                                        label="Address Line 1"
                                        name='address_line1'
                                        value={formValue.address_line1}
                                        focus={!!(typeof formError === "object" && formError?.address_line1)}
                                        error={!!(typeof formError === "object") ? formError?.address_line1 : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={cities}
                                        onSelect={(data) => handleCityChange(data, 'city')}
                                        value={formValue?.city}
                                        placeholder="Select a city"
                                        error={formError["city.label"]}
                                        label="City"
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="State"
                                        label="State"
                                        name='state'
                                        disabled={true}
                                        value={formValue?.state?.label}
                                        focus={!!(typeof formError === "object" && formError?.state)}
                                        error={!!(typeof formError === "object") ? formError?.state : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="PIN Code"
                                        label="PIN Code"
                                        name='pincode'
                                        value={formValue.pincode}
                                        focus={!!(typeof formError === "object" && formError?.pincode)}
                                        error={!!(typeof formError === "object") ? formError?.pincode : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <div className="h4Head">Other Contact</div>
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="Alternate Number"
                                        label="Alternate Number"
                                        name='contact_no'
                                        maxLength="10"
                                        onInput={maxLengthCheck}
                                        value={formValue.contact_no}
                                        focus={!!(typeof formError === "object" && formError?.contact_no)}
                                        error={!!(typeof formError === "object") ? formError?.contact_no : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="Email"
                                        placeholder="Email ID"
                                        label='Email ID'
                                        name='email_id'
                                        value={formValue.email_id}
                                        focus={!!(typeof formError === "object" && formError?.email_id)}
                                        error={!!(typeof formError === "object") ? formError?.email_id : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 2 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>
                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={masterData?.gst_type_list?.map((el) => {
                                            return {
                                                label: el?.name,
                                                value: el?.id
                                            }
                                        })}
                                        name="gst_treatment_id"
                                        flotingLabel="GST Type"
                                        value={formValue.gst_treatment_id}
                                        error={formError["gst_treatment_id"]}
                                        // focus={!!(typeof formError === "object" && formError?.gst_treatment_id)}
                                        // error={!!(typeof formError === "object") ? formError?.gst_treatment_id : ""}
                                        onSelect={(data) => onhandleSelect(data, 'gst_treatment_id')}
                                        placeholder="Select GST Type"
                                    />
                                </div>

                                {
                                    (formValue?.gst_treatment_id?.value == '2' || formValue?.gst_treatment_id?.value == '3') && (
                                        <div className='singleDiv'>
                                            <CommonInput
                                                type="number"
                                                placeholder="GST Number"
                                                label='GST Number'
                                                name='gst_no'
                                                value={formValue.gst_no}
                                                onChange={({ target: { name, value } }) =>
                                                    handleOnChange(name, value)
                                                }
                                            />
                                        </div>
                                    )
                                }

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={states?.map((el) => {
                                            return {
                                                label: el?.name,
                                                value: el?.isoCode
                                            }
                                        })}
                                        name="gst_state"
                                        value={formValue.gst_state}
                                        flotingLabel="GST State"
                                        focus={!!(typeof formError === "object" && formError?.gst_state)}
                                        error={!!(typeof formError === "object") ? formError?.gst_state : ""}
                                        onSelect={(data) => onhandleSelect(data, 'gst_state')}
                                        placeholder="Select GST State"
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 3 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={allZoneList?.map((el) => {
                                            return {
                                                label: el?.name,
                                                value: el?._id
                                            }
                                        })}
                                        placeholder="Zone"
                                        flotingLabel="Zone"
                                        value={formValue.zone_id}
                                        name="zone_id"
                                        focus={!!(typeof formError === "object" && formError?.zone_id)}
                                        error={!!(typeof formError === "object") ? formError?.zone_id : ""}
                                        onSelect={(data) => onhandleSelect(data, 'zone_id')}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <div className='offcanvas-footer'>
                {
                    (activeTab === 3) ? (
                        <React.Fragment>

                            <AddButton onClick={handleClick} disabled={loading} name={loading ? 'Loading...' : 'Add Consignee'} />
                            {/* <AddButton onClick={handleClick} disabled={loading} name='Add Market Driver' /> */}
                            <button
                                className={isButtonDisabled ? '_addNewComm_btn_disabled' : '_addNewComm_btn'}
                                onClick={handleResetFormAndSubmit}
                                disabled={isButtonDisabled}

                            >
                                Add & New
                            </button>
                        </React.Fragment>
                    ) : (activeTab === 2 ? (
                        <AddButton onClick={handleClick} name='Continue' />
                    ) : (
                        <AddButton onClick={handleClick} disabled={!formValue?.name} name='Continue' />
                    ))
                }
            </div>

        </>
    )
}

export default AddEditConsignee