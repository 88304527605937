import { Images } from 'constants'
import React from 'react'

const Support = () => {
    return (
        <>
            <div className='_suppBack_bg'>
                <div className='_suppHeading'>
                    <h4>Support & Help Videos</h4>
                    <p>Registered Mobile Number : 9993455581</p>
                </div>

                <div className='ant-row' style={{ rowGap: "32px" }}>

                    <div className='ant-col ant-col-24'>
                        <div className='ant-card ant-card-bordered'>
                            <div className='ant-card-body'>
                                <div className='ant-row ant-row-space-between' style={{ marginLeft: "-6px", marginRight: "-6px", rowGap: "12px" }}>
                                    <div className='ant-col d-flex align-items-center' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <div>
                                            <img src={Images.CLIENTIMG1} alt="" width={164} />
                                        </div>

                                        <div className='grw_buss'>
                                            <h5>Grow Your Business With Transport360</h5>

                                            <ul className='list-style-none'>
                                                <p>
                                                    <img src={Images.GREENTICK.default} alt="" className='mr16' />
                                                    Mark Attendance and Deduct Salary for Absents
                                                </p>

                                                <p>
                                                    <img src={Images.GREENTICK.default} alt="" className='mr16' />
                                                    Record Salary, Advance and Pending Payments
                                                </p>

                                                <p>
                                                    <img src={Images.GREENTICK.default} alt="" className='mr16' />
                                                    Send Payment & Attendance Reports via WhatsApp & SMS
                                                </p>
                                            </ul>

                                            <div className='_bookDemo_bx d-flex align-items-center mt32'>

                                                <button className='_bookDemo_btn'>
                                                    <img src={Images.CALENDERICON.default} alt="" />
                                                    Book A Demo
                                                </button>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='ant-col ant-col-8 d-flex justify-content-end' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <div className="playerOp1" style={{ width: "240px" }}>
                                            <div className='_inPlayer1'>
                                                <div className='playIcon'>
                                                    <img src={Images.PLAYICON.default} alt="" />
                                                </div>
                                            </div>
                                            <p>How To Use Transport360</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='ant-col ant-col-24'>
                        <h6 className='_crd_head'>Frequently Asked Questions</h6>
                        <div className='ant-card ant-card-bordered mt16'>
                            <div className='ant-card-body'>
                                <div className='ant-row ' style={{ marginLeft: "-20px", marginRight: "-20px", rowGap: "32px" }}>
                                    <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                        <div className="playerOp1" style={{ width: "240px" }}>
                                            <div className='_inPlayer1'>
                                                <div className='playIcon'>
                                                    <img src={Images.PLAYICON.default} alt="" />
                                                </div>
                                            </div>
                                            <p>How to add multiple staff in one go?</p>
                                        </div>
                                    </div>

                                    <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                        <div className="playerOp1" style={{ width: "240px" }}>
                                            <div className='_inPlayer1'>
                                                <div className='playIcon'>
                                                    <img src={Images.PLAYICON.default} alt="" />
                                                </div>
                                            </div>
                                            <p>How to clear previous month dues?</p>
                                        </div>
                                    </div>

                                    <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                        <div className="playerOp1" style={{ width: "240px" }}>
                                            <div className='_inPlayer1'>
                                                <div className='playIcon'>
                                                    <img src={Images.PLAYICON.default} alt="" />
                                                </div>
                                            </div>
                                            <p>How can staff mark their attendance?</p>
                                        </div>
                                    </div>

                                    <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                        <div className="playerOp1" style={{ width: "240px" }}>
                                            <div className='_inPlayer1'>
                                                <div className='playIcon'>
                                                    <img src={Images.PLAYICON.default} alt="" />
                                                </div>
                                            </div>
                                            <p>How to edit salary?</p>
                                        </div>
                                    </div>

                                    <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                        <div className="playerOp1" style={{ width: "240px" }}>
                                            <div className='_inPlayer1'>
                                                <div className='playIcon'>
                                                    <img src={Images.PLAYICON.default} alt="" />
                                                </div>
                                            </div>
                                            <p>How to add and assign shifts to your staff?</p>
                                        </div>
                                    </div>

                                    <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                        <div className="playerOp1" style={{ width: "240px" }}>
                                            <div className='_inPlayer1'>
                                                <div className='playIcon'>
                                                    <img src={Images.PLAYICON.default} alt="" />
                                                </div>
                                            </div>
                                            <p>How can I add and change business holiday?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='ant-col ant-col-24'>
                        <h6 className='_crd_head'>Select Category</h6>
                        <div className='ant-card ant-card-bordered mt16'>
                            <div className='ant-card-body'>
                                <div className='ant-row' style={{ marginLeft: "-6px", marginRight: "-6px", rowGap: "12px" }}>
                                    <div className='ant-col' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <button className='_filt_tabBtn _filt_tabActive'>Adding Staff</button>
                                    </div>

                                    <div className='ant-col' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <button className='_filt_tabBtn'>Attendance</button>
                                    </div>

                                    <div className='ant-col' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <button className='_filt_tabBtn'>Reports</button>
                                    </div>

                                    <div className='ant-col' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <button className='_filt_tabBtn'>Payments</button>
                                    </div>

                                    <div className='ant-col' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <button className='_filt_tabBtn'>Staff Details</button>
                                    </div>

                                    <div className='ant-col' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <button className='_filt_tabBtn'>Data and Security</button>
                                    </div>

                                    <div className='ant-col' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <button className='_filt_tabBtn'>Salary</button>
                                    </div>

                                    <div className='ant-col' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <button className='_filt_tabBtn'>Staff App</button>
                                    </div>

                                    <div className='ant-col' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <button className='_filt_tabBtn'>Cashbook</button>
                                    </div>

                                    <div className='ant-col' style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                                        <button className='_filt_tabBtn'>Others</button>
                                    </div>
                                </div>

                                <div>
                                    <div className='ant-row mt32' style={{ marginLeft: "-20px", marginRight: "-20px", rowGap: "32px" }}>
                                        <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                            <div className="playerOp1" style={{ width: "240px" }}>
                                                <div className='_inPlayer1'>
                                                    <div className='playIcon'>
                                                        <img src={Images.PLAYICON.default} alt="" />
                                                    </div>
                                                </div>
                                                <p>How to add multiple staff in one go?</p>
                                            </div>
                                        </div>

                                        <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                            <div className="playerOp1" style={{ width: "240px" }}>
                                                <div className='_inPlayer1'>
                                                    <div className='playIcon'>
                                                        <img src={Images.PLAYICON.default} alt="" />
                                                    </div>
                                                </div>
                                                <p>How to clear previous month dues?</p>
                                            </div>
                                        </div>

                                        <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                            <div className="playerOp1" style={{ width: "240px" }}>
                                                <div className='_inPlayer1'>
                                                    <div className='playIcon'>
                                                        <img src={Images.PLAYICON.default} alt="" />
                                                    </div>
                                                </div>
                                                <p>How can staff mark their attendance?</p>
                                            </div>
                                        </div>

                                        <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                            <div className="playerOp1" style={{ width: "240px" }}>
                                                <div className='_inPlayer1'>
                                                    <div className='playIcon'>
                                                        <img src={Images.PLAYICON.default} alt="" />
                                                    </div>
                                                </div>
                                                <p>How to edit salary?</p>
                                            </div>
                                        </div>

                                        <div className="ant-col" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                            <div className="playerOp1" style={{ width: "240px" }}>
                                                <div className='_inPlayer1'>
                                                    <div className='playIcon'>
                                                        <img src={Images.PLAYICON.default} alt="" />
                                                    </div>
                                                </div>
                                                <p>How to add and assign shifts to your staff?</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='footerPosition'>
                    <div className="footer-ctr">
                        <div className='promise-txt'>Our Promise</div>

                        <div className='content-ctr safe'>
                            <div className='img-ctr'></div>
                            <div className='text-ctr'>
                                <div className='mainTxt'>100% Safe</div>
                                <div className='sub'>Transport360 is safe</div>
                            </div>
                        </div>

                        <div className='content-ctr bkp'>
                            <div className='img-ctr'></div>
                            <div className='text-ctr'>
                                <div className='mainTxt'>100% Auto Backup</div>
                                <div className='sub'>All data is linked to your phone number</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Support