import { API, axiosInstance } from "lib";

export const createMaterial = (data = {}) => {
  return axiosInstance
    .post(API.MATERIAL_DATA + 'add_material', data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getMaterialListData = (data={}) => {
  return axiosInstance
    .post(API.MATERIAL_DATA + 'material_list' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteMaterialData = (data={}) => {
  return axiosInstance
    .post(API.MATERIAL_DATA + 'delete_material' , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};