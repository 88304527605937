import { getMasterRole, getRoleDetail, updateRoles } from "_services";
import {  ButtonHeading, CommonInput, CommonTextarea } from "components";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";

const EditRole = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((state) => state.auth);
  const [roleName, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [whichRole, setWhichRole] = useState();
  const [permission, setPermission] = useState(false);
  const [disble, setDisable] = useState(true);
  const [roleError, setRoleError] = useState({
    name: false,
    check: false,
  });

  useEffect(() => {
    getAllMain();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values
    if (value) {
      setRoleName(value);
      setRoleError("");
    } else {
      setRoleName("");
      setRoleError({ ...roleError, name: "Role Name is required" });
    }
  };
  async function getAllMain() {
    try {
      const res = await getMasterRole({
        company_id: StorageService.getCompanyId(),
      });
      
      if (res?.responseCode === 200) {
        setWhichRole(res?.data?.which_role);
        res?.data?.menu_list.map((user) => {
          let array = [];
          user?.menus?.map((menus) => {
            array.push(
              ...array,
              menus?.permissions?.create === 1 ||
                menus?.permissions?.edit === 1 ||
                menus?.permissions?.delete === 1 ||
                menus?.permissions?.view === 1
            );
          });
          setPermission(array.every((i) => i) ? false : true);
        });
      }

      const payload = {
        company_id: StorageService.getCompanyId(),
        role_id: atob(id),
      };
      const resp = await getRoleDetail(payload);
      if (resp?.responseCode === 200) {
        
        setRoleName(resp.data.role_name);
        setDescription(resp.data.description);
        const updatedData = resp?.data?.menus.map((user, index1) => ({
          ...user,
          menus: user.menus.map((menu, index2) => ({
            ...menu,
            view: menu.permissions.view === 1 ? 1 : 0,
            edit: menu.permissions.edit === 1 ? 1 : 0,
            delete: menu.permissions.delete === 1 ? 1 : 0,
            create: menu.permissions.create === 1 ? 1 : 0,
            full_access:
              menu.permissions.view === 1 &&
              menu.permissions.edit === 1 &&
              menu.permissions.delete === 1 &&
              menu.permissions.create === 1
                ? 1
                : 0,
          })),
        }));
        setRoleData(updatedData);
      }

      // setWhichRole(res?.payload?.which_role)

      await res?.payload?.menu_list.map((user) => {
        let array = [];
        user?.menus?.map((menus) => {
          array.push(
            ...array,
            menus?.permissions?.create === 1 ||
              menus?.permissions?.edit === 1 ||
              menus?.permissions?.delete === 1 ||
              menus?.permissions?.view === 1
          );
        });
        setPermission(array.every((i) => i) ? false : true);
      });
    } catch (error) {
      throw error;
    }
  }

  const handleChange = (e, index) => {
    const { id, checked, value } = e.target;

    roleData?.map((category, cateIndex) => {
      category?.menus.map((oneMenu, newIndex) => {
        if (
          id === `view_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          console.log("permission", permission);

          let newData = [...roleData];
          console.log("oneMenu", oneMenu);
          console.log("newData", newData);
          newData[cateIndex].menus[newIndex].view = newData[cateIndex].menus[
            newIndex
          ]?.view
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].view) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;

            setDisable(true);
          }

          setRoleData(newData);
        } else if (
          id === `create_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];

          newData[cateIndex].menus[newIndex].create = newData[cateIndex].menus[
            newIndex
          ]?.create
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].create) {
            newData[cateIndex].menus[newIndex].create = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].create = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;

            setDisable(true);
          }

          // newData[cateIndex].menus[newIndex].view = newData[cateIndex].menus[
          //   newIndex
          // ]?.view
          //   ? 0
          //   : 1;

          if (
            newData[cateIndex].menus[newIndex].create ||
            newData[cateIndex].menus[newIndex].edit ||
            newData[cateIndex].menus[newIndex].delete
          ) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            setDisable(true);
          }

          setRoleData(newData);
        } else if (
          id === `edit_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].edit = newData[cateIndex].menus[
            newIndex
          ]?.edit
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].edit) {
            newData[cateIndex].menus[newIndex].edit = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].edit = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;

            setDisable(true);
          }

          if (
            newData[cateIndex].menus[newIndex].create ||
            newData[cateIndex].menus[newIndex].edit ||
            newData[cateIndex].menus[newIndex].delete
          ) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            setDisable(true);
          }

          setRoleData(newData);
        } else if (
          id === `delete_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].delete = newData[cateIndex].menus[
            newIndex
          ]?.delete
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].delete) {
            newData[cateIndex].menus[newIndex].delete = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].delete = 0;
            newData[cateIndex].menus[newIndex].full_access = 0;
            setDisable(true);
          }

          if (
            newData[cateIndex].menus[newIndex].create ||
            newData[cateIndex].menus[newIndex].edit ||
            newData[cateIndex].menus[newIndex].delete
          ) {
            newData[cateIndex].menus[newIndex].view = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            setDisable(true);
          }

          setRoleData(newData);
        }

        let newData = [...roleData];

        if (
          newData[cateIndex].menus[newIndex].create &&
          newData[cateIndex].menus[newIndex].edit &&
          newData[cateIndex].menus[newIndex].delete &&
          newData[cateIndex].menus[newIndex].view
        ) {
          newData[cateIndex].menus[newIndex].full_access = 1;
        } else {
          newData[cateIndex].menus[newIndex].full_access = 0;
        }
      });
    });
  };

  const handleSelectAll = (e, index) => {
    const { id, checked, value } = e.target;

    let fullcheckValue = false;

    roleData?.map((category, cateIndex) => {
      category?.menus.map((oneMenu, newIndex) => {
        if (
          id === `allSelect_${index}_${oneMenu?.category_id?._id}` &&
          index === newIndex
        ) {
          let newData = [...roleData];
          newData[cateIndex].menus[newIndex].full_access = newData[cateIndex]
            .menus[newIndex]?.full_access
            ? 0
            : 1;

          if (newData[cateIndex].menus[newIndex].full_access) {
            newData[cateIndex].menus[newIndex].view = 1;
            newData[cateIndex].menus[newIndex].edit = 1;
            newData[cateIndex].menus[newIndex].create = 1;
            newData[cateIndex].menus[newIndex].delete = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            newData[cateIndex].menus[newIndex].edit = 0;
            newData[cateIndex].menus[newIndex].create = 0;
            newData[cateIndex].menus[newIndex].delete = 0;
            setDisable(true);
          }

          setRoleData(newData);
        }
        if (oneMenu.full_access) {
          fullcheckValue = true;
        }
      });

      setDisable(!fullcheckValue);
    });
  };

  const handleFullCheck = (e, index) => {
    const { id, checked, value } = e.target;

    roleData?.map((category, cateIndex) => {
      category?.menus.map((oneMenu, newIndex) => {
        if (id === `fullCheck_${index}` && index === cateIndex) {
          let newData = [...roleData];

          // newData[cateIndex].menus[newIndex].full_access = newData[cateIndex]
          //   .menus[newIndex]?.full_access
          //   ? 0
          //   : 1;

          if (checked) {
            newData[cateIndex].menus[newIndex].full_access = 1;
          } else {
            newData[cateIndex].menus[newIndex].full_access = 0;
          }

          if (newData[cateIndex].menus[newIndex].full_access) {
            newData[cateIndex].menus[newIndex].view = 1;
            newData[cateIndex].menus[newIndex].edit = 1;
            newData[cateIndex].menus[newIndex].create = 1;
            newData[cateIndex].menus[newIndex].delete = 1;
            setDisable(false);
          } else {
            newData[cateIndex].menus[newIndex].view = 0;
            newData[cateIndex].menus[newIndex].edit = 0;
            newData[cateIndex].menus[newIndex].create = 0;
            newData[cateIndex].menus[newIndex].delete = 0;
            setDisable(true);
          }

          setRoleData(newData);
        }
      });
    });
  };


  const handuleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    //prepare add role data here
    let role_menus = [];
    roleData?.map((i) => {
      i?.menus?.map((a) => {
        role_menus.push({
          menu_id: a?._id,
          permissions: {
            view: a?.view,
            create: a?.create,
            edit: a?.edit,
            delete: a?.delete,
          },
        });
      });
    });

    let payload = {
      company_id:StorageService.getCompanyId(),
      role_name: roleName,
      role_menus: role_menus,
      description: description,
      role_id: atob(id),
    };
    try {
      const res = await updateRoles(payload);
     

      if (res?.responseCode === 200) {
        toast.success(res?.responseMessage);
        navigate("/setting/role");
        setDisable(false);
      } else {
        toast.error(res?.responseMessage);
        setDisable(false);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <div>
      <ButtonHeading title={"Edit Role"} buttonTitle={"Add Role"} onClick={()=>navigate("/setting/role/addrole")}/>

      <div>
        <div className="_addContainer">
          <div className="_addRl_bx">
            <div className="_addRlHead">Role Details</div>

            <div className="_addRl_tableBx">
              <div className="_rlsInputs_bx">
                <div className="addRl_inputOtr">
                  {/* <input type="text" className='simpleInput' placeholder='Role Name' /> */}
                  <CommonInput
                    type="text"
                    placeholder="Role Name"
                    label="Role Name"
                    value={roleName}
                    onChange={handleInputChange}
                    error={roleError.name}
                  />
                </div>

                <div className="addRl_inputOtr">
                  {/* <textarea name="" className='simpleTextarea' placeholder='Description' id=""></textarea> */}
                  <CommonTextarea
                    placeholder="Description"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              {roleError.check && (
                <span className={"input_drop_error"}>{roleError.check}</span>
              )}
              <div className="_otrRl_table">
                {roleData.map((category, categoryIndex) => {
                  return (
                    <div className="_mainRl_table" key={category?.name}>
                      <div className="_addRl_headCheck">
                        <input
                          type="checkbox"
                          label="Dashboard"
                          id={`fullCheck_${categoryIndex}`}
                          onChange={(e) => handleFullCheck(e, categoryIndex)}
                          checked={category?.menus.every(
                            (i) => i.full_access === 1
                          )}
                          value={
                            category?.menus.every((i) => i.full_access === 1)
                              ? 1
                              : 0
                          }
                          disabled={
                            whichRole === 0
                              ? permission
                                ? false
                                : true
                              : false
                          }
                        />
                        <label
                          htmlFor={`fullCheck_${categoryIndex}`}
                          className="anvLabel"
                        >
                          {category?.name}
                        </label>
                      </div>

                      {category?.name === "Dashboard" ? (
                        <table>
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>View</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>Summary</td>
                              <td>
                                <input
                                  type="checkbox"
                                  id="Summary"
                                  className="_checkTbl"
                                />
                                <label htmlFor="Summary">
                                  <span style={{ visibility: "hidden" }}>
                                    Summary
                                  </span>
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td>Live Location</td>
                              <td>
                                <input
                                  type="checkbox"
                                  id="LiveLocation"
                                  className="_checkTbl"
                                />
                                <label htmlFor="LiveLocation">
                                  <span style={{ visibility: "hidden" }}>
                                    Live Location
                                  </span>
                                </label>
                              </td>
                            </tr>

                            <tr>
                              <td>Timeline</td>
                              <td>
                                <input
                                  type="checkbox"
                                  id="Timeline"
                                  className="_checkTbl"
                                />
                                <label htmlFor="Timeline">
                                  <span style={{ visibility: "hidden" }}>
                                    Timeline
                                  </span>
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        category?.category_type === 1 && (
                          <table>
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>Full Access</th>
                                <th>View</th>
                                <th>Create</th>
                                <th>Edit</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            {category?.menus.map((menus, menuIndex) => (
                              <tbody key={`menuIndex_${menuIndex}`}>
                                <tr>
                                  <td> {menus?.label}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`allSelect_${menuIndex}_${menus?.category_id._id}`}
                                      checked={
                                        menus?.full_access ? true : false
                                      }
                                      onChange={(e) =>
                                        handleSelectAll(e, menuIndex)
                                      }
                                      value={menus?.full_access}
                                      disabled={
                                        whichRole === 0
                                          ? menus?.permissions?.create === 1 &&
                                            menus?.permissions?.edit === 1 &&
                                            menus?.permissions?.delete === 1 &&
                                            menus?.permissions?.view === 1
                                            ? false
                                            : true
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`allSelect_${menuIndex}_${menus?.category_id._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl1
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`view_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.view}
                                      checked={menus?.view ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? menus?.permissions?.create === 1 &&
                                            menus?.permissions?.edit === 1 &&
                                            menus?.permissions?.delete === 1 &&
                                            menus?.permissions?.view === 1
                                            ? false
                                            : true
                                          : menus?.create ||
                                            menus?.edit ||
                                            menus?.delete
                                      }
                                    />
                                    <label
                                      htmlFor={`view_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl2
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`create_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.create}
                                      checked={menus?.create ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? !menus?.permissions.create
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`create_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl3
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`edit_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.edit}
                                      checked={menus?.edit ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? !menus?.permissions.edit
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`edit_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl4
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="_checkTbl"
                                      id={`delete_${menuIndex}_${menus?.category_id?._id}`}
                                      value={menus?.delete}
                                      checked={menus?.delete ? true : false}
                                      onChange={(e) =>
                                        handleChange(e, menuIndex)
                                      }
                                      disabled={
                                        whichRole === 0
                                          ? !menus?.permissions.delete
                                          : false
                                      }
                                    />
                                    <label
                                      htmlFor={`delete_${menuIndex}_${menus?.category_id?._id}`}
                                    >
                                      <span style={{ visibility: "hidden" }}>
                                        cl5
                                      </span>
                                    </label>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        )
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="bottomFix">
          <button
            className="_addComm_btn"
            type="button"
            disabled={loading || disble || !roleName}
            onClick={(e) => handuleSubmit(e)}
          >
            Update Role
          </button>
          
          <button className="_cancel_btn" onClick={()=>navigate("/setting/role")}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default EditRole;
