import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    search: "",
};

const lrSearchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    updateSearchValue: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const { updateSearchValue } = lrSearchSlice.actions;
export default lrSearchSlice.reducer;
