import { UserContext } from "context";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { StorageServices } from "utils";

const PrivateRoute = ({ element }) => {
    const navigate = useNavigate();
    const { token, setShowAddAcountModal, setShowCreateCompanyModal } = useContext(UserContext)
    const { loading, error, userData, data } = useSelector((state) => state.auth);

    let isAuthenticated = StorageServices.getToken()
    let userExist = StorageServices.getUserExist()
    let company = StorageServices.getCompanyId()

  
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
        if (isAuthenticated && userExist == 0) {
            setShowAddAcountModal(true)
        }
        if (isAuthenticated && userExist == 1 && company === "undefined") {
            setShowCreateCompanyModal(true)
        }
    }, [navigate, isAuthenticated, userExist, token, company])

    return isAuthenticated
        ? element
        : <Navigate to="/" />;
}

export default PrivateRoute