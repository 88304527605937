import { API, axiosInstance } from "lib";

export const getBranch = (data = {}) => {
  return axiosInstance
    .post(API.GET_SINGLE_BRANCH, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const createBranch = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_BRANCH, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};
export const updateBranch = (data = {}) => {
  return axiosInstance
    .post(API.UPDATE_BRANCH, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getBranchListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_BRANCH_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteBranchData = (data = {}) => {
  return axiosInstance
    .post(API.DELETE_BRANCH, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const fetchBranchDetailsData = (data = {}) => {
  return axiosInstance
    .post(API.GET_SINGLE_BRANCH, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const changeBranchStatusData = (data = {}) => {
  return axiosInstance
    .post(API.CHANGE_BRANCH_STATUS, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};
