import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CommonInput, CommonTextarea, CustomSelect } from 'components'
import { errorSchema, utils } from 'helper';
import { City, State } from "country-state-city";
import Dropdown from 'components/common/Customdropdown/Dropdown';
import StorageService from "utils/StorageServices/StorageServices";
import { UserContext } from "context";
import debounce from 'lodash.debounce';
import toast, { Toaster } from 'react-hot-toast';
import { createConsignor, getAllConsignorgGroup, getConsignorListData } from '_services/accountsServices/consignorServices';
import { AddButton } from 'components/common/CommonButton';
import { constant } from 'constants/constant';
import { createVenders } from '_services/accountsServices/vendorsServices';

const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const formObj = {
    company_id: StorageService.getCompanyId(),
    name: '',
    contact_no: '',
    opening_balance: '',
    pay_type: '1',
    as_of_date: '',
    address_line1: '',
    state: {},
    city: {},
    pincode: '',
    email_id: '',
    alt_contact_no: '',
    gst_no: '',
    gst_treatment_id: {},
    gst_state: {},
    notes: ''
}

export default function AddEditVendors(props) {
    let { isOpen, onClose, isPreviousModal, showingPrefield } = props;

    const { masterData, cities, states } = useContext(UserContext)

    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [stateValue, setStateValue] = useState({});

    const [activeTab, setActiveTab] = useState(1);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createVendersSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async (shouldCloseModal) => {
        try {
            let object = {
                ...formValue,
                gst_treatment_id: formValue?.gst_treatment_id?.value,
            }
            const res = await createVenders(object);
            if (!!res) {
                setFormValue({});
                setLoading(false);
                toast.success('Vendor Created Successfully');
                if (shouldCloseModal) {
                    onClose();
                } else {
                    setActiveTab(1); // Reset tab to the first tab
                }
                showingPrefield(true);
                isPreviousModal(true);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setIsButtonDisabled(false);
        }
    }

    const handleClick = async (e) => {
        e.preventDefault();

        const validationResult = await utils?.checkFormError(formValue, errorSchema.createVendersSchema);
        if (activeTab === 3) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(true); // Pass true to close the modal
                setFormError("");

            } else {
                setFormError(validationResult);
                setLoading(false);

            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
        }
    };

    const handleResetFormAndSubmit = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true); // Disable the button immediately
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createVendersSchema);
        if (activeTab === 3) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(false); // Pass false to keep the modal open and reset the form
                setFormValue((prevData) => ({
                    ...prevData,
                    company_id: StorageService.getCompanyId(),
                    name: '',
                    contact_no: '',
                    opening_balance: '',
                    pay_type: '1',
                    as_of_date: getFormattedDate(),
                    address_line1: '',
                    pincode: '',
                    email_id: '',
                    alt_contact_no: '',
                    gst_no: '',
                    gst_treatment_id: {},
                    gst_state: {},
                    notes: ''
                }))
                setFormError("");

            } else {
                setFormError(validationResult);
                setLoading(false);
                setIsButtonDisabled(false); // Re-enable the button if validation fails
            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
            setIsButtonDisabled(false); // Re-enable the button if validation fails
        }
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const onhandleSelect = async (item, name) => {
        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createVendersSchema);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }

    };

    // edit consignor group

    useEffect(() => {
        if (masterData?.gst_type_list?.length > 0 && states?.length > 0) {
            setFormValue((s) => ({
                ...s,
                as_of_date: utils?.getTodayDate(),
                gst_state: states?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?.isoCode
                    }
                })[0],
                gst_treatment_id: masterData?.gst_type_list?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?.id
                    }
                })[0],
                company_id: StorageService.getCompanyId(),
            }));
        }
    }, [masterData?.gst_type_list?.length > 0, states?.length > 0, StorageService.getCompanyId()]);

    const changeActiveTab = async (event, value) => {
        event.preventDefault();
        if (value > activeTab) {
            const validationResult = await utils.checkFormError(formValue, errorSchema.createVendersSchema);
            if (validationResult === true) {
                setActiveTab(value);
            } else {
                setFormError(validationResult);
            }
        } else {
            setActiveTab(value);
        }
    };

    const handleCityChange = (data) => {
        handleOnChange("city", {
            label: data?.label,
            value: data?.name,
        })
        const stateDetails = State.getStateByCodeAndCountry(
            data?.stateCode,
            data?.countryCode
        );
        if (stateDetails?.name && stateDetails?.isoCode) {
            setStateValue(stateDetails)
        }
    }

    useEffect(() => {
        if (stateValue?.name && stateValue?.isoCode) {
            handleOnChange("state", { label: stateValue?.name, value: stateValue?.isoCode })
        }
    }, [stateValue])

    return (
        <>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title"> Add Venders </h5>
                <button type="button" className="btn-close" onClick={() => onClose(!isOpen)}></button>
            </div>

            <div className="offcanvas-body">

                <div className='pr_procssBx'>
                    <div className='pr_procssBx_grid'>

                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                placeholder="Vendor Name"
                                label="Vendor Name *"
                                name='name'
                                value={formValue.name}
                                focus={!!(typeof formError === "object" && formError?.name)}
                                error={!!(typeof formError === "object") ? formError?.name : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='singleDiv'>
                            <CommonInput
                                type="number"
                                placeholder="Mobile Number"
                                label='Mobile Number'
                                name='contact_no'
                                maxLength="10"
                                onInput={maxLengthCheck}
                                value={formValue.contact_no}
                                focus={!!(typeof formError === "object" && formError?.contact_no)}
                                error={!!(typeof formError === "object") ? formError?.contact_no : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='halfDiv'>
                            <CommonInput
                                type="number"
                                placeholder="Opening Balance"
                                label="Opening Balance"
                                name='opening_balance'
                                maxLength="8"
                                onInput={maxLengthCheck}
                                value={formValue.opening_balance}
                                focus={!!(typeof formError === "object" && formError?.opening_balance)}
                                error={!!(typeof formError === "object") ? formError?.opening_balance : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='halfDiv'>
                            <CommonInput
                                type="date"
                                placeholder="As of Date *"
                                label="As of Date *"
                                // className="simpleDate_picker"
                                name='as_of_date'
                                value={formValue.as_of_date}
                                focus={!!(typeof formError === "object" && formError?.as_of_date)}
                                error={!!(typeof formError === "object") ? formError?.as_of_date : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>
                    </div>

                    <div className='checkBx_lv mt-2'>
                        {
                            constant.Receive_Pay?.map((el, ind) => {
                                return (
                                    <React.Fragment key={ind}>
                                        <div className='_dualCheckr_bx'>
                                            <input
                                                className="form-check-input"
                                                id={+ind + "pay_type" + el?.value}
                                                type="radio"
                                                name="pay_type"
                                                checked={formValue?.pay_type === el?.value}
                                                value={formValue?.pay_type ? formValue?.pay_type : ""}
                                                onChange={({ target: { name, value } }) =>
                                                    handleOnChange(name, el?.value)
                                                }
                                            />
                                            <label htmlFor={+ind + "pay_type" + el.value} className="form-check-label" >
                                                {el.label}
                                            </label>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='addPr_flBx'>
                    <ul className="nav nav-tabs cstNew_tabs" id="addParty" role="tablist">
                        {constant.addPartyTabs?.map((item, indx) => (
                            <li
                                className="nav-item"
                                role="presentation"
                                key={indx + item?.value}
                            >
                                <button
                                    className={
                                        "nav-link " + (activeTab === item?.id ? "active" : "")
                                    }
                                    type="button"
                                    aria-selected={activeTab === item?.id}
                                    onClick={(e, val) => changeActiveTab(e, item.id)}
                                >
                                    {item?.title}
                                </button>
                            </li>
                        ))}

                        {/* <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="address-tab" data-bs-toggle="tab" data-bs-target="#address-tab-pane" type="button" role="tab" aria-controls="address-tab-pane" aria-selected="true">Address</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="tax-tab" data-bs-toggle="tab" data-bs-target="#tax-tab-pane" type="button" role="tab" aria-controls="tax-tab-pane" aria-selected="false">Tax Info</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="other-tab" data-bs-toggle="tab" data-bs-target="#other-tab-pane" type="button" role="tab" aria-controls="other-tab-pane" aria-selected="false">Other Details</button>
                            </li> */}
                    </ul>

                    {activeTab === 1 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>

                                <div className='singleDiv'>
                                    <div className="h4Head">Add Address</div>
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="Address Line 1"
                                        label="Address Line 1"
                                        name='address_line1'
                                        value={formValue.address_line1}
                                        focus={!!(typeof formError === "object" && formError?.address_line1)}
                                        error={!!(typeof formError === "object") ? formError?.address_line1 : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={cities}
                                        onSelect={(data) => handleCityChange(data, 'city')}
                                        value={formValue?.city}
                                        placeholder="Select a city"
                                        error={formError["city.label"]}
                                        label="City"
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="text"
                                        placeholder="State"
                                        label="State"
                                        name='state'
                                        disabled={true}
                                        value={formValue?.state?.label}
                                        focus={!!(typeof formError === "object" && formError?.state)}
                                        error={!!(typeof formError === "object") ? formError?.state : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='halfDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="PIN Code"
                                        label="PIN Code"
                                        name='pincode'
                                        value={formValue.pincode}
                                        focus={!!(typeof formError === "object" && formError?.pincode)}
                                        error={!!(typeof formError === "object") ? formError?.pincode : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <div className="h4Head">Other Contact</div>
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="number"
                                        placeholder="Alternate Number"
                                        label="Alternate Number"
                                        name='alt_contact_no'
                                        maxLength="10"
                                        onInput={maxLengthCheck}
                                        value={formValue.alt_contact_no}
                                        focus={!!(typeof formError === "object" && formError?.alt_contact_no)}
                                        error={!!(typeof formError === "object") ? formError?.alt_contact_no : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>

                                <div className='singleDiv'>
                                    <CommonInput
                                        type="Email"
                                        placeholder="Email ID"
                                        label='Email ID'
                                        name='email_id'
                                        value={formValue.email_id}
                                        focus={!!(typeof formError === "object" && formError?.email_id)}
                                        error={!!(typeof formError === "object") ? formError?.email_id : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 2 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>
                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={masterData?.gst_type_list?.map((el) => {
                                            return {
                                                label: el?.name,
                                                value: el?.id
                                            }
                                        })}
                                        name="gst_treatment_id"
                                        flotingLabel="GST Type"
                                        value={formValue?.gst_treatment_id}
                                        error={formError["gst_treatment_id.label"]}
                                        onSelect={(data) => onhandleSelect(data, 'gst_treatment_id')}
                                        placeholder="Select GST Type"
                                    />
                                </div>

                                {
                                    (formValue?.gst_treatment_id?.value == '2' || formValue?.gst_treatment_id?.value == '3') && (
                                        <div className='singleDiv'>
                                            <CommonInput
                                                type="number"
                                                placeholder="GST Number"
                                                label='GST Number'
                                                name='gst_no'
                                                maxLength="10"
                                                onInput={maxLengthCheck}
                                                value={formValue.gst_no}
                                                onChange={({ target: { name, value } }) =>
                                                    handleOnChange(name, value)
                                                }
                                            />
                                        </div>
                                    )
                                }

                                <div className='singleDiv'>
                                    <CustomSelect
                                        options={states?.map((el) => {
                                            return {
                                                label: el?.name,
                                                value: el?.isoCode
                                            }
                                        })}
                                        name="gst_state"
                                        flotingLabel="GST State"
                                        value={formValue?.gst_state}
                                        error={formError["gst_state.label"]}
                                        onSelect={(data) => onhandleSelect(data, 'gst_state')}
                                        placeholder="Select GST State"
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === 3 && (
                        <div className='pr_procssBx' id="addPartyContent">
                            <div className='pr_procssBx_grid'>

                                <div className='singleDiv'>
                                    <CommonTextarea
                                        type="textarea"
                                        label="Notes "
                                        rows={5}
                                        placeholder="Notes "
                                        name='notes'
                                        value={formValue.notes}
                                        focus={!!(typeof formError === "object" && formError?.notes)}
                                        error={!!(typeof formError === "object") ? formError?.notes : ""}
                                        onChange={({ target: { name, value } }) =>
                                            handleOnChange(name, value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                </div>

            </div>

            <div className='offcanvas-footer'>
                {
                    (activeTab === 3) ? (
                        <React.Fragment>
                            <AddButton onClick={handleClick} disabled={loading} name={loading ? 'Loading...' : 'Add Vendor'} />
                            <button
                                className={isButtonDisabled ? '_addNewComm_btn_disabled' : '_addNewComm_btn'}
                                onClick={handleResetFormAndSubmit}
                                disabled={isButtonDisabled}

                            >
                                Add & New
                            </button>
                        </React.Fragment>
                    ) : (activeTab === 2 ? (
                        <AddButton onClick={handleClick} name='Continue' />
                    ) : (
                        <AddButton onClick={handleClick} disabled={!formValue?.name || !formValue?.as_of_date} name='Continue' />
                    ))
                }
            </div>

        </>
    )
}
