import { API, axiosInstance } from "lib";

export const dashboardCount = (data={}) => {
    return axiosInstance
      .post(API.GET_DASHBOARD_COUNT , data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };