import { API, axiosInstance } from "lib";

export const createOwnDriver = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_OWN_DRIVER, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getOwnDriverListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_OWN_DRIVER_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteOwnDriverData = (data = {}) => {
  return axiosInstance
    .post(API.DELETE_OWN_DRIVER, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const fetchOwnDriverDetailsData = (data = {}) => {
  return axiosInstance
    .post(API.GET_DRIVER_DETAILS, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const changeDriverStatusData = (data = {}) => {
  return axiosInstance
    .post(API.CHANGE_DRIVER_STATUS, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};