import React, { useEffect, useState } from 'react'
import { CommonModal, CustomSelect } from 'components'
import { DotLottiePlayer, Controls } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';

const ThankyouModal = (props) => {
    let { closeModal, modalMainClass = '_dashCommon_Modal _smModal', callNavigateFunction } = props;

    const closehandleSubmit = async (e) => {
        e.preventDefault();
        callNavigateFunction();
        closeModal(false);
    }

    return (
        <>
            <div className={`modal fade show d-block thankYouModal ${modalMainClass}`} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content pb-4">

                        <div className='_checkAnimation'>
                            <DotLottiePlayer
                                src="https://lottie.host/090a6977-f09e-45d8-94bd-9e4a9546f52a/XgTGJg6Pyw.lottie"
                                autoplay
                                loop={false}
                            >
                            </DotLottiePlayer>
                        </div>

                        <div className='_thkTx'>
                        <div className='_thankYou_txt'> Your invoice has been generated successfully </div>
                            <button onClick={(e) => closehandleSubmit(e)} className='_discardBtn mt-3'> View </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    )
}

export default ThankyouModal