import { API, axiosInstance } from "lib";

export const createContract = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_CONTRACT, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const updateContract = (data = {}) => {
  return axiosInstance
    .post(API.EDIT_CONTRACT, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getContractListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_CONTRACT_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteContractData = (data = {}) => {
  return axiosInstance
    .post(API.DELETE_CONTRACT, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const singleContractDetailsData = (data = {}) => {
  return axiosInstance
    .post(API.GET_SINGLE_CONTRACT, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const activeInactiveContractStatus = (data = {}) => {
  return axiosInstance
    .post(API.CONTRACT_STATUS_CHANGES, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};