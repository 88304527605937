import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonHeading, CommonModal } from 'components'
import StorageService from "utils/StorageServices/StorageServices";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import AddEditSupplier from 'modals/AddEditSupplier';
import { deleteSupplierData, getSupplierListData } from '_services/accountsServices/supplierServices';

import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import MastersFilterModal from 'modals/MastersFilterModal';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { getInvoiceBillRaiseListData } from '_services/invoiceBillRaiseServices/invoiceBillRaiseServices';
import { utils } from 'helper';
import { UserContext } from 'context';
import Tab from 'components/common/Tab';
import { constant } from 'constants/constant';
import Loading from 'pages/Loading';

const Invoicing = () => {
  const navigate = useNavigate()
  const { setInvoiceBillRaiseData } = useContext(UserContext)
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [supplierList, setSupplierList] = useState([])
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: '',
    status: 1,
  });

  const getDataInvoiceBillRaiseList = async () => {
    try {
      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      }
      const res = await getInvoiceBillRaiseListData(object);
      if (res?.responseCode == 200) {
        setLoading(false);
        setSupplierList(res?.data?.billraise_list);
        setTotalItems(res?.data?.total_record);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  }

  useEffect(() => {
    setLoading(true);
    getDataInvoiceBillRaiseList();
  }, [filter])

  const deleteHandler = (item) => {
    setIsDelete(item)
  }

  const submitDeleteInvoiceBillRaise = async () => {
    setLoading(true);
    try {
      let object = {
        company_id: isDelete?.company_id,
        supplier_id: isDelete?._id,
      }
      const res = await deleteSupplierData(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataInvoiceBillRaiseList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success('Error !! ');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteInvoiceBillRaise,
    isLoadding: loading,
  }

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Invoice Number", accessor: "invoice_number" },
    { header: "Invoice Date", accessor: "date" },
    { header: "Party", accessor: "consignor_id" },
    { header: "Branch Name", accessor: "branch" },
    { header: "Invoice Amount", accessor: "invoice_amount" },
    { header: "Balance", accessor: "balance" },
    { header: "Due Date", accessor: "due_date" },
    { header: "Actions", accessor: "action" },
  ];

  const rowListData = (data) => {
    return data?.map((item, i) => ({
      ...item,
      // serial: <>{i + 1 + filter?.limit * (filter?.page - 1)}</>,
      serial: <>
        <div className="signDropdown">
          <button className="dropbtn11">
            <BsThreeDotsVertical />
          </button>
          <div className="dropdownContent">

            <Link to={`details/${item?._id}`}
              className='_avPointer'
              style={{ textDecoration: 'none' }}
            >
              <span className='_samAnchor'>
                <span className='viewIcon_avt'>
                  <AiOutlineEye />
                </span>View
              </span>
            </Link>

            <div
              // onClick={(e) => editHandler(item)}
              className='_avPointer'
            >
              <span className='_samAnchor'>
                <span className='editIcon_avt'>
                  <FiEdit />
                </span>
                Edit
              </span>
            </div>

            <div
              // onClick={(e) => deleteHandler(item)}
              className='_avPointer'
            >
              <span className='_samAnchor'>
                <span className='deleteIcon_avt'>
                  <FaRegTrashCan color='red' />
                </span>
                Delete
              </span>
            </div>
          </div>
        </div>
      </>,
      consignor_id: <>{item?.consignor_id?.name ? item?.consignor_id?.name : '-'}</>,
      invoice_number: <Link to={`details/${item?._id}`}>{item?.invoice_number ? item?.invoice_number : '-'}</Link>,
      invoice_amount: <> {item?.invoice_summary?.invoice_amount ? <> &#x20B9; {item?.invoice_summary?.invoice_amount} </> : '-'} </>,
      balance: <> {item?.invoice_summary?.balance ? <> &#x20B9; {item?.invoice_summary?.balance} </> : '-'} </>,
      date: <>{item?.createdAt ? utils.formateDateCalander(item?.createdAt) : '-'}</>,
      due_date: <>{item?.due_date ? utils.formateDateCalander(item?.due_date) : '-'}</>,
      branch: <> {item?.branch_id?.branch ? item?.branch_id?.branch : '-'} </>,
      action: (
        <>
          -
        </>
      ),
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${i === filter.offset ? "active" : ""}`}
        >
          <a className="page-link _avPointer" onClick={(e) => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    return (
      <ul className="pagination ">
        <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset - 1)}
          >
            <IoChevronBack />
          </a>
        </li>
        {pages}
        <li
          className={`page-item ${filter?.offset === totalPages ? "disabled" : ""
            }`}
        >
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset + 1)}
          >
            <IoChevronForward />
          </a>
        </li>
      </ul>
    );
  };

  const handleCreateBillRaise = (event) => {
    event.preventDefault();
    navigate('createbillraise')
    setInvoiceBillRaiseData({});
  }

  const handleCallBckValue = (data) => {
    setFilter((s) => ({ ...s, status: data }))
  }

  const tabProps = {
    tabList: constant.billRaiseTabs,
    callBackTab: handleCallBckValue,
    activeTab: filter?.status,
    invoiceBillRaiseClass: "toggle_bill_invc",
  }

  return (
    <div>
      <ButtonHeading
        title={"Bill Raise"}
        onClick={(e) => handleCreateBillRaise(e)}
        buttonTitle="Raise Bill"
        showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />

      <div className='addPr_flBx'>
        <Tab {...tabProps} />
      </div>

      {loading ? <div><Loading /></div> : <div className='_tabWith_invoiceTab'>
        <BootstrapTable
          columns={columns}
          data={rowListData(supplierList?.length > 0 ? supplierList : [])}
          isLoading={loading}
        />

        <div className='paginationFt_avt '>
          {renderPagination()}
        </div>
      </div>}

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
      >
        {showFilterModal &&
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        }
      </div>
      <div className={showFilterModal ? 'offBack_drop' : ''}></div>

      {
        isDelete?.company_id && (
          <CommonModal {...propsObject}>
            <p className='p-3'> Do you really want to delete {isDelete?.name} </p>
          </CommonModal>
        )
      }
    </div>
  )
}

export default Invoicing