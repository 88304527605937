import React, { useEffect, useState } from 'react'
import images from 'constants/images';
import { useParams } from 'react-router-dom';
import { getInvoiceBillRaise } from '_services/invoiceBillRaiseServices';
import StorageService from 'utils/StorageServices/StorageServices';

const zeroValue = 0;

const BillSummaryDetails = (props) => {

  let { data } = props

  return (
    <>
      <div className='_blsSummary'>
        <div className="bill_symry_details">
          <table className="_trpTable_xd">
            <thead className="table_head_style">
              <tr className="accordiTr">
                <th>Date</th>
                <th>Voucher Number</th>
                <th>Category</th>
                <th>Description</th>
                {/* <th>From Account</th> */}
                {/* <th>To Account</th> */}
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>
                    10/12/1996
                  </span>
                  <br />
                </td>

                <td>
                  <span className="sameSpan">
                    1124510
                  </span>
                </td>

                <td>
                  <span className="sameSpan fw-500">
                    expense_category
                  </span>
                  <br />
                  <span className="sameSpan">
                    trip_number | trip_number
                  </span>
                </td>
                <td className="sameSpan">
                  description
                </td>
                {/* <td>
                                                <span className="sameSpan fw-500">
                                                    account_name
                                                </span>
                                                <br />
                                                <span className="sameSpan">
                                                    account_sub_category_name
                                                </span>
                                            </td> */}

                {/* <td>
                                                <span className="sameSpan fw-500">
                                                    supplier_id
                                                </span>
                                                <br />
                                            </td> */}

                <td>
                  <span className="sameSpan">
                    debit_amount
                  </span>
                </td>

                <td align="right">
                  <button className="_actionBtn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <rect
                        x="0.390625"
                        y="0.390625"
                        width="24.2188"
                        height="24.2188"
                        rx="2.73438"
                        stroke="#0049D7"
                        strokeWidth="0.78125"
                      />
                      <path
                        d="M12.5 7.8125C13.3629 7.8125 14.0625 7.11295 14.0625 6.25C14.0625 5.38706 13.3629 4.6875 12.5 4.6875C11.6371 4.6875 10.9375 5.38706 10.9375 6.25C10.9375 7.11295 11.6371 7.8125 12.5 7.8125Z"
                        fill="#0049D7"
                      />
                      <path
                        d="M12.5 14.0625C13.3629 14.0625 14.0625 13.3629 14.0625 12.5C14.0625 11.6371 13.3629 10.9375 12.5 10.9375C11.6371 10.9375 10.9375 11.6371 10.9375 12.5C10.9375 13.3629 11.6371 14.0625 12.5 14.0625Z"
                        fill="#0049D7"
                      />
                      <path
                        d="M12.5 20.3125C13.3629 20.3125 14.0625 19.6129 14.0625 18.75C14.0625 17.8871 13.3629 17.1875 12.5 17.1875C11.6371 17.1875 10.9375 17.8871 10.9375 18.75C10.9375 19.6129 11.6371 20.3125 12.5 20.3125Z"
                        fill="#0049D7"
                      />
                    </svg>
                  </button>
                </td>
              </tr>

            </tbody>
            <tfoot>
              <tr>
                <td colSpan={7}>
                  <div className="_muliAddbtns_bx">

                    <button
                      className="addAble_btn addFreight"
                    >
                      <div className="_otrIcon_imb">
                        <img src={images.RUPEE} alt="" />
                      </div>{" "}
                      <span>Add Freight</span>
                    </button>
                    <button
                      className="addAble_btn addFreight"
                    >
                      <div className="_otrIcon_imb">
                        <img src={images.RUPEE} alt="" />
                      </div>{" "}
                      <span>Add Charges</span>
                    </button>

                    <button className="addAble_btn addClaim" >
                      <div className="_otrIcon_imb">
                        <img src={images.CLAIM} alt="" />
                      </div>{" "}
                      <span>Add Claims</span>
                    </button>

                    <button className="addAble_btn addFuel">
                      <div className="_otrIcon_imb">
                        <img src={images?.PUMP} alt="" />
                      </div>{" "}
                      <span>Add Fuel</span>
                    </button>
                  </div>
                </td>

                <td></td>
                <td className="_totalAtm">Balance</td>
                <td className="_totalAtm"> 1000 </td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className='billDetal_viw'>
          <div className='_invoiceSummary'>
            <h2>Invoice Summary</h2>
            <div className='_alInvoiceSumry'>
              <div className='_invsSum_fx'>
                <p className='_blackText'>Total Freight</p> <p className='_blackText'>
                  {/* &#x20B9; 0.00 */}
                  {data?.invoice_summary?.total_freight ? <> &#x20B9; {(data?.invoice_summary?.total_freight).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>}
                </p>
              </div>

              <div className='_invsSum_fx'>
                <p className='_blackText'>Claims Receivable (+)</p> <p className='_blackText'>
                  {data?.invoice_summary?.claim_receivable ? <> &#x20B9; {(data?.invoice_summary?.claim_receivable).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>}
                  {/* &#x20B9; 0.00 */}
                </p>
              </div>

              <div className='_invsSum_fx'>
                <p className='_blackText'> GST @ 12% (+) </p> <p className='_blackText'>
                  {data?.invoice_summary?.gst_amount ? <> &#x20B9; {(data?.invoice_summary?.gst_amount).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>}
                  {/* &#x20B9; 0.00 */}
                </p>
              </div>

              <div className='_invsSum_fx'>
                <p className='_boldBlack'>Invoice Amount</p> <p className='_boldBlack'>
                  {/* &#x20B9; 0.00 */}
                  {data?.invoice_summary?.total_freight ? <> &#x20B9; {(data?.invoice_summary?.total_freight).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>}
                </p>
              </div>

              <div className='_invsSum_fx'>
                <p className='_blackText'>Freight Received (-)</p>
                <p className='_blackText'>
                  {data?.invoice_summary?.freight_received ? <> &#x20B9; {(data?.invoice_summary?.freight_received).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>}
                  {/* &#x20B9; 0.00 */}
                </p>
              </div>

              <div className='_invsSum_fx'>
                <p className='_blackText'>Claims Payable (-)</p> <p className='_blackText'>
                  {data?.invoice_summary?.claim_payable ? <> &#x20B9; {(data?.invoice_summary?.claim_payable).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>}
                  {/* &#x20B9; 0.00 */}
                </p>
              </div>

              <div className='_invsSum_fx'>
                <p className='remainBal'>Balance</p> <p className='remainBal'>
                  {data?.invoice_summary?.balance ? <> &#x20B9; {(data?.invoice_summary?.balance).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>}
                  {/* &#x20B9; 0.00 */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BillSummaryDetails