import React, { useState, useEffect } from 'react';
import { FaRegTrashAlt } from "react-icons/fa";

const Attachement = (props) => {

    const { attachments, setAttachments } = props;

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setAttachments([...attachments, ...files]);
    };

    const removeAttachment = (index) => {
        const updatedAttachments = [...attachments];
        updatedAttachments.splice(index, 1);
        setAttachments(updatedAttachments);
    };

    return (
        <div className="col-md-12 mt-2">
            <div className="frm_grp">
                <div className="col-md-12">
                    <div className="mb-2">

                        <label className="attachFilelabel">
                            <span className="title"> + Attach Form </span>
                            <input
                                className="FileUpload1"
                                name="booking_attachment"
                                id="file-input"
                                onChange={handleFileChange}
                                type="file"
                                multiple
                            />
                        </label>
                    </div>
                    <ol className="fn_td_multi">
                        {attachments?.map((file, index) => (
                            <li key={index}>
                                <a className="p_colored" href={file.name ? URL.createObjectURL(file) : file.name} target="_blank">
                                    Choosed file : {file.name}
                                </a>
                                <span onClick={() => removeAttachment(index)} className="rm_rf_remove_Cls"> &nbsp; <FaRegTrashAlt /> </span>
                            </li>
                        ))}
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default Attachement;
