import { createSlice } from '@reduxjs/toolkit';
import { addroleCreate,addUser ,editUser} from './pageAction';

const initialState = {
    data: null,
    userData: null,
    loading: false,
    error: null,
};

const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        resetError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(addroleCreate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addroleCreate.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(addroleCreate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
          
            .addCase(addUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(addUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(editUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(editUser.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(editUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export const { resetError } = pageSlice.actions;

export default pageSlice.reducer;
