import { Images } from "constants";
import { UserContext } from "context";
import { utils } from "helper";
import { LrDetailModal } from "modals";
import Loading from "pages/Loading";
import React, { useContext, useState } from "react";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

const AllDispatchDetailTab = ({
  list,
  loading,
  handlePageChange,
  filter,
  totalItems,
  itemsPerPage,
  callBackLrId
  
}) => {
  const { masterData } = useContext(UserContext);
  const nevigate = useNavigate();
  const [showLrDetail, setShowLrDetail] = useState(false);
  const [showAddLR, setShowAddLR] = useState(false);


  const handleClick = (event) => {
    event.stopPropagation();
  };

  const handleDetailsAction = (e, data) => {
    e.stopPropagation();
    setShowLrDetail(data);
  };

  const handleNextModal = (data) => {
    setShowLrDetail(!showLrDetail);
    setShowAddLR(data);
  };

  

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${i === filter.offset ? "active" : ""}`}
        >
          <a
            className="page-link _avPointer"
            onClick={(e) => handlePageChange(i)}
          >
            {i}
          </a>
        </li>
      );
    }

    return (
      <ul className="pagination ">
        <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset - 1)}
          >
            <IoChevronBack />
          </a>
        </li>
        {pages}
        <li
          className={`page-item ${
            filter?.offset === totalPages ? "disabled" : ""
          }`}
        >
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset + 1)}
          >
            <IoChevronForward />
          </a>
        </li>
      </ul>
    );
  };

  return (
    <>
      <div className="_indentTable _disPatchView">
        <table>
          <thead>
            <tr>
              <th>Dispatch Date</th>
              <th>Party</th>
              <th>From Station</th>
              <th>To Station</th>
              <th>Branch Name</th>
              <th>LR Date</th>
              <th>LR Number</th>
              <th>Invoice Number</th>
              <th>LR Status</th>
              <th>Eway Bill Date</th>
              <th>Eway Bill No.</th>
            </tr>
          </thead>

          {loading?<div><Loading /></div>:list?.trip_list?.length > 0 ? (
            list?.trip_list?.map((trip) => {
              return (
                <tbody onClick={() => nevigate(`dispatched/${trip?._id}`)} key={trip?._id}>
                  {trip?.lr_list?.map((lr) => {
                    return (
                      <tr key={lr?._id}>
                        <td>
                          <div className="_dateNtime _pDate">
                            <p className="_tblText_inDent">
                              {utils.formatDateMonth(lr?.dispatch_date)}
                            </p>
                            <p className="_lgtText_inDent">
                              {utils.formatTime(lr?.dispatch_date)}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {lr?.consignor_id?.name}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {lr?.from_location}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">{lr?.to_location}</p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {lr?.branch_id?.branch}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="_dateNtime">
                            <div className="__locationWid_bx">
                              <p className="_tblText_inDent">
                                {utils.formatDateMonth(lr?.invoice_date)}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="_dateNtime">
                            <div className="__locationWid_bx">
                              <p className="_tblText_inDent">
                              <Link
                                className=""
                                onClick={(e) => handleDetailsAction(e, lr)}
                              >
                                {lr?.lr_number}
                              </Link>
                                </p>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              <Link
                                className=""
                                onClick={(e) => handleDetailsAction(e, lr)}
                              >
                                {lr?.invoice_number}
                              </Link>
                            </p>
                          </div>
                        </td>

                        <td>
                          <div>
                            <span
                              className={
                                masterData?.lr_status[lr?.lr_status] ===
                                "Pending"
                                  ? "_lrPending_status"
                                  : masterData?.lr_status[lr?.lr_status] ===
                                    "In Transit"
                                  ? "_lrInTransit_status"
                                  : masterData?.lr_status[lr?.lr_status] ===
                                    "At Party Point"
                                  ? "_lrPartyPoint_status"
                                  : masterData?.lr_status[lr?.lr_status] ===
                                    "Delivered"
                                  ? "_lrDelivered_status"
                                  : masterData?.lr_status[lr?.lr_status] ===
                                    "Bill Raised"
                                  ? "_lrBillRaised_status"
                                  : ""
                              }
                            >
                              {" "}
                              {masterData?.lr_status[lr?.lr_status]
                                ? masterData?.lr_status[lr?.lr_status]
                                : ""}{" "}
                            </span>
                            {/* <span className="_lrInTransit_status ">
                              {masterData?.lr_status[lr?.lr_status]}
                            </span> */}
                          </div>
                        </td>

                        <td>
                          <div className="_dateNtime _pDate">
                           { lr?.eway_bill_date?<>
                            <p className="_tblText_inDent">
                            
                               { utils?.formatDateMonth(lr?.eway_bill_date)}
                              
                               
                            </p>
                            <p className="_tblText_inDent">
                           
                             {  utils?.formatDateMonth(lr?.eway_expiry_date)}
                             
                            </p>
                            </>: <span onClick={(e) => {
                              e.stopPropagation()
                              callBackLrId(lr)}}>
                                  + Add Eway Bill
                                </span>}
                           
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              
                              { lr?.eway_bill_number?<>
                                {lr?.eway_bill_number}
                           
                            </>: <span onClick={(e) => {
                              e.stopPropagation()
                              callBackLrId(lr)}}>
                                  + Add Eway Bill
                                </span>}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td colSpan={11}>
                      <div className="_separateBxs">
                        <Link
                          className="_trip_ind me-2"
                          to={`dispatched/${trip?._id}`}
                          onClick={handleClick}
                        >
                          {" "}
                          <span>Trip</span>
                          <div className="trpCode">
                            {trip?.trip_number}
                          </div>{" "}
                        </Link>

                        <span className={
                                masterData?.trip_status[trip?.trip_status] ===
                                "Pending"
                                  ? "_lrPending_status"
                                  : masterData?.trip_status[trip?.trip_status] ===
                                    "In Transit"
                                  ? "_lrInTransit_status"
                                  : masterData?.trip_status[trip?.trip_status] ===
                                    "At Party Point"
                                  ? "_lrPartyPoint_status"
                                  : masterData?.trip_status[trip?.trip_status] ===
                                    "Delivered"
                                  ? "_lrDelivered_status"
                                  : masterData?.trip_status[trip?.trip_status] ===
                                    "Bill Raised"
                                  ? "_lrBillRaised_status"
                                  : ""
                              }>
                          {masterData?.trip_status[trip?.trip_status]}
                        </span>

                        <div className="_trckDetail ms-3">
                          <img src={Images.TRUCKICON.default} alt="" />

                          <p>
                            {" "}
                            {trip?.vehicle_id?.vehicle_no.slice(0, 4) +
                              " " +
                              trip?.vehicle_id?.vehicle_no.slice(4, 6) +
                              " " +
                              trip?.vehicle_id?.vehicle_no.slice(6)}{" "}
                          </p>
                        </div>

                        <div
                          className={
                            trip?.vehicle_id?.vehicle_ownership == 1
                              ? "_trkMark _lrDelivered_status"
                              : trip?.vehicle_id?.vehicle_ownership == 2
                              ? "_trkMark _lrPartyPoint_status"
                              : "_trkMark _lrInTransit_status"
                          }
                        >
                          {
                            masterData?.vehicle_ownership[
                              trip?.vehicle_id?.vehicle_ownership
                            ]
                          }
                        </div>

                        <div className="_trkTyre">
                          <span>{trip?.vehicle_type_id?.name}</span>
                        </div>

                        <p className="_rsp_suplier ms-4">
                          {trip?.supplier_id
                            ? `Supplier- ${trip?.supplier_id?.name}`
                            : `Driver- ${trip?.driver_id?.name}`}
                        </p>

                        <p className="_rsp_suplier ms-4">{trip?.route_name}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={11} style={{ background: "transparent" }}></td>
                  </tr>
                </tbody>
              );
            })
          ) : (
           
            <tr>
              <td> No data available </td>
            </tr>
            
          )}
        </table>
      </div>
      <div className="paginationFt_avt ">{renderPagination()}</div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${
          showLrDetail ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showLrDetail ? "visible" : "hidden" }}
      >
        {showLrDetail && (
          <LrDetailModal
            isOpen={showLrDetail}
            onClose={(e) => setShowLrDetail(false)}
            nextModal={(e) => handleNextModal(e)}
          />
        )}
      </div>
      <div className={showLrDetail ? 'offBack_drop' : ''}></div>
    </>
  );
};

export default AllDispatchDetailTab;
