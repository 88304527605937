import React, { useRef, useState } from 'react'
import { ButtonHeading, VendorDetailsTab } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import LoadingBar from 'react-top-loading-bar';
import Ledger from '../Ledger';

const VendorsDetails = () => {

    const loadingBarRef = useRef(null)
    const [activeTab, setActiveTab] = useState(1);
    const [dataDetails, setDataDetail] = useState(null)

    const tabProps = {
        tabList: constant.vendersDetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    }

    const onDataFetch = (data) => {
        setDataDetail(data)
    }

    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <ButtonHeading title={dataDetails?.name} buttonTitle="Edit" />

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && (
                    <VendorDetailsTab onDataFetch={onDataFetch} loadingBarRef={loadingBarRef} />
                )}
                {activeTab === 2 && <Ledger type={4} />}
            </div>

        </div>
    )
}

export default VendorsDetails;