import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

const COLORS = ["#ffa800", "#23b7e5", "#36B37E", "#F05050"];

export const PieCharts = ({ dataList }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (dataList && dataList.length > 0) {
      const transformedData = dataList.map(item => ({
        name: item.name,
        value: item.value,
      }));
      setData(transformedData);
    } else {
      setData([]);
    }
  }, [dataList]);

  if (!dataList || dataList.length === 0) {
    return <div className="NoPreviewAvl">No Preview Available</div>;
  }

  const totalValue = data.reduce((acc, item) => acc + item.value, 0);

  const renderLegendValue = (value, entry) => {
    const percent = (entry.payload.value / totalValue) * 100;
    return `${entry.payload.name} (${percent.toFixed(0)}%)`;
  };

  return (
    <div className="chart_pie">
      <PieChart width={400} height={250} key={dataList.toString()}>
        <Pie
          data={data}
          cx={90}
          cy={100}
          labelLine={false}
          outerRadius={85}
          fill="#8884d8"
          dataKey="value"
          animationBegin={0}
          animationDuration={1000}
          animationEasing="ease-out"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          // layout="horizontal"
          // align="center"
          // verticalAlign="bottom"
          formatter={(value, entry) => renderLegendValue(value, entry)}
        />
      </PieChart>
    </div>
  );
};