import React, { useEffect, useRef, useState } from 'react'
import { ButtonHeading, NavTabs } from 'components'
import StorageService from 'utils/StorageServices/StorageServices'
import { getLRListData } from '_services/accountsServices/lrServices'
import LoadingBar from 'react-top-loading-bar';

const Lorryreceipt = (props) => {
    let { id } = props;

    const [lrList, setLrList] = useState([]);
    const loadingBarRef = useRef(null);

    const getDataLrList = async () => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
                lr_status: 1,
                consignor_id: '6662ec052531588031a1f46a',
                select_lr_ids: [],
            };
            const res = await getLRListData(object);
            if (res?.responseCode === 200) {
                setLrList(res?.data?.lr_list);
            }
        } catch (error) {
            console.error(error);
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    };

    useEffect(() => {
        getDataLrList();
    }, [])

    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            {/* <ButtonHeading title="Parle Transportation" buttonTitle="Edit" /> */}

            {/* <NavTabs id={id} /> */}

            <div>
                <div className='p-3'>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">S. No.</th>
                                <th scope="col">LR Number</th>
                                <th scope="col">Party Name</th>
                                <th scope="col"> From Station </th>
                                <th scope="col"> To Station </th>
                                <th scope="col"> Invoice Number </th>
                                <th scope="col"> Status </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lrList?.length > 0 ? (
                                    lrList?.map((el, ind) => {
                                        return (
                                            <tr key={ind + 'abc'}>
                                                <td scope="row">{ind + 1}</td>
                                                <td scope="row"> - </td>
                                                <td>
                                                    {el?.name}
                                                </td>
                                                <td> - </td>
                                                <td> - </td>
                                                <td> - </td>
                                                <td> - </td>

                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr > <td> No data available </td> </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default Lorryreceipt