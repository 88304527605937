import React from 'react'


const Heading = ({title}) => {


    return (
        <div className="contentHead">
            <h4>{title}</h4>
        </div>
    )
}

export default Heading