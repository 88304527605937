import React, { useEffect, useState } from 'react'
import { ButtonHeading, CommonModal } from 'components'
import { deleteConsignorData } from '_services/accountsServices/consignorServices';
import StorageService from "utils/StorageServices/StorageServices";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { AddEditVehicleGroupModal, AddEditVendors, AddEditZoneModal } from 'modals';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { deleteZoneData, getZoneListData } from '_services/accountsServices/zoneServices';

import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import MastersFilterModal from 'modals/MastersFilterModal';
import { constant } from 'constants/constant';
import { deleteVehicleGroupData, getVehicleGroupListData } from '_services/accountsServices/vehicleGroupServices';

const VehicleGroup = () => {

  const [isOpenZoneModal, setIsOpenZoneModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [zoneList, setZoneList] = useState([])
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: '',
    status: 1
  });

  const toggleZoneModal = () => {
    setIsOpenZoneModal(!isOpenZoneModal);
  };

  const getDataVehicleGroupList = async () => {
    try {
      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      }
      const res = await getVehicleGroupListData(object);
      if (res?.responseCode == 200) {
        setZoneList(res?.data?.vehicle_group_list);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    getDataVehicleGroupList();
  }, [filter, isOpenZoneModal])

  const deleteHandler = (item) => {
    setIsDelete(item)
  }

  const submitDeleteVehicleGroup = async () => {
    setLoading(true);
    try {
      let object = {
        company_id: isDelete?.company_id,
        vehicle_group_id: isDelete?._id,
      }
      const res = await deleteVehicleGroupData(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataVehicleGroupList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success('Error !! ');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteVehicleGroup,
    isLoadding: loading,
  }

  let openZoneModal = {
    isOpen: isOpenZoneModal,
    closeModal: (e) => setIsOpenZoneModal(false),
    // nextModal: (e) => submitUpdateData(e),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: 'Add Vehicle Group',
    submitTitle: 'Add Vehicle Group',
    footerPart: true
  }

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Vehicle Group Name", accessor: "vehicle_group_name" },
    { header: "No. of Vehicle", accessor: "vehicle_no" },
    { header: "Status", accessor: "status" },
    { header: "Actions", accessor: "action" },
  ];

  const rowListData = (data) => {
    return data?.map((item, i) => ({
      ...item,
      // serial: <>{i + 1 + filter?.limit * (filter?.page - 1)}</>,
      serial: <>
      <div className="signDropdown">
              <button className="dropbtn11">
                <BsThreeDotsVertical />
              </button>
              <div className="dropdownContent">

                <Link to={`/`}
                  className='_avPointer'
                  style={{ textDecoration: 'none' }}
                >
                  <span className='_samAnchor'>
                    <span className='viewIcon_avt'>
                      <AiOutlineEye />
                    </span>View
                  </span>
                </Link>

                <div
                  // onClick={(e) => editHandler(item)}
                  className='_avPointer'
                >
                  <span className='_samAnchor'>
                    <span className='editIcon_avt'>
                      <FiEdit />
                    </span>
                    Edit
                  </span>
                </div>

                <div
                  onClick={(e) => deleteHandler(item)}
                  className='_avPointer'
                >
                  <span className='_samAnchor'>
                    <span className='deleteIcon_avt'>
                      <FaRegTrashCan color='red' />
                    </span>
                    Delete
                  </span>
                </div>
              </div>
            </div>
      </>,
      vehicle_group_name: <>{item?.name ? item?.name : '-'}</>,
      number_of_unit: <>{item?.contact_nunber ? item?.contact_nunber : '-'}</>,
      number_of_consignee: <>{item?.consignor_group_id?.name ? item?.consignor_group_id?.name : '-'}</>,
      status: <>  {item?.active ? constant.activeArray[item?.active] : '-'} </>,
      action: (
        <>
          -
        </>
      ),
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${i === filter.offset ? "active" : ""}`}
        >
          <a className="page-link" onClick={(e) => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    return (
      <ul className="pagination _avPointer">
        <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
          <a
            className="page-link"
            onClick={() => handlePageChange(filter?.offset - 1)}
          >
            <IoChevronBack />
          </a>
        </li>
        {pages}
        <li
          className={`page-item ${filter?.offset === totalPages ? "disabled" : ""
            }`}
        >
          <a
            className="page-link"
            onClick={() => handlePageChange(filter?.offset + 1)}
          >
            <IoChevronForward />
          </a>
        </li>
      </ul>
    );
  };

  return (
    <div>
      <ButtonHeading
        title={"Vehicle Group"}
        onClick={toggleZoneModal}
        buttonTitle="Add Vehicle Group"
        showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />

      <div className='_tabWith_outTab'>
        <BootstrapTable
          columns={columns}
          data={rowListData(zoneList?.length > 0 ? zoneList : [])}
          isLoading={loading}
        />

        <div className='paginationFt_avt'>
          {renderPagination()}
        </div>
      </div>

      {
        isOpenZoneModal
        && (
          <AddEditVehicleGroupModal {...openZoneModal} />
        )
      }

      {
        isDelete?.company_id && (
          <CommonModal {...propsObject}>
            <p className='p-3'> Do you really want to delete {isDelete?.name} </p>
          </CommonModal>
        )
      }

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
      >
        {showFilterModal &&
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        }
      </div>
      <div className={showFilterModal ? 'offBack_drop' : ''}></div>

    </div>
  )
}

export default VehicleGroup