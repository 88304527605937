import { combineReducers } from "@reduxjs/toolkit";
import { authSlice, lrFilterSlice, pageSlice, tripSlice } from "features";
import lrSearchSlice from "features/lrFilterServices/lrSearchSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  page: pageSlice,
  trip: tripSlice,
  lrFilter: lrFilterSlice,
  lrSearch: lrSearchSlice,
  // Add additional reducers here
});

export default rootReducer;
