import React from 'react'

export const AddButton = ({onClick , disabled, name="Add", style}) => {
    return (
        <>
            <button className='_addComm_btn' onClick={onClick} disabled={disabled} style={style} > 
                {name}
            </button>
        </>
  )
}