import React, { useContext, useEffect, useState } from 'react'
import { fetchBranchDetailsData } from '_services/accountsServices/branchServices';
import { CommonInput } from 'components';
import { UserContext } from 'context';
import { utils } from 'helper';
import { useParams } from 'react-router-dom';
import StorageService from "utils/StorageServices/StorageServices";

const BranchOtherDetailsTab = ({ onDataFetch }) => {

    let { id } = useParams();
    const { masterData } = useContext(UserContext)
    const [detailsData, setDetailsData] = useState({})
    const [loading, setLoading] = useState(false);
    const companyId = StorageService.getCompanyId();
    const [builtyFormat, setBuiltyFormat] = useState({})

    const getDataBranchDetails = async (id_1, id_2) => {
        try {
            let object = {
                branch_id: id_1,
                company_id: id_2,
            }
            const res = await fetchBranchDetailsData(object);
            if (res?.responseCode == 200) {
                setDetailsData(res?.data?.branch)
                onDataFetch(res?.data?.branch)
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // throw error;
        }
    }

    useEffect(() => {
        if (id) {
            setLoading(true);
            getDataBranchDetails(id, companyId);
        }
    }, [id])

    useEffect(() => {
        if (detailsData && masterData) {
            const biltyObject = masterData?.lr_bilty_format_list?.find(item => item.id === detailsData.lr_bilty_format)
            setBuiltyFormat(biltyObject)
        }
    }, [detailsData, masterData])


    return (
        <div>
            {
                loading ? <div className='p-5'> Loading... </div> : !detailsData ? (
                    <div className='p-5'> No Data Found </div>) : (
                    <div className='_addContainer2 '>
                        <div className='_ptyDetail_bx'>
                            <div className="inrPrt_bxs">
                                <div className='_inrPrt_head'>Basic Details</div>
                                <div className='_inrPrt_flx'>
                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            placeholder="FSSAI LICENCE"
                                            label="FSSAI LICENCE"
                                            name='name'
                                            value={detailsData?.fssai_licence}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="Terms & Conditions"
                                            placeholder="Terms & Conditions"
                                            value={detailsData?.terms_conditions}
                                            disabled
                                        />
                                    </div>

                                    <div className='_divForth'>
                                        <CommonInput
                                            type="text"
                                            label="LR Bilty Format"
                                            placeholder="LR Bilty Format"
                                            value={builtyFormat?.name}
                                            disabled
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default BranchOtherDetailsTab