
import React, { useState, useRef } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

const CommonDateRange = ({ initialStartDate, initialEndDate, onApply, datePrefield = true }) => {
  const [range, setRange] = useState({
    startDate: initialStartDate || moment().subtract(32, 'days'),
    endDate: initialEndDate || moment(),
  });

  const [placeHolderValues, setPlaceHolderValues] = useState({
    startDate: '',
    endDate: '',
  })

  const pickerRef = useRef(null);

  const handleEvent = (event, picker) => {
    const newRange = {
      startDate: picker.startDate,
      endDate: picker.endDate,
    };
    setPlaceHolderValues(newRange);
    setRange(newRange);

    picker.hide();

    if (onApply) {
      onApply(newRange);
    }
  };

  const ranges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };

  return (
    <DateRangePicker
      initialSettings={{
        startDate: range.startDate.toDate(),
        endDate: range.endDate.toDate(),
        ranges: ranges,
        locale: { format: 'MMMM D, YYYY' },
      }}
      onApply={handleEvent}
      ref={pickerRef}
    >
      <div className="input-group anvCustom_floatInput form-floating ">
        <input
          type="text"
          label="Select Date Range"
          className="form-control"
          placeholder='Select Date Range'
          value={(!datePrefield && !placeHolderValues?.startDate) ? '' : `${range.startDate.format('MMMM D, YYYY')} - ${range.endDate.format('MMMM D, YYYY')}`}
          readOnly
        />
        <label htmlFor="floatingInput">
          <span>Select Date Range</span>
        </label>
      </div>
    </DateRangePicker>
  );
};

export default CommonDateRange;

