import React from 'react'
import { Images } from "constants";
import { IoCallOutline, IoCallSharp, IoSearchOutline } from "react-icons/io5";
import { CiStopwatch } from "react-icons/ci";
import { FaRegEdit, FaShareAlt } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { FaRoute } from "react-icons/fa6";
import { GiChatBubble } from "react-icons/gi";

const VehicleDashboard = () => {
    return (
        <div>
            <ul className="_vehiCle_dashUl">
                <li className="active">
                    <a href="#">
                        <span>10</span>
                        All
                    </a>
                </li>

                <li className="">
                    <a href="#" style={{ color: "#46b493" }}>
                        <span>5</span>
                        Ongoing
                    </a>
                </li>

                <li className="">
                    <a href="#" style={{ color: "#dfbe4c" }}>
                        <span>1</span>
                        Empty
                    </a>
                </li>

                <li className="">
                    <a href="#" style={{ color: "#aa403b" }}>
                        <span>3</span>
                        Maintainance
                    </a>
                </li>

                <li className="">
                    <a href="#" style={{ color: "#cc1e29" }}>
                        <span>4</span>
                        Delay
                    </a>
                </li>

                <li className="">
                    <a href="#" style={{ color: "#5d7e9d" }}>
                        <span>2</span>
                        Completed
                    </a>
                </li>
            </ul>

            <div className="tab_vehicleDashbord">
                <ul >
                    <li className="_tbv_active">
                        <a href="#" className="_btnEdv">All (10)</a>
                    </li>

                    <li className="">
                        <a href="#" className="_btnEdv">Running (1)</a>
                    </li>

                    <li className="">
                        <a href="#" className="_btnEred">Stopped (0)</a>
                    </li>

                    <li className="">
                        <a href="#" className="_btnEgray">No Device (6)</a>
                    </li>

                    <li className="">
                        <a href="#" className="_btnE_idel">Idle (1)</a>
                    </li>
                </ul>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5 px-2">
                        <div className="reactour__searchBar">
                            <div className="pos-rel w-100">
                                <input type="text" className="_vehCle_search" />
                                <span className="pos-abs _serIcon"><IoSearchOutline /></span>
                            </div>
                            <div className="pos-rel ms-1">
                                <div className="d-flex h-100">
                                    <div className="toggle-btn-wrapper p-1 h-100 d-flex">
                                        <div className="toggle-btn d-flex align-items-center h-100 active-toggle-btn"><span>Vehicles</span></div>
                                        <div className="toggle-btn d-flex align-items-center h-100 inactive-toggle-btn"><span>Location</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pre-scrollable ">
                            <div className="_realTime_bx _idle">
                                <div className="d-flex justify-content-between">
                                    <div className="pe-1 w-100">
                                        <div className="fw-bold d-flex justify-content-between">
                                            <div className="text-md">
                                                <span className="me-1">0220</span>
                                                <span>MH40BL0220</span>
                                            </div>

                                            <div>
                                                <a href="#"><span className="ant-tag ant-tag-red">Insurance</span></a>
                                            </div>
                                        </div>

                                        <div className="text-muted" >
                                            Driver :<span> Ashish</span>
                                            <div className="d-inline-block">
                                                <div className="p-md-1 cursor-pointer"> <div className="mx-1"> <FaRegEdit /> </div></div>
                                            </div>
                                        </div>


                                        <div className="mt-1 d-flex align-items-center">
                                            <span className="me-1 d-flex" style={{ fontSize: "20px" }}><CiStopwatch /></span>

                                            <div>
                                                <span class="_tmtTxt">12m <span class="mx-1 text-muted">|</span>3.3 km</span>
                                                <span><span class="mx-1"><span class="mx-1 text-muted">|</span></span>
                                                    Speed: <span class="_tmtTxt"> 42 km/h</span></span>
                                            </div>
                                        </div>

                                        <div className="mt-1 d-flex">
                                            <span className="me-2" style={{ fontSize: "18px" }}><ImLocation /></span>
                                            <div className="mt-1">
                                                <div style={{ color: "#555555" }}>Sambalpur - Sundargarh - Rourkela Highway, Tudalaga, Sundargarh, Odisha</div>
                                                <div className="d-flex fw-bold" style={{ color: "#555555" }}>
                                                    <div><span class="">Nearest Address: </span><span class="">214 km from gati angul</span></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex  mt-1">
                                            <span className="me-2"><FaRoute /></span>

                                            <div className="fw-bold">
                                                <a href="" style={{ color: "#0078d4", textDecoration: "none" }}>
                                                    <span>Not On Job</span>
                                                </a>

                                                <div class="text-muted"><span>Since:</span><span class="ms-1">5 hrs </span></div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <div className="justify-content-end d-flex  ">
                                            <img className="_idelImg" src={Images.VEHICLEIDLE} alt="" width={20} height={20} />
                                            <img className="_movingImg" src={Images.VEHICLEMOVING} alt="" width={20} height={20} />
                                        </div>
                                        <div className="justify-content-end flex-column d-flex gap-2" style={{ marginTop: "auto" }}>
                                            <div className="_bm_contIcon">
                                                <IoCallSharp />
                                            </div>

                                            <div className="_bm_contIcon">
                                                <FaShareAlt />
                                            </div>

                                            <div className="_bm_contIcon">
                                                <GiChatBubble />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 mb-1" style={{ marginLeft: "-8px", marginRight: "-8px", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}></div>

                                <div className="control-button-container">
                                    <div className="row-control-button">History</div>
                                    <div className="row-control-button">Timeline</div>
                                    <div className="row-control-button">Details</div>
                                    <div className="row-control-button">Add Tag</div>
                                    <div className="row-control-button">Historical Location</div>
                                </div>
                            </div>

                            <div className="_realTime_bx _moving">
                                <div className="d-flex justify-content-between">
                                    <div className="pe-1 w-100">
                                        <div className="fw-bold d-flex justify-content-between">
                                            <div className="text-md">
                                                <span className="me-1">0220</span>
                                                <span>MH40BL0220</span>
                                            </div>

                                            <div>
                                                <a href="#"><span className="ant-tag ant-tag-red">Insurance</span></a>
                                            </div>
                                        </div>

                                        <div className="text-muted" >
                                            Driver :<span> Ashish</span>
                                            <div className="d-inline-block">
                                                <div className="p-md-1 cursor-pointer"> <div className="mx-1"> <FaRegEdit /> </div></div>
                                            </div>
                                        </div>


                                        <div className="mt-1 d-flex align-items-center">
                                            <span className="me-1 d-flex" style={{ fontSize: "20px" }}><CiStopwatch /></span>

                                            <div>
                                                <span class="_tmtTxt">12m <span class="mx-1 text-muted">|</span>3.3 km</span>
                                                <span><span class="mx-1"><span class="mx-1 text-muted">|</span></span>
                                                    Speed: <span class="_tmtTxt"> 42 km/h</span></span>
                                            </div>
                                        </div>

                                        <div className="mt-1 d-flex">
                                            <span className="me-2" style={{ fontSize: "18px" }}><ImLocation /></span>
                                            <div className="mt-1">
                                                <div style={{ color: "#555555" }}>Sambalpur - Sundargarh - Rourkela Highway, Tudalaga, Sundargarh, Odisha</div>
                                                <div className="d-flex fw-bold" style={{ color: "#555555" }}>
                                                    <div><span class="">Nearest Address: </span><span class="">214 km from gati angul</span></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex  mt-1">
                                            <span className="me-2"><FaRoute /></span>

                                            <div>
                                                <div className="d-flex"><span>GWR  TO BANGALORE</span></div>
                                                <div>
                                                    <span ><span className="fw-bold">Loaded</span> <span class="text-muted">Since: 5 hrs</span>
                                                    </span>

                                                    <div class="d-inline-block text-danger" ><span class="mx-1 text-muted">|</span><div class="d-inline-block" >Late</div><div class="d-inline-block ps-1" >8h 24m</div></div>

                                                    <div class="d-inline-block"><span class="mx-1 text-muted">|</span><div class="d-inline-block">ETA</div><div class="d-inline-block ps-1">02/8 3:29</div></div>

                                                    <div className="d-inline-block">
                                                        <button className="realtime-row-more-info">More Info</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <div className="justify-content-end d-flex  ">
                                            <img className="_idelImg" src={Images.VEHICLEIDLE} alt="" width={20} height={20} />
                                            <img className="_movingImg" src={Images.VEHICLEMOVING} alt="" width={20} height={20} />
                                        </div>
                                        <div className="justify-content-end flex-column d-flex gap-2" style={{ marginTop: "auto" }}>
                                            <div className="_bm_contIcon">
                                                <IoCallSharp />
                                            </div>

                                            <div className="_bm_contIcon">
                                                <FaShareAlt />
                                            </div>

                                            <div className="_bm_contIcon">
                                                <GiChatBubble />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 mb-1" style={{ marginLeft: "-8px", marginRight: "-8px", borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}></div>

                                <div className="control-button-container">
                                    <div className="row-control-button">History</div>
                                    <div className="row-control-button">Timeline</div>
                                    <div className="row-control-button">Details</div>
                                    <div className="row-control-button">Add Tag</div>
                                    <div className="row-control-button">Historical Location</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 px-2">
                        <div style={{ width: "100%", height: "98%" }}><iframe style={{ width: "100%", height: "100%" }} frameborder={0} scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=Charted%20Bus%20Stand+(Transport360)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps tracker sport</a></iframe></div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default VehicleDashboard