import { indentCountApi, indentListApi } from "_services";
import { ButtonHeading, CustomSelect } from "components";
import { utils } from "helper";
import { AddIndentModal, IndentAndBidDetailModal } from "modals";
import React, { useEffect, useState } from "react";
import {
  IoChevronBack,
  IoChevronDownCircleOutline,
  IoChevronForward,
} from "react-icons/io5";
import StorageService from "utils/StorageServices/StorageServices";

const Plan = () => {
  const [showAddIndent, setShowAddIndent] = useState(false);
  const [showPlanDetail, setShowPlanDetail] = useState(false);
  const [indentList, setIndentList] = useState([]);
  const [indentCount, setIndentCount] = useState([]);
  const [filter, setFilter] = useState({
    plan_status: "",
    bid_status: "",
    responses: "",
    limit: 10,
    offset: 1,
    search: "",
  });
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    getIndentCount();
  }, []);

  useEffect(() => {
    if (filter?.plan_status != 1) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        bid_status: 0,
        responses: 0,
      }));
      return;
    }

    setFilter((prevFilter) => ({
      ...prevFilter,
      bid_status: indentCount?.bid_status[0].id,
      responses: indentCount?.responses[0].id,
    }));
  }, [filter?.plan_status, indentCount]);

  useEffect(() => {
    if (filter?.plan_status) {
      getIndentList();
    }
  }, [filter?.plan_status, filter?.bid_status, filter?.responses]);

  const arryOptions = [
    { label: "Arjun", value: "01" },
    { label: "Anvit", value: "02" },
  ];

  const getIndentList = async () => {
    const payload = {
      company_id: StorageService?.getCompanyId(),
      branch_id: "",
      consignor_id: "",
      vehicle_type_id: "",
      start_date: "",
      end_date: "",
      search: "",
      plan_status: filter?.plan_status,
      bid_status: filter?.bid_status,
      responses: filter?.responses,
      limit: 50,
      offset: 1,
    };

    try {
      const res = await indentListApi(payload);

      if (res?.responseCode == 200) {
        setIndentList(res?.data);
        setTotalItems(res?.data?.total_record);
      }
    } catch (error) {}
  };

  const getIndentCount = async () => {
    const payload = {
      company_id: StorageService?.getCompanyId(),
      branch_id: "",
      consignor_id: "",
      vehicle_type_id: "",
      start_date: "",
      end_date: "",
      search: "",
    };

    try {
      const res = await indentCountApi(payload);

      if (res?.responseCode == 200) {
        setIndentCount(res?.data);
        setFilter({
          ...filter,
          plan_status: res?.data?.plan_status[0].id,
          bid_status: res?.data?.bid_status[0].id,
          responses: res?.data?.responses[0].id,
        });
      }
    } catch (error) {}
  };

  const togglePlanDetail = () => {
    setShowPlanDetail(!showPlanDetail);
  };

  const handleFilter = (id, title) => {
    if (title == "plan") {
      if (id !== filter?.plan_status) {
        setFilter({ ...filter, plan_status: id });
      }
    }

    if (title == "bid") {
      if (id !== filter?.bid_status) {
        setFilter({ ...filter, bid_status: id });
      }
    }

    if (title == "responses") {
      if (id !== filter?.responses) {
        setFilter({ ...filter, responses: id });
      }
    }
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${i === filter.offset ? "active" : ""}`}
        >
          <a
            className="page-link _avPointer"
            onClick={(e) => handlePageChange(i)}
          >
            {i}
          </a>
        </li>
      );
    }

    return (
      <ul className="pagination ">
        <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset - 1)}
          >
            <IoChevronBack />
          </a>
        </li>
        {pages}
        <li
          className={`page-item ${
            filter?.offset === totalPages ? "disabled" : ""
          }`}
        >
          <a
            className="page-link _avPointer"
            onClick={() => handlePageChange(filter?.offset + 1)}
          >
            <IoChevronForward />
          </a>
        </li>
      </ul>
    );
  };

  const planFromLoc = (loc) => {
    return loc.map((item) => item.from_city).join(" + ");
  };

  const planToLoc = (loc) => {
    return loc.map((item) => item.to_city).join(" + ");
  };

  return (
    <>
      <ButtonHeading
        title="Indent"
        onClick={() => setShowAddIndent(!showAddIndent)}
        buttonTitle="Add Indent"
      />
      <div className="_indentView_flx">
        <div className="indentSide_bx">
          <div className="_indtsLs_flx">
            <div className="_indtsList">
              <h5>PLAN STATUS</h5>
              <ul className="nav nav-tabs" id="myIndentTab" role="tablist">
                {indentCount?.plan_status?.map((item) => (
                  <li className="nav-item" role="presentation" key={item.id}>
                    <button
                      className={`nav-link ${
                        filter?.plan_status === item.id ? "active" : ""
                      }`}
                      id={`${item.name.toLowerCase().replace(/\s+/g, "-")}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${item.name
                        .toLowerCase()
                        .replace(/\s+/g, "-")}-tab-pane`}
                      type="button"
                      role="tab"
                      aria-controls={`${item.name
                        .toLowerCase()
                        .replace(/\s+/g, "-")}-tab-pane`}
                      aria-selected={filter?.plan_status === item.id}
                      onClick={() => handleFilter(item.id, "plan")}
                    >
                      <span>{item.name}</span> <span>{item.count}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="_indtsList">
              <h5>BIDS 500</h5>
              <div className="_bidsLs_flx">
                {indentCount?.bid_status?.map((bid) => {
                  return (
                    <div className="_bidsList" key={bid.id}>
                      <input
                        className="form-check-input _avPointer"
                        id={`bid-${bid.id}`}
                        type="radio"
                        name="bidList"
                        checked={filter?.bid_status === bid.id}
                        onChange={() => handleFilter(bid.id, "bid")}
                        disabled={filter?.plan_status != 1}
                      />
                      <label htmlFor={`bid-${bid.id}`}>
                        <span>{bid.name}</span>
                        <span>{bid.count}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="_indtsList">
              <h5>RESPONSES</h5>
              <div className="_bidsLs_flx">
                {indentCount?.responses?.map((bid) => {
                  return (
                    <div className="_bidsList" key={bid.id}>
                      <input
                        className="form-check-input _avPointer"
                        id={`responses-${bid.id}`}
                        type="radio"
                        name="responseList"
                        checked={filter?.responses === bid.id}
                        onChange={() => handleFilter(bid.id, "responses")}
                        disabled={filter?.plan_status != 1}
                      />
                      <label htmlFor={`responses-${bid.id}`}>
                        <span>{bid.name}</span>
                        <span>{bid.count}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="_indTabl_right">
          <div className="_indentTable _planLTable">
            <div class="accordion" id="indentTableAccordian">
              <table>
                <thead>
                  <tr>
                    <th>Indent Date</th>
                    <th>Indent No.</th>
                    <th>Party</th>
                    <th>From Station</th>
                    <th>To Station</th>
                    <th>Branch Name</th>
                    <th>Fleet Type</th>
                    <th>Material Type</th>
                    <th>View</th>
                  </tr>
                </thead>
                {indentList?.plan_list?.map((indent, index) => {
                  const isFirstItem = index === 0;
                  return (
                    <tbody class="accordion-item">
                      <tr>
                        <td>
                          <div className="_dateNtime _pDate">
                            <p className="_tblText_inDent">
                              {utils?.formatDateMonth(indent?.indent_date)}
                            </p>
                            <p className="_lgtText_inDent">
                              {utils?.formatTime(indent?.indent_date)}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {indent?.indent_number}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {indent?.consignor_id
                                ? indent?.consignor_id?.name
                                : "Self"}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {planFromLoc(indent?.from_location)}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {planToLoc(indent?.to_location)}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {indent?.branch_id?.branch}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="_dateNtime">
                            <div className="__locationWid_bx">
                              <p className="_tblText_inDent">
                                {indent?.lorry_type_id?.lorry_type}
                              </p>
                            </div>
                            <p className="_lgtText_inDent">
                              {indent?.vehicle_type_id?.name}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div className="__locationWid_bx">
                            <p className="_tblText_inDent">
                              {indent?.material_type_id?.name}
                            </p>
                          </div>
                        </td>

                        <td>
                          <button
                            class={`accordion-button _plnChev_btn ${
                              isFirstItem ? "" : "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${indent?._id}`}
                            aria-expanded={isFirstItem ? "true" : "false"}
                            aria-controls={`${indent?._id}`}
                          >
                            <IoChevronDownCircleOutline />
                          </button>
                        </td>
                      </tr>

                      <tr className="collapseRow">
                        <td colSpan={9}>
                          <div
                            id={`${indent?._id}`}
                            className={`accordion-collapse collapse ${
                              isFirstItem ? "show" : ""
                            }`}
                            data-bs-parent="#indentTableAccordian"
                          >
                            <div class="accordion-body">
                              {indent?.to_location?.map((loca) => {
                                return (
                                  <>
                                    {loca?.po_list?.map((po) => {
                                      return (
                                        <div className="_innrPO_trs">
                                          <div className="_poDetails">
                                            <span>PO Date</span>
                                            <p>
                                              {utils?.formatDateMonth(
                                                po.po_date
                                              )}
                                            </p>
                                          </div>

                                          <div className="_poDetails">
                                            <span>PO Number</span>
                                            <p>{po?.po_number}</p>
                                          </div>

                                          <div className="_poDetails">
                                            <span>Party</span>
                                            <p>
                                              {indent?.consignor_id
                                                ? indent?.consignor_id?.name
                                                : "Self"}
                                            </p>
                                          </div>

                                          <div className="_poDetails">
                                            <span>From Station</span>
                                            <p>{po?.from_location}</p>
                                          </div>

                                          <div className="_poDetails">
                                            <span>Pickup Address</span>
                                            <p>{po?.unit_id?.name}</p>
                                          </div>

                                          <div className="_poDetails">
                                            <span>To Station</span>
                                            <p>{po?.to_location}</p>
                                          </div>

                                          <div className="_poDetails">
                                            <span>Drop Address</span>
                                            <p>{po?.consignee_id?.name}</p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={9}>
                          <div className="d-flex justify-content-between gap-2">
                            <div className="_separateBxs">
                              <button className="_disPatch_ind">
                                Dispatch
                              </button>

                              <span className="activeStatus">Active</span>

                              <div className="_bidBtn_fx">
                                <div
                                  className="_sepBid_fx"
                                  onClick={togglePlanDetail}
                                >
                                  <span className="_bidTxt">New Bid</span>
                                  <span className="_bidCount">0</span>
                                </div>

                                <div
                                  className="_sepBid_fx"
                                  onClick={togglePlanDetail}
                                >
                                  <span className="_bidTxt">Accepted</span>
                                  <span className="_bidCount">0</span>
                                </div>

                                <div
                                  className="_sepBid_fx"
                                  onClick={togglePlanDetail}
                                >
                                  <span className="_bidTxt">Closed</span>
                                  <span className="_bidCount">0</span>
                                </div>
                              </div>
                            </div>

                            <div>
                              <CustomSelect options={arryOptions} />
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={9}></td>
                      </tr>
                    </tbody>
                  );
                })}
                {/* 
                <tbody class="accordion-item">
                  <tr>
                    <td>
                      <div className="_dateNtime _pDate">
                        <p className="_tblText_inDent">19 Jun 2024</p>
                        <p className="_lgtText_inDent">05:30 AM</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">234234</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">Parle Biscuit</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">Indore</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">Jabalpur</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">Branch Number 1</p>
                      </div>
                    </td>

                    <td>
                      <div className="_dateNtime">
                        <div className="__locationWid_bx">
                          <p className="_tblText_inDent">Container</p>
                        </div>
                        <p className="_lgtText_inDent">32Feet XL</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">Biscuit</p>
                      </div>
                    </td>

                    <td>
                      <button
                        class="accordion-button _plnChev_btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        <IoChevronDownCircleOutline />
                      </button>
                    </td>
                  </tr>

                  <tr className="collapseRow">
                    <td colSpan={9}>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse "
                        data-bs-parent="#indentTableAccordian"
                      >
                        <div class="accordion-body">
                          <div className="_innrPO_trs">
                            <div className="_poDetails">
                              <span>PO Date</span>
                              <p>24 Jun 2024</p>
                            </div>

                            <div className="_poDetails">
                              <span>PO Number</span>
                              <p>234234</p>
                            </div>

                            <div className="_poDetails">
                              <span>Party</span>
                              <p>Parle Biscuit</p>
                            </div>

                            <div className="_poDetails">
                              <span>From Station</span>
                              <p>Indore</p>
                            </div>

                            <div className="_poDetails">
                              <span>Pickup Address</span>
                              <p>Indore</p>
                            </div>

                            <div className="_poDetails">
                              <span>To Station</span>
                              <p>Jabalpur</p>
                            </div>

                            <div className="_poDetails">
                              <span>Drop Address</span>
                              <p>Jabalpur</p>
                            </div>
                          </div>

                          <div className="_innrPO_trs">
                            <div className="_poDetails">
                              <span>PO Date</span>
                              <p>24 Jun 2024</p>
                            </div>

                            <div className="_poDetails">
                              <span>PO Number</span>
                              <p>234234</p>
                            </div>

                            <div className="_poDetails">
                              <span>Party</span>
                              <p>Parle Biscuit</p>
                            </div>

                            <div className="_poDetails">
                              <span>From Station</span>
                              <p>Indore</p>
                            </div>

                            <div className="_poDetails">
                              <span>Pickup Address</span>
                              <p>Indore</p>
                            </div>

                            <div className="_poDetails">
                              <span>To Station</span>
                              <p>Jabalpur</p>
                            </div>

                            <div className="_poDetails">
                              <span>Drop Address</span>
                              <p>Jabalpur</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={9}>
                      <div className="d-flex justify-content-between gap-2">
                        <div className="_separateBxs">
                          <button className="_disPatch_ind">Dispatch</button>

                          <span className="activeStatus">Active</span>

                          <div className="_bidBtn_fx">
                            <div
                              className="_sepBid_fx"
                              onClick={togglePlanDetail}
                            >
                              <span className="_bidTxt">New Bid</span>
                              <span className="_bidCount">0</span>
                            </div>

                            <div
                              className="_sepBid_fx"
                              onClick={togglePlanDetail}
                            >
                              <span className="_bidTxt">Accepted</span>
                              <span className="_bidCount">0</span>
                            </div>

                            <div
                              className="_sepBid_fx"
                              onClick={togglePlanDetail}
                            >
                              <span className="_bidTxt">Closed</span>
                              <span className="_bidCount">0</span>
                            </div>
                          </div>
                        </div>

                        <div>
                          <CustomSelect options={arryOptions} />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={9}></td>
                  </tr>
                </tbody>

                <tbody class="accordion-item">
                  <tr>
                    <td>
                      <div className="_dateNtime _pDate">
                        <p className="_tblText_inDent">19 Jun 2024</p>
                        <p className="_lgtText_inDent">05:30 AM</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">234234</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">Parle Biscuit</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">Indore</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">Jabalpur</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">Branch Number 1</p>
                      </div>
                    </td>

                    <td>
                      <div className="_dateNtime">
                        <div className="__locationWid_bx">
                          <p className="_tblText_inDent">Container</p>
                        </div>
                        <p className="_lgtText_inDent">32Feet XL</p>
                      </div>
                    </td>

                    <td>
                      <div className="__locationWid_bx">
                        <p className="_tblText_inDent">Biscuit</p>
                      </div>
                    </td>

                    <td>
                      <button
                        class="accordion-button _plnChev_btn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        <IoChevronDownCircleOutline />
                      </button>
                    </td>
                  </tr>

                  <tr className="collapseRow">
                    <td colSpan={9}>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse "
                        data-bs-parent="#indentTableAccordian"
                      >
                        <div class="accordion-body">
                          <div className="_innrPO_trs">
                            <div className="_poDetails">
                              <span>PO Date</span>
                              <p>24 Jun 2024</p>
                            </div>

                            <div className="_poDetails">
                              <span>PO Number</span>
                              <p>234234</p>
                            </div>

                            <div className="_poDetails">
                              <span>Party</span>
                              <p>Parle Biscuit</p>
                            </div>

                            <div className="_poDetails">
                              <span>From Station</span>
                              <p>Indore</p>
                            </div>

                            <div className="_poDetails">
                              <span>Pickup Address</span>
                              <p>Indore</p>
                            </div>

                            <div className="_poDetails">
                              <span>To Station</span>
                              <p>Jabalpur</p>
                            </div>

                            <div className="_poDetails">
                              <span>Drop Address</span>
                              <p>Jabalpur</p>
                            </div>
                          </div>

                          <div className="_innrPO_trs">
                            <div className="_poDetails">
                              <span>PO Date</span>
                              <p>24 Jun 2024</p>
                            </div>

                            <div className="_poDetails">
                              <span>PO Number</span>
                              <p>234234</p>
                            </div>

                            <div className="_poDetails">
                              <span>Party</span>
                              <p>Parle Biscuit</p>
                            </div>

                            <div className="_poDetails">
                              <span>From Station</span>
                              <p>Indore</p>
                            </div>

                            <div className="_poDetails">
                              <span>Pickup Address</span>
                              <p>Indore</p>
                            </div>

                            <div className="_poDetails">
                              <span>To Station</span>
                              <p>Jabalpur</p>
                            </div>

                            <div className="_poDetails">
                              <span>Drop Address</span>
                              <p>Jabalpur</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={9}>
                      <div className="d-flex justify-content-between gap-2">
                        <div className="_separateBxs">
                          <button className="_disPatch_ind">Dispatch</button>

                          <span className="activeStatus">Active</span>

                          <div className="_bidBtn_fx">
                            <div
                              className="_sepBid_fx"
                              onClick={togglePlanDetail}
                            >
                              <span className="_bidTxt">New Bid</span>
                              <span className="_bidCount">0</span>
                            </div>

                            <div
                              className="_sepBid_fx"
                              onClick={togglePlanDetail}
                            >
                              <span className="_bidTxt">Accepted</span>
                              <span className="_bidCount">0</span>
                            </div>

                            <div
                              className="_sepBid_fx"
                              onClick={togglePlanDetail}
                            >
                              <span className="_bidTxt">Closed</span>
                              <span className="_bidCount">0</span>
                            </div>
                          </div>
                        </div>

                        <div>
                          <CustomSelect options={arryOptions} />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={9}></td>
                  </tr>
                </tbody> */}
              </table>
            </div>
            <div className="paginationFt_avt ">{renderPagination()}</div>
          </div>
        </div>
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas mdOffcanvas ${
          showAddIndent ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showAddIndent ? "visible" : "hidden" }}
      >
        {showAddIndent && (
          <AddIndentModal
            isOpen={showAddIndent}
            onClose={(e) => setShowAddIndent(false)}
            callList={getIndentList}
          />
        )}
      </div>
      <div className={showAddIndent ? "offBack_drop" : ""}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${
          showPlanDetail ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showPlanDetail ? "visible" : "hidden" }}
      >
        {showPlanDetail && (
          <IndentAndBidDetailModal
            isOpen={showPlanDetail}
            onClose={(e) => setShowPlanDetail(false)}
          />
        )}
      </div>
      <div className={showPlanDetail ? "offBack_drop" : ""}></div>
    </>
  );
};

export default Plan;
