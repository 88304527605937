import React, { useContext, useState } from 'react'
import { ButtonHeading, DriverDetailsTab, DriverTripTab } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import Ledger from '../Ledger';
import { UserContext } from 'context';


const DriverDetails = () => {

    const [activeTab, setActiveTab] = useState(1);
    const { isDriverName } = useContext(UserContext)

    const tabProps = {
        tabList: constant.driverDetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    }

    return (
        <div className=''>
            <ButtonHeading title={isDriverName ? isDriverName : 'Loading...'} buttonTitle="Edit" />

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && (
                    <DriverDetailsTab />
                )}
                {activeTab === 2 && (
                    <Ledger type={3} />
                )}
            </div>
        </div>
    )
}

export default DriverDetails;