import { addIndentApi, getMarketVisiblityApi, getMaterialApi, handleKeyDown } from "_services";
import { getLorryTypeList } from "_services/accountsServices/lrServices";
import {
  getBranchDropdownListData,
  getConsignorDropdownListData,
  getPlanResponseApi,
} from "_services/dropdownServices/dropdownService";
import { CommonDatePicker, CommonInput, CustomSelect } from "components";
import { UserContext } from "context";
import { State } from "country-state-city";
import { errorSchema, utils } from "helper";
import AddPoModal from "modals/AddPoModal";
import { Checkbox } from "pages/User/AddUser";
import React, { useContext, useEffect, useState } from "react";
import StorageService from "utils/StorageServices/StorageServices";

const AddIndentModal = (props) => {
  const initialForm = {
    company_id: StorageService.getCompanyId(),
    indent_number: "",
    indent_date: new Date(),
    indent_expiry_date: new Date().setDate(new Date().getDate() + 2),
    consignor_id: "",
    lorry_type_id: "",
    vehicle_type_id: "",
    weight: "",
    material_type_id: "",
    location: [],
    from_location: [],
    to_location: [],
    freight_type: 4,
    freight_rate: "",
    freight_amount: "",
    payment_terms: 1,
    payment_percentage: "",
    branch_id: "",
    assign_to: "",
    is_allow_visibility: 0,
    connection_id: "",
    is_allow_calls: 0,
    responses: "",
  };

  const initialFrom = {
    from_city: "",
    from_state: "",
    lat: "",
    long: "",
  };
  const initialTo = {
    to_city: "",
    to_state: "",
    lat: "",
    long: "",
    po_list: [],
  };
  const { masterData, cities ,modalZIndex} = useContext(UserContext);
  const [showAddPo, setShowAddPo] = useState(false);
  const { setModalZIndex } = useContext(UserContext);
  const [form, setForm] = useState(initialForm);
  const [fromLoaction, setFromLocation] = useState([initialFrom]);
  const [toLoaction, setToLocation] = useState([initialTo]);
  const [formError, setFormError] = useState("");
  const [consignorList, setConsignorList] = useState([]);
  const [lorryTypeList, setLorryTypeList] = useState([]);
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [toIndex, setToIndex] = useState("");
  const [freightTypeList, setFreightTypeList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [responseList, setResponseList] = useState([]);
  const [marketVisiList, setMarketVisiList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [loading,setLoading] = useState(false)
  const [paymentTermsList, setPaymentTermsList] = useState([
    {
      label: "Advance",
      value: 1,
    },
    {
      label: "To Pay",
      value: 2,
    },
  ]);

  const paymentPer = [
    { label: "90%", value: 90 },
    { label: "80%", value: 80 },
    { label: "70%", value: 70 },
  ];

  let { isOpen, onClose } = props;

  const citie = [
    { label: "Indore", value: "01" },
    { label: "Bhopal", value: "02" },
  ];

  const handleAddPo = (index) => {
    setShowAddPo(!showAddPo);
    setModalZIndex(999);
    setToIndex(index);
  };

  useEffect(() => {
    if (isOpen) {
      getDropdownDataConsignorList();
      getDataLorryTypeList();
      getMasterDetails();
      getBranchList();
      getMarketList();
      getMaterialList()
      // getPlanRespList()
    }
  }, [isOpen]);

  useEffect(() => {
    if (!showAddPo) {
      setModalZIndex("");
    }
  }, [showAddPo]);

  useEffect(() => {
    if (form?.lorry_type_id) {
      const findLorry = lorryTypeList?.find(
        (i) => i?.value == form?.lorry_type_id
      );
      if (findLorry) {
        handleOnChange("vehicle_type_id", findLorry?.props[0]?.values[0]._id);
        setVehicleTypeList(
          findLorry?.props[0]?.values?.map((i) => {
            return {
              ...i,
              label: i?.name,
              value: i?._id,
            };
          })
        );
      }
    }
  }, [form?.lorry_type_id]);

  useEffect(() => {
    if (form?.is_allow_visibility == 0) {
      handleOnChange("connection_id", "");
    }else{
      handleOnChange("connection_id", marketVisiList[0]?.value);
    }
  }, [form?.is_allow_visibility]);

  useEffect(() => {
    if (form?.payment_terms != 1) {
      handleOnChange("payment_percentage", "");
    }
  }, [form?.payment_terms]);

  useEffect(() => {
    if (form?.freight_type == 4) {
      handleOnChange("freight_rate", "");
    } else {
      handleOnChange("freight_amount", "");
    }
  }, [form?.freight_type]);

  useEffect(() => {
    if (form?.indent_date) {
      handleOnChange("indent_expiry_date", addDays(form?.indent_date, 2));
    }
  }, [form?.indent_date]);

  useEffect(() => {
    if (form?.vehicle_type_id) {
      handleOnChange("branch_id", branchList[0]?.value);
    }
  }, [form?.vehicle_type_id, branchList]);

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const getDropdownDataConsignorList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: "",
      };
      const res = await getConsignorDropdownListData(object);
      if (res?.responseCode == 200) {
        const list = res?.data?.consignor_list?.map((el) => {
          return {
            label: el?.name,
            value: el?._id,
          };
        });

        setConsignorList([{ label: "Self", value: "" }, ...list]);
      }
    } catch (error) {
      throw error;
    }
  };

  const getDataLorryTypeList = async () => {
    try {
      const res = await getLorryTypeList();
      if (res?.responseCode == 200) {
        setLorryTypeList(
          res?.data?.lorry_type?.map((i) => {
            return {
              ...i,
              label: i?.lorry_type,
              value: i?._id,
            };
          })
        );
        handleOnChange("lorry_type_id", res?.data?.lorry_type[0]._id);
      }
    } catch (error) {
      throw error;
    }
  };

  const getMarketList = async () => {
    try {
      const res = await getMarketVisiblityApi({
        company_id: StorageService?.getCompanyId(),
        keyword: "",
      });
     
      if (res?.responseCode == 200) {
        
         const list = res?.data?.map((i) => {
            return {
              ...i,
              label: i?.name,
              value: i?._id,
            };
          })
          setMarketVisiList([{label:"All Transport 360 Members",value:""}, ...list])
      }
    } catch (error) {
      throw error;
    }
  };

  const getMaterialList = async () => {
    try {
      const res = await getMaterialApi({
        company_id: StorageService?.getCompanyId(),
      });
     
      if (res?.responseCode == 200) {
        setMaterialList(
          res?.data?.map((i) => {
            return {
              ...i,
              label: i?.name,
              value: i?._id,
            };
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const getBranchList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: "",
      };
      const res = await getBranchDropdownListData(object);
      if (res?.responseCode == 200) {
        let arrayData = res?.data?.branch_list?.map((el) => {
          return {
            label: el?.branch,
            value: el?._id,
          };
        });

        setBranchList(arrayData);
      }
    } catch (error) {
      // throw error;
    }
  };

  const getMasterDetails = () => {
    setResponseList(
      masterData?.plan_responses_list?.map((i) => {
        return {
          label: i?.name,
          value: i?.id,
        };
      })
    );

    setFreightTypeList(
      masterData?.freight_type_list
        ?.map((i) => {
          return {
            label: i?.name,
            value: i?.id,
          };
        })
        ?.filter((item) => item.value === 6 || item.value === 4)
    );
  };

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...form,
      [name]: value,
    };

    setForm(stateObj);

    if (formError) {
      const validationResult = await utils.checkFormError(
        {
          ...stateObj,
          toLoction: toLoaction,
          fromLoction: fromLoaction,
        },
        errorSchema.addPostIndentSchima
      );

      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleInputChange = async (value, index, label, tableIndex) => {
    if (label === "From City") {
      const stateDetails = State.getStateByCodeAndCountry(
        value?.stateCode,
        value?.countryCode
      );
      const NewFromLocation = [...fromLoaction];

      NewFromLocation[index].from_city = value.name;
      NewFromLocation[index].from_state = stateDetails.name;
      NewFromLocation[index].lat = value?.latitude;
      NewFromLocation[index].long = value?.longitude;

      setFromLocation(NewFromLocation);

      if (!!formError) {
        const validationResult = await utils.checkFormError(
          { ...form, toLoaction: toLoaction, fromLoaction: fromLoaction },
          errorSchema.createTripSchima
        );

        if (validationResult === true) {
          setFormError("");
        } else {
          setFormError(validationResult);
        }
      }
    }

    if (label === "To City") {
      const stateDetails = State.getStateByCodeAndCountry(
        value?.stateCode,
        value?.countryCode
      );
      const NewToLocation = [...toLoaction];

      NewToLocation[index].to_city = value.name;
      NewToLocation[index].to_state = stateDetails.name;
      NewToLocation[index].lat = value?.latitude;
      NewToLocation[index].long = value?.longitude;

      setToLocation(NewToLocation);

      if (!!formError) {
        const validationResult = await utils.checkFormError(
          { ...form, toLoaction: toLoaction, fromLoaction: fromLoaction },
          errorSchema.createTripSchima
        );

        if (validationResult === true) {
          setFormError("");
        } else {
          setFormError(validationResult);
        }
      }
    }
  };

  const addFromInput = (e) => {
    setFromLocation((preState) => {
      return [...preState, initialFrom];
    });
  };

  const addToInput = () => {
    setToLocation((preState) => {
      return [...preState, initialTo];
    });
  };

  const callBackPo = (poData) => {
    const updatedLocations = [...toLoaction];
    updatedLocations[toIndex].po_list.push(poData);
    setToLocation(updatedLocations);
  };

  const handleDeletePo = (index, poIndex) => {
    const updatedLocations = [...toLoaction];
    updatedLocations[index]?.po_list?.splice(poIndex, 1);
    setToLocation(updatedLocations);
  };

  const handleRemovePickup = (index) => {
    const updatedLocations = [...fromLoaction];
    updatedLocations.splice(index, 1);
    setFromLocation(updatedLocations);
  };

  const handleRemoveDrop = (index) => {
    const updatedLocations = [...toLoaction];
    updatedLocations.splice(index, 1);
    setToLocation(updatedLocations);
  };

  let addPoModal = {
    isOpen: showAddPo,
    closeModal: (e) => setShowAddPo(false),
    modalMainClass: "_dashCommon_Modal _poModal ",
    modalTitle: "Add PO",
    footerPart: true,
    submitTitle: "Add PO",
    callBackPo: callBackPo,
    fromLoaction:fromLoaction,
    toLoaction:toLoaction,
    toIndex:toIndex,
    modalZIndex:modalZIndex
  };

  const handleSubimitPost = async () => {
    const removePoList = toLoaction.map(({ po_list, ...rest }) => rest);
    const listOfloaction = [...fromLoaction, ...removePoList]?.map((i) => {
      return {
        city: i?.from_city ? i?.from_city : i?.to_city,
        state: i?.from_state ? i?.from_state : i?.to_state,
        lat: i?.lat,
        long: i?.long,
      };
    });

   

    const updatedList = toLoaction.map(item => ({
      ...item,
      po_list: item.po_list.map(po => {
        const { unit_details, consignee_detials, ...rest } = po; 
        return rest; 
      })
    }));

    const payload = {
      ...form,
      from_location: fromLoaction,
      to_location: updatedList,
      location: listOfloaction,
      indent_expiry_date: new Date(form?.indent_date),
    };

   
    const validationResult = await utils.checkFormError(
      {
        ...form,
        toLoction: toLoaction,
        fromLoction: fromLoaction,
      },
      errorSchema.addPostIndentSchima
    );

    if (validationResult == true) {
      setLoading(true);
      try {
        const res = await addIndentApi(payload);

        if (res?.responseCode == 200) {
          setLoading(true);
          onClose(!isOpen)
          props.callList()
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }else{
      setFormError(validationResult);
    }


  };

  return (
    <>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Add Indent</h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => onClose(!isOpen)}
        ></button>
      </div>

      <div className="offcanvas-body">
        <div className="pr_procssBx" style={{ marginBottom: "9px" }}>
          <div className="_lrdMax">
            <div className="flxInr">
              <div className="halfDiv">
                <CommonDatePicker
                  name="indent_date"
                  label="Indent Date"
                  startDate={form?.indent_date}
                  setStartDate={(data) => {
                    handleOnChange("indent_date", data);
                    // setStartDate(data);
                  }}
                  error={formError.indent_date}
                  value={form.indent_date}
                />
              </div>

              <div className="halfDiv">
                <CommonDatePicker
                  name="indent_expiry_date"
                  label="Indent Expiry Date"
                  startDate={form?.indent_expiry_date}
                  setStartDate={(data) => {
                    handleOnChange("indent_expiry_date", data);
                    // setStartDate(data);
                  }}
                  error={formError.indent_expiry_date}
                  value={form.indent_expiry_date}
                  minDate={form?.indent_expiry_date}
                />
              </div>

              <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="Indent Number"
                  placeholder="Indent Number"
                  name="indent_number"
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  error={formError.indent_number}
                  value={form.indent_number}
                />
                <p className="_bottomPLine">Leave Blank to Auto Generate</p>
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={consignorList}
                  label="Select Party"
                  placeholder="Select Party"
                  onSelect={(data) => {
                    handleOnChange("consignor_id", data?.value);
                  }}
                  error={formError.consignor_id}
                  value={consignorList?.find(
                    (i) => i?.value == form.consignor_id
                  )}
                />
                <p className="_bottomPLine">
                  Visible Internally. Not visible to Others
                </p>
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={lorryTypeList}
                  label="Select Fleet"
                  placeholder="Select Fleet"
                  onSelect={(data) => {
                    handleOnChange("lorry_type_id", data?.value);
                  }}
                  error={formError.lorry_type_id}
                  value={lorryTypeList?.find(
                    (i) => i?.value == form.lorry_type_id
                  )}
                />
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={vehicleTypeList}
                  label="Fleet Type"
                  placeholder="Select Fleet Type"
                  onSelect={(data) => {
                    handleOnChange("vehicle_type_id", data?.value);
                  }}
                  error={formError.vehicle_type_id}
                  value={vehicleTypeList?.find(
                    (i) => i?.value == form.vehicle_type_id
                  )}
                />
              </div>

              <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="Weight"
                  placeholder="Weight"
                  name="weight"
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  value={form?.weight}
                  onKeyDown={handleKeyDown}
                  error={formError.weight}
                />
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={materialList}
                  label="Material"
                  placeholder="Select Material"
                  name="material_type_id"
                  onSelect={(data)=>{
                  handleOnChange("material_type_id", data?.value)
                 }}
                 value={materialList?.find((i)=>i?.value==form?.material_type_id)}
                 error={formError?.material_type_id}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="_sltIndent_bx" style={{ marginBottom: "9px" }}>
          <div className="_sltHead">
            <h4>Select Location</h4>
          </div>

          <div className="container-fluid _verLine_cts">
            <div className="row _btmRow_cts mt-0 pb-0 " style={{zIndex: '10'}}>
              {fromLoaction?.map((data, index1) => {
                return (
                  <div className="col-md-12">
                    <div className="_addRouts_flex">
                      <div className="_cityInput_bx">
                        <CustomSelect
                          label="From City"
                          options={cities}
                          dynamicIndex={index1}
                          onSelect={handleInputChange}
                          value={{
                            label: data?.from_city
                              ? `${data?.from_city}, ${data?.from_state}`
                              : "",
                            value: `${data?.from_city}, ${data?.from_state}`,
                          }}
                          error={
                            `${
                              formError[`fromLoction[${index1}].from_city`]
                            }` !== "undefined"
                              ? `${
                                  formError[`fromLoction[${index1}].from_city`]
                                }`
                              : ""
                          }
                        />
                      </div>

                      <div className="_addDel_bx">
                        {!fromLoaction[index1]?.from_city ? (
                          <button className="_pickN_dropBtn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                fill="#1956F6"
                              ></path>
                            </svg>{" "}
                            <span>Add Pickup</span>
                          </button>
                        ) : (
                          <button
                            className="_pickN_dropBtn"
                            onClick={addFromInput}
                            disabled={fromLoaction.some(
                              (item) => item.from_city == ""
                            )}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                fill="#1956F6"
                              />
                            </svg>{" "}
                            <span>Add Pickup</span>
                          </button>
                        )}
                        {fromLoaction?.length > 1 && (
                          <button
                            className="_deleteBtn"
                            onClick={() => handleRemovePickup(index1)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                fill="#C41F1F"
                              ></path>
                            </svg>
                            <span>Delete</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {toLoaction?.map((data, index) => {
                return (
                  <>
                    <div className="col-md-12">
                      <div className="_addRouts_flex">
                        <div className="_cityInput_bx">
                          <CustomSelect
                            label="To City"
                            options={cities}
                            dynamicIndex={index}
                            onSelect={handleInputChange}
                            value={{
                              label: data?.to_city
                                ? `${data?.to_city}, ${data?.to_state}`
                                : "",
                              value: `${data?.to_city}, ${data?.to_state}`,
                            }}
                            error={
                              `${formError[`toLoction[${index}].to_city`]}` !==
                              "undefined"
                                ? `${formError[`toLoction[${index}].to_city`]}`
                                : ""
                            }
                          />
                        </div>

                        <div className="_addDel_bx">
                          {!toLoaction[index]?.to_city ? (
                            <button className="_pickN_dropBtn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                  fill="#1956F6"
                                />
                              </svg>{" "}
                              <span>Add Drop</span>
                            </button>
                          ) : (
                            <button
                              className="_pickN_dropBtn"
                              onClick={addToInput}
                              disabled={toLoaction.some(
                                (item) => item.to_city === ""
                              )}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                  fill="#1956F6"
                                />
                              </svg>{" "}
                              <span>Add Drop</span>
                            </button>
                          )}

                          {toLoaction?.length > 1 && (
                            <button
                              className="_deleteBtn"
                              onClick={() => handleRemoveDrop(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                  fill="#C41F1F"
                                ></path>
                              </svg>
                              <span>Delete</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {data?.po_list?.map((po, poIndex) => {
                        return (
                          <div className="_poDetail_bx mb-2">
                            <ul>
                              <li>
                                <div className="__pdxTx">PO Date</div>{" "}
                                <div className="__pdseTx">
                                  {utils?.formateDateCalander(po?.po_date)}
                                </div>
                              </li>
                              <li>
                                <div className="__pdxTx">PO Number</div>{" "}
                                <div className="__pdseTx">{po?.po_number}</div>
                              </li>
                              <li>
                                <div className="__pdxTx">Pickup Location</div>{" "}
                                <div>
                                  <span className="_fstAddress_tx">
                                    {po?.from_location}
                                  </span>{" "}
                                  <span className="_scdAddress_tx">
                                   {`${po?.unit_details?.code} , ${po?.unit_details?.name}`}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="__pdxTx">Drop Location</div>{" "}
                                <div>
                                  <span className="_fstAddress_tx">
                                    {po?.to_location}
                                  </span>{" "}
                                  <span className="_scdAddress_tx">
                                  {`${po?.consignee_detials?.code} , ${po?.consignee_detials?.name}`}
                                  </span>
                                </div>
                              </li>
                            </ul>

                            <button
                              className="__delM_btn"
                              onClick={() => handleDeletePo(index, poIndex)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                  fill="#C41F1F"
                                />
                              </svg>
                            </button>
                          </div>
                        );
                      })}

                      <div className="_addDel_bx bg-white pt-2">
                        <button
                          className="_pickN_dropBtn"
                          onClick={() => handleAddPo(index)}
                          disabled={!toLoaction[index].to_city}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                              fill="#1956F6"
                            ></path>
                          </svg>{" "}
                          <span>Add PO & Location</span>
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <div className="pr_procssBx">
          <div className="_lrdMax">
            <div className="flxInr">
              <div className="__flxseBx">
                <h6>Freight Type</h6>

                <div className="_flxOtr">
                  {freightTypeList?.map((freight) => (
                    <div className="_dualCheckr_bx">
                      <input
                        className="form-check-input"
                        id={freight?.value}
                        type="radio"
                        name="freight_type"
                        value={freight?.value}
                        checked={form?.freight_type == freight?.value}
                        onChange={({ target: { name, value } }) =>
                          handleOnChange(name, value)
                        }
                      />
                      <label
                        htmlFor={freight?.value}
                        className="form-check-label"
                      >
                        {freight?.label}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="_cityInput_bx">
                  {form?.freight_type == 4 ? (
                    <CommonInput
                      type="text"
                      label="Freight Amount"
                      placeholder="Freight Amount"
                      name="freight_amount"
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      onKeyDown={handleKeyDown}
                      value={form?.freight_amount}
                      error={formError.freight_amount}
                    />
                  ) : (
                    <CommonInput
                      type="text"
                      label="Freight Rate"
                      placeholder="Freight Rate"
                      name="freight_rate"
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      value={form?.freight_rate}
                      onKeyDown={handleKeyDown}
                      error={formError.freight_rate}
                    />
                  )}
                </div>
              </div>

              <div className="__flxseBx">
                <h6>Payment Terms</h6>

                <div className="_flxOtr">
                  {paymentTermsList?.map((payment) => {
                    return (
                      <div className="_dualCheckr_bx">
                        <input
                          className="form-check-input"
                          id={payment?.value}
                          type="radio"
                          name="payment_terms"
                          value={payment?.value}
                          checked={form?.payment_terms == payment?.value}
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                        />
                        <label
                          htmlFor={payment?.value}
                          className="form-check-label"
                        >
                          {payment?.label}
                        </label>
                      </div>
                    );
                  })}

                  {form?.payment_terms == 1 && (
                    <div className="__numPercen_btBx">
                      {paymentPer?.map((i) => (
                        <button
                          value={i?.value}
                          name="payment_percentage"
                          onClick={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                        >
                          {i?.label}
                        </button>
                      ))}
                      <>
                        <input
                          type="text"
                          name="payment_percentage"
                          className={
                            formError?.payment_percentage ? "anvInputError" : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          value={form?.payment_percentage}
                          error={formError?.payment_percentage}
                        />
                        <div>%</div>
                        {formError?.payment_percentage && (
                          <span className={"input_drop_error"}>
                            {formError?.payment_percentage}
                          </span>
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>

              <div className="_cityInput_bx">
                <CustomSelect
                  label="Branch"
                  options={branchList}
                  placeholder="Select Branch"
                  onSelect={(data) => {
                    handleOnChange("branch_id", data?.value);
                  }}
                  value={branchList?.find((i) => i?.value == form?.branch_id)}
                  error={formError?.branch_id}
                />
              </div>

              <div className="_cityInput_bx">
                <CustomSelect
                  label="Assign to"
                  options={citie}
                  placeholder="Select"
                  error={formError?.assign_to}
                />
              </div>

              <div className=" _cityInput_bx">
                <div className="_flxOtr d-flex justify-content-between align-items-center">
                  <h6 style={{ margin: "0" }}>Market Visiblity / Allow Bids</h6>

                  <div
                    className="__numPercen_btBx"
                    style={{ flex: "0 0 111px", margin: "0" }}
                  >
                    <button
                      className={form?.is_allow_visibility == 1 ? "active" : ""}
                      name="is_allow_visibility"
                      value={1}
                      onClick={({ target: { name, value } }) =>
                        handleOnChange(name, Number(value))
                      }
                    >
                      Yes
                    </button>
                    <button
                      className={form?.is_allow_visibility == 0 ? "active" : ""}
                      name="is_allow_visibility"
                      value={0}
                      onClick={({ target: { name, value } }) =>
                        handleOnChange(name, Number(value))
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
                {form?.is_allow_visibility == 1 && (
                  <CustomSelect
                    label="Set Market Visiblity"
                    options={marketVisiList}
                    placeholder="Select Connection"
                    onSelect={(data) => {
                      handleOnChange("connection_id", data?.value);
                    }}
                    error={formError?.connection_id}
                    value={marketVisiList?.find(
                      (i) => i?.value == form?.connection_id
                    )}
                  />
                )}
              </div>

              <div className=" _cityInput_bx">
                <div className="_flxOtr d-flex justify-content-between align-items-center">
                  <h6 style={{ margin: "0" }}>Allow Calls</h6>

                  <div
                    className="__numPercen_btBx"
                    style={{ flex: "0 0 111px", margin: "0" }}
                  >
                    <button
                      className={form?.is_allow_calls == 1 ? "active" : ""}
                      name="is_allow_calls"
                      value={1}
                      onClick={({ target: { name, value } }) =>
                        handleOnChange(name, Number(value))
                      }
                    >
                      Yes
                    </button>
                    <button
                      className={form?.is_allow_calls == 0 ? "active" : ""}
                      name="is_allow_calls"
                      value={0}
                      onClick={({ target: { name, value } }) =>
                        handleOnChange(name, Number(value))
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
                <CustomSelect
                  label="Response"
                  options={responseList}
                  placeholder="Select Response"
                  isDropdownTop={true}
                  onSelect={(data) => {
                    handleOnChange("responses", data?.value);
                  }}
                  value={responseList?.find((i) => i?.value == form?.responses)}
                  error={formError?.responses}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="offcanvas-footer">
        <button className="_addComm_btn _sameWid" disabled={loading} onClick={handleSubimitPost}>
          {" "}
          {!loading?"Post Indent":"Creating..."}{" "}
        </button>
      </div>

      {showAddPo && <AddPoModal {...addPoModal} />}
    </>
  );
};

export default AddIndentModal;
