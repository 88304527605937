import axios from "axios";
import AppConfig from "./app-config";

const axiosInstance = axios.create({
  baseURL: AppConfig.api_baseurl,
  timeout: 10000, // Set a timeout limit for requests
  headers: AppConfig.headers,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Add any custom logic before the request is sent
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Token"] = `${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Any custom logic for handling successful responses
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Example: Redirect to login if unauthorized
      localStorage.clear();
      window.location.href = "/";
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
