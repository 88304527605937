const AUTH_KEY = "token";
const LANG_KEY = "lang";
const PHONE_KEY = "phone";
const COMP_KEY = "companyId";
const BRANCH_KEY = "branchId";
const COMPNAME_KEY = "company";
const USER_ID = "userId";
const USER_NAME = "userName";
const CURRENT_MENU_ID_KEY = "current_menu_id";
const CITIES = "cities";
const STATE = "states"
class StorageService {
    static clearStorage(key = "") {
        if (key) {
            localStorage.removeItem(key);
        } else {
            localStorage.clear();
        }
    }

    static setItem(key, value) {
        try {
            localStorage.setItem(key, value);
        } catch (error) {
            console.error(`Error setting item in localStorage: ${error}`);
        }
    }

    static getItem(key) {
        try {
            return localStorage.getItem(key);
        } catch (error) {
            console.error(`Error getting item from localStorage: ${error}`);
            return null;
        }
    }

    static setBranchId(id) {
        this.setItem(BRANCH_KEY, id);
    }

    static getBranchId() {
        return this.getItem(BRANCH_KEY);
    }

    static setCompName(name) {
        this.setItem(COMPNAME_KEY, name);
    }

    static getCompName() {
        return this.getItem(COMPNAME_KEY);
    }

    static setToken(token) {
        this.setItem(AUTH_KEY, token);
    }

    static getToken() {
        return this.getItem(AUTH_KEY);
    }

    static setCurrentMenu(menuId) {
        this.setItem(CURRENT_MENU_ID_KEY, menuId);
    }

    static getCurrentMenu() {
        return this.getItem(CURRENT_MENU_ID_KEY);
    }

    static setLang(lang) {
        this.setItem(LANG_KEY, lang);
    }

    static getLang() {
        return this.getItem(LANG_KEY);
    }

    static setMobile(phone) {
        this.setItem(PHONE_KEY, phone);
    }

    static getMobile() {
        return this.getItem(PHONE_KEY);
    }

    static setCompanyId(companyId) {
        this.setItem(COMP_KEY, companyId);
    }

    static getCompanyId() {
        return this.getItem(COMP_KEY);
    }

    static setUseryID(userId) {
        this.setItem(USER_ID, userId);
    }

    static getUseryID() {
        return this.getItem(USER_ID);
    }
    static setUserExist(userName) {
        this.setItem(USER_NAME, userName);
    }

    static getUserExist() {
        return this.getItem(USER_NAME);
    }

    static setCities(userName) {
        this.setItem(CITIES, userName);
    }

    static getCities() {
        return this.getItem(CITIES);
    }
    static setStates(userName) {
        this.setItem(STATE, userName);
    }

    static getStates() {
        return this.getItem(STATE);
    }

    static getLocalItem(key) {
        return this.getItem(key);
    }

    static setLocalItem(key, value) {
        return this.setItem(key, value);
    }
}

export default StorageService;

// Example usage:
// StorageService.setToken('your-token');
// const token = StorageService.getToken();
// StorageService.clearStorage();
