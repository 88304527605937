import React, { useContext, useEffect, useState } from 'react'
import { errorSchema, utils } from 'helper';
import { CommonInput, CommonModal, CommonTextarea, CustomSelect } from 'components'
import StorageService from 'utils/StorageServices/StorageServices';
import toast, { Toaster } from 'react-hot-toast';
import { createMaterialCategory } from '_services/materialServices/materialCategoryServices';
import { podReceivedData, updateLrProcess, uploadFiles } from '_services';
import { RiAddLargeFill } from "react-icons/ri";
import { createZone } from '_services/accountsServices/zoneServices';
import { createConsignorGroup } from '_services/accountsServices/consignorGroupServices';

const formObj = {
    company_id: StorageService.getCompanyId(),
    name: '',
}

const AddEditConsignorGroupModal = (props) => {

    const { isOpen, closeModal, nextModal } = props;
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addEditZoneSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async () => {
        try {
            const res = await createConsignorGroup(formValue);
            if (!!res) {
                setFormValue({});
                setLoading(false);
                toast.success('Consignor Group Created Successfully');
                closeModal(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleClick = async (value) => {
        const validationResult = await utils?.checkFormError(formValue, errorSchema.addEditZoneSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            apiCallingFunction();
            setFormError("");
        } else {
            setFormError(validationResult);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setFormValue((s) => ({
                ...s,
                company_id: StorageService.getCompanyId(),
            }))
        }
    }, [isOpen])

    let inhanceProps = {
        ...props,
        submitTitle: loading ? 'Loading...' : props.submitTitle,
        disabled: loading,
        onSubmit: handleClick,
    }

    return (
        <div>
            <CommonModal {...inhanceProps}>
                <div className='addItem_body mt-2'>
                    <div className='colFlx'>
                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                label='Consignor Group Name * '
                                placeholder='Consignor Group Name * '
                                name='name'
                                value={formValue.name}
                                focus={!!(typeof formError === "object" && formError?.name)}
                                error={!!(typeof formError === "object") ? formError?.name : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </CommonModal>
            {/* <div className='offBack_drop'></div> */}

        </div>
    )
}

export default AddEditConsignorGroupModal
