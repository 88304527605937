import React, { useState, useRef } from 'react'
import { ButtonHeading, ConsigneeDetailsTab } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import LoadingBar from 'react-top-loading-bar';

const ConsigneeDetails = () => {

    const loadingBarRef = useRef(null);
    const [activeTab, setActiveTab] = useState(1);
    const [singleConsignee, setSingleConsignee] = useState(null)

    const tabProps = {
        tabList: constant.consigneeDetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    }

    const handleConsigneeData = (data) => {
        setSingleConsignee(data)
    }

    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <ButtonHeading title={singleConsignee?.name} buttonTitle="Edit" />

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && (
                    <ConsigneeDetailsTab
                        onDataFetch={handleConsigneeData}
                        loadingBarRef={loadingBarRef}
                    />
                )}
            </div>
        </div>
    )
}

export default ConsigneeDetails;