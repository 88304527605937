import { Heading } from "components";
import { Images } from "constants";
import { AddAccount, CreateCompany } from "modals";
import React, { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import Tab from 'components/common/Tab';
import { constant } from 'constants/constant';
import { IoCallOutline, IoCallSharp, IoSearchOutline } from "react-icons/io5";
import { CiStopwatch } from "react-icons/ci";
import { FaRegEdit, FaShareAlt } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { FaRoute } from "react-icons/fa6";
import { GiChatBubble } from "react-icons/gi";
import MainDashboard from "./MainDashboard";
import VehicleDashboard from "./VehicleDashboard";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(1);
  const loadingBarRef = React.useRef(null)
  const simulateLoading = () => {
    loadingBarRef.current.continuousStart();
    setTimeout(() => {
      loadingBarRef.current.complete();
    }, 1000); // Adjust this timeout as needed to simulate loading time
  };

  // Simulate loading when the component mounts
  React.useEffect(() => {
    simulateLoading();
  }, []);

  const tabProps = {
    tabList: constant.dashboardTabs,
    callBackTab: (e) => setActiveTab(e),
    activeTab: activeTab
  };

  return (
    <>
      {/* <Heading title={"Dashboard"} /> */}
      {/* <div>Dashboard</div> */}
      {/* <AddAccount /> */}
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />

      <div className='addPr_flBx'>
        <Tab {...tabProps} />
        {activeTab === 1 && (
          <MainDashboard/>
        )}

        {activeTab === 2 && (
          <VehicleDashboard/>
        )}
      </div >


    </>
  );
};

export default Dashboard;
