import {
  consignorGroup,
  getAddUserBranch,
  getAllAccount,
  getDashbordData,
  getRole,
  getUserAccount,
  handleKeyDown,
  vehicleGroup,
} from "_services";
import {
  ButtonHeading,
  CommonInput,
  CustomSelect,
  Customdropdown,
} from "components";
import { addUser, editUser, getUserDetail } from "features";
import { errorSchema, utils } from "helper";
import Loading from "pages/Loading";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";

const AddUser = () => {
  const initial = {
    company_user_name: "",
    mobileno: "",
    email_id: "",
    role_id: "",
    branch_list: [],
    language: "eg",
    company_id: StorageService.getCompanyId(),
    consignor_group_id: "",
    vehicle_group_id: "",
    web_or_mobile: 0,
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState(initial);
  const [formError, setFormError] = useState("");
  const [consignorGroupList, setConsignorGroupList] = useState([]);
  const [vehicleGroupList, setVehicleGroupList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [allAccount, setAllAccount] = useState([]);
  const [userAccount, setUserAccount] = useState([]);
  const [modifyAccount, setModifyAccount] = useState([]);
  const [allCheckbox, setAllCheckbox] = useState(false);
  const [filterAccountList, setFilterAccountList] = useState([]);
  const [userDetais, setUserDetais] = useState([]);
  const [addTransaction, setAddTransaction] = useState({
    approval_required: 0,
    submit_access: 0,
  });

  const [partyFreight, setPartyFreight] = useState({
    can_view_freight: 0,
    can_edit_freight: 0,
    add_total_freight: 0,
    add_advance_freight: 0,
    add_balance: 0,
    add_claim_receivable: 0,
    add_claim_payable: 0,
  });

  const [supplierFreight, setSupplierFreight] = useState({
    add_total_freight: 0,
    add_advance_freight: 0,
    add_balance: 0,
    add_claim_receivable: 0,
    add_claim_payable: 0,
  });

  const [other, setOther] = useState({
    add_other_expenses: 0,
    add_other_receipt: 0,
  });

  const [paymentAprrove, setPaymentAprrove] = useState({
    pending: 0,
    approve: 0,
    hold: 0,
    complete: 0,
    cancel: 0,
  });

  const [contractSetting, setContractSetting] = useState({
    show_amount: 0,
    edit_amount: 0, 
  });

  const [consignorView, setConsignorView] = useState({
    restricted_view: 0,
  });

  const [suplierView, setSuplierView] = useState({
    restricted_view: 0,
  });

  const [selectAll, setSelectAll] = useState({
    add_transaction: 0,
    party_freight: 0,
    supplier_freight: 0,
    other_freight: 0,
    payment_approval: 0,
  });



  useEffect(() => {
    getConsignorGroupList();
    getVehicleGroupList()
    getRoleList();
    getAccountHandle();
    if (!id) {
      getUserAccountHandle();
      getBranchList();
    } else {
      getUserDetaisHandle(id);
    }
  }, [id]);

  useEffect(() => {
    let array = [];
    // let newArray = userAccount?.sub_category?.map((item, index) =>
    //   item?.accounts.map((item2, index) => {
    //     array.push({
    //       ...item2,
    //       category_id: userAccount?.id,
    //       category_name: userAccount?.name,
    //       sub_category_name: item?.name,
    //       sub_category_id: item?.id,
    //       account_id: item2?.id,
    //       account_name: item2?.name,
    //       account_code: item2?.code,
    //       isSelected: 0,
    //       selection: 0,
    //       view_balance: 0,
    //       edit_account: 0,
    //       delete_account: 0,
    //       // view_transaction: 0,
    //       // edit_transaction: 0,
    //       // delete_transaction: 0,
    //     });
    //   })
    // );

    let newArray = userAccount?.map((item, index) => {
      array.push({
        ...item,
        category_id: item?.category_id,
        category_name: item?.sub_category_name,
        sub_category_name: item?.sub_category_name,
        sub_category_id: item?.sub_category_id,
        account_id: item?.account_id,
        account_name: item?.account_name,
        account_code: item?.code,
        isSelected:
          item?.selection &&
          item?.view_balance &&
          item?.edit_account &&
          item?.delete_account
            ? 1
            : 0,
        selection: item?.selection,
        view_balance: item?.view_balance,
        edit_account: item?.edit_account,
        delete_account: item?.delete_account,
      });
    });

    setModifyAccount(array);
  }, [userAccount]);

  const memoizedSetSelectAll = useCallback(setSelectAll, []);

  useSelectAll(addTransaction, "add_transaction", memoizedSetSelectAll);
  useSelectAll(partyFreight, "party_freight", memoizedSetSelectAll);
  useSelectAll(supplierFreight, "supplier_freight", memoizedSetSelectAll);
  useSelectAll(other, "other_freight", memoizedSetSelectAll);
  useSelectAll(paymentAprrove, "payment_approval", memoizedSetSelectAll);

  const getConsignorGroupList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: "",
        status: 1,
      };
      const res = await consignorGroup(object);
      if (res?.responseCode == 200) {
        const resList = res?.data.map((i) => {
          return {
            label: i?.name,
            value: i?._id,
          };
        });

        setConsignorGroupList(resList);
        setFormValue({ ...formValue, consignor_group_id: resList[0].value });
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      throw error;
    }
  };
  const getVehicleGroupList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: "",
        status: 1,
      };
      const res = await vehicleGroup(object);
      if (res?.responseCode == 200) {
        const resList = res?.data.vehicle_group_list?.map((i) => {
          return {
            label: i?.name,
            value: i?._id,
          };
        });

        setVehicleGroupList(resList);
        setFormValue({ ...formValue, vehicle_group_id: resList[0]?.value });
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      throw error;
    }
  };
  const getBranchList = async () => {
    // const res = await getDashbordData({
    //   company_id: StorageService.getCompanyId(),
    //   select_company_id: 1,
    // });
    const res = await getAddUserBranch({
      company_id: StorageService.getCompanyId(),
    });

    if (res?.responseCode === 200) {
      setBranchList(res?.data?.branch_list);
    } else {
      toast.error(res?.responseMessage);
    }
  };

  const getRoleList = async () => {
    const res = await getRole({
      company_id: StorageService.getCompanyId(),
    });

    if (res?.responseCode === 200) {
      setRoleList(
        res?.data?.roleDetail?.map((i) => {
          return {
            label: i?.role_name,
            value: i?._id,
          };
        })
      );
    } else {
      toast.error(res?.responseMessage);
    }
  };

  const getAccountHandle = async () => {
    try {
      const resp = await getAllAccount({
        company_id: StorageService.getCompanyId(),
      });
      setAllAccount(resp);
    } catch (error) {
      throw error;
    }
  };

  const getUserAccountHandle = async () => {
    try {
      const resp = await getUserAccount({
        company_id: StorageService.getCompanyId(),
        category_id: 1,
      });

      if (resp?.responseCode === 200) {
        setUserAccount(resp?.data?.account_list);
      }
    } catch (error) {
      throw error;
    }
  };

  const getUserDetaisHandle = async (id) => {
    try {
      const resp = await getUserDetail({
        company_id: StorageService.getCompanyId(),
        param_user_id: id,
      });

      if (resp?.responseCode === 200) {
        setFormValue({
          ...formValue,
          company_user_name: resp?.data?.user?.company_user_name,
          mobileno: resp?.data?.user?.user_id?.mobile_no,
          email_id: resp?.data?.user?.email_id,
          role_id: resp?.data?.user?.role_id?._id,
          web_or_mobile:resp?.data?.user?.web_or_mobile,
          consignor_group_id: resp?.data?.user?.consignor_group_id?._id,
          vehicle_group_id: resp?.data?.user?.vehicle_group_id?._id,
        });
        setUserDetais(resp?.data?.user);
        setBranchList(resp?.data?.user?.branch_list);
        setUserAccount(resp?.data?.user?.account_list);
        setAddTransaction(resp?.data?.user?.adding_transaction);
        setPartyFreight(resp?.data?.user?.party_freight);
        setSupplierFreight(resp?.data?.user?.supplier_freight);
        setOther(resp?.data?.user?.other);
        setPaymentAprrove(resp?.data?.user?.payment_approval);
        setConsignorView(resp?.data?.user?.consignor_view);
        setSuplierView(resp?.data?.user?.supplier_view);
        setContractSetting(resp?.data?.user?.contract)
      
      }
    } catch (error) {
      throw error;
    }
  };
  

  const onHandleAll = async (name, checked, label) => {
    if (label === "addTransaction") {
      const updatedFreight = Object.keys(addTransaction).reduce((acc, key) => {
        acc[key] = checked ? 1 : 0;
        return acc;
      }, {});
      setAddTransaction(updatedFreight);
      setSelectAll({ ...selectAll, add_transaction: checked ? 1 : 0 });
    }
    if (label === "party") {
      const updatedFreight = Object.keys(partyFreight).reduce((acc, key) => {
        acc[key] = checked ? 1 : 0;
        return acc;
      }, {});
      setPartyFreight(updatedFreight);
      setSelectAll({ ...selectAll, party_freight: checked ? 1 : 0 });
    }
    if (label === "supplier") {
      const updatedFreight = Object.keys(supplierFreight).reduce((acc, key) => {
        acc[key] = checked ? 1 : 0;
        return acc;
      }, {});
      setSupplierFreight(updatedFreight);
      setSelectAll({ ...selectAll, supplier_freight: checked ? 1 : 0 });
    }
    if (label === "paymentAprrove") {
      const updatedFreight = Object.keys(paymentAprrove).reduce((acc, key) => {
        acc[key] = checked ? 1 : 0;
        return acc;
      }, {});
      setPaymentAprrove(updatedFreight);
      setSelectAll({ ...selectAll, payment_approval: checked ? 1 : 0 });
    }
    if (label === "other") {
      const updatedFreight = Object.keys(other).reduce((acc, key) => {
        acc[key] = checked ? 1 : 0;
        return acc;
      }, {});
      setOther(updatedFreight);
      setSelectAll({ ...selectAll, other_freight: checked ? 1 : 0 });
    }
  };

  const onHandleChange = async (name, checked, label) => {
    if (label === "addTransaction") {
      if (checked) {
        setAddTransaction({ ...addTransaction, [name]: 1 });
      } else {
        setAddTransaction({ ...addTransaction, [name]: 0 });
      }
    }

    if (label === "party") {
      if (checked) {
        setPartyFreight({ ...partyFreight, [name]: 1 });
      } else {
        setPartyFreight({ ...partyFreight, [name]: 0 });
      }
    }

    if (label === "supplier") {
      if (checked) {
        setSupplierFreight({ ...supplierFreight, [name]: 1 });
      } else {
        setSupplierFreight({ ...supplierFreight, [name]: 0 });
      }
    }

    if (label === "paymentAprrove") {
      if (checked) {
        setPaymentAprrove({ ...paymentAprrove, [name]: 1 });
      } else {
        setPaymentAprrove({ ...paymentAprrove, [name]: 0 });
      }
    }
    if (label === "other") {
      if (checked) {
        setOther({ ...other, [name]: 1 });
      } else {
        setOther({ ...other, [name]: 0 });
      }
    }
    if (label === "consignorView") {
      if (checked) {
        setConsignorView({ ...consignorView, [name]: 1 });
      } else {
        setConsignorView({ ...consignorView, [name]: 0 });
      }
    }
    if (label === "supplierView") {
      if (checked) {
        setSuplierView({ ...suplierView, [name]: 1 });
      } else {
        setSuplierView({ ...suplierView, [name]: 0 });
      }
    }

    if (label === "contractSetting") {
      if (checked) {
        setContractSetting({ ...contractSetting, [name]: 1 });
      } else {
        if (name === "show_amount") {
          setContractSetting({ ...contractSetting, edit_amount: 0 ,show_amount:0});
        }else{
          setContractSetting({ ...contractSetting, [name]: 0 });
        }
        
      }
    }
  };

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...formValue,
      [name]:
        name === "role_id" ||
        name === "consignor_group_id" ||
        name === "vehicle_group_id"
          ? value.value
          : value,
    };

    setFormValue(stateObj);

    if (!!formError) {
      const validationResult = await utils.checkFormError(
        formValue,
        errorSchema.addUserSchema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleCheckboxChange = (id) => {
    setBranchList(
      branchList.map((branch) =>
        branch._id === id
          ? { ...branch, selection: branch.selection ? 0 : 1 }
          : branch
      )
    );
  };

  
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setBranchList(
      branchList.map((branch) => ({ ...branch, selection: isChecked ? 1 : 0 }))
    );
  };

  const selectedBranchIds = branchList
    .filter((branch) => branch.selection === 1)
    .map((branch) => branch._id);

  const hasFullAccess = (account) => {
    const permissions = [
      "selection",
      "isSelected",
      "view_balance",
      "edit_account",
      "delete_account",
    ];

    return permissions.every((permission) => account[permission] === 1);
  };

  useEffect(() => {
    const allAccountsHaveFullAccess = modifyAccount.every(hasFullAccess);

    if (allAccountsHaveFullAccess) {
      setAllCheckbox(true);
    } else {
      setAllCheckbox(false);
    }

    const filteredAccount = modifyAccount
      .filter((item) => item.view_balance === 1)
      .map((item) => ({
        category_id: item?.category_id,
        sub_category_id: item?.sub_category_id,
        account_id: item?.account_id,
        selection: item?.selection,
        view_balance: item?.view_balance,
        edit_account: item?.edit_account,
        delete_account: item?.delete_account,
      }));

    setFilterAccountList(filteredAccount);
  }, [modifyAccount]);

  const onSubmit = async (e, action) => {
    e.preventDefault();
    const validationResult = await utils.checkFormError(
      { ...formValue },
      errorSchema.addUserSchema
    );

    const payloadData = {
      company_user_name: formValue?.company_user_name,
      mobileno: formValue?.mobileno,
      email_id: formValue?.email_id,
      role_id: formValue?.role_id,
      branch_list: selectedBranchIds,
      language: formValue?.language,
      company_id: formValue?.company_id,
      consignor_group_id: formValue?.consignor_group_id,
      vehicle_group_id: formValue?.vehicle_group_id,
      web_or_mobile: formValue?.web_or_mobile,
      account_list: filterAccountList ? filterAccountList : [],
      adding_transaction: addTransaction,
      party_freight: partyFreight,
      supplier_freight: supplierFreight,
      other: other,
      payment_approval: paymentAprrove,
      consignor_view: consignorView,
      supplier_view: suplierView,
      contract:contractSetting
    };

    if (validationResult === true) {
      try {
        setDisabled(true);
        const res = await dispatch(
          !id
            ? addUser(payloadData)
            : editUser({ ...payloadData, param_user_id: id })
        );
        if (res?.payload?.responseCode === 200) {
          toast.success(res?.payload?.responseMessage);
          if (action === "add") {
            navigate("/setting/user");
            setDisabled(false);
            clearAll();
          } else {
            clearAll();
            setDisabled(false);
          }
        } else {
          toast.error(res?.payload?.responseMessage);
          setDisabled(false);
        }
      } catch (error) {
        setDisabled(false);
        throw error;
      }
    } else {
      setFormError(validationResult);
    }
  };

  const clearAll = () => {
    setFormValue(initial);
    modifyAccount?.map((i, index) => {
      let newData = [...modifyAccount];
      
        setAllCheckbox(false);
        newData[index].isSelected = 0;
        newData[index].selection = 0;
        newData[index].view_balance = 0;
        newData[index].edit_account = 0;
        newData[index].delete_account = 0;
      

      setModifyAccount(newData);
    });
    setBranchList(
      branchList.map((branch) => ({ ...branch, selection:0 }))
    );
    setPartyFreight({
      can_view_freight: 0,
      can_edit_freight: 0,
      add_total_freight: 0,
      add_advance_freight: 0,
      add_balance: 0,
      add_claim_receivable: 0,
      add_claim_payable: 0,
    });
    setAddTransaction({
      approval_required: 0,
      submit_access: 0,
    });
    setPartyFreight({
      can_view_freight: 0,
      can_edit_freight: 0,
      add_total_freight: 0,
      add_advance_freight: 0,
      add_balance: 0,
      add_claim_receivable: 0,
      add_claim_payable: 0,
    });

    setSupplierFreight({
      add_total_freight: 0,
      add_advance_freight: 0,
      add_balance: 0,
      add_claim_receivable: 0,
      add_claim_payable: 0,
    });

    setOther({
      add_other_expenses: 0,
      add_other_receipt: 0,
    });

    setPaymentAprrove({
      pending: 0,
      approve: 0,
      hold: 0,
      complete: 0,
      cancel: 0,
    });

    setConsignorView({
      restricted_view: 0,
    });
    setSuplierView({
      restricted_view: 0,
    });

    setContractSetting({
      show_amount:0,
      edit_amount:0
    })

    setSelectAll({
      add_transaction: 0,
      party_freight: 0,
      supplier_freight: 0,
      other_freight: 0,
      payment_approval: 0,
    });
  };

  const handleChange = (e, propsindex) => {
    const { id, checked } = e.target;
    setAllCheckbox(false);
    modifyAccount?.map((i, index) => {
      let newData = [...modifyAccount];

      if (
        id == `isSelected_${i?.sub_category_id}_${i?.account_id}` &&
        index === propsindex
      ) {
        if (newData[index].isSelected) {
          newData[index].isSelected = 0;
          newData[index].selection = 0;
          newData[index].view_balance = 0;
          newData[index].edit_account = 0;
          newData[index].delete_account = 0;
        } else {
          newData[index].isSelected = 1;
          newData[index].selection = 1;
          newData[index].view_balance = 1;
          newData[index].edit_account = 1;
          newData[index].delete_account = 1;
        }
      }

      if (
        id == `selection_${i?.sub_category_id}_${i?.account_id}` &&
        index === propsindex
      ) {
        if (newData[index].selection) {
          newData[index].selection = 0;
        } else {
          newData[index].selection = 1;
        }
        if (
          newData[index].selection ||
          newData[index].edit_account ||
          newData[index].delete_account
        ) {
          newData[index].view_balance = 1;
        } else {
          newData[index].view_balance = 0;
        }
      }

      if (
        id == `view_balance_${i?.sub_category_id}_${i?.account_id}` &&
        index === propsindex
      ) {
        if (newData[index].view_balance) {
          newData[index].view_balance = 0;
        } else {
          newData[index].view_balance = 1;
        }
      }

      if (
        id == `edit_account_${i?.sub_category_id}_${i?.account_id}` &&
        index === propsindex
      ) {
        if (newData[index].edit_account) {
          newData[index].edit_account = 0;
        } else {
          newData[index].edit_account = 1;
        }

        if (
          newData[index].selection ||
          newData[index].edit_account ||
          newData[index].delete_account
        ) {
          newData[index].view_balance = 1;
        } else {
          newData[index].view_balance = 0;
        }
      }

      if (
        id == `delete_account_${i?.sub_category_id}_${i?.account_id}` &&
        index === propsindex
      ) {
        if (newData[index].delete_account) {
          newData[index].delete_account = 0;
        } else {
          newData[index].delete_account = 1;
        }
        if (
          newData[index].selection ||
          newData[index].edit_account ||
          newData[index].delete_account
        ) {
          newData[index].view_balance = 1;
        } else {
          newData[index].view_balance = 0;
        }
      }

      if (
        newData[index].selection &&
        newData[index].edit_account &&
        newData[index].delete_account
      ) {
        newData[index].isSelected = 1;
      } else {
        newData[index].isSelected = 0;
      }

      setModifyAccount(newData);
    });
  };

  const handleAllCheacks = (e) => {
    const { id, checked, value } = e.target;

    modifyAccount?.map((i, index) => {
      let newData = [...modifyAccount];
      if (checked) {
        setAllCheckbox(true);
        newData[index].isSelected = 1;
        newData[index].selection = 1;
        newData[index].view_balance = 1;
        newData[index].edit_account = 1;
        newData[index].delete_account = 1;
      } else {
        setAllCheckbox(false);
        newData[index].isSelected = 0;
        newData[index].selection = 0;
        newData[index].view_balance = 0;
        newData[index].edit_account = 0;
        newData[index].delete_account = 0;
      }

      setModifyAccount(newData);
    });
  };

  return (
    <div>
      <ButtonHeading title={"Add User"} buttonTitle={"Add User"} />

      {branchList?.length > 0 ? (
        <div>
          <div className="_addContainer">
            <div className="_allUrs_bx">
              <div className="_addRl_bx">
                <div className="_addRlHead">User Details</div>

                <div className="_addRl_tableBx">
                  <div className="_usrDetails">
                    <div className="pr_procssBx_grid">
                      <div className="_tierDivs">
                        <CommonInput
                          type="text"
                          placeholder="User Name*"
                          label="User Name*"
                          name="company_user_name"
                          value={
                            formValue?.company_user_name
                              ? formValue?.company_user_name
                              : ""
                          }
                          focus={!!formError?.company_user_name}
                          error={formError?.company_user_name}
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                        />
                      </div>

                      <div className="_tierDivs">
                        <CommonInput
                          type="text"
                          placeholder="Mobile Number*"
                          label="Mobile Number*"
                          name="mobileno"
                          onKeyDown={handleKeyDown}
                          maxLength={10}
                          disabled={id}
                          value={formValue?.mobileno ? formValue?.mobileno : ""}
                          focus={!!formError?.mobileno}
                          error={formError.mobileno}
                          onChange={({ target: { name, value } }) => {
                            if (/^\d*$/.test(value)) {
                              handleOnChange(name, value);
                              // setPhoneError("");
                            }
                          }}
                        />
                      </div>

                      <div className="_tierDivs">
                        <CommonInput
                          type="text"
                          placeholder="Emai ID"
                          label="Emai ID"
                          name="email_id"
                          value={formValue?.email_id ? formValue?.email_id : ""}
                          focus={!!formError?.email_id}
                          error={formError.email_id}
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                        />
                      </div>

                      <div className="_tierDivs">
                        {/* <Customdropdown /> */}
                        <CustomSelect
                          options={roleList}
                          label="Role"
                          name="role_id"
                          id="role_id"
                          value={formValue.role_id?roleList?.find(
                            (i) => i?.value === formValue.role_id
                          ):{label:'',value:""}}
                          onSelect={(data) => handleOnChange("role_id", data)}
                          placeholder="Select a Role"
                          error={formError.role_id}
                        />
                      </div>

                      <div className="_tierDivs">
                        {/* <Customdropdown /> */}
                        <CustomSelect
                          options={consignorGroupList}
                          // value={consignorGroupList[0]}
                          value={formValue.consignor_group_id?consignorGroupList?.find(
                            (i) => i?.value === formValue.consignor_group_id
                          ):{label:'',value:""}}
                          label="Consignor Group"
                          name="consignor_group_id"
                          onSelect={(data) =>
                            handleOnChange("consignor_group_id", data)
                          }
                          id="consignor_group_id"
                          placeholder="Select Consignor Group"
                          error={formError.consignor_group_id}
                        />
                      </div>

                      <div className="_tierDivs">
                        {/* <Customdropdown /> */}
                        <CustomSelect
                          options={vehicleGroupList}
                          label="Vehicle Group"
                          name="vehicle_group_id"
                          // value={vehicleGroupList[0]}
                          value={formValue.vehicle_group_id?vehicleGroupList?.find(
                            (i) => i?.value === formValue.vehicle_group_id
                          ):{label:'',value:""}}
                          onSelect={(data) =>
                            handleOnChange("vehicle_group_id", data)
                          }
                          id="vehicle_group_id"
                          placeholder="Select Vehicle Group"
                          error={formError.vehicle_group_id}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="checkBx_lv ">
                    <input
                      className="form-check-input _avPointer"
                      id="0pay_type1"
                      type="radio"
                      name="web_or_mobile"
                      value={0}
                      checked={formValue?.web_or_mobile == 0}
                      autoComplete="off"
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                    />
                    <label
                      htmlFor="0pay_type1"
                      className="form-check-label _avPointer"
                    >
                      All
                    </label>{" "}
                    &nbsp;
                    <input
                      className="form-check-input _avPointer"
                      id="1pay_type2"
                      type="radio"
                      name="web_or_mobile"
                      value={1}
                      checked={formValue?.web_or_mobile == 1}
                      autoComplete="off"
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                    />
                    <label
                      htmlFor="1pay_type2"
                      className="form-check-label _avPointer"
                    >
                      Web Access
                    </label>{" "}
                    &nbsp;
                    <input
                      className="form-check-input _avPointer"
                      id="1pay_type3"
                      type="radio"
                      name="web_or_mobile"
                      value={2}
                      checked={formValue?.web_or_mobile == 2}
                      autoComplete="off"
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                    />
                    <label
                      htmlFor="1pay_type3"
                      className="form-check-label _avPointer"
                    >
                      Mobile Access
                    </label>{" "}
                    &nbsp;
                  </div>
                </div>
              </div>

              <div className="_mainRl_table">
                <div className="_addRl_headCheck">
                  <Checkbox
                    type="checkbox"
                    name="Branch Access"
                    id="selectAll"
                    handleClick={handleSelectAll}
                    isChecked={branchList.every(
                      (branch) => branch.selection === 1
                    )}
                  />
                </div>

                <div className="_addRl_checkList">
                  <div className="_brxFlex">
                    {branchList &&
                      branchList.map((i, index) => {
                        return (
                          <div className="_dLass" key={`${i?._id}_${index}`}>
                            <Checkbox
                              className="checkbox_2"
                              index={index}
                              type="checkbox"
                              name={i?.branch}
                              id={i._id}
                              handleClick={() => handleCheckboxChange(i._id)}
                              isChecked={i?.selection === 1}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="_mainRl_table">
                <div className="_addRl_headCheck">
                  <input
                    id="accountAccess"
                    boxclassName="_addRl_headCheck"
                    type="checkbox"
                    label="accountAccess"
                    value={allCheckbox}
                    checked={allCheckbox}
                    onChange={(e) => handleAllCheacks(e)}
                  />
                  <label htmlFor="accountAccess" className="anvLabel">
                    Accounts Access
                  </label>
                </div>

                {modifyAccount?.map((i, index) => {
                  return (
                    <div
                      className="_addRl_checkList"
                      key={`${i?.sub_category_id}${i?.account_id}`}
                    >
                      <div className="_brxFlex">
                        <div className="_dLass">
                          <div>
                            <input
                              type="checkbox"
                              id={`isSelected_${i?.sub_category_id}_${i?.account_id}`}
                              label="cash"
                              value={i?.isSelected}
                              checked={i?.isSelected == 0 ? false : true}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <label
                              htmlFor={`isSelected_${i?.sub_category_id}_${i?.account_id}`}
                              className="anvLable"
                            >
                              {i?.sub_category_name} | {i?.account_name}
                            </label>
                          </div>
                        </div>

                        <div className="_dLass">
                          <div>
                            <input
                              type="checkbox"
                              id={`selection_${i?.sub_category_id}_${i?.account_id}`}
                              label="Selection"
                              value={i?.selection}
                              checked={i?.selection == 0 ? false : true}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <label
                              htmlFor={`selection_${i?.sub_category_id}_${i?.account_id}`}
                              className="anvLable"
                            >
                              Selection
                            </label>
                          </div>
                        </div>

                        <div className="_dLass">
                          <div>
                            <input
                              type="checkbox"
                              id={`view_balance_${i?.sub_category_id}_${i?.account_id}`}
                              label="View Balance"
                              value={i?.view_balance}
                              disabled={
                                i?.isSelected === 1 ||
                                i?.selection === 1 ||
                                i?.edit_account === 1 ||
                                i?.delete_account === 1
                              }
                              checked={i?.view_balance == 0 ? false : true}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <label
                              htmlFor={`view_balance_${i?.sub_category_id}_${i?.account_id}`}
                              className="anvLable"
                            >
                              View Balance
                            </label>
                          </div>
                        </div>

                        <div className="_dLass">
                          <div>
                            <input
                              type="checkbox"
                              label="Edit Account"
                              id={`edit_account_${i?.sub_category_id}_${i?.account_id}`}
                              value={i?.edit_account}
                              checked={i?.edit_account == 0 ? false : true}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <label
                              htmlFor={`edit_account_${i?.sub_category_id}_${i?.account_id}`}
                              className="anvLable"
                            >
                              Edit Account
                            </label>
                          </div>
                        </div>

                        <div className="_dLass">
                          <div>
                            <input
                              type="checkbox"
                              label="Delete Account"
                              id={`delete_account_${i?.sub_category_id}_${i?.account_id}`}
                              value={i?.delete_account}
                              checked={i?.delete_account == 0 ? false : true}
                              onChange={(e) => handleChange(e, index)}
                            />
                            <label
                              htmlFor={`delete_account_${i?.sub_category_id}_${i?.account_id}`}
                              className="anvLable"
                            >
                              Delete Account
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="_mainRl_table">
                <div className="_addRl_headCheck">
                  <p className="anvLabel">Transactions Permission</p>
                </div>

                <div className="_addRl_checkList">
                  <div className="_permisionBx_ls">
                    <div className="_headPermission">
                      At the time of Adding Transations
                    </div>
                    <div className="_brxFlex">
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="all"
                            label="all"
                            checked={
                              selectAll?.add_transaction === 1 ? true : false
                            }
                            onChange={({ target: { name, checked } }) =>
                              onHandleAll(name, checked, "addTransaction")
                            }
                          />
                          <label htmlFor="all" className="anvLable">
                            All
                          </label>
                        </div>
                      </div>
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="approval"
                            name="approval_required"
                            label="Approval Required"
                            checked={
                              addTransaction?.approval_required === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "addTransaction")
                            }
                          />
                          <label htmlFor="approval" className="anvLable">
                            Approval Required
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="submitAccess"
                            label="Submit Access"
                            name="submit_access"
                            checked={
                              addTransaction?.submit_access === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "addTransaction")
                            }
                          />
                          <label htmlFor="submitAccess" className="anvLable">
                            Submit Access
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="_permisionBx_ls">
                    <div className="_headPermission">Party Freight</div>
                    <div className="_brxFlex">
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="prtyAll"
                            label="All"
                            checked={
                              selectAll?.party_freight === 1 ? true : false
                            }
                            onChange={({ target: { name, checked } }) =>
                              onHandleAll(name, checked, "party")
                            }
                          />
                          <label htmlFor="prtyAll" className="anvLable">
                            All
                          </label>
                        </div>
                      </div>
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="canView"
                            label="Can View Freight"
                            name="can_view_freight"
                            checked={
                              partyFreight?.can_view_freight === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "party")
                            }
                          />
                          <label htmlFor="canView" className="anvLable">
                            Can View Freight
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="canEdit"
                            label="Can Edit Freights"
                            name="can_edit_freight"
                            checked={
                              partyFreight?.can_edit_freight === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "party")
                            }
                          />
                          <label htmlFor="canEdit" className="anvLable">
                            Can Edit Freights
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="_brxFlex">
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="atf1"
                            label="Add Total Freight"
                            name="add_total_freight"
                            checked={
                              partyFreight?.add_total_freight === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "party")
                            }
                          />
                          <label htmlFor="atf1" className="anvLable">
                            Add Total Freight
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="aaf1"
                            label="Add Advance Freight"
                            name="add_advance_freight"
                            checked={
                              partyFreight?.add_advance_freight === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "party")
                            }
                          />
                          <label htmlFor="aaf1" className="anvLable">
                            Add Advance Freight
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="addBalance1"
                            label="Add Balance"
                            name="add_balance"
                            checked={
                              partyFreight?.add_balance === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "party")
                            }
                          />
                          <label htmlFor="addBalance1" className="anvLable">
                            Add Balance
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="addClaimReciev1"
                            label="Add Claim Receivable"
                            name="add_claim_receivable"
                            checked={
                              partyFreight?.add_claim_receivable === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "party")
                            }
                          />
                          <label htmlFor="addClaimReciev1" className="anvLable">
                            Add Claim Receivable
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="addClaimPay1"
                            label="Add Claim Payable"
                            name="add_claim_payable"
                            checked={
                              partyFreight?.add_claim_payable === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "party")
                            }
                          />
                          <label htmlFor="addClaimPay1" className="anvLable">
                            Add Claim Payable
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="_permisionBx_ls">
                    <div className="_headPermission">Supplier Freights</div>

                    <div className="_brxFlex">
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="suppAll"
                            label="All"
                            checked={
                              selectAll?.supplier_freight === 1 ? true : false
                            }
                            onChange={({ target: { name, checked } }) =>
                              onHandleAll(name, checked, "supplier")
                            }
                          />
                          <label htmlFor="suppAll" className="anvLable">
                            All
                          </label>
                        </div>
                      </div>
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="atf2"
                            label="Add Total Freight"
                            name="add_total_freight"
                            checked={
                              supplierFreight?.add_total_freight === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "supplier")
                            }
                          />
                          <label htmlFor="atf2" className="anvLable">
                            Add Total Freight
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="aaf2"
                            label="Add Advance Freight"
                            name="add_advance_freight"
                            checked={
                              supplierFreight?.add_advance_freight === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "supplier")
                            }
                          />
                          <label htmlFor="aaf2" className="anvLable">
                            Add Advance Freight
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="addBalance2"
                            label="Add Balance"
                            name="add_balance"
                            checked={
                              supplierFreight?.add_balance === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "supplier")
                            }
                          />
                          <label htmlFor="addBalance2" className="anvLable">
                            Add Balance
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="addClaimReciev2"
                            label="Add Claim Receivable"
                            name="add_claim_receivable"
                            checked={
                              supplierFreight?.add_claim_receivable === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "supplier")
                            }
                          />
                          <label htmlFor="addClaimReciev2" className="anvLable">
                            Add Claim Receivable
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="addClaimPay2"
                            label="Add Claim Payable"
                            name="add_claim_payable"
                            checked={
                              supplierFreight?.add_claim_payable === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "supplier")
                            }
                          />
                          <label htmlFor="addClaimPay2" className="anvLable">
                            Add Claim Payable
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="_permisionBx_ls">
                    <div className="_headPermission">Other</div>
                    <div className="_brxFlex">
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="otherAll"
                            label="All"
                            checked={
                              selectAll?.other_freight === 1 ? true : false
                            }
                            onChange={({ target: { name, checked } }) =>
                              onHandleAll(name, checked, "other")
                            }
                          />
                          <label htmlFor="otherAll" className="anvLable">
                            All
                          </label>
                        </div>
                      </div>
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="aoe"
                            label="Add Other Expenses"
                            name="add_other_expenses"
                            checked={
                              other?.add_other_expenses === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "other")
                            }
                          />
                          <label htmlFor="aoe" className="anvLable">
                            Add Other Expenses
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="aor"
                            label="Add Other Receipt"
                            name="add_other_receipt"
                            checked={
                              other?.add_other_receipt === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "other")
                            }
                          />
                          <label htmlFor="aor" className="anvLable">
                            Add Other Receipt
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="_permisionBx_ls">
                    <div className="_headPermission">Payment Approval</div>

                    <div className="_brxFlex">
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="payAll"
                            label="All"
                            checked={
                              selectAll?.payment_approval === 1 ? true : false
                            }
                            onChange={({ target: { name, checked } }) =>
                              onHandleAll(name, checked, "paymentAprrove")
                            }
                          />
                          <label htmlFor="payAll" className="anvLable">
                            All
                          </label>
                        </div>
                      </div>
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="pending"
                            label="Pending"
                            name="pending"
                            checked={
                              paymentAprrove?.pending === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "paymentAprrove")
                            }
                          />
                          <label htmlFor="pending" className="anvLable">
                            Pending
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="approve"
                            label="Approve"
                            name="approve"
                            checked={
                              paymentAprrove?.approve === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "paymentAprrove")
                            }
                          />
                          <label htmlFor="approve" className="anvLable">
                            Approve
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="hold"
                            label="Hold"
                            name="hold"
                            checked={paymentAprrove?.hold === 0 ? false : true}
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "paymentAprrove")
                            }
                          />
                          <label htmlFor="hold" className="anvLable">
                            Hold
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="Complete"
                            label="Complete"
                            name="complete"
                            checked={
                              paymentAprrove?.complete === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "paymentAprrove")
                            }
                          />
                          <label htmlFor="Complete" className="anvLable">
                            Complete   
                          </label>
                        </div>
                      </div>

                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="Cancel"
                            label="Cancel"
                            name="cancel"
                            checked={
                              paymentAprrove?.cancel === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "paymentAprrove")
                            }
                          />
                          <label htmlFor="Cancel" className="anvLable">
                            Cancel
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="_permisionBx_ls">
                    <div className="_brxFlex">
                      <div className="_dLass">
                        <div className="_headPermission">Consignor View</div>
                        <div>
                          <input
                            type="checkbox"
                            id="consignorView"
                            label="Add Other Expenses"
                            name="restricted_view"
                            checked={
                              consignorView?.restricted_view === 0
                                ? false
                                : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "consignorView")
                            }
                          />
                          <label htmlFor="consignorView" className="anvLable">
                            Add Other Expenses
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="_permisionBx_ls">
                    <div className="_brxFlex">
                      <div className="_dLass">
                        <div className="_headPermission">Supplier</div>
                        <div>
                          <input
                            type="checkbox"
                            id="supplierView"
                            label="Add Other Receipt"
                            name="restricted_view"
                            checked={
                              suplierView?.restricted_view === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "supplierView")
                            }
                          />
                          <label htmlFor="supplierView" className="anvLable">
                            Add Other Receipt
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="_permisionBx_ls">
                    <div className="_headPermission">Contract Setting</div>

                    <div className="_brxFlex">                   
                      <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="show_amount"
                            label="show_amount"
                            name="show_amount"
                            checked={
                              contractSetting?.show_amount === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "contractSetting")
                            }
                          />
                          <label htmlFor="show_amount" className="anvLable">
                            Show amount
                          </label>
                        </div>
                      </div>

                      {contractSetting.show_amount==1 && <div className="_dLass">
                        <div>
                          <input
                            type="checkbox"
                            id="edit_amount"
                            label="edit_amount"
                            name="edit_amount"
                            checked={
                              contractSetting?.edit_amount === 0 ? false : true
                            }
                            onChange={({ target: { name, checked, id } }) =>
                              onHandleChange(name, checked, "contractSetting")
                            }
                          />
                          <label htmlFor="edit_amount" className="anvLable">
                            Edit amount
                          </label>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottomFix">
            <button
              className="_addComm_btn"
              onClick={(e) => onSubmit(e, "add")}
              type="button"
              disabled={
                disabled ||
                !selectedBranchIds.length ||
                !formValue?.company_id ||
                !formValue?.company_user_name ||
                !formValue?.role_id ||
                !formValue?.mobileno ||
                filterAccountList.length == 0
              }
            >
              {id ? "Edit User" : "Add User"}
            </button>
            {!id && (
              <button
                className="_addComm_btn"
                onClick={(e) => onSubmit(e, "addSave")}
                type="button"
                disabled={
                  disabled ||
                  !selectedBranchIds.length ||
                  !formValue?.company_id ||
                  !formValue?.company_user_name ||
                  !formValue?.role_id ||
                  !formValue?.mobileno ||
                  filterAccountList.length == 0
                }
              >
                Add & New
              </button>
            )}
            <button className="_cancel_btn">Cancel</button>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default AddUser;

export const Checkbox = ({
  id,
  type,
  index,
  name,
  handleClick,
  isChecked,
  className,
  isDisabled
}) => {
  return (
    <div className={className}>
      <input
        type="checkbox"
        id={id}
        onChange={handleClick}
        checked={isChecked}
        disabled={isDisabled}
      />
      <label htmlFor={id} className="anvLable">
        {name}
      </label>
    </div>
  );
};

export const useSelectAll = (state, key, setSelectAll) => {
  useEffect(() => {
    const anyChecked = Object.values(state).some((value) => value === 0);
    setSelectAll((prevSelectAll) => ({
      ...prevSelectAll,
      [key]: anyChecked ? 0 : 1,
    }));
  }, [state, key, setSelectAll]);
};
