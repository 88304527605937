import { Images } from "constants";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../assets/css/modal.css";
import { UserContext } from "context";
import { CommonInput, CommonModal, CustomSelect } from "components";
import { City, State } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { addCompany } from "features";
import { StorageServices } from "utils";
import { debounce } from "lodash";
import { getDashbordData, handleKeyDown } from "_services";
import StorageService from "utils/StorageServices/StorageServices";
import { errorSchema, utils } from "helper";


const CreateCompanyModal = ({ isOpen, closeModal }) => {
  const {
    masterData,
    cities,
    setCommonError,
    commonError,
    setShowCreateCompanyModal,
    setUserData,
    setCities,setMasterData,
    userDetails,
    showCreateCompanyModal,
    setToken,
    userData

  } = useContext(UserContext);
  const { error, data,loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userExst = JSON.parse(StorageService.getLocalItem("userData"))
  const [cityDetails,setCityDetails] = useState({label:"",value:""})
 
  const initForm = {
    company: "",
    contact_no: userData?.mobile_no,
    type_id: masterData?masterData?.company_type[0]?._id:JSON.parse(StorageService.getLocalItem("master_data")).company_type[0]?._id,
    gst_no: "",
    pan_no: "",
    address_line1: "",
    address_line2: "",
    state: {},
    city: {label:userData?.user_detail?.city?.name?`${userData?.user_detail?.city?.name}`:"", value:userData?.user_detail?.city?.name?userData?.user_detail?.city?.name:""},
    pincode: "",
    is_gst:false
  };

  const initDrop = {
    city: "",
    state: "",
    gst: false,
  };

  const [formValue, setFormValue] = useState(initForm);
  const [formError, setFormError] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filteredCities, setFilteredCities] = useState([]);
  const [stateValue,setStateValue] = useState('')
  const [isLogout, setIsLogout] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  //.......city ........//
  useEffect(()=>{
  if(cities){
    const optionsCity = cities?.map((d) => {
      return {
        ...d,
        label: `${d?.name}, ${d?.stateCode}`,
      };
    });
    setFilteredCities(optionsCity);
  }else{
    setCities(JSON.parse(StorageService.getCities()))
  }

 if(masterData===null){
  setMasterData(JSON.parse(StorageService.getLocalItem("master_data")))
 }
   
  },[cities,masterData])
  
  useEffect(()=>{
if(!showCreateCompanyModal){
allClear()
}
  },[showCreateCompanyModal])
useEffect(()=>{
  if( userData?.user_detail?.city?.stateCode){
    const stateDetails = State.getStateByCodeAndCountry(
      userData?.user_detail?.city?.stateCode,
      userData?.user_detail?.city?.countryCode
    );
    setStateValue(stateDetails)
  }
  setCityDetails({label:userData?.user_detail?.city?.name?`${userData?.user_detail?.city?.name}, ${userData?.user_detail?.city?.stateCode}`:"",value:userData?.user_detail?.city?.name?userData?.user_detail?.city?.name:''})
},[userData])
  

  const handleOnChange = async (name, value) => {
    const stateObj = { ...formValue, [name]: value };
    setFormValue(stateObj);
    setCommonError(false);
    if (!!formError) {
      const validationResult = await utils.checkFormError(
        stateObj,
        errorSchema.addCompanyModalSchema
      );

      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleCityChange = (data)=>{
    handleOnChange("city", {
      label: data?.name,
      value: data?.name,
    })

  setCityDetails({label:`${data?.name}, ${ data?.stateCode}`,value:data?.name})

    const stateDetails = State.getStateByCodeAndCountry(
      data?.stateCode,
      data?.countryCode
    );
    setStateValue(stateDetails)
  }

  useEffect(()=>{
   
    if(stateValue){
      handleOnChange("state",{ label: stateValue?.name, value: stateValue?.name })
    }
  },[stateValue])

  const handleCompany = async (e) => {
    e.preventDefault();
    const validationResult = await utils.checkFormError(
      { ...formValue },
      errorSchema.addCompanyModalSchema
    );

    const payload ={
      company: formValue?.company,
    contact_no: formValue?.contact_no,
    type_id: formValue?.type_id,
    gst_no: formValue?.gst_no,
    pan_no: formValue?.pan_no,
    address_line1: formValue?.address_line1,
    address_line2: formValue?.address_line2,
    state: formValue?.state,
    city: formValue?.city,
    pincode: formValue?.pincode,
    }

    try {
      if (isEdit === true) {
        // setIsLoadding(true);
        // handleUpdate();
      } else {
        if (validationResult === true) {
          const res = await dispatch(addCompany(payload));
          if (res.payload?.responseCode === 200) {
            StorageServices.setCompanyId(
              res?.payload?.data?.user_detail?.select_company_id
            );
            setShowCreateCompanyModal(false);
            if(res?.payload?.data?.user_detail?.select_company_id){
              getAllDashboardData(
                res?.payload?.data?.user_detail?.select_company_id
              );
            }
            allClear() 
            navigate("/dashboard")
           
          } else {
            setCommonError(res.payload?.responseMessage);
          }
        } else {
          // setIsLoadding(false);
          setFormError(validationResult);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const getAllDashboardData = async (data) => {
    const postData = {
      company_id: data,
      select_company_id: 1,
    };
    try {
      let response = await getDashbordData(postData);
      if (response?.responseCode === 200) {
        // StorageService.setLocalItem("userData", JSON.stringify(response.data));
        // setUserData(response.data);
      } else {
        // throw response?.responseMessage;
      }
    } catch (error) {
      console.error("Error fetching master data:", error);
      throw error;
    } finally {
    }
  };

  const allClear = ()=>{
    setFormValue(initForm)
  }

  const signOut = async () => {
    setIsLoading(true)
    await StorageServices.clearStorage();
    setToken(null);
    setIsLoading(false)
    setShowCreateCompanyModal(false);
  };

  let propsObject = {
    isOpen: isLogout,
    closeModal: ()=>setIsLogout(false),
    modalTitle: "Logout",
    submitTitle: "Logout",
    cancleTitle: "Close",
    onSubmit: signOut,
    isLoadding: isloading,
  }
  


  return (
    <div>
      {isOpen && (
        <div
          className="modal fade show d-block _addAccount_modal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex={0}
                >
                  <div className="modal-header ">
                    <h1 className="modal-title " id="staticBackdropLabel">
                      <button className="_modBack_btn" onClick={()=>{
userExst?.user_detail?.company_list.length > 0?closeModal():setIsLogout(true)
                      }}>
                        <img src={Images.BACKICON} alt="" />
                      </button>
                      <span>Create Company</span>
                    </h1>

                    <span className="_stepNum">Step 1/2</span>
                  </div>

                  <div className="modal-body ">
                    <div className="_crtComp_modBx">
                      <h3 className="_headH3">
                        Which of these best describes your business?
                      </h3>

                      <div className="_businessType_listOtr">
                        {masterData?.company_type?.map((item,index) => (
                          <a
                            className={`_businessType_listBx ${
                              formValue?.type_id === item?._id ? "bxActive" : ""
                            }`}
                            onClick={() =>
                              setFormValue({
                                ...formValue,
                                type_id: item?._id,
                              })
                            }
                            key={`master_${index}`}
                          >
                            <div className="_trkSide">
                              <img
                                src={`${masterData?.s3_url}${item?.icon}`}
                                alt=""
                              />

                              <div>
                                <h3 className="_headH3">{item?.name}</h3>
                                <p>{item?.description}</p>
                              </div>
                            </div>

                            <div className="checkSide">
                              <img src={Images.CHECKFILLICON.default} alt="" />
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabIndex={0}
                >
                  <div className="modal-header ">
                    <h1 className="modal-title " id="staticBackdropLabel">
                      <button
                        className="_modBack_btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src={Images.BACKICON} alt="" />
                      </button>
                      <span>Create Company</span>
                    </h1>

                    <span className="_stepNum">Step 2/2</span>
                  </div>

                  <div className="modal-body ">
                    <div className="_crtComp_modBx2">
                      <div className="_inputsInx_bx">
                        <CommonInput
                          type="text"
                          placeholder="Business Name"
                          label="Business Name *"
                          value={formValue?.company ? formValue?.company : ""}
                          name="company"
                          focus={!!formError?.company}
                          error={formError.company}
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          
                        />
                        <CommonInput
                          type="text"
                          placeholder="Business Contact Number "
                          label="Business Contact Number *"
                          value={
                            formValue?.contact_no ? formValue?.contact_no : ""
                          }
                          maxLength={10}
                          onKeyDown={handleKeyDown}
                          name="contact_no"
                          focus={!!formError?.contact_no}
                          error={formError.contact_no}
                          onChange={({ target: { name, value } }) =>{
                            if (/^\d*$/.test(value)) {
                              handleOnChange(name, value);
                              // setPhoneError("");
                            }
                          }
                            
                          }
                        />

                        <div className="_gstFlx_bx">
                          <span>GST Registered</span>

                          <div className="anvSwitch_frm form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              name="is_gst"
                              checked={formValue?.is_gst}
                              onChange={({ target: { name, checked } }) =>
                              handleOnChange(name, checked)
                            }
                            />
                          </div>
                        </div>

                        {formValue?.is_gst && <CommonInput
                          type="text"
                          placeholder="GST Number"
                          label="GST Number"
                          value={formValue?.gst_no ? formValue?.gst_no : ""}
                          name="gst_no"
                          focus={!!formError?.gst_no}
                          error={formError.gst_no}
                          maxLength={15}
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                        />}
                      </div>

                      <div className="_inputsInx_bx">
                        <div className="_gstFlx_bx">
                          <span> Business Address</span>
                        </div>

                        <CommonInput
                          type="text"
                          placeholder="Address Line 1"
                          label="Address Line 1"
                          value={
                            formValue?.address_line1
                              ? formValue?.address_line1
                              : ""
                          }
                          name="address_line1"
                          focus={!!formError?.address_line1}
                          error={formError.address_line1}
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                        />

                        <div className="_inrInx">
                          <div className="_divDivide_half">
                           
                            <CustomSelect
                              options={filteredCities}
                              onSelect={handleCityChange}
                              placeholder="Select a city"
                              error={formError["city.label"
                              ]
                              }
                              value={cityDetails}
                              label="City"
                            />
                          </div>

                          <div className="_divDivide_half">
                            
                            <CustomSelect
                              options={filteredCities}
                              onSelect={handleCityChange}
                              placeholder="Select a state"
                              value={formValue?.state}
                              disabled={true}
                            />
                          </div>
                        </div>

                        <CommonInput
                          type="text"
                          placeholder="PIN Code"
                          label="PIN Code"
                          value={formValue?.pincode ? formValue?.pincode : ""}
                          name="pincode"
                          maxLength={6}
                          onKeyDown={handleKeyDown}
                          focus={!!formError?.pincode}
                          error={formError.pincode}
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                        />
                      </div>
                    </div>
                    {error && (
                      <small className="text-danger">
                        {error?.responseMessage}
                      </small>
                    )}
                  </div>
                </div>
              </div>

              <div className="modal-footer ">
                <ul
                  className="nav nav-tabs anvTbl_list"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item backTabs" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      <img src={Images.BACKICON} alt="" />
                    </button>
                  </li>

                  <li className="nav-item stepRedirect" role="presentation">
                    <button
                      className="anime-btn _submitOn_modal"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                    >
                      Continue
                    </button>
                  </li>

                  <li className="nav-item finalSub_bx" role="presentation">
                    <button
                      className="anime-btn _submitOn_modal"
                      type="button"
                      onClick={handleCompany}
                      disabled={loading}
                    >
                      {!loading?"Continue":"Creating..."}
                     
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <div className="modal-backdrop fade show" onClick={closeModal}></div>
      )}

{
        isLogout && (
          <CommonModal {...propsObject}>
            <p className='p-3'> Are you want to Logout </p>
          </CommonModal>
        )
      }
    </div>
  );
};

export default CreateCompanyModal;
