import React from 'react'

const CommonSwitch = (props) => {
    let { checked, onChange, name, id, label , disabled=false, isHidden } = props;
    return (
        <>
            <div className="anvSwitch_frm form-check form-switch">
                <label htmlFor={id}>{label}</label>
                <input
                    className="form-check-input"
                    type={isHidden ? "hidden" : "checkbox"}
                    role="switch"
                    id={id}
                    name={name}
                    disabled={disabled}
                    checked={checked}
                    onChange={onChange}
                />
            </div>
        </>
    )
}

export default CommonSwitch