import { UserContext } from "context";
import { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { StorageServices } from "utils";


const PublicRoute = ({ element }) => {
  const navigate = useNavigate();

  const { genrateOtp, token } = useContext(UserContext)
  let isAuthenticated = token

  useEffect(() => {
    if (!genrateOtp && !isAuthenticated) {
      navigate("/");
    }

  }, [navigate, genrateOtp, isAuthenticated])

  return !isAuthenticated ? element : <Navigate to="/dashboard" />;
};

export default PublicRoute
