import { API, axiosInstance } from "lib";

export const getVehicleGroupListData = (data={}) => {
  return axiosInstance
    .post(API.GET_VEHICLE_GROUP_LIST , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const createVehicleGroup = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_VEHICLE_GROUP , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getSingleVehicleGroupData = (data={}) => {
  return axiosInstance
    .post(API.GET_SINGLE_VEHICLE_GROUP , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteVehicleGroupData = (data={}) => {
  return axiosInstance
    .post(API.DELETE_VEHICLE_GROUP , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};