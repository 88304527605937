import React, { useContext, useEffect, useState } from 'react'
import { errorSchema, utils } from 'helper';
import { CommonInput, CommonModal, CommonTextarea, CommonDatePicker } from 'components'
import StorageService from 'utils/StorageServices/StorageServices';
import toast, { Toaster } from 'react-hot-toast';
import { createMaterialCategory } from '_services/materialServices/materialCategoryServices';
import { podReceivedData, updateLrProcess, uploadFiles } from '_services';
import { RiAddLargeFill } from "react-icons/ri";
import ContinuePodRecievedModal from 'modals/ContinuePodRecievedModal';
import moment from 'moment';
import { Images } from 'constants';
import { UserContext } from 'context';
import { IoCloseCircle } from 'react-icons/io5';

const formObj = {
    company_id: '',
    lr_id: '',
    date: new Date(),
    notes: '',
    file: [],
};

const PodRecievedModal = (props) => {
    const { masterData, setLrDetialsFrom } = useContext(UserContext)
    const { isOpen, closeModal, nextModal } = props;
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addPodReceviedSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const handleClick = async (value) => {
        const validationResult = await utils?.checkFormError(formValue, errorSchema.addPodReceviedSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            nextModal(formValue)
            closeModal(false);
            setFormError("");
        } else {
            setFormError(validationResult);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setFormValue((s) => ({
                ...s,
                lr_id: isOpen?._id,
                company_id: isOpen?.company_id,
                date: new Date(), // Set date with moment
            }));
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen?.isEdit) {
            setFormValue((s) => ({
                ...s,
                lr_id: isOpen?._id,
                company_id: isOpen?.company_id,
                notes: isOpen?.pod_remark,
                file: isOpen?.pod_file,
                date: moment(isOpen?.pod_date).hours(16).minutes(30).toDate(), // Set date with moment
            }));
        }
    }, [isOpen?.isEdit]);

    const handleFileChange = async (e) => {
        if (e?.target?.files[0]?.type === "image/gif" || e?.target?.files[0]?.type === "image/jpeg" || e?.target?.files[0]?.type === "image/png") {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append("file", e?.target?.files[0]);
                const res = await uploadFiles(formData);
                if (res?.responseCode === 200) {
                    var fileArray = []
                    fileArray.push(...formValue?.file, { filename: res?.data?.filename, path: res?.data?.path });
                    handleOnChange('file', fileArray)
                    // setFormValue((s) => ({ ...s, file: fileArray }));
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
            }
        } else {
            toast.error("Invalid file, choose jpeg/png images")
        }
    }

    let inhanceProps = {
        ...props,
        submitTitle: loading ? 'Loading...' : props.submitTitle,
        disabled: loading,
        onSubmit: handleClick,
    }

    console.log("formValue?.file", formValue)

    return (
        <div>
            <CommonModal {...inhanceProps}>
                <div className='addItem_body'>
                    <div className='colFlx'>
                        <div className='singleDiv'>
                            {/* <CommonInput
                                type="date"
                                label='POD Received Date & Time * '
                                placeholder='POD Received Date & Time * '
                                name='date'
                                value={formValue.date}
                                focus={!!(typeof formError === "object" && formError?.date)}
                                error={!!(typeof formError === "object") ? formError?.date : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            /> */}
                            <CommonDatePicker
                                label={'POD Received Date & Time * '}
                                name='date'
                                startDate={formValue.date}
                                setStartDate={(date) => handleOnChange('date', date)}
                                error={!!(typeof formError === "object") ? formError?.date : ""}
                            />
                        </div>

                        <div className='singleDiv'>
                            <CommonTextarea
                                type="textarea"
                                label="Notes "
                                placeholder="Notes "
                                name='notes'
                                value={formValue.notes}
                                focus={!!(typeof formError === "object" && formError?.notes)}
                                error={!!(typeof formError === "object") ? formError?.notes : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>
                        <div className='singleDiv'>
                            <div className='_flUpload'>
                                <input
                                    type="file"
                                    name='file'
                                    id='_file'
                                    hidden
                                    onChange={(e) => handleFileChange(e)}
                                />
                                <label htmlFor='_file'>+</label>
                            </div>
                            {formError?.file && (
                                <span className="input_drop_error">
                                    {formError?.file}
                                </span>
                            )}
                        </div>
                        <div className='img_fnex_src'>
                            {
                                formValue?.file?.length > 0 &&
                                formValue?.file?.map((el) => {
                                    return (
                                        <>
                                            <div className='pos-relative'>
                                                <img className='_pdfIcon_sigxe'
                                                    src={`${masterData?.s3_url}${el?.path}`}
                                                    alt=""
                                                />
                                                <span className='_clDel_icon'>
                                                <IoCloseCircle />
                                                </span>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        {/* <div>
                            <div>
                                <ul
                                    className={formValue?.file?.length > 0 ? "_attachedFile_bx" : "_withOutAttach_bx"}
                                >
                                    {
                                        formValue?.file ? (
                                            <li>
                                                <span
                                                    className='_delAttach' onClick={(e) => setFormValue((s) => ({...s, file:[]}))}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path d="M6 0C2.65714 0 0 2.65714 0 6C0 9.34286 2.65714 12 6 12C9.34286 12 12 9.34286 12 6C12 2.65714 9.34286 0 6 0ZM8.31429 9L6 6.68571L3.68571 9L3 8.31429L5.31429 6L3 3.68571L3.68571 3L6 5.31429L8.31429 3L9 3.68571L6.68571 6L9 8.31429L8.31429 9Z" fill="black" />
                                                    </svg>
                                                </span>
                                                {attachments?.type === 'application/pdf' ? (
                                                    <img className='_pdfIcon'
                                                        src={Images.PDFICON}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <img
                                                        src={(attachments?.name && URL?.createObjectURL(attachments)) ? URL?.createObjectURL(attachments) : ''}
                                                        alt=""
                                                    />
                                                )}
                                            </li>
                                        ) : <></>
                                    }
                                </ul>
                            </div>
                        </div> */}

                    </div>
                </div>
            </CommonModal>
            <div className='offBack_drop'></div>

        </div>
    )
}

export default PodRecievedModal
