import React from 'react'
import { CommonInput, } from 'components';
import { constant } from 'constants';
import { utils } from 'helper';


const Partydetail = ({detailsData, masterData}) => {

    return (
        <div>
            <div className='_addContainer2 '>
                <div className='_ptyDetail_bx'>
                    <div className="inrPrt_bxs">
                        <div className='_inrPrt_head'>Basic Details</div>
                        <div className='_inrPrt_flx'>
                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    placeholder="Party Name"
                                    label="Party Name *"
                                    name='name'
                                    value={detailsData?.name}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="Mobile Number"
                                    placeholder="Mobile Number"
                                    value={detailsData?.contact_no}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="Alternate Mobile Number"
                                    placeholder="Alternate Mobile Number"
                                    value={detailsData?.alt_contact_no}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="Email ID"
                                    placeholder="Email ID"
                                    value={detailsData?.email}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="Opening Balance"
                                    placeholder="Opening Balance"
                                    value={detailsData?.opening_balance}
                                    disabled
                                />
                            </div>
                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="As of Date"
                                    placeholder="As of Date"
                                    value={utils.formatDate(detailsData?.as_of_date)}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="inrPrt_bxs ">
                        <div className='_inrPrt_head'> Address </div>
                        <div className='_inrPrt_flx _min_h_auto'>
                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="Address Line 1"
                                    placeholder="Address Line 1"
                                    value={detailsData?.address_line1}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="State"
                                    placeholder="State"
                                    value={detailsData?.state?.label}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="City"
                                    placeholder="City"
                                    value={detailsData?.city?.label}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="PIN Code"
                                    placeholder="PIN Code"
                                    value={detailsData?.pincode}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="inrPrt_bxs">
                        <div className='_inrPrt_head'>Tax Info</div>
                        <div className='_inrPrt_flx'>
                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="GST Type"
                                    placeholder="GST Type"
                                    value={masterData?.gst_type_list?.filter((el) => (el?.id == detailsData?.gst_type))[0]?.name}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="GST Number"
                                    placeholder="GST Number"
                                    value={detailsData?.gst_no}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="State State"
                                    placeholder="State State"
                                    value={detailsData?.gst_state?.label}
                                    disabled
                                />
                            </div>
                            
                            <div className='checkBx_lv mt-2'>
                                {
                                    constant.chargeGST?.map((el, ind) => {
                                        return (
                                            <React.Fragment>
                                                <input
                                                    className="form-check-input"
                                                    id={+ind + "gst_charges" + el?.value}
                                                    type="radio"
                                                    name="gst_charges"
                                                    checked={detailsData?.gst_charges == el?.value}
                                                    value={detailsData?.gst_charges == el?.value}
                                                    disabled
                                                /> &nbsp; &nbsp;
                                                <label htmlFor={+ind + "gst_charges" + el.value} className="form-check-label" >
                                                    {el.label}
                                                    <br />
                                                    <span className='gts_clasFrs'> GST will be paid & Manage by Consignor </span>
                                                </label>  <br />
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="inrPrt_bxs">
                        <div className='_inrPrt_head'> Other Details </div>
                        <div className='_inrPrt_flx'>
                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="Consignor Group"
                                    placeholder="Consignor Group"
                                    value={detailsData?.name}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="Invoice Format"
                                    placeholder="Invoice Format"
                                    value={detailsData?.name}
                                    disabled
                                />
                            </div>

                            <div className='_divForth'>
                                <CommonInput
                                    type="text"
                                    label="LR Sequence"
                                    placeholder="LR Sequence"
                                    value={detailsData?.name}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partydetail