import React from 'react'

const CommonCheckbox = (props) => {
    let { id, type = "checkbox", index, name, handleClick, isChecked, className,disabled=false } = props;
    return (
        <>
            <input
                type={type}
                id={id}
                onChange={handleClick}
                checked={isChecked}
                disabled={disabled}
            />
            <label htmlFor={id} className="anvLable">
                {name}
            </label>
        </>
    )
}

export default CommonCheckbox