import { API, axiosInstance } from "lib";

export const createUnit = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_UNIT , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getUnitListData = (data={}) => {
  return axiosInstance
    .post(API.GET_UNIT_LIST , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteUnitData = (data={}) => {
  return axiosInstance
    .post(API.DELETE_UNIT , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const fetchSingleUnitData = (data={}) => {
  return axiosInstance
    .post(API.GET_SINGLE_UNIT , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};
export const changeUnitStatusData = (data={}) => {
  return axiosInstance
    .post(API.CHANGE_STATUS_OF_UNIT , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};
