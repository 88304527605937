import { constant } from 'constants/constant';
import React, { useState } from 'react'
import bidderImg from "../../../src/assets/img/bidderImg.png"
import { FaStar } from 'react-icons/fa6';
import { IoCall } from 'react-icons/io5';

const IndentAndBidDetailModal = (props) => {
    let { isOpen, onClose } = props;

    const [activeTab, setActiveTab] = useState(2);
    const [subActiveTab, setSubActiveTab] = useState(1);

    return (
        <>
            <div className="offcanvas-header lrd-header">
                <h5 className="offcanvas-title"> View Indent #234234 <span className='_lrPending_status'>Active</span></h5>
                <button type="button" className="btn-close" onClick={() => onClose(!isOpen)}></button>
            </div>


            <div className='addPr_flBx _indentView'>
                <ul className="nav nav-tabs cstNew_tabs px-0" id="addParty" role="tablist">
                    {constant.indentAndBidsDetailTabs?.map((item, indx) => (
                        <li
                            className="nav-item"
                            role="presentation"
                            key={indx + item?.value}
                        >
                            <button
                                className={
                                    "nav-link " + (activeTab === item?.id ? "active" : "")
                                }
                                type="button"
                                aria-selected={activeTab === item?.id}
                                onClick={(e) => setActiveTab(item.id)}
                            >
                                <span className='_tbTitle'>{item?.title}</span>

                            </button>
                        </li>
                    ))}
                </ul>

                {activeTab === 1 && (
                    <div>1</div>
                )}

                {activeTab === 2 && (
                    <div>
                        <ul className="nav nav-tabs cstNew_tabs px-0" id="addParty" role="tablist">
                            {constant.bidsStatusTabs?.map((item, indx) => (
                                <li
                                    className="nav-item"
                                    role="presentation"
                                    key={indx + item?.value}
                                >
                                    <button
                                        className={
                                            "nav-link " + (subActiveTab === item?.id ? "active" : "")
                                        }
                                        type="button"
                                        aria-selected={subActiveTab === item?.id}
                                        onClick={(e) => setSubActiveTab(item.id)}
                                    >
                                        <span className='_tbTitle'>{item?.title}</span>
                                        <span className='_counts'>6</span>
                                    </button>
                                </li>
                            ))}
                        </ul>

                        {subActiveTab === 1 && (
                            <>
                                <div className='offcanvas-body'>
                                    <div className='_bidTable'>
                                        <table >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className='_bidTh_txt'>Bidder Details</div>
                                                    </th>

                                                    <th>
                                                        <div className='_bidTh_txt'>Status</div>
                                                    </th>

                                                    <th>
                                                        <div className='_bidTh_txt'>Date of Biding</div>
                                                    </th>

                                                    <th>
                                                        <div className='_bidTh_txt'>Rates</div>
                                                    </th>

                                                    <th>
                                                        <div className='_bidTh_txt _actAction'>Actions</div>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_pending_bidSts' >Pending</span>
                                                            {/* <span className='_accepted_bidSts' >Accepted</span> */}
                                                            {/* <span className='_rejected_bidSts' >Rejected</span> */}
                                                            {/* <span className='_withDrawn_bidSts' >Withdrawn</span> */}
                                                            {/* <span className='_dispatched_bidSts' >Dispatched</span> */}
                                                            {/* <span className='_closed_bidSts' >Closed</span> */}
                                                            {/* <span className='_expired_bidSts' >Expired</span> */}
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _acceptSts'>Accept</button>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_pending_bidSts' >Pending</span>
                                                            {/* <span className='_accepted_bidSts' >Accepted</span> */}
                                                            {/* <span className='_rejected_bidSts' >Rejected</span> */}
                                                            {/* <span className='_withDrawn_bidSts' >Withdrawn</span> */}
                                                            {/* <span className='_dispatched_bidSts' >Dispatched</span> */}
                                                            {/* <span className='_closed_bidSts' >Closed</span> */}
                                                            {/* <span className='_expired_bidSts' >Expired</span> */}
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _acceptSts'>Accept</button>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_pending_bidSts' >Pending</span>
                                                            
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _acceptSts'>Accept</button>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_pending_bidSts' >Pending</span>
                                                            
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _acceptSts'>Accept</button>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_pending_bidSts' >Pending</span>
                                                            
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _acceptSts'>Accept</button>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_pending_bidSts' >Pending</span>
                                                            
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _acceptSts'>Accept</button>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        )}

                        {subActiveTab === 2 && (
                            <>
                                <div className='offcanvas-body'>
                                    <div className='_bidTable'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className='_bidTh_txt'>Bidder Details</div>
                                                    </th>

                                                    <th>
                                                        <div className='_bidTh_txt'>Status</div>
                                                    </th>

                                                    <th>
                                                        <div className='_bidTh_txt'>Date of Biding</div>
                                                    </th>

                                                    <th>
                                                        <div className='_bidTh_txt'>Rates</div>
                                                    </th>

                                                    <th>
                                                        <div className='_bidTh_txt _actAction'>Actions</div>
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_accepted_bidSts' >Accepted</span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_accepted_bidSts' >Accepted</span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_accepted_bidSts' >Accepted</span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_accepted_bidSts' >Accepted</span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_accepted_bidSts' >Accepted</span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='_bidR_bxs'>
                                                            <div className='__bidR_usrBx'>
                                                                <span className='_bidR_verifyIcon'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                    </svg>
                                                                </span>
                                                                <img src={bidderImg} alt="" />

                                                                <div className='_ratingBx'>
                                                                    <FaStar />
                                                                    <span>4.5</span>
                                                                </div>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_bidStatus_bxs'>
                                                            <span className='_accepted_bidSts' >Accepted</span>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_dobBid'>
                                                            <p className='tsm_txt_gry'>Bid on</p>
                                                            <p className='tl_txt'>12/8/2024</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_rateBid'>
                                                            <p className='tsm_txt_gry'>Amount</p>
                                                            <p className='tl_txt'>Rs. 5000.00</p>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className='_actionOtr_bx'>
                                                            <button className='_callBtn'>
                                                                <IoCall />
                                                            </button>

                                                            <div className='_actionInr_bx'>
                                                                <button className=' _rejectSts'>Reject</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        )}

                        {subActiveTab === 3 && (
                            <>
                            <div className='offcanvas-body'>
                                <div className='_bidTable'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className='_bidTh_txt'>Bidder Details</div>
                                                </th>

                                                <th>
                                                    <div className='_bidTh_txt'>Status</div>
                                                </th>

                                                <th>
                                                    <div className='_bidTh_txt'>Date of Biding</div>
                                                </th>

                                                <th>
                                                    <div className='_bidTh_txt'>Rates</div>
                                                </th>

                                                <th>
                                                    <div className='_bidTh_txt'>Remarks</div>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='_bidR_bxs'>
                                                        <div className='__bidR_usrBx'>
                                                            <span className='_bidR_verifyIcon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                    <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                </svg>
                                                            </span>
                                                            <img src={bidderImg} alt="" />

                                                            <div className='_ratingBx'>
                                                                <FaStar />
                                                                <span>4.5</span>
                                                            </div>
                                                        </div>
                                                        <div className='w-100'>
                                                            <p className='tl_txt'>Yash Road Carriers</p>
                                                            <p className='tsm_txt'>Rajesh Thakur</p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_bidStatus_bxs'>
                                                        <span className='_rejected_bidSts' >Rejected</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_dobBid'>
                                                        <p className='tsm_txt_gry'>Bid on</p>
                                                        <p className='tl_txt'>12/8/2024</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_rateBid'>
                                                        <p className='tsm_txt_gry'>Amount</p>
                                                        <p className='tl_txt'>Rs. 5000.00</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_reRemark_bx'>
                                                    <p className='tsm_txt_gry'>12 Aug 2024 | 5:30PM</p>
                                                    <p className='tl_txt'>Rejected by User Rohit Ghorawat</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className='_bidR_bxs'>
                                                        <div className='__bidR_usrBx'>
                                                            <span className='_bidR_verifyIcon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                    <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                </svg>
                                                            </span>
                                                            <img src={bidderImg} alt="" />

                                                            <div className='_ratingBx'>
                                                                <FaStar />
                                                                <span>4.5</span>
                                                            </div>
                                                        </div>
                                                        <div className='w-100'>
                                                            <p className='tl_txt'>Yash Road Carriers</p>
                                                            <p className='tsm_txt'>Rajesh Thakur</p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_bidStatus_bxs'>
                                                        <span className='_withDrawn_bidSts' >Withdrawn</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_dobBid'>
                                                        <p className='tsm_txt_gry'>Bid on</p>
                                                        <p className='tl_txt'>12/8/2024</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_rateBid'>
                                                        <p className='tsm_txt_gry'>Amount</p>
                                                        <p className='tl_txt'>Rs. 5000.00</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_reRemark_bx'>
                                                    <p className='tsm_txt_gry'>12 Aug 2024 | 5:30PM</p>
                                                    <p className='tl_txt'>Rejected by User Rohit Ghorawat</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className='_bidR_bxs'>
                                                        <div className='__bidR_usrBx'>
                                                            <span className='_bidR_verifyIcon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                    <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                </svg>
                                                            </span>
                                                            <img src={bidderImg} alt="" />

                                                            <div className='_ratingBx'>
                                                                <FaStar />
                                                                <span>4.5</span>
                                                            </div>
                                                        </div>
                                                        <div className='w-100'>
                                                            <p className='tl_txt'>Yash Road Carriers</p>
                                                            <p className='tsm_txt'>Rajesh Thakur</p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_bidStatus_bxs'>
                                                        <span className='_dispatched_bidSts' >Dispatched</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_dobBid'>
                                                        <p className='tsm_txt_gry'>Bid on</p>
                                                        <p className='tl_txt'>12/8/2024</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_rateBid'>
                                                        <p className='tsm_txt_gry'>Amount</p>
                                                        <p className='tl_txt'>Rs. 5000.00</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_reRemark_bx'>
                                                    <p className='tsm_txt_gry'>12 Aug 2024 | 5:30PM</p>
                                                    <p className='tl_txt'>Rejected by User Rohit Ghorawat</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className='_bidR_bxs'>
                                                        <div className='__bidR_usrBx'>
                                                            <span className='_bidR_verifyIcon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                    <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                </svg>
                                                            </span>
                                                            <img src={bidderImg} alt="" />

                                                            <div className='_ratingBx'>
                                                                <FaStar />
                                                                <span>4.5</span>
                                                            </div>
                                                        </div>
                                                        <div className='w-100'>
                                                            <p className='tl_txt'>Yash Road Carriers</p>
                                                            <p className='tsm_txt'>Rajesh Thakur</p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_bidStatus_bxs'>
                                                        <span className='_closed_bidSts' >Closed</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_dobBid'>
                                                        <p className='tsm_txt_gry'>Bid on</p>
                                                        <p className='tl_txt'>12/8/2024</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_rateBid'>
                                                        <p className='tsm_txt_gry'>Amount</p>
                                                        <p className='tl_txt'>Rs. 5000.00</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_reRemark_bx'>
                                                    <p className='tsm_txt_gry'>12 Aug 2024 | 5:30PM</p>
                                                    <p className='tl_txt'>Rejected by User Rohit Ghorawat</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className='_bidR_bxs'>
                                                        <div className='__bidR_usrBx'>
                                                            <span className='_bidR_verifyIcon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                    <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                </svg>
                                                            </span>
                                                            <img src={bidderImg} alt="" />

                                                            <div className='_ratingBx'>
                                                                <FaStar />
                                                                <span>4.5</span>
                                                            </div>
                                                        </div>
                                                        <div className='w-100'>
                                                            <p className='tl_txt'>Yash Road Carriers</p>
                                                            <p className='tsm_txt'>Rajesh Thakur</p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_bidStatus_bxs'>
                                                    <span className='_closed_bidSts' >Closed</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_dobBid'>
                                                        <p className='tsm_txt_gry'>Bid on</p>
                                                        <p className='tl_txt'>12/8/2024</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_rateBid'>
                                                        <p className='tsm_txt_gry'>Amount</p>
                                                        <p className='tl_txt'>Rs. 5000.00</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_reRemark_bx'>
                                                    <p className='tsm_txt_gry'>12 Aug 2024 | 5:30PM</p>
                                                    <p className='tl_txt'>Rejected by User Rohit Ghorawat</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className='_bidR_bxs'>
                                                        <div className='__bidR_usrBx'>
                                                            <span className='_bidR_verifyIcon'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                    <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                                </svg>
                                                            </span>
                                                            <img src={bidderImg} alt="" />

                                                            <div className='_ratingBx'>
                                                                <FaStar />
                                                                <span>4.5</span>
                                                            </div>
                                                        </div>
                                                        <div className='w-100'>
                                                            <p className='tl_txt'>Yash Road Carriers</p>
                                                            <p className='tsm_txt'>Rajesh Thakur</p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_bidStatus_bxs'>
                                                        <span className='_expired_bidSts' >Expired</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_dobBid'>
                                                        <p className='tsm_txt_gry'>Bid on</p>
                                                        <p className='tl_txt'>12/8/2024</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_rateBid'>
                                                        <p className='tsm_txt_gry'>Amount</p>
                                                        <p className='tl_txt'>Rs. 5000.00</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='_reRemark_bx'>
                                                    <p className='tsm_txt_gry'>12 Aug 2024 | 5:30PM</p>
                                                    <p className='tl_txt'>Rejected by User Rohit Ghorawat</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                        )}

                    </div>
                )}



            </div>



        </>
    )
}

export default IndentAndBidDetailModal