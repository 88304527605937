import { getPayment } from "_services";
import { utils } from "helper";
import { MakePaymentModal } from "modals";
import React, { useEffect, useState } from "react";
import StorageService from "utils/StorageServices/StorageServices";

const AllDetails = (props) => {
  const { activeTab } = props;
  const [paymentList, setPaymentList] = useState([]);
  const [showMakePayment, setShowMakePayment] = useState(false);
  const [isLoading,setIsLoading] = useState(false)

  const columns = [
    { header: "Date", accessor: "tADate" },
    { header: "Related", accessor: "TripNumber" },
    { header: "Requested User", accessor: "" },
    { header: "Category", accessor: "category" },
    { header: "From Account", accessor: "fromAcc" },
    { header: "To Account", accessor: "toAcc" },
    { header: "Description", accessor: "description" },
    { header: "Receipt", accessor: "credit_amount" },
    { header: "Payment", accessor: "debit_amount" },
    { header: "Actions", accessor: "action" },
  ];

  const accessorList = (data) => {
    const listArray = data?.map((item, i) => ({
      ...item,
      serial: i + 1,
      tADate: (
        <>
          {" "}
          <div className="_dateNtime"> <span className="_tblText">{utils.formatDateMonth(item?.date)}</span></div>
        </>
      ),
      transactionType: (
        <>
          <div>
            {item?.sub_type_name} | {item?.type_name}
          </div>
        </>
      ),
      TripNumber: <>{`Trip-#${item?.trip?.trip_number}`}</>,
      category: (
        <>
          {" "}
          <div>
            {item?.sub_type_name} | {item?.type_name}
          </div>
        </>
      ),
      fromAcc: (
        <>
          {" "}
          <div>
            {item?.account_sub_category_name} | {item?.account_name}
          </div>
        </>
      ),
      toAcc: (
        <>
          {" "}
          <div>{item?.trip?.supplier_id?.name}</div>
        </>
      ),
      action: (
        <>
          <button onClick={(e) => setShowMakePayment(true)}>Approve</button>
          <button>Hold</button>
          <button>Reject</button>
          <button>Attachment (0)</button>
        </>
      ),
    }));
    return listArray;
  };

  useEffect(() => {
    handlePaymentAprovel();
  }, [activeTab]);

  const handlePaymentAprovel = async () => {
    try {
        setIsLoading(true)
      const res = await getPayment({
        company_id: StorageService?.getCompanyId(),
        approval_status: activeTab,
      });

      if (res?.responseCode === 200) {
        setPaymentList(res?.data?.list);
        setIsLoading(false)
    }else{
      setIsLoading(false)
    }
    } catch (error) {
        setIsLoading(false)
    }
  };

  let firstMakePayment = {
    isOpen: showMakePayment,
    closeModal: (e) => setShowMakePayment(false),
    headerPart: false,
    footerPart: true,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Make Payment",
    submitTitle: "Add Freight",
    cancelButtonTitle: "Send for Approval",
  };
  return (
    <div className="_transactionTable">
      <CustomeTable
        columns={columns}
        data={accessorList(paymentList?.length ? paymentList : [])}
        isLoading={isLoading}
      />

      {showMakePayment && (
        // <AddBankAccount {...firstMakePayment} />
        <MakePaymentModal {...firstMakePayment} />
      )}
    </div>
  );
};

export default AllDetails;

const CustomeTable = ({ columns, data, isLoading }) => {
  
    return (
      <table className="">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.header}</th>
            ))}
  
            <th></th>
          </tr>
        </thead>
  
        <tbody>
          {!isLoading ? (
            <>
              {" "}
              {data.length > 0 ? (
                data?.map((row, rowIndex) => {
                  return (
                    <tr key={rowIndex}>
                      {columns.map((column, colIndex) => (
                        <td key={colIndex}>{row[column.accessor]}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No data</td>
                </tr>
              )}
            </>
          ) : (
            <>
              <tr>
                <td>Loadding...</td>
              </tr>
              ;
            </>
          )}
        </tbody>
      </table>
    );
  };
