import React from "react";

const CommonButton = ({onClick,buttonTitle,className,type,disabled}) => {
  return (
    <button type={type} className={className} onClick={onClick} disabled={disabled}>
      {buttonTitle}
    </button>
  );
};

export default CommonButton;
