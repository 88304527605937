import React, { useRef, useState } from 'react'
import { ButtonHeading, FuelStationDetailsTab } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import LoadingBar from 'react-top-loading-bar';
import Ledger from '../Ledger';
import FuelStatement from '../FuelStatement';

const FuelStationDetails = () => {

    const [activeTab, setActiveTab] = useState(1);
    const [fuelData, setFuelData] = useState(null)
    const loadingBarRef = useRef(null)

    const tabProps = {
        tabList: constant.fuel_StationDetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    }

    const onDataFetch = (data) => {
        setFuelData(data)
    }

    return (
        <div className=''>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <ButtonHeading title={fuelData?.name} buttonTitle="Edit" />

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && (
                    <FuelStationDetailsTab onDataFetch={onDataFetch} loadingBarRef={loadingBarRef} />
                )}
                {activeTab === 2 && <Ledger type={5} />}
                {activeTab === 3 && <FuelStatement />}
            </div>

        </div>
    )
}

export default FuelStationDetails;