import React, { useState } from 'react'
import { BranchDetailsTab, ButtonHeading } from 'components';
import { constant } from 'constants/constant';
import Tab from 'components/common/Tab';
import BranchOtherDetailsTab from 'components/MastersDetailsTabs/BranchDetailsTabs/BranchOtherDetailsTab';

const BranchDetails = () => {

    const [activeTab, setActiveTab] = useState(1);
    const [branchData, setBranchData ]= useState(null)

    const tabProps = {
        tabList: constant.consigneeDetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    }

    const onDataFetch = (data) => {
        setBranchData(data)
    }

    return (
        <div className=''>
            <ButtonHeading title={branchData?.branch} buttonTitle="Edit" />

            <div className='addPr_flBx'>
                <Tab {...tabProps} />
                {activeTab === 1 && (
                    <BranchDetailsTab onDataFetch={onDataFetch} />
                )}
                {activeTab === 2 && (
                    <BranchOtherDetailsTab onDataFetch={onDataFetch} />
                )}
            </div>
        </div>
    )
}

export default BranchDetails;