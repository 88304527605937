
import { Heading } from 'components'
import { Images } from 'constants'
import React from 'react'

const SelectCompany = () => {
  return (
    <div>
      <Heading title={"Select Company"}/>
      <img src={Images.Logo} height={50} width={50} />
    </div>
  )
}

export default SelectCompany