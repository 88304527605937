import { getMasterData } from '_services';
import { CustomSelect, CustomTable, Customdropdown, Heading } from 'components';
import { UserContext } from 'context';
import { API, axiosInstance } from 'lib';
import React, { useEffect, useState, useContext, useMemo } from 'react';

const Testing = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const postData = {};

        try {
            let response = await getMasterData(postData);
            console.log('res==>>', response);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching master data:', error);
            setError(error);
        } finally {
            setLoading(false);
        }

    };


    const { cities } = useContext(UserContext);
    const [filteredCities, setFilteredCities] = useState([]);
    const [city, setCity] = useState([]);
    useEffect(() => {
        setFilteredCities(cities);
    }, [])



    const [selectedCity, setSelectedCity] = useState(null);


    const cities_new = [
        { label: 'New York', value: 'ny' },
        { label: 'Los Angeles', value: 'la' },
        { label: 'Chicago', value: 'ch' },
        { label: 'Houston', value: 'ho' },
        { label: 'Phoenix', value: 'ph' }
    ];

    const handleCitySelect = (city) => {
        setSelectedCity(city);
        console.log('Selected city:', city);
    };
    const handleCityChange = (city) => {
        setCity(city);
        console.log('Selected city:', city);
    };

    const SimpleColumns1 = ['Name', 'Age', 'Address',];
  
    const SimpleData1 = [
        {
            Name: 'John Doe',
            Age: 28,
            Address: `<div><p>123 Main St</p><p>Indore</p></div>`,
        },
        {
            Name: 'Jane Doe',
            Age: 34,
            Address: `<div><p>456 Maple Ave</p><p>jaipur</p></div>`,

        },
        {
            Name: 'Alice Johnson',
            Age: 23,
            Address: `<div><p>789 Oak St</p><p>delhi</p></div>`,

        },
    ];



    const filterableColumns1 = useMemo(() => ['Name', 'Age'], []); // Only 'Name' column is filterable for first table



    return (
        <>
            {/* <h1>Post Request Testing</h1>
            <form onSubmit={handleSubmit}>
                <button type="submit" disabled={loading}>
                    {loading ? 'Loading...' : 'Submit'}
                </button>
            </form> */}

            {/* <div className='mt-5 px-4' style={{ width: "350px" }}>
                <Customdropdown />
            </div> */}

            {/* {error && <div style={{ color: 'red' }}>Error: {error.message}</div>}
            {data && (
                <div>
                    <h2>Response Data:</h2>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
            )} */}

            {/* <div className="">
                <h1>Custom City</h1>
                <CustomSelect
                    options={cities}
                    onSelect={handleCityChange}
                    placeholder="Select a city"
                />
                {city && (
                    <div>
                        <h2>Selected City</h2>
                        <p>{city.label}</p>
                    </div>
                )}
            </div>
            <div className="">
                <h1>Custom Select Component</h1>
                <CustomSelect
                    options={cities_new}
                    onSelect={handleCitySelect}
                    placeholder="Select a city"
                />
                {selectedCity && (
                    <div>
                        <h2>Selected City</h2>
                        <p>{selectedCity.label}</p>
                    </div>
                )}
            </div> */}

            <Heading title={"Testing Datatable"} />
            <div className='filter-container'>
                <CustomTable columns={SimpleColumns1} data={SimpleData1} filterableColumns={filterableColumns1} isMasterFilter={true} />
            </div>


        </>
    );
};

export default Testing;
