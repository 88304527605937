import { Heading } from 'components';
import React, { useState } from 'react';
import { constant } from 'constants/constant';
import { FaStar } from 'react-icons/fa6';
import bidderImg from "../../../../src/assets/img/bidderImg.png"
import { IoCall } from 'react-icons/io5';

const BidsRecieved = () => {

    const [activeTab, setActiveTab] = useState(1);


    return (
        <>
            <Heading title='Bids Received' />

            <div>
                <div className='addPr_flBx _indentView'>
                    <ul className="nav nav-tabs cstNew_tabs px-0" id="addParty" role="tablist">
                        {constant.bidsStatusTabs?.map((item, indx) => (
                            <li
                                className="nav-item"
                                role="presentation"
                                key={indx + item?.value}
                            >
                                <button
                                    className={
                                        "nav-link " + (activeTab === item?.id ? "active" : "")
                                    }
                                    type="button"
                                    aria-selected={activeTab === item?.id}
                                    onClick={(e) => setActiveTab(item.id)}
                                >
                                    <span className='_tbTitle'>{item?.title}</span>
                                    <span className='_counts'>6</span>

                                </button>
                            </li>
                        ))}
                    </ul>

                    {activeTab === 1 && (
                        <div className='_myBid_listBx'>
                            <div className='_mrkList_otrBx'>

                                <div className='_rtrBxs'>
                                    <div className='_routDetl'>
                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><circle cx="6" cy="6" r="5" stroke="#22A57A" stroke-width="2"></circle></svg></span>
                                        <div class="_rct_st"><span class="_rtCity">Indore</span><span class="_rtState"> <span> | </span>Madhya Pradesh</span></div>
                                    </div>

                                    <div className='_routDetl'>
                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><g clip-path="url(#clip0_7368_6226)"><path d="M11.0853 2.73106C10.1198 1.27452 8.75268 0.388306 6.98397 0.0853333C6.81846 0.0571499 6.64768 0.0567584 6.48744 0C6.14222 0 5.79659 0 5.45137 0C5.44204 0.034055 5.41242 0.0274006 5.38727 0.031315C5.13211 0.071633 4.87532 0.0986422 4.6234 0.158141C3.10824 0.51748 1.89449 1.31445 1.03042 2.56C-0.00929966 4.05764 -0.249454 5.69463 0.252355 7.43261C0.585406 8.58618 1.12251 9.65441 1.7379 10.6858C2.81657 12.4943 4.10943 14.1559 5.51993 15.7346C5.83634 16.0889 6.16656 16.0885 6.48338 15.735C7.48578 14.6167 8.42327 13.4498 9.27517 12.2203C10.1169 11.0053 10.8743 9.74483 11.4252 8.37793C11.7132 7.66356 11.9376 6.93001 11.9871 6.16162C12.0666 4.9282 11.7773 3.77541 11.0853 2.73106ZM6.00145 8.70948C4.33741 8.70909 2.97843 7.39151 2.98533 5.78544C2.99222 4.18055 4.3439 2.87706 6.00186 2.87746C7.6659 2.87785 9.02488 4.19543 9.01798 5.80149C9.01068 7.40639 7.659 8.70987 6.00145 8.70948Z" fill="#D62727"></path></g><defs><clipPath id="clip0_7368_6226"><rect width="12" height="16" fill="white"></rect></clipPath></defs></svg></span>
                                        <div class="_rct_st" ><span class="_rtCity">Jabalpur</span><span class="_rtState"> <span> | </span> Madhya Pradesh</span></div>
                                    </div>
                                </div>

                                <div className='_materialBxs'>
                                    <p class="tsm_txt_gry">Indent Number</p>
                                    <p class="tl_txt"><a href="">3453453</a></p>
                                </div>

                                <div className='_fleerBxs'>
                                    <p class="tsm_txt_gry">Fleet Type</p>
                                    <p class="tl_txt">Container &gt; 32 Feet XXL</p>
                                </div>

                                <div className='_materialBxs'>
                                    <p class="tsm_txt_gry">Material</p>
                                    <p class="tl_txt">Biscuit</p>
                                </div>

                                <div className='_payTerms_bxs'>
                                    <p class="tsm_txt_gry">Payment Terms</p>
                                    <p class="tl_txt">Advance 10%</p>
                                </div>

                                <div className='_whtBxs'>
                                    <p class="tsm_txt_gry">Weight</p>
                                    <p class="tl_txt">15 Ton</p>
                                </div>

                                <div className='_peDate'>
                                    <p class="tsm_txt_gry">Plan Date</p>
                                    <p class="tl_txt">23 Aug 24</p>
                                </div>

                                <div className='_peDate'>
                                    <p class="tsm_txt_gry">Expiry Date</p>
                                    <p class="tl_txt">23 Aug 24</p>
                                </div>

                                <div className='_plc_Nbid'>
                                    <div className='_myPlcBix'>
                                        <div className='_bidR_bxs'>
                                            <div className='__bidR_usrBx'>
                                                <span className='_bidR_verifyIcon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                    </svg>
                                                </span>
                                                <img src={bidderImg} alt="" />

                                                <div className='_ratingBx'>
                                                    <FaStar />
                                                    <span>4.5</span>
                                                </div>
                                            </div>
                                            <div className='w-100'>
                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                            </div>
                                        </div>

                                        <div class="_bidStatus_bxs" ><span class="_pending_bidSts">Pending</span></div>

                                        <div class="_dobBid" ><p class="tsm_txt_gry">Bid on</p><p class="tl_txt">12/8/2024</p></div>


                                        <div class="_rateBid" ><p class="tsm_txt_gry">Amount</p><p class="tl_txt">Rs. 5000.00</p></div>
                                    </div>

                                    <div className='_actionOtr_bx'>
                                        <button className='_callBtn'>
                                            <IoCall />
                                        </button>

                                        <div className='_actionInr_bx'>
                                            <button className=' _acceptSts'>Accept</button>
                                            <button className=' _rejectSts'>Reject</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='_mrkList_otrBx'>

                                <div className='_rtrBxs'>
                                    <div className='_routDetl'>
                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><circle cx="6" cy="6" r="5" stroke="#22A57A" stroke-width="2"></circle></svg></span>
                                        <div class="_rct_st"><span class="_rtCity">Indore</span><span class="_rtState"> <span> | </span>Madhya Pradesh</span></div>
                                    </div>

                                    <div className='_routDetl'>
                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><g clip-path="url(#clip0_7368_6226)"><path d="M11.0853 2.73106C10.1198 1.27452 8.75268 0.388306 6.98397 0.0853333C6.81846 0.0571499 6.64768 0.0567584 6.48744 0C6.14222 0 5.79659 0 5.45137 0C5.44204 0.034055 5.41242 0.0274006 5.38727 0.031315C5.13211 0.071633 4.87532 0.0986422 4.6234 0.158141C3.10824 0.51748 1.89449 1.31445 1.03042 2.56C-0.00929966 4.05764 -0.249454 5.69463 0.252355 7.43261C0.585406 8.58618 1.12251 9.65441 1.7379 10.6858C2.81657 12.4943 4.10943 14.1559 5.51993 15.7346C5.83634 16.0889 6.16656 16.0885 6.48338 15.735C7.48578 14.6167 8.42327 13.4498 9.27517 12.2203C10.1169 11.0053 10.8743 9.74483 11.4252 8.37793C11.7132 7.66356 11.9376 6.93001 11.9871 6.16162C12.0666 4.9282 11.7773 3.77541 11.0853 2.73106ZM6.00145 8.70948C4.33741 8.70909 2.97843 7.39151 2.98533 5.78544C2.99222 4.18055 4.3439 2.87706 6.00186 2.87746C7.6659 2.87785 9.02488 4.19543 9.01798 5.80149C9.01068 7.40639 7.659 8.70987 6.00145 8.70948Z" fill="#D62727"></path></g><defs><clipPath id="clip0_7368_6226"><rect width="12" height="16" fill="white"></rect></clipPath></defs></svg></span>
                                        <div class="_rct_st" ><span class="_rtCity">Jabalpur</span><span class="_rtState"> <span> | </span> Madhya Pradesh</span></div>
                                    </div>
                                </div>

                                <div className='_fleerBxs'>
                                    <p class="tsm_txt_gry">Fleet Type</p>
                                    <p class="tl_txt">Container &gt; 32 Feet XXL</p>
                                </div>

                                <div className='_materialBxs'>
                                    <p class="tsm_txt_gry">Material</p>
                                    <p class="tl_txt">Biscuit</p>
                                </div>

                                <div className='_payTerms_bxs'>
                                    <p class="tsm_txt_gry">Payment Terms</p>
                                    <p class="tl_txt">Advance 10%</p>
                                </div>

                                <div className='_whtBxs'>
                                    <p class="tsm_txt_gry">Weight</p>
                                    <p class="tl_txt">15 Ton</p>
                                </div>

                                <div className='_peDate'>
                                    <p class="tsm_txt_gry">Plan Date</p>
                                    <p class="tl_txt">23 Aug 24</p>
                                </div>

                                <div className='_peDate'>
                                    <p class="tsm_txt_gry">Expiry Date</p>
                                    <p class="tl_txt">23 Aug 24</p>
                                </div>

                                <div className='_plc_Nbid'>
                                    <div className='_myPlcBix'>
                                        <div className='_bidR_bxs'>
                                            <div className='__bidR_usrBx'>
                                                <span className='_bidR_verifyIcon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                    </svg>
                                                </span>
                                                <img src={bidderImg} alt="" />

                                                <div className='_ratingBx'>
                                                    <FaStar />
                                                    <span>4.5</span>
                                                </div>
                                            </div>
                                            <div className='w-100'>
                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                            </div>
                                        </div>

                                        <div class="_bidStatus_bxs"><span class="_accepted_bidSts">Accepted</span></div>

                                        <div class="_dobBid" ><p class="tsm_txt_gry">Bid on</p><p class="tl_txt">12/8/2024</p></div>

                                        <div class="_rateBid" ><p class="tsm_txt_gry">Amount</p><p class="tl_txt">Rs. 5000.00</p></div>
                                    </div>

                                    <div className='_actionOtr_bx'>
                                        <button className='_callBtn'>
                                            <IoCall />
                                        </button>

                                        <div className='_actionInr_bx'>
                                            <button className=' _rejectSts'>Reject</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='_mrkList_otrBx'>

                                <div className='_rtrBxs'>
                                    <div className='_routDetl'>
                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><circle cx="6" cy="6" r="5" stroke="#22A57A" stroke-width="2"></circle></svg></span>
                                        <div class="_rct_st"><span class="_rtCity">Indore</span><span class="_rtState"> <span> | </span>Madhya Pradesh</span></div>
                                    </div>

                                    <div className='_routDetl'>
                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><g clip-path="url(#clip0_7368_6226)"><path d="M11.0853 2.73106C10.1198 1.27452 8.75268 0.388306 6.98397 0.0853333C6.81846 0.0571499 6.64768 0.0567584 6.48744 0C6.14222 0 5.79659 0 5.45137 0C5.44204 0.034055 5.41242 0.0274006 5.38727 0.031315C5.13211 0.071633 4.87532 0.0986422 4.6234 0.158141C3.10824 0.51748 1.89449 1.31445 1.03042 2.56C-0.00929966 4.05764 -0.249454 5.69463 0.252355 7.43261C0.585406 8.58618 1.12251 9.65441 1.7379 10.6858C2.81657 12.4943 4.10943 14.1559 5.51993 15.7346C5.83634 16.0889 6.16656 16.0885 6.48338 15.735C7.48578 14.6167 8.42327 13.4498 9.27517 12.2203C10.1169 11.0053 10.8743 9.74483 11.4252 8.37793C11.7132 7.66356 11.9376 6.93001 11.9871 6.16162C12.0666 4.9282 11.7773 3.77541 11.0853 2.73106ZM6.00145 8.70948C4.33741 8.70909 2.97843 7.39151 2.98533 5.78544C2.99222 4.18055 4.3439 2.87706 6.00186 2.87746C7.6659 2.87785 9.02488 4.19543 9.01798 5.80149C9.01068 7.40639 7.659 8.70987 6.00145 8.70948Z" fill="#D62727"></path></g><defs><clipPath id="clip0_7368_6226"><rect width="12" height="16" fill="white"></rect></clipPath></defs></svg></span>
                                        <div class="_rct_st" ><span class="_rtCity">Jabalpur</span><span class="_rtState"> <span> | </span> Madhya Pradesh</span></div>
                                    </div>
                                </div>

                                <div className='_fleerBxs'>
                                    <p class="tsm_txt_gry">Fleet Type</p>
                                    <p class="tl_txt">Container &gt; 32 Feet XXL</p>
                                </div>

                                <div className='_materialBxs'>
                                    <p class="tsm_txt_gry">Material</p>
                                    <p class="tl_txt">Biscuit</p>
                                </div>

                                <div className='_payTerms_bxs'>
                                    <p class="tsm_txt_gry">Payment Terms</p>
                                    <p class="tl_txt">Advance 10%</p>
                                </div>

                                <div className='_whtBxs'>
                                    <p class="tsm_txt_gry">Weight</p>
                                    <p class="tl_txt">15 Ton</p>
                                </div>

                                <div className='_peDate'>
                                    <p class="tsm_txt_gry">Plan Date</p>
                                    <p class="tl_txt">23 Aug 24</p>
                                </div>

                                <div className='_peDate'>
                                    <p class="tsm_txt_gry">Expiry Date</p>
                                    <p class="tl_txt">23 Aug 24</p>
                                </div>

                                <div className='_plc_Nbid'>
                                    <div className='_myPlcBix'>
                                        <div className='_bidR_bxs'>
                                            <div className='__bidR_usrBx'>
                                                <span className='_bidR_verifyIcon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                    </svg>
                                                </span>
                                                <img src={bidderImg} alt="" />

                                                <div className='_ratingBx'>
                                                    <FaStar />
                                                    <span>4.5</span>
                                                </div>
                                            </div>
                                            <div className='w-100'>
                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                            </div>
                                        </div>

                                        <div class="_bidStatus_bxs" ><span class="_rejected_bidSts">Rejected</span></div>

                                        <div class="_dobBid" ><p class="tsm_txt_gry">Bid on</p><p class="tl_txt">12/8/2024</p></div>

                                        <div class="_rateBid" ><p class="tsm_txt_gry">Amount</p><p class="tl_txt">Rs. 5000.00</p></div>

                                        <div class="_rateBid" ><p class="tsm_txt_gry">Reason</p><p class="tl_txt">Rejected by Party</p></div>
                                    </div>

                                </div>

                            </div>

                            <div className='_mrkList_otrBx'>

                                <div className='_rtrBxs'>
                                    <div className='_routDetl'>
                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><circle cx="6" cy="6" r="5" stroke="#22A57A" stroke-width="2"></circle></svg></span>
                                        <div class="_rct_st"><span class="_rtCity">Indore</span><span class="_rtState"> <span> | </span>Madhya Pradesh</span></div>
                                    </div>

                                    <div className='_routDetl'>
                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><g clip-path="url(#clip0_7368_6226)"><path d="M11.0853 2.73106C10.1198 1.27452 8.75268 0.388306 6.98397 0.0853333C6.81846 0.0571499 6.64768 0.0567584 6.48744 0C6.14222 0 5.79659 0 5.45137 0C5.44204 0.034055 5.41242 0.0274006 5.38727 0.031315C5.13211 0.071633 4.87532 0.0986422 4.6234 0.158141C3.10824 0.51748 1.89449 1.31445 1.03042 2.56C-0.00929966 4.05764 -0.249454 5.69463 0.252355 7.43261C0.585406 8.58618 1.12251 9.65441 1.7379 10.6858C2.81657 12.4943 4.10943 14.1559 5.51993 15.7346C5.83634 16.0889 6.16656 16.0885 6.48338 15.735C7.48578 14.6167 8.42327 13.4498 9.27517 12.2203C10.1169 11.0053 10.8743 9.74483 11.4252 8.37793C11.7132 7.66356 11.9376 6.93001 11.9871 6.16162C12.0666 4.9282 11.7773 3.77541 11.0853 2.73106ZM6.00145 8.70948C4.33741 8.70909 2.97843 7.39151 2.98533 5.78544C2.99222 4.18055 4.3439 2.87706 6.00186 2.87746C7.6659 2.87785 9.02488 4.19543 9.01798 5.80149C9.01068 7.40639 7.659 8.70987 6.00145 8.70948Z" fill="#D62727"></path></g><defs><clipPath id="clip0_7368_6226"><rect width="12" height="16" fill="white"></rect></clipPath></defs></svg></span>
                                        <div class="_rct_st" ><span class="_rtCity">Jabalpur</span><span class="_rtState"> <span> | </span> Madhya Pradesh</span></div>
                                    </div>
                                </div>

                                <div className='_fleerBxs'>
                                    <p class="tsm_txt_gry">Fleet Type</p>
                                    <p class="tl_txt">Container &gt; 32 Feet XXL</p>
                                </div>

                                <div className='_materialBxs'>
                                    <p class="tsm_txt_gry">Material</p>
                                    <p class="tl_txt">Biscuit</p>
                                </div>

                                <div className='_payTerms_bxs'>
                                    <p class="tsm_txt_gry">Payment Terms</p>
                                    <p class="tl_txt">Advance 10%</p>
                                </div>

                                <div className='_whtBxs'>
                                    <p class="tsm_txt_gry">Weight</p>
                                    <p class="tl_txt">15 Ton</p>
                                </div>

                                <div className='_peDate'>
                                    <p class="tsm_txt_gry">Plan Date</p>
                                    <p class="tl_txt">23 Aug 24</p>
                                </div>

                                <div className='_peDate'>
                                    <p class="tsm_txt_gry">Expiry Date</p>
                                    <p class="tl_txt">23 Aug 24</p>
                                </div>

                                <div className='_plc_Nbid'>
                                    <div className='_myPlcBix'>
                                        <div className='_bidR_bxs'>
                                            <div className='__bidR_usrBx'>
                                                <span className='_bidR_verifyIcon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                    </svg>
                                                </span>
                                                <img src={bidderImg} alt="" />

                                                <div className='_ratingBx'>
                                                    <FaStar />
                                                    <span>4.5</span>
                                                </div>
                                            </div>
                                            <div className='w-100'>
                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                            </div>
                                        </div>

                                        <div class="_bidStatus_bxs" ><span class="_closed_bidSts">Closed</span></div>

                                        <div class="_dobBid" ><p class="tsm_txt_gry">Bid on</p><p class="tl_txt">12/8/2024</p></div>

                                        <div class="_rateBid" ><p class="tsm_txt_gry">Amount</p><p class="tl_txt">Rs. 5000.00</p></div>

                                        <div class="_rateBid" ><p class="tsm_txt_gry">Reason</p><p class="tl_txt">Plan Expired</p></div>
                                    </div>

                                </div>

                            </div>

                            <div className='_mrkList_otrBx'>

                                <div className='_rtrBxs'>
                                    <div className='_routDetl'>
                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><circle cx="6" cy="6" r="5" stroke="#22A57A" stroke-width="2"></circle></svg></span>
                                        <div class="_rct_st"><span class="_rtCity">Indore</span><span class="_rtState"> <span> | </span>Madhya Pradesh</span></div>
                                    </div>

                                    <div className='_routDetl'>
                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><g clip-path="url(#clip0_7368_6226)"><path d="M11.0853 2.73106C10.1198 1.27452 8.75268 0.388306 6.98397 0.0853333C6.81846 0.0571499 6.64768 0.0567584 6.48744 0C6.14222 0 5.79659 0 5.45137 0C5.44204 0.034055 5.41242 0.0274006 5.38727 0.031315C5.13211 0.071633 4.87532 0.0986422 4.6234 0.158141C3.10824 0.51748 1.89449 1.31445 1.03042 2.56C-0.00929966 4.05764 -0.249454 5.69463 0.252355 7.43261C0.585406 8.58618 1.12251 9.65441 1.7379 10.6858C2.81657 12.4943 4.10943 14.1559 5.51993 15.7346C5.83634 16.0889 6.16656 16.0885 6.48338 15.735C7.48578 14.6167 8.42327 13.4498 9.27517 12.2203C10.1169 11.0053 10.8743 9.74483 11.4252 8.37793C11.7132 7.66356 11.9376 6.93001 11.9871 6.16162C12.0666 4.9282 11.7773 3.77541 11.0853 2.73106ZM6.00145 8.70948C4.33741 8.70909 2.97843 7.39151 2.98533 5.78544C2.99222 4.18055 4.3439 2.87706 6.00186 2.87746C7.6659 2.87785 9.02488 4.19543 9.01798 5.80149C9.01068 7.40639 7.659 8.70987 6.00145 8.70948Z" fill="#D62727"></path></g><defs><clipPath id="clip0_7368_6226"><rect width="12" height="16" fill="white"></rect></clipPath></defs></svg></span>
                                        <div class="_rct_st" ><span class="_rtCity">Jabalpur</span><span class="_rtState"> <span> | </span> Madhya Pradesh</span></div>
                                    </div>
                                </div>

                                <div className='_fleerBxs'>
                                    <p class="tsm_txt_gry">Fleet Type</p>
                                    <p class="tl_txt">Container &gt; 32 Feet XXL</p>
                                </div>

                                <div className='_materialBxs'>
                                    <p class="tsm_txt_gry">Material</p>
                                    <p class="tl_txt">Biscuit</p>
                                </div>

                                <div className='_payTerms_bxs'>
                                    <p class="tsm_txt_gry">Payment Terms</p>
                                    <p class="tl_txt">Advance 10%</p>
                                </div>

                                <div className='_whtBxs'>
                                    <p class="tsm_txt_gry">Weight</p>
                                    <p class="tl_txt">15 Ton</p>
                                </div>

                                <div className='_peDate'>
                                    <p class="tsm_txt_gry">Plan Date</p>
                                    <p class="tl_txt">23 Aug 24</p>
                                </div>

                                <div className='_peDate'>
                                    <p class="tsm_txt_gry">Expiry Date</p>
                                    <p class="tl_txt">23 Aug 24</p>
                                </div>

                                <div className='_plc_Nbid'>
                                    <div className='_myPlcBix'>
                                        <div className='_bidR_bxs'>
                                            <div className='__bidR_usrBx'>
                                                <span className='_bidR_verifyIcon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                    </svg>
                                                </span>
                                                <img src={bidderImg} alt="" />

                                                <div className='_ratingBx'>
                                                    <FaStar />
                                                    <span>4.5</span>
                                                </div>
                                            </div>
                                            <div className='w-100'>
                                                <p className='tl_txt'>Yash Road Carriers</p>
                                                <p className='tsm_txt'>Rajesh Thakur</p>
                                            </div>
                                        </div>

                                        <div class="_bidStatus_bxs" ><span class="_withDrawn_bidSts">Withdrawn</span></div>

                                        <div class="_dobBid" ><p class="tsm_txt_gry">Bid on</p><p class="tl_txt">12/8/2024</p></div>

                                        <div class="_rateBid" ><p class="tsm_txt_gry">Amount</p><p class="tl_txt">Rs. 5000.00</p></div>

                                        <div class="_rateBid" ><p class="tsm_txt_gry">Reason</p><p class="tl_txt">Request Withdrawn </p></div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    )}

                    {activeTab === 2 && (
                        <div>2</div>
                    )}

                    {activeTab === 3 && (
                        <div>3</div>
                    )}

                </div>
            </div>
        </>
    )
}

export default BidsRecieved