import React from 'react';

export default function RupeeIcon({ size = 24, color = 'currentColor' }) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke={color} 
            width={size} 
            height={size}
        >
            <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" 
            />
        </svg>
    );
}
