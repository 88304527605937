import React, { useContext, useEffect, useState } from 'react'
import { errorSchema, utils } from 'helper';
import { CommonInput, CommonModal, CustomSelect } from 'components'
import StorageService from 'utils/StorageServices/StorageServices';
import toast, { Toaster } from 'react-hot-toast';
import { createMaterialCategory } from '_services/materialServices/materialCategoryServices';
import { UserContext } from 'context';

const formObj = {
    name: '',
    hsn_code: '',
    company_id: StorageService.getCompanyId(),
}

const AddItemGroupModal = (props) => {
    const { masterData, cities, modalZIndex, setModalZIndex } = useContext(UserContext);
    const { isOpen, previousModal, closeModal, isCurrentModal } = props;
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addItemGroupInLrSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async () => {
        try {
            const res = await createMaterialCategory(formValue);
            if (!!res) {
                if (isOpen?.nextView && isOpen?.previousView) {
                    previousModal(true);
                    setModalZIndex("");
                    isCurrentModal(false);
                } else {
                    isCurrentModal(false);
                }
                setFormValue({});
                setLoading(false);
                toast.success('Item Group Created Successfully');
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleClick = async (e) => {
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.addItemGroupInLrSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            apiCallingFunction();
            setFormError("");

        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    }

    let inhanceProps = {
        ...props,
        submitTitle: loading ? 'Loading...' : 'Add Item Group',
        disabled: loading,
        onSubmit: handleClick,
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            company_id: StorageService.getCompanyId()
        }))
    }, [StorageService.getCompanyId()])

    return (
        <div>
            <CommonModal {...inhanceProps}>
                <div className='addItem_body'>
                    <div className='colFlx'>
                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                label="Item Group Name* "
                                placeholder="Item Group Name* "
                                name='name'
                                value={formValue.name}
                                focus={!!(typeof formError === "object" && formError?.name)}
                                error={!!(typeof formError === "object") ? formError?.name : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='singleDiv'>
                            <CommonInput
                                type="number"
                                label="HSN Code (Code) "
                                placeholder="HSN Code (Code) "
                                name='hsn_code'
                                maxLength="6"
                                onInput={maxLengthCheck}
                                value={formValue.hsn_code}
                                focus={!!(typeof formError === "object" && formError?.hsn_code)}
                                error={!!(typeof formError === "object") ? formError?.hsn_code : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </CommonModal>
            <div className='offBack_drop'></div>
        </div>
    )
}

export default AddItemGroupModal
