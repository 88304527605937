import React, { useEffect, useState } from 'react'
import { CommonModal } from 'components'
import { getLrSeriesListData, deleteLrSeriesData, setLrSeriesData } from '_services/accountsServices/lrSeriesServices'
import StorageService from 'utils/StorageServices/StorageServices'
import AddNewSeriesModal from 'modals/AddNewSeriesModal'
import { fetchLrNumberData } from '_services/accountsServices/lrServices'

const LrSeriesModal = (props) => {

    let { formData, nextModal, closeModal, setLrSeries, setIsEditLR , setIsLrNumber} = props;
    const [formValue, setFormValue] = useState({
        select_series: '',
    })
    const [loading, setLoading] = useState(false);
    const [isLrSeriesList, setIsLrSeriesList] = useState([]);

    const modalHandler = (e) => {
        e.preventDefault();
        closeModal();
        nextModal();
    }

    const editCaseHandle = (el) => {
        setIsEditLR(el);
        closeModal();
        nextModal();
    }

    const handleSubmit = () => {
        closeModal();
    }

    useEffect(() => {
        if (isLrSeriesList?.length > 0) {
            setFormValue((s) => ({
                ...s,
                select_series: isLrSeriesList?.filter((el) => (el?.set_as_default ? el : ''))[0]._id,
            }))
        }
    }, [isLrSeriesList])

    let handleProps = {
        ...props,
        previosModal: formValue?.select_series,
        onSubmit: (e) => closeModal(false),
    }

    const getDataLrNumber = async (id) => {
        try {
            let object = {
                branch_id: id,
            }
            const res = await fetchLrNumberData(object);
            if (res?.responseCode == 200) {
                setIsLrNumber(res?.data?.lr_number)
            }
        } catch (error) {
            throw error;
        }
    }

    const fetchLrSeriesDataList = async (branchId) => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                branch_id: branchId,
            }
            const res = await getLrSeriesListData(object);
            if (res?.responseCode == 200) {
                setIsLrSeriesList(res?.data?.lr_series)
            }
        } catch (error) {
            // throw error;
        }
    }

    useEffect(() => {
        if (formData?.branch_id?.value) {
            fetchLrSeriesDataList(formData?.branch_id?.value);
        }
    }, [formData?.branch_id?.value])

    const deleteLrSeriesItem = async (id) => {
        try {
            let object = {
                series_id: id,
            }
            const res = await deleteLrSeriesData(object);
            if (res?.responseCode == 200) {
                setLoading(false);
                fetchLrSeriesDataList(formData?.branch_id?.value);
            }
        } catch (error) {
            setLoading(false);
            // throw error;
        }
    }

    const handleDeleteLrSeries = async (series_id) => {
        setLoading(true);
        deleteLrSeriesItem(series_id);
    }

    const callSetLrSeriesData = async (isSetLrSeries) => {
        try {
            let object = {
                series_id: isSetLrSeries,
            }
            const res = await setLrSeriesData(object);
            if (res?.responseCode == 200) {
                fetchLrSeriesDataList(formData?.branch_id?.value);
                getDataLrNumber(formData?.branch_id?.value);
            }
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        if(formData?.branch_id?.value){
            getDataLrNumber(formData?.branch_id?.value)
        }
    }, [formData?.branch_id?.value])

    return (
        <div>
            <CommonModal {...handleProps}>
                <div className='lrSeries_body'>
                    <div className='lrSeries_head'>
                        <p>Continue auto-generating number</p>

                        <button className='addNew_seriesBtn' onClick={(e) => modalHandler(e)}>
                            <div className='_serFx'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.57124 0.257203C6.66509 -0.0857342 9.78738 -0.0857342 12.8812 0.257203C14.5942 0.449203 15.9762 1.7982 16.1772 3.5172C16.5442 6.65518 16.5442 9.82523 16.1772 12.9632C15.9762 14.6822 14.5942 16.0312 12.8812 16.2232C9.78738 16.5662 6.66509 16.5662 3.57124 16.2232C1.85824 16.0312 0.476236 14.6822 0.275236 12.9632C-0.0917452 9.82556 -0.0917452 6.65584 0.275236 3.5182C0.376903 2.68307 0.757615 1.90675 1.35567 1.31505C1.95372 0.723347 2.73407 0.350947 3.57024 0.258203M8.22624 3.2472C8.42515 3.2472 8.61591 3.32622 8.75657 3.46687C8.89722 3.60752 8.97624 3.79829 8.97624 3.9972V7.4902H12.4692C12.6681 7.4902 12.8589 7.56922 12.9996 7.70987C13.1402 7.85052 13.2192 8.04129 13.2192 8.2402C13.2192 8.43912 13.1402 8.62988 12.9996 8.77053C12.8589 8.91119 12.6681 8.9902 12.4692 8.9902H8.97624V12.4832C8.97624 12.6821 8.89722 12.8729 8.75657 13.0135C8.61591 13.1542 8.42515 13.2332 8.22624 13.2332C8.02732 13.2332 7.83656 13.1542 7.69591 13.0135C7.55525 12.8729 7.47624 12.6821 7.47624 12.4832V8.9902H3.98324C3.78432 8.9902 3.59356 8.91119 3.45291 8.77053C3.31225 8.62988 3.23324 8.43912 3.23324 8.2402C3.23324 8.04129 3.31225 7.85052 3.45291 7.70987C3.59356 7.56922 3.78432 7.4902 3.98324 7.4902H7.47624V3.9972C7.47624 3.79829 7.55525 3.60752 7.69591 3.46687C7.83656 3.32622 8.02732 3.2472 8.22624 3.2472Z" fill="#1450EC" />
                                </svg>

                                <span>Add new series</span>
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.37629 5.93771C7.56376 6.12524 7.66907 6.37955 7.66907 6.64471C7.66907 6.90988 7.56376 7.16418 7.37629 7.35171L1.71929 13.0087C1.62704 13.1042 1.5167 13.1804 1.39469 13.2328C1.27269 13.2852 1.14147 13.3128 1.00869 13.314C0.87591 13.3151 0.744231 13.2898 0.621335 13.2395C0.498438 13.1893 0.386786 13.115 0.292893 13.0211C0.199 12.9272 0.124747 12.8156 0.0744663 12.6927C0.0241854 12.5698 -0.00111606 12.4381 3.77571e-05 12.3053C0.00119157 12.1725 0.0287779 12.0413 0.0811869 11.9193C0.133596 11.7973 0.209778 11.687 0.305288 11.5947L5.25529 6.64471L0.305288 1.69471C0.12313 1.50611 0.0223355 1.25351 0.0246139 0.99131C0.0268924 0.729113 0.132061 0.478301 0.317469 0.292893C0.502877 0.107485 0.75369 0.00231622 1.01589 3.78026e-05C1.27808 -0.00224062 1.53069 0.0985537 1.71929 0.280712L7.37629 5.93771Z" fill="#1450EC" />
                            </svg>
                        </button>
                    </div>

                    <div className='serierList_otrBx'>
                        {
                            isLrSeriesList?.length > 0 ? isLrSeriesList?.map((el, ind) => {
                                return (
                                    <div className='_innrSeries_list' key={ind}>
                                        <input
                                            type="radio"
                                            name='select_series'
                                            className='seriesInput'
                                            id={ind + 'series'}
                                            checked={formValue?.select_series === el?._id}
                                            value={formValue?.select_series ? formValue?.select_series : ""}
                                            onChange={(e) => {
                                                callSetLrSeriesData(el?._id);
                                                setLrSeries(el?._id);
                                                setFormValue((s) => ({
                                                    ...s,
                                                    select_series: el?._id
                                                }));
                                            }
                                            }
                                        />
                                        <label htmlFor={ind + 'series'}>
                                            <p>Prefix: {el?.prefix}</p>
                                            <p>Next Number: {el?.next_number}</p>
                                        </label>

                                        <div className='_srActions_bx'>
                                            {/* onClick={(e) => nextModal(el)} */}
                                            <button className='_srEdit_btn' onClick={(e) => editCaseHandle(el)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                    <path d="M4 5.00011H3C2.46957 5.00011 1.96086 5.21082 1.58579 5.58589C1.21071 5.96097 1 6.46967 1 7.00011V16.0001C1 16.5305 1.21071 17.0392 1.58579 17.4143C1.96086 17.7894 2.46957 18.0001 3 18.0001H12C12.5304 18.0001 13.0391 17.7894 13.4142 17.4143C13.7893 17.0392 14 16.5305 14 16.0001V15.0001" stroke="#1956F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M13 3.00011L16 6.00011M17.385 4.58511C17.7788 4.19126 18.0001 3.65709 18.0001 3.10011C18.0001 2.54312 17.7788 2.00895 17.385 1.61511C16.9912 1.22126 16.457 1 15.9 1C15.343 1 14.8088 1.22126 14.415 1.61511L6 10.0001V13.0001H9L17.385 4.58511Z" stroke="#1956F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button>

                                            {el?.set_as_default !== 1 && <button className='_srDel_btn' onClick={(e) => handleDeleteLrSeries(el?._id)} disabled={loading}>
                                                <svg className='_delSvg' xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                                                    <path d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z" fill="#ADADAD" />
                                                </svg>
                                            </button>}
                                        </div>
                                    </div>
                                )
                            }) : (
                                <div className='p-2'>
                                    Loading...
                                </div>
                            )
                        }
                    </div>
                </div>
            </CommonModal>
        </div>
    )
}

export default LrSeriesModal
