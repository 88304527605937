import { Heading } from 'components'
import React from 'react'

const DataTable = () => {
    return (
        <>
            <Heading title={"Data Table"} />

            <div className='filter-container'>


                <div className='anvData_table viewOne'>

                    <div className='filterOtrs'>
                        <div className='_tabUr_bx'>
                            <button className='tabCst_btn _btnActive'> <span >Pending LR</span></button>

                            <button className='tabCst_btn'> <span >Pending LR</span> <span className='_counts'>5</span> </button>

                            <button className='tabCst_btn'> <span >Pending LR</span> <span className='_counts'>5</span> </button>
                        </div>

                        <div className='add_nFilter_bx'>
                            <button className='filterOpen_btn'>
                                <svg className="" focusable="false" aria-hidden="true" viewBox="0 0 24 24" ><path d="M7 6h10l-5.01 6.3zm-2.75-.39C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39c.51-.66.04-1.61-.79-1.61H5.04c-.83 0-1.3.95-.79 1.61"></path></svg>
                            </button>

                            <button className='_addCs_btn'>+ Add LR</button>
                        </div>
                    </div>

                    <div className='datagrid-table'>
                        <div className='_tableFilter_inrFx'>
                            <div className='_filtrFlx'>
                                <input type='date' className='simpleDate_picker' />
                            </div>

                            <div className='_filtrFlx'>
                                <input type="text" className='simpleFilter_input' name="" id="" placeholder='Filter tasks...' />
                            </div>

                            <div className="dropdown _selectorDropdown">
                                <button className="btn  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <path d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM7.50003 4C7.77617 4 8.00003 4.22386 8.00003 4.5V7H10.5C10.7762 7 11 7.22386 11 7.5C11 7.77614 10.7762 8 10.5 8H8.00003V10.5C8.00003 10.7761 7.77617 11 7.50003 11C7.22389 11 7.00003 10.7761 7.00003 10.5V8H4.50003C4.22389 8 4.00003 7.77614 4.00003 7.5C4.00003 7.22386 4.22389 7 4.50003 7H7.00003V4.5C7.00003 4.22386 7.22389 4 7.50003 4Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
                                    </svg>
                                    Status
                                </button>

                                <ul className="dropdown-menu menuAnimate">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className='gridRoot'>
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Employee</th>
                                        <th>User ID</th>
                                        <th>Employee ID</th>
                                        <th>Phone Number (with country code)</th>
                                        <th>Joining Date</th>
                                        <th>Reporting Manager</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='sticky_paginAtion'>
                    <span>0 of 81 row(s) selected.</span>

                </div>
            </div>


        </>
    )
}

export default DataTable