import { CommonModal, CustomSelect } from "components";
import { UserContext } from "context";
import React, { useContext, useState } from "react";

const VehicleTypeModal = (props) => {
  const { lorryType, callBackVehicleType } = props;
  let { setModalZIndex } = useContext(UserContext)

  const [activeTab, setActiveTab] = useState(lorryType[0]);
  const [tyre, setTyre] = useState('')
  const [otpError, setOtpError] = useState(false);
  const [loading, setLoading] = useState(false)

  const changeActiveTab = async (event, value) => {
    event.preventDefault();
    setActiveTab(value);
    setTyre({ label: "", value: "" });
    setOtpError("");
  };

  const handleOnChange = (name, value) => {
    // Allow only numeric values
    if (value?.value) {
      setTyre(value);
      setOtpError("");
    }
  };

  const validateOtp = () => {
    if (!tyre?.value) {
      setOtpError("Tyre is Required");
      return false;
    }
    return true;
  };

  const vehicleSubmit = () => {
    if (validateOtp()) {
      const data = {
        activeTab,
        tyre
      }
      callBackVehicleType(data)
      setModalZIndex('');
    }
  }


  const modifyProps = {
    ...props,
    onSubmit: vehicleSubmit
  }
  return (
    <div>
      <CommonModal {...modifyProps}>
        <div className="addVehicle_body addPr_flBx">
          <ul
            className="nav nav-tabs cstNew_tabs _truckSelection"
            id="addParty"
            role="tablist"
          >
            {lorryType?.length > 0 ? (
              lorryType?.map((item, indx) => (
                <li className="nav-item" role="presentation">
                  <button
                    id={item?.lorry_type}
                    className={
                      "nav-link " + (activeTab?.lorry_type === item?.lorry_type ? "active" : "")
                    }
                    type="button"
                    aria-selected={activeTab?.lorry_type === item?.lorry_type}
                    onClick={(e, val) => changeActiveTab(e, item)}
                  >
                    {item?.lorry_type}
                  </button>
                </li>
              ))
            ) : (
              <p className="p-2 mt-3"> Loading... </p>
            )}
          </ul>

          <div className="_grpDivFx">
            <div className="singleDiv">
              <CustomSelect
                label="Tyre"
                placeholder="Select Tyre"
                options={(activeTab && activeTab?.props?.length > 0 && activeTab?.props[0]?.values?.length > 0) ? activeTab?.props[0]?.values?.map((el) => {
                  return {
                    label: el?.name,
                    value: el?._id
                  }
                }) : []}
                onSelect={(data) => {
                  handleOnChange("tyre", data);
                }}
                value={tyre}
              />
              {otpError && <small className="text-danger">{otpError}</small>}
            </div>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default VehicleTypeModal;
