import React from 'react';
import { CommonModal, CommonTextarea } from 'components';
import { FaStar } from 'react-icons/fa6';
import bidderImg from "../../../src/assets/img/bidderImg.png";

const AddReviewModal = (props) => {

    const reviewProps = {
        ...props
    }


    return (
        <div>
            <CommonModal {...reviewProps}>
                <div className='_wrtReview_bx'>
                    <div className='_bidR_bxs' >
                        <div className='__bidR_usrBx'>
                            <span className='_bidR_verifyIcon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <circle cx="12.1999" cy="12.1999" r="8.34737" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5579 23.1158C13.0757 23.1158 14.5786 22.8168 15.9809 22.236C17.3832 21.6552 18.6573 20.8038 19.7306 19.7306C20.8038 18.6573 21.6552 17.3832 22.236 15.9809C22.8168 14.5786 23.1158 13.0757 23.1158 11.5579C23.1158 10.0401 22.8168 8.53715 22.236 7.13488C21.6552 5.73261 20.8038 4.45848 19.7306 3.38523C18.6573 2.31198 17.3832 1.46063 15.9809 0.879792C14.5786 0.298954 13.0757 -2.2617e-08 11.5579 0C8.49255 4.56772e-08 5.55275 1.2177 3.38523 3.38523C1.2177 5.55275 0 8.49255 0 11.5579C0 14.6232 1.2177 17.563 3.38523 19.7306C5.55275 21.8981 8.49255 23.1158 11.5579 23.1158ZM11.26 16.2324L17.681 8.52716L15.7085 6.88337L10.1864 13.5086L7.32899 10.65L5.51312 12.4658L9.36575 16.3185L10.3597 17.3124L11.26 16.2324Z" fill="#00934C" />
                                </svg>
                            </span>
                            <img src={bidderImg} alt="" />

                            <div className='_ratingBx'>
                                <FaStar />
                                <span>4.5</span>
                            </div>
                        </div>
                        <div className='w-100'>
                            <p className='tl_txt'>Yash Road Carriers</p>
                            <p className='tsm_txt'>Indore, Madhya Pradesh</p>
                        </div>
                    </div>

                    <div className='_dtOtr_txtBx' style={{ marginTop: '30px' }}>
                        <div>
                            <h4>Rating</h4>

                            <div className='_starGrp_bx' >
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                                <FaStar />
                            </div>
                        </div>

                        <div className='mt-1'>
                            <CommonTextarea
                            type="text"
                            label="Write a Review" 
                            placeholder="Write a Review"
                            />
                        </div>
                    </div>
                </div>
            </CommonModal>
            <div className='offBack_drop'></div>
        </div>
    )
}

export default AddReviewModal