import { getAccountList } from "_services";
import { AddButton } from "components/common/CommonButton";
import { UserContext } from "context";
import React, { useContext, useEffect, useState } from "react";
import StorageService from "utils/StorageServices/StorageServices";

const BankListCanvas = (props) => {
  const { globTripDetails,setBankDetails,bankDetails,setModalZIndex } = useContext(UserContext);
  const [bankAccount, setBankAccount] = useState();
 
  return (
    <div>
      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          props?.canvasOpen ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: props?.canvasOpen ? "visible" : "hidden" }}
      >
        {props?.canvasOpen && (
          <>
            <div className="offcanvas-header">
              <h5 className="offcanvas-title"> Select Bank Account </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {props?.setCanvasOpen(!props?.canvasOpen)
                  setModalZIndex("")
                }}
              ></button>
            </div>

            <div className="offcanvas-body">
              <div className="_addBank_dBx">
                <div className="_compNy_nameBx">
                  <div>
                    <h4>{globTripDetails?.supplier_id?globTripDetails?.supplier_id.name:globTripDetails?.driver_id.name}</h4>
                  </div>
                  <button className="_addNewComm_btn" onClick={()=>{props?.setShowAddBank(true)
                  props?.setCanvasOpen(false)}}>Add Account</button>
                </div>

                <div className="_bnkDetail_otrBx">
                  {props?.accountList?.map((i)=>{
                    return <>
                    {!i?.upi_id?<div className={`_bnkDetail_bx ${i?._id===bankDetails?._id?"selectedBxs":""}`} onClick={()=>{
                      setBankDetails(i)
                      setBankAccount(i)}}>
                    <p>{i?.bank_name}</p>
                    <h4>{i?.account_name}</h4>
                    <p>{`A/C No- ${i?.account_number}`}</p>
                    <p>{`IFSC- ${i?.ifsc_code}`}</p>
                  </div>:<div className={`_bnkDetail_bx ${i?._id===bankDetails?._id?"selectedBxs":""}`} onClick={()=>{
                      setBankDetails(i)
                      setBankAccount(i)}}>
                    <p>{`UPI-ID - ${i?.upi_id}`}</p>
                  </div>}
                    </>
                  })}

                  {/* <div className="_bnkDetail_bx">
                    <p>Bank of Maharashtra</p>
                    <h4>AJAY RITESH VERMA</h4>
                    <p>A/C No- 882610110008117</p>
                    <p>IFSC- BKID0008826</p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="offcanvas-footer">
              <AddButton
                name="Select"
                buttonTitle="Select"
                onClick={() => {
                  setModalZIndex('')
                  props?.setCanvasOpen(false)}}
              />

              {/* <button className='_addNewComm_btn'>Add & New</button> */}
            </div>
          </>
        )}
      </div>
      <div className={props?.canvasOpen ? "offBack_drop" : ""}></div>
    </div>
  );
};

export default BankListCanvas;
