import { ButtonHeading } from 'components'
import { Images } from 'constants'
import React from 'react'
import { IoChevronForward } from 'react-icons/io5'

const TripReports = () => {
  return (
    <>
      <ButtonHeading title="Reports"
      // onClick={{}} 
      // buttonTitle="Reports Sharing" 
      />

      <section className='__categories-container'>
        <div className='_section-categories'>
          <div className='__categories-titleBx'>
            <img src={Images.FAVOURITICON.default} className='me-2' alt="" />
            <div className='__category-title'>Favourite</div>
          </div>

          <div className='__categoriesOtr'>
            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GST Purchase (With HSN) </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.STARICON.default} alt="" className='ms-2' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-1 (Sales) </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.STARICON.default} alt="" className='ms-2' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> Item Sales and Purchase Summary </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.STARICON.default} alt="" className='ms-2' />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='_section-categories'>
          <div className='__categories-titleBx'>
            <img src={Images.FAVOURITICON.default} className='me-2' alt="" />
            <div className='__category-title'>GST</div>
          </div>

          <div className='__categoriesOtr'>
            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> Balance Sheet </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-2 (Purchase) </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-3b </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='see-more-container'>
              <span className='see-more me-2'>See more</span>
              <span className='see-more'><IoChevronForward /></span>
            </div>
          </div>
        </div>

        <div className='_section-categories'>
          <div className='__categories-titleBx'>
            <img src={Images.FAVOURITICON.default} className='me-2' alt="" />
            <div className='__category-title'>GST</div>
          </div>

          <div className='__categoriesOtr'>
            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> Balance Sheet </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-2 (Purchase) </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-3b </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='see-more-container'>
              <span className='see-more me-2'>See more</span>
              <span className='see-more'><IoChevronForward /></span>
            </div>
          </div>
        </div>

        <div className='_section-categories'>
          <div className='__categories-titleBx'>
            <img src={Images.FAVOURITICON.default} className='me-2' alt="" />
            <div className='__category-title'>GST</div>
          </div>

          <div className='__categoriesOtr'>
            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> Balance Sheet </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-2 (Purchase) </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-3b </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='see-more-container'>
              <span className='see-more me-2'>See more</span>
              <span className='see-more'><IoChevronForward /></span>
            </div>
          </div>
        </div>

        <div className='_section-categories'>
          <div className='__categories-titleBx'>
            <img src={Images.FAVOURITICON.default} className='me-2' alt="" />
            <div className='__category-title'>GST</div>
          </div>

          <div className='__categoriesOtr'>
            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> Balance Sheet </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-2 (Purchase) </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-3b </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='see-more-container'>
              <span className='see-more me-2'>See more</span>
              <span className='see-more'><IoChevronForward /></span>
            </div>
          </div>
        </div>

        <div className='_section-categories'>
          <div className='__categories-titleBx'>
            <img src={Images.FAVOURITICON.default} className='me-2' alt="" />
            <div className='__category-title'>GST</div>
          </div>

          <div className='__categoriesOtr'>
            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> Balance Sheet </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-2 (Purchase) </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='__categoriesReport'>
              <div className='d-flex justify-content-between align-items-center me-2 cursor-pointer'>
                <div className='d-flex'> GSTR-3b </div>

                <div className='d-flex'>
                  <img src={Images.CROWNICON.default} alt="" className='me-1' />

                  <img src={Images.HOLLOWSTARICON.default} alt="" className='ms-2 hollow-star' />
                </div>
              </div>
            </div>

            <div className='see-more-container'>
              <span className='see-more me-2'>See more</span>
              <span className='see-more'><IoChevronForward /></span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TripReports