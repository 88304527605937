import React, { useContext, useEffect, useState } from 'react'
import { ButtonHeading } from 'components'
import { AddEditLrModal, AddItemGroupModal, AddItemModal, AddLrModal, AddNewSeriesModal, LrDetailModal, LrSeriesModal } from 'modals'
import { getLrCountData, getMainLrListData, updateUserLrColumns, getLRDeaultColumn } from '_services/accountsServices/lrServices'
import StorageService from 'utils/StorageServices/StorageServices'
import { CustomTableNew } from 'components/CustomTable'
import moment from 'moment';
import AddEditConsignor from 'modals/AddEditConsignor/AddEditConsignor'
import { UserContext } from 'context'
import { updateInputValue } from 'features/lrFilterServices/lrFilterSlice';
import { useDispatch, useSelector } from 'react-redux'
import { updateSearchValue } from 'features/lrFilterServices/lrSearchSlice'

const Lr = () => {
  const dispatch = useDispatch();
  const { masterData, setIsMaterialIndex, dashboardLrStatus, setDashboardLrStatus } = useContext(UserContext)
  const [show, setShow] = useState(false)
  const [showAddLR, setShowAddLR] = useState(false)
  const [showLrDetail, setShowLrDetail] = useState(false)
  const [showAddItem, setShowAddItem] = useState(false)
  const [showAddGroupItem, setShowAddGroupItem] = useState(false)
  const [isAddConsignor, setIsAddConsignor] = useState(false);
  const [dataLrCount, setDataLrCount] = useState('');
  const getFiltersValues = useSelector((state) => state.lrFilter)?.filters;
  const getSearchValue = useSelector((state) => state.lrSearch);

  // Lr table start 

  const [columns, setColumns] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [filterableColumns, setFilterableColumns] = useState([]);
  const [isMasterFilter, setIsMasterFilter] = useState(true);
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeStatus, setActiveStatus] = useState(dashboardLrStatus ? dashboardLrStatus : 0);
  const [showPrefield, setShowingPrefield] = useState(false);

  useEffect(() => {
    if (dashboardLrStatus) {
      setActiveStatus(dashboardLrStatus);
    }
  }, [dashboardLrStatus])

  const handleDetailsAction = (data) => {
    setShowLrDetail(data);
  }

  const transformData = (data) => {
    return data.map(item => ({
      lr_number: item?.lr_number ? <div className='_tblText _lrNums' onClick={(e) => handleDetailsAction(item)}> <a className='_avPointer'> {item?.lr_number} </a> </div> : '-',
      plan_id: item?.plan_id?.indent_number ? <div className='_tblText'>{item?.plan_id?.indent_number} </div> : '-',
      invoice_date: item?.invoice_date ? <div className='_dateNtime'>
        <span className='_tblText'>{moment(item?.invoice_date).format('DD MMM YYYY')}</span>
        <span className='_lgtText'>{moment(item?.invoice_date).format('hh:mm A')}</span>
      </div> : '-',
      dispatch_date: item?.trip_id?.dispatch_date ? <div className='_dateNtime'>
        <span className='_tblText'>{moment(item?.trip_id?.dispatch_date).format('DD MMM YYYY')}</span>
        <span className='_lgtText'>{moment(item?.trip_id?.dispatch_date).format('hh:mm A')}</span>
      </div> : '-',
      consignor_name: item?.consignor_id?.name ? <div className='_tblText _oneLine_tx'>{item?.consignor_id?.name}</div> : '-',
      branch_name: item?.branch_id?.branch ? <div className='_tblText _oneLine_tx'>{item?.branch_id.branch}</div> : '-',
      // from_location: item?.from_location ? item?.from_location : '-',
      from_location: item?.from_location ? (
        <div className='_locationWid_bx' onClick={(e) => handleDetailsAction(item)}>
          <p className="_tblText">{item.from_location}</p>
          {((item.consignee_id && item.consignee_id.consignee_code) || (item.consignee_id && item.consignee_id.name)) && <p className="_lgtText">{item.consignee_id && item.consignee_id.consignee_code && item.consignee_id.consignee_code} |{item.consignee_id && item.consignee_id.name && item.consignee_id.name} </p>}
        </div>
      ) : '-',
      // to_location: item?.to_location ? item?.to_location : '-',
      to_location: item?.to_location ? (
        <div className='_locationWid_bx'>
          <p className="_tblText">{item.to_location}</p>
          {((item.unit_id && item.unit_id.unit_code) || (item.unit_id && item.unit_id.name)) && <p className="_lgtText">{item.unit_id && item.unit_id.unit_code && item.unit_id.unit_code} |{item.unit_id && item.unit_id.name && item.unit_id.name} </p>}
        </div>
      ) : '-',
      invoice_number: item?.invoice_number ? <div className='_tblText'>{item?.invoice_number}</div> : '-',
      sap_invoice_number: item?.sap_invoice_number ? <div className='_tblText'>{item?.sap_invoice_number}</div> : '-',
      gst_number: item?.gst_number ? <div className='_tblText'>{item?.gst_number}</div> : '-',
      po_number: item?.po_number ? <div className='_tblText'>{item?.po_number}</div> : '-',
      mlr_number: item?.mlr_number ? <div className='_tblText'>{item?.mlr_number}</div> : '-',
      // lr_status: item?.lr_status ? item?.lr_status : '-',
      lr_status: item?.lr_status ? (
        // <span className="bg-success"> {item.lr_status} </span>
        <span className={masterData?.lr_status[item?.lr_status] === 'Pending' ? '_lrPending_status' : (masterData?.lr_status[item?.lr_status] === 'In Transit' ? '_lrInTransit_status' : (masterData?.lr_status[item?.lr_status] === 'At Party Point' ? '_lrPartyPoint_status' : ((masterData?.lr_status[item?.lr_status] === 'Delivered' ? '_lrDelivered_status' : (masterData?.lr_status[item?.lr_status] === 'Bill Raised' ? '_lrBillRaised_status' : '')))))}> {masterData?.lr_status[item?.lr_status] ? masterData?.lr_status[item?.lr_status] : ''} </span>
      ) : '-',
      pod_status: item?.pod_status ? <span className={masterData?.pod_status[item?.pod_status] === 'Pending' ? '_lrPending_status' : (masterData?.pod_status[item?.pod_status] === 'In Transit' ? '_lrInTransit_status' : (masterData?.pod_status[item?.pod_status] === 'At Party Point' ? '_lrPartyPoint_status' : ((masterData?.pod_status[item?.pod_status] === 'Received' ? '_lrDelivered_status' : (masterData?.pod_status[item?.pod_status] === 'Bill Raised' ? '_lrBillRaised_status' : '')))))}> {masterData?.pod_status[item?.pod_status] ? masterData?.pod_status[item?.pod_status] : ''} </span>
        : '-',
      created_by: item?.created_by ? <div className='_tblText'>{item?.created_by}</div> : '-',
      eway_bill_number: item?.eway_bill_number ? <div className='_tblText'>{item?.eway_bill_number}</div> : '-',
      eway_bill_date: item?.eway_bill_date ? <div className='_dateNtime'>
        <span className='_tblText'>{moment(item?.eway_bill_date).format('DD MMM YYYY')}</span>
        <span className='_lgtText'>{moment(item?.eway_bill_date).format('hh:mm A')}</span>
      </div> : '-',
      eway_expiry_date: item?.eway_expiry_date ? <div className='_dateNtime'>
        <span className='_tblText'>{moment(item?.eway_expiry_date).format('DD MMM YYYY')}</span>
        <span className='_lgtText'>{moment(item?.eway_expiry_date).format('hh:mm A')}</span>
      </div> : '-',
      eway_bill_status: item?.eway_bill_status ? <div className='_tblText'>{item?.eway_bill_status}</div> : '-',
      total_qty: item?.total_qty ? <div className='_tblText'>{item?.total_qty}</div> : '-',
      total_kot: item?.total_kot ? <div className='_tblText'>{item?.total_kot}</div> : '-',
      total_freight: item?.total_freight ? <div className='_tblText'>{item?.total_freight}</div> : '-',
      dispatch_date: item?.dispatch_date ? <div className='_tblText'>{moment(item?.dispatch_date).format('DD MMM YYYY')}</div> : '-',
      eway_bill_status: item?.eway_bill_status === 0 ? <span className='_lrPending_status'>  Pending </span> : '-',
      vehicle_no: item?.vehicle_no ? <div className='_tblText'>{item?.vehicle_no}</div> : '-',
      vehicle_ownership: item?.vehicle_ownership ? <div className='_tblText'>{masterData?.vehicle_ownership[item?.vehicle_ownership]}</div> : '-',
      lorry_type: item?.lorry_type ? <div className='_tblText'>{item?.lorry_type}</div> : '-',
      supplier_name: item?.supplier_name ? <div className='_tblText'>{item?.supplier_name}</div> : '-',
      vehicle_type: item?.vehicle_type ? <div className='_tblText'>{item?.vehicle_type}</div> : '-',
      indent_number: item?.indent_number ? <div className='_tblText'>{item?.indent_number}</div> : '-',
      pod_date: item?.pod_date ? <div className='_tblText'>{moment(item?.pod_date).format('DD MMM YYYY')}</div> : '-',
      pod_file: item?.pod_file ? <div className='_tblText'>{item?.pod_file?.length ? item?.pod_file?.length : 0}</div> : '-',
      trip_number: item?.trip_number ? <div className='_tblText'>{item?.trip_number} </div> : '-',
      driver_name: item?.driver_name ? <div className='_tblText'>{item?.driver_name}</div> : '-',
      total_weight: item?.total_weight ?
        <div className='_tblText'>{item?.total_weight}</div>
        : '-'
    }));
  };

  const getData = async (page, lrStatus, lrListFiltersData) => {
    setLoading(true);
    try {
      let postData = {
        company_id: StorageService.getCompanyId(),
        branch_id: lrListFiltersData?.branch_id?.value ? lrListFiltersData?.branch_id?.value : '',
        consignor_id: lrListFiltersData?.consignor_id?.value ? lrListFiltersData?.consignor_id?.value : '',
        start_date: lrListFiltersData?.start_date ? moment(lrListFiltersData?.start_date) : '',
        end_date: lrListFiltersData?.end_date ? moment(lrListFiltersData?.end_date) : '',
        search: getSearchValue?.search ? getSearchValue?.search : '',
        from_location: lrListFiltersData?.from_location?.value ? lrListFiltersData?.from_location?.value : '',
        to_location: lrListFiltersData?.to_location?.value ? lrListFiltersData?.to_location?.value : '',
        pod_status: lrListFiltersData?.pod_status?.value ? lrListFiltersData?.pod_status?.value : 0,
        lr_status: lrStatus,
        limit: pageLimit,
        // offset: (page - 1) * pageLimit,
        offset: currentPage,
        select_lr_ids: []
      }
      const response = await getMainLrListData(postData);
      setColumns(response?.data?.lr_columns);
      setApiData(transformData(response?.data?.lr_list));
      setFilterableColumns(response?.data?.lr_filter_columns);
      setIsMasterFilter(true);
      setTotalPages(Math.ceil(response?.data?.total_record / pageLimit));
      setLoading(false);
      // setFilterableColumns(response.filterableColumns);
      // setIsMasterFilter(response.isMasterFilter);
    } catch (error) {
      setLoading(false);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const updateLrColumns = async (data) => {
    let postData = {
      company_id: StorageService.getCompanyId(),
      lr_columns: data
    }
    try {
      const response = await updateUserLrColumns(postData);
      setColumns(response?.data?.lr_columns);
      setApiData(transformData(response?.data?.lr_list));
      setFilterableColumns(response?.data?.lr_filter_columns);
      setIsMasterFilter(true);
      setTotalPages(Math.ceil(response?.data?.total_record / pageLimit));
      // setFilterableColumns(response.filterableColumns);
      // setIsMasterFilter(response.isMasterFilter);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const getLrTopStatusCount = async (data) => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        branch_id: data?.branch_id?.value ? data?.branch_id?.value : '',
        consignor_id: data?.consignor_id?.value ? data?.consignor_id?.value : '',
        start_date: data?.start_date ? moment(data?.start_date) : '',
        end_date: data?.end_date ? moment(data?.end_date) : '',
        search: getSearchValue?.search ? getSearchValue?.search : '',
        from_location: data?.from_location?.value ? data?.from_location?.value : '',
        to_location: data?.to_location?.value ? data?.to_location?.value : '',
        // pod_status: data?.pod_status?.value ? data?.pod_status?.value : 0,
        // lr_status: activeStatus,
      }
      const res = await getLrCountData(object);
      if (res?.responseCode == 200) {
        setDataLrCount(res?.data?.lr_status)
      }
    } catch (error) {
      // throw error;
    }
  }

  useEffect(() => {
    getData(currentPage, activeStatus, getFiltersValues);
    // getLrTopStatusCount(getFiltersValues);
  }, [masterData, currentPage, activeStatus])

  useEffect(() => {
    setIsMaterialIndex('');
  }, [showAddLR])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCurrentPage(1);
      getData(currentPage, activeStatus, getFiltersValues);
      getLrTopStatusCount(getFiltersValues);
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [getSearchValue?.search])

  const listApiCallOnSaveButtonFunction = async (data) => {
    if (data?.empty === true) {
      setCurrentPage(1);
      getData(currentPage, activeStatus);
      getLrTopStatusCount();
    } else {
      setCurrentPage(1);
      getData(currentPage, activeStatus, getFiltersValues);
      getLrTopStatusCount(getFiltersValues);
    }
  }

  const AllClearResetFunction = async () => {
    let obj = {
      branch_id: { label: "All", value: "" },
      consignor_id: { label: "All", value: "" },
      from_location: { label: "All", value: "" },
      to_location: { label: "All", value: "" },
      pod_status: { label: "All", value: 0 },
      start_date: "",
      end_date: "",
      lr_status: 0,
    }
    setActiveStatus(0);
    dispatch(updateSearchValue(''))
    dispatch(updateInputValue(obj))
    listApiCallOnSaveButtonFunction({ empty: true });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  // lr list filters start

  const handleOnChange = async (name, value) => {
    dispatch(updateSearchValue(value ? value : ''))
  };

  const onhandleSelect = async (item, name) => {
    if (item?.startDate && item?.endDate) {
      const stateObj = {
        ...getFiltersValues,
        start_date: moment(item?.startDate),
        end_date: moment(item?.endDate),
      }
      dispatch(updateInputValue(stateObj))
    } else {
      const stateObj = { ...getFiltersValues, [name]: item };
      dispatch(updateInputValue(stateObj))
    }
  };

  // lr list filters end

  // Lr table end

  let firstAddLrModal = {
    isOpen: show,
    closeModal: (e) => setShow(false),
    addButtonAction: (e) => setIsAddConsignor(e),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add LR",
    submitTitle: "Continue",
    nextModal: (e) => setShowAddLR(e),
    showPrefield: showPrefield,
    showingPrefield: (e) => setShowingPrefield(e),
  }

  let firstAddItemModal = {
    isOpen: showAddItem,
    closeModal: (e) => setShowAddItem(false),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add Item",
    submitTitle: "Add Item",
  }

  let firstAddGroupItemModal = {
    isOpen: showAddGroupItem,
    closeModal: (e) => setShowAddGroupItem(false),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add Item Group",
    submitTitle: "Add Item Group",
  }

  const handleNextModal = (data) => {
    setShowLrDetail(!showLrDetail);
    setShowAddLR(data);
  }

  const handleStatusChange = async (lrStatus) => {
    if (lrStatus !== activeStatus) { // Check if status actually changes
      setActiveStatus(lrStatus); // Update activeStatus
      setCurrentPage(1); // Reset page to 1 when status changes

      // Debounce the API call to prevent multiple rapid calls
      await new Promise(resolve => setTimeout(resolve, 300)); // Adjust debounce time as needed

      // getData(1, lrStatus, getFiltersValues);
      // getLrTopStatusCount(getFiltersValues);
    }
  };

  const resetLRTableColumn = async () => {
    try {
      let postData = {
        company_id: StorageService.getCompanyId(),
      }
      const response = await getLRDeaultColumn(postData);
      if (response?.responseCode == 200) {
        setColumns(response?.data?.lr_columns)
      }

    } catch (error) {
      console.error('Error fetching deault LR table column', error);
    } finally {
    }
  }

  const renderStatusFilters = () => (
    <div className='_tabUr_bx'>
      <button className={`tabCst_btn ${activeStatus === 0 ? '_btnActive' : ''}`} onClick={() => handleStatusChange(0)}> <span >All</span> <span className='_counts'> {dataLrCount?.all} </span> </button>
      <button className={`tabCst_btn ${activeStatus === 1 ? '_btnActive' : ''}`} onClick={() => handleStatusChange(1)}> <span >Pending</span> <span className='_counts'> {dataLrCount?.pending} </span> </button>
      {/* <button className={`tabCst_btn ${activeStatus === 2 ? '_btnActive' : ''}`} onClick={() => handleStatusChange(2)}> <span >In Progress LR</span> <span className='_counts'>5</span> </button> */}
      <button className={`tabCst_btn ${activeStatus === 3 ? '_btnActive' : ''}`} onClick={() => handleStatusChange(3)}> <span >In Transit</span> <span className='_counts'> {dataLrCount?.in_transit}</span> </button>
      <button className={`tabCst_btn ${activeStatus === 4 ? '_btnActive' : ''}`} onClick={() => handleStatusChange(4)}> <span >At Party Point </span> <span className='_counts'>{dataLrCount?.at_party_point ? dataLrCount?.at_party_point : 0} </span> </button>
      <button className={`tabCst_btn ${activeStatus === 5 ? '_btnActive' : ''}`} onClick={() => handleStatusChange(5)}> <span >Delivered </span> <span className='_counts'> {dataLrCount?.delivered} </span> </button>
      <button className={`tabCst_btn ${activeStatus === 6 ? '_btnActive' : ''}`} onClick={() => handleStatusChange(6)}> <span >Bill Raised</span> <span className='_counts'>{dataLrCount?.bill_raised ? dataLrCount?.bill_raised : 0}</span> </button>
      <button className={`tabCst_btn ${activeStatus === 7 ? '_btnActive' : ''}`} onClick={() => handleStatusChange(7)}> <span >Cancelled </span> <span className='_counts'> {dataLrCount?.cancelled} </span> </button>
    </div>

  );

  return (
    <div>
      <ButtonHeading title={"Lorry Receipt"} onClick={() => setShow(!show)} buttonTitle="Add LR" />

      <CustomTableNew
        columns={columns}
        data={apiData}
        filterableColumns={filterableColumns}
        isMasterFilter={isMasterFilter}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        itemsPerPage={pageLimit}
        setColumns={(data) => updateLrColumns(data)}
        statusApiFilter={renderStatusFilters()}
        initialListFilters={getFiltersValues}
        searchFilterOnChange={handleOnChange}
        dropdownFilterOnChange={onhandleSelect}
        listApiCallOnSaveButtonFunction={listApiCallOnSaveButtonFunction}
        AllClearResetFunction={AllClearResetFunction}
        resetLRTableColumn={() => resetLRTableColumn()}
        loading={loading}
        filterShow={true}
      />

      {show
        && (
          <AddLrModal {...firstAddLrModal} />
        )
      }

      {showAddItem
        && (
          <AddItemModal {...firstAddItemModal} />
        )
      }

      {showAddGroupItem
        && (
          <AddItemGroupModal {...firstAddGroupItemModal} />
        )
      }

      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${showAddLR ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showAddLR ? 'visible' : 'hidden' }}
      >
        {showAddLR
          && (
            <AddEditLrModal isOpen={showAddLR} onClose={() => setShowAddLR(false)} callGetData={() => getData()} />
          )
        }
      </div>
      <div className={showAddLR ? 'offBack_drop' : ''}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${showLrDetail ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showLrDetail ? 'visible' : 'hidden' }}
      >
        {showLrDetail
          && (
            <LrDetailModal
              isOpen={showLrDetail}
              onClose={(e) => setShowLrDetail(false)}
              nextModal={(e) => handleNextModal(e)}
            />
          )
        }
      </div>
      <div className={showLrDetail ? 'offBack_drop' : ''}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${isAddConsignor ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: isAddConsignor ? 'visible' : 'hidden' }}
      >
        {isAddConsignor &&
          <AddEditConsignor
            isOpen={isAddConsignor}
            onClose={() => setIsAddConsignor(false)}
            isPreviousModal={(e) => setShow(e)}
            showingPrefield={(e) => setShowingPrefield(true)}
            isTrue={true}
            withoutFirstModalDirectLrOpen={(e) => setShowAddLR(e)}
          />
        }
      </div>

      <div className={isAddConsignor ? 'offBack_drop' : ''}></div>

    </div>
  )
}

export default Lr