import React, { useContext, useState, useEffect, useRef } from 'react';
import { getBranchDropdownListData, getConsignorDropdownListData } from '_services/dropdownServices/dropdownService';
import { ButtonHeading, CommonInput, CustomSelect } from 'components';
import { constant } from 'constants/constant';
import { UserContext } from 'context';
import { errorSchema, utils } from 'helper';
import { ConfirmModalFor } from 'modals';
import { Link, useNavigate } from 'react-router-dom';
import StorageService from 'utils/StorageServices/StorageServices';

const formObj = {
    company_id: '',
    consignor_id: {},
    invoice_number: '',
    invoice_date: '',
    due_date: '',
    branch_id: {},
    charge_gst: 0,
    gst_percentage: {},
    tds: {},
    receiving_state: {},
    select_lr_ids: [],
    // invoice_summary: {
    //     total_freight: '',
    //     claim_receivable: '',
    //     gst_amount: '',
    //     invoice_amount: '',
    //     freight_received: '',
    //     claim_payable: '',
    //     balance: '',
    // }
}

const BillRaise = () => {

    const navigate = useNavigate()
    const { masterData, states, invoiceBillRaiseData, setInvoiceBillRaiseData } = useContext(UserContext)
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [isChargeGST, setIsChargeGST] = useState(false)
    const [dropdownConsignorList, setDropdownConsignorList] = useState([]);
    const [dropdownBranchList, setDropdownBranchList] = useState([]);
    const [leaveShowConfirm, setLeaveShowConfirm] = useState(false);

    const hiddenInputRef = useRef(null);
    const [showBackModal, setShowBackModal] = useState(false);

    const shouldReloadRef = useRef(false);

    const getDropdownDataConsignorList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getConsignorDropdownListData(object);
            if (res?.responseCode == 200) {
                setDropdownConsignorList(res?.data?.consignor_list)
            }
        } catch (error) {
            throw error;
        }
    }

    const getDropdownDataBranchList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: "",
            };
            const res = await getBranchDropdownListData(object);
            if (res?.responseCode == 200) {

                let arrayData = res?.data?.branch_list?.map((el) => {
                    return {
                        label: el?.branch,
                        value: el?._id,
                    }
                })
                setDropdownBranchList(arrayData);

                if (arrayData?.length > 0 && arrayData?.length === 1) {
                    setFormValue((s) => ({
                        ...s,
                        branch_id: arrayData[0],
                    }))
                }
            }
        } catch (error) {
            // throw error;
        }
    };

    useEffect(() => {
        getDropdownDataConsignorList();
        getDropdownDataBranchList();
    }, [masterData, StorageService.getCompanyId()])

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createBillRaiseSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const onhandleSelect = async (item, name) => {
        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.createBillRaiseSchema);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }
    };

    // const apiCallingFunction = async () => {
    //     try {
    //         console.log("create bill raise", formValue)
    //         // let object = {
    //         //     consignor_id: formValue?.party?.value,
    //         //     company_id: StorageService.getCompanyId(),
    //         // }
    //         // const res = await fetchConsignorDetailsData(object);
    //         // if (!!res) {
    //         //     nextModal(res?.data?.consignor);
    //         //     closeModal(false);
    //         //     setFormValue({});
    //         //     setLoading(false);
    //         // } else {
    //         //     setLoading(false);
    //         // }
    //     } catch (error) {
    //         setLoading(false);
    //     }
    // }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.createBillRaiseSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            setInvoiceBillRaiseData(formValue);
            navigate('/invoicing/selectlr')
            // apiCallingFunction();
            setFormError("");
        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            invoice_date: utils.getTodayDate(),
            company_id: StorageService.getCompanyId(),
        }));
    }, [dropdownConsignorList, masterData, StorageService.getCompanyId()]);

    useEffect(() => {
        if (formValue?.invoice_date) {
            setFormValue((s) => ({ ...s, due_date: utils.getDateFifteenDaysAfterDate(formValue?.invoice_date) }))
        }

    }, [formValue?.invoice_date])

    useEffect(() => {
        if (invoiceBillRaiseData?.consignor_id?.value) {
            setIsChargeGST(true);
            setFormValue(invoiceBillRaiseData);
        }
    }, [invoiceBillRaiseData])

    const submitLeaveModalProps = () => {
        navigate('/invoicing')
    }

    const discardHandlerFunction = (e) => {
        e.preventDefault();
        if (formValue?.consignor_id?.label || formValue?.invoice_number || formValue?.branch_id?.label || formValue?.charge_gst || formValue?.gst_percentage?.label || formValue?.tds?.label || formValue?.receiving_state?.label) {
            setLeaveShowConfirm(true);
        } else {
            submitLeaveModalProps();
        }
    }

    let leaveModalProps = {
        show: leaveShowConfirm,
        closeModal: (e) => setLeaveShowConfirm(!leaveShowConfirm),
        title: "Leave This Page",
        submitTitle: "Confirm",
        onSubmit: submitLeaveModalProps,
        confirmModal: "confirmModal",
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (!shouldReloadRef.current) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // browser back button functionality

    const backModalProps = {
        show: showBackModal,
        closeModal: (e) => setShowBackModal(false),
        title: "Leave This Page",
        submitTitle: "Confirm",
        onSubmit: submitLeaveModalProps,
        confirmModal: "confirmModal",
    };

    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            setShowBackModal(true);
            window.history.pushState({ modalOpened: false }, "");
        };

        window.addEventListener("popstate", handlePopState);
        window.history.pushState({ modalOpened: false }, "");

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    return (
        <>
            <div>
                <div className='contentHead_new'>
                    <h4>Create Bill Raise</h4>

                    <div className='_stepedBxs'>
                        <div className='_seperateCheckes activated'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <circle cx="10.4999" cy="10.5" r="10.5" fill="#20A154" />
                                <path d="M5.99988 10.1672L9.16704 13.3343L15.4999 7" stroke="white" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span>Bill Details</span>
                        </div>

                        <div className='_seperateCheckes '>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                                <path d="M6 10.1672L9.16716 13.3343L15.5 7" stroke="#828282" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span>Select LR</span>
                        </div>

                        <div className='_seperateCheckes'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                                <path d="M6 10.1672L9.16716 13.3343L15.5 7" stroke="#828282" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span>Selected LR</span>
                        </div>

                        <div className='_seperateCheckes'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                                <path d="M6 10.1672L9.16716 13.3343L15.5 7" stroke="#828282" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span>Summary</span>
                        </div>
                    </div>
                </div>

                <div className='_billRaiseBody'>
                    <div className='billDetial_modBxs'>
                        <div className='billDetail_mdModal'>
                            <div className='pr_procssBx'>
                                <div className='pr_procssBx_grid'>
                                    <div className='singleDiv'>
                                        <CustomSelect
                                            options={dropdownConsignorList?.map((el) => {
                                                return {
                                                    label: el?.name,
                                                    value: el?._id,
                                                }
                                            })}
                                            name="consignor_id"
                                            label="Select Party"
                                            placeholder="Select Party"
                                            value={formValue?.consignor_id?.value ? formValue?.consignor_id : {}}
                                            error={formError["consignor_id.label"]}
                                            onSelect={(data) => onhandleSelect(data, 'consignor_id')}
                                        />
                                    </div>

                                    <div className='halfDiv'>
                                        <CommonInput
                                            type="number"
                                            placeholder="Invoice Number * "
                                            label="Invoice Number * "
                                            name='invoice_number'
                                            maxLength="10"
                                            onInput={maxLengthCheck}
                                            value={formValue.invoice_number}
                                            focus={!!(typeof formError === "object" && formError?.invoice_number)}
                                            error={!!(typeof formError === "object") ? formError?.invoice_number : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, value)
                                            }
                                        />
                                    </div>

                                    <div className='halfDiv'>
                                        <CommonInput
                                            type="date"
                                            placeholder="As of Date"
                                            label="As of Date *"
                                            name='invoice_date'
                                            value={formValue.invoice_date}
                                            focus={!!(typeof formError === "object" && formError?.invoice_date)}
                                            error={!!(typeof formError === "object") ? formError?.invoice_date : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, value)
                                            }
                                        />
                                    </div>

                                    <div className='singleDiv'>
                                        <CommonInput
                                            type="date"
                                            placeholder="Due Date * "
                                            label="Due Date * "
                                            name='due_date'
                                            value={formValue.due_date}
                                            focus={!!(typeof formError === "object" && formError?.due_date)}
                                            error={!!(typeof formError === "object") ? formError?.due_date : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, value)
                                            }
                                        />
                                    </div>
                                    {/* <div className='halfDiv'>
                                        <CustomSelect
                                            options={constant.dueDateArray}
                                            name="days"
                                            label="Days"
                                            placeholder="Days"
                                            value={formValue?.days?.value ? formValue?.days : {}}
                                            error={formError["days.label"]}
                                            onSelect={(data) => onhandleSelect(data, 'days')}
                                        />
                                    </div> */}

                                    <div className='singleDiv'>
                                        <CustomSelect
                                            options={dropdownBranchList}
                                            name="branch_id"
                                            label="Select Branch for Billing"
                                            placeholder="Select Branch for Billing"
                                            value={formValue?.branch_id?.value ? formValue?.branch_id : {}}
                                            error={formError["branch_id.label"]}
                                            onSelect={(data) => onhandleSelect(data, 'branch_id')}
                                        />
                                    </div>

                                    <div className='singleDiv _gstChr_div'>
                                        <div className="_anvSwitcherNew form-check form-switch">
                                            <label className="form-check-label" htmlFor="charge_gst">Charge GST</label>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name='charge_gst'
                                                id="charge_gst"
                                                checked={isChargeGST === true}
                                                onChange={(e) => setIsChargeGST(!isChargeGST)}
                                            />
                                        </div>

                                        {
                                            isChargeGST && (
                                                <div className='singleDiv _gstChr_div'>
                                                    <div className='_gstChr_dualChecker'>
                                                        <div className="_dualCheckr_bx" >
                                                            <input
                                                                className="form-check-input"
                                                                id="forward_charge_mechanism"
                                                                type="radio"
                                                                name="charge_gst"
                                                                checked={formValue?.charge_gst === 1}
                                                                onChange={(e) => setFormValue((s) => ({ ...s, charge_gst: 1 }))}
                                                            />
                                                            <label htmlFor="forward_charge_mechanism" className="form-check-label">Forward charge mechanism (FCM) <span className='_btmSmall_txt'>GST will be paid & Manage by Party </span></label>

                                                            {formValue?.charge_gst === 1 && <div className='pr_procssBx_grid singleDiv mt-3'>
                                                                <div className='halfDiv'>
                                                                    <CustomSelect
                                                                        options={masterData?.gst_percentage_list?.map((el) => {
                                                                            return {
                                                                                label: el?.name,
                                                                                value: el?.id,
                                                                            }
                                                                        })}
                                                                        name="gst_percentage"
                                                                        label="GST % 5/ 12/18"
                                                                        placeholder="GST % 5/ 12/18"
                                                                        value={formValue?.gst_percentage?.value ? formValue?.gst_percentage : {}}
                                                                        error={formError["gst_percentage.label"]}
                                                                        onSelect={(data) => onhandleSelect(data, 'gst_percentage')}
                                                                    />
                                                                </div>

                                                                <div className='halfDiv'>
                                                                    <CustomSelect
                                                                        options={masterData?.tds_section_list?.map((el) => {
                                                                            return {
                                                                                label: el?.name,
                                                                                value: el?.id,
                                                                            }
                                                                        })}
                                                                        name="tds"
                                                                        label="TDS % 1/2"
                                                                        placeholder="TDS % 1/2"
                                                                        value={formValue?.tds?.value ? formValue?.tds : {}}
                                                                        error={formError["tds.label"]}
                                                                        onSelect={(data) => onhandleSelect(data, 'tds')}
                                                                    />
                                                                </div>

                                                            </div>}
                                                        </div>

                                                        <div className="_dualCheckr_bx" >
                                                            <input
                                                                className="form-check-input"
                                                                id="reverse_charge_mechanism"
                                                                type="radio"
                                                                name="charge_gst"
                                                                checked={formValue?.charge_gst === 2}
                                                                onChange={(e) => setFormValue((s) => ({ ...s, charge_gst: 2 }))}
                                                            />
                                                            <label htmlFor="reverse_charge_mechanism" className="form-check-label">Reverse charge mechanism (RCM) <span className='_btmSmall_txt'>GST will be paid & Manage by Own</span></label>

                                                            {formValue?.charge_gst === 2 && <div className='pr_procssBx_grid singleDiv mt-3'>
                                                                <div className='halfDiv'>
                                                                    <CustomSelect
                                                                        options={masterData?.gst_percentage_list?.map((el) => {
                                                                            return {
                                                                                label: el?.name,
                                                                                value: el?.id,
                                                                            }
                                                                        })}
                                                                        name="gst_percentage"
                                                                        label="GST % 5/ 12/18"
                                                                        placeholder="GST % 5/ 12/18"
                                                                        value={formValue?.gst_percentage?.value ? formValue?.gst_percentage : {}}
                                                                        error={formError["gst_percentage.label"]}
                                                                        onSelect={(data) => onhandleSelect(data, 'gst_percentage')}
                                                                    />
                                                                </div>

                                                                <div className='halfDiv'>
                                                                    <CustomSelect
                                                                        options={masterData?.tds_section_list?.map((el) => {
                                                                            return {
                                                                                label: el?.name,
                                                                                value: el?.id,
                                                                            }
                                                                        })}
                                                                        name="tds"
                                                                        label="TDS % 1/2"
                                                                        placeholder="TDS % 1/2"
                                                                        value={formValue?.tds?.value ? formValue?.tds : {}}
                                                                        error={formError["tds.label"]}
                                                                        onSelect={(data) => onhandleSelect(data, 'tds')}
                                                                    />
                                                                </div>

                                                                <div className='singleDiv'>
                                                                    <CustomSelect
                                                                        options={states?.length > 0 ? states?.map((el) => {
                                                                            return {
                                                                                ...el,
                                                                                label: el?.name,
                                                                                value: el?.name,
                                                                            }
                                                                        }) : []}
                                                                        name="receiving_state"
                                                                        isDropdownTop={true}
                                                                        label="Select Receiving State *"
                                                                        placeholder="Select Receiving State *"
                                                                        value={formValue?.receiving_state?.value ? formValue?.receiving_state : {}}
                                                                        error={formError["receiving_state.label"]}
                                                                        onSelect={(data) => onhandleSelect(data, 'receiving_state')}
                                                                    />
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='_billRaiseFooter'>
                    <div className='prevDiscard_bx'>
                        {/* <button className='_previousBtn' onClick={(e) => navigate('/invoicing')}><span>Previous</span></button> */}
                        <button className='_discardBtn' onClick={(e) => discardHandlerFunction(e)}><span>Discard</span></button>
                    </div>

                    <div>
                        <button className='_stepSuccessBtn' onClick={(e) => handleSubmit(e)}>Next : Select  LR</button>
                    </div>
                </div>
            </div>
            {leaveShowConfirm && (
                <ConfirmModalFor {...leaveModalProps}>
                    <>
                        <p className="_confirmPrx"> Do you really want leave this Page </p>
                    </>
                </ConfirmModalFor>
            )}
            <input ref={hiddenInputRef} type="hidden" />
            {
                showBackModal && (
                    <ConfirmModalFor {...backModalProps}>
                        <>
                            <p className="_confirmPrx"> Do you really want leave this Page </p>
                        </>
                    </ConfirmModalFor>
                )}
        </>
    )
}

export default BillRaise