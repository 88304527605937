import React, { useEffect, useState, useRef } from 'react'
import { fetchConsignorDetailsData, updateConsignorSetting } from '_services/accountsServices/consignorServices';
import { ButtonHeading, CommonCheckbox, CommonInput, Customdropdown, CustomSelect, NavTabs } from 'components'
import { AddButton } from 'components/common/CommonButton';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import StorageService from "utils/StorageServices/StorageServices";
import LoadingBar from 'react-top-loading-bar';

const options = [
    { id: 1, label: 'Alphanumeric' },
    { id: 2, label: 'Numeric' },
    { id: 3, label: 'Any' }
];

const Settings = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const loadingBarRef = useRef(null)

    const formObj = {
        company_id: StorageService.getCompanyId(),
        consignor_id: id,
        permission_at_dispatch: {
            lr_mandatory: 0,
            eway_bill_mandatory: 0,
            freight_mandatory: 0
        },
        permission_at_lr: {
            unit_mandatory: 0,
            consignee_mandatory: 0,
            eway_bill_mandatory: 0
        },
        permission_at_invoice_number: {
            view: 0,
            mandatory: 0,
            input_length: 0,
            min: null,
            max: null,
            field_type: '',
        },
        permission_at_field2: {
            view: 0,
            mandatory: 0,
            field_name: '',
            input_length: 0,
            min: null,
            max: null,
            field_type: '',
        },
        permission_at_field3: {
            view: 0,
            mandatory: 0,
            field_name: '',
            input_length: 0,
            min: null,
            max: null,
            field_type: '',
        },
        permission_at_field4: {
            view: 0,
            mandatory: 0,
            field_name: '',
            input_length: 0,
            min: null,
            max: null,
            field_type: '',
        },
        permission_at_field5: {
            view: 0,
            mandatory: 0,
            field_name: '',
            input_length: 0,
            min: null,
            max: null,
            field_type: '',
        },
        permission_at_item: {
            item_group_hsn: {
                view: 1,
                mandatory: 0
            },
            weight: {
                view: 0,
                mandatory: 0
            },
            kot: {
                view: 0,
                mandatory: 0
            },
            sales: {
                view: 0,
                mandatory: 0
            },
            qty: {
                view: 0,
                mandatory: 0
            }
        },
        permission_at_pod: {
            attachment: {
                view: 0,
                mandatory: 0
            }
        }
    }

    const [formValue, setFormValue] = useState(formObj);
    const [loading, setLoading] = useState(false);


    const [minMaxFields, setMinMaxFields] = useState({
        invoiceNo: { min: null, max: null },
        fieldTwo: { min: null, max: null },
        fieldThree: { min: null, max: null },
        fieldFour: { min: null, max: null },
        fieldFive: { min: null, max: null },
    });

    const handleChangeMinMax = (field, e) => {
        const { name, value } = e.target;
        setMinMaxFields(prevFields => ({
            ...prevFields,
            [field]: {
                ...prevFields[field],
                [name]: value,
            }
        }));
    };


    const [selectedValues, setSelectedValues] = useState({
        invoiceNo: null,
        fieldTwo: null,
        fieldThree: null,
        fieldFour: null,
        fieldFive: null,
    });

    const handleSelectType = (name, option) => {
        setSelectedValues(prevValues => ({
            ...prevValues,
            [name]: option,
        }));
    };



    const handleStringOnChange = async (name, value, parent_name) => {
        setFormValue((formValue) => ({
            ...formValue,
            [parent_name]: {
                ...formValue[parent_name],
                [name]: value ? value : ''
            }
        }));
    };

    const handleNumberOnChange = async (name, value, parent_name) => {
        setFormValue((formValue) => ({
            ...formValue,
            [parent_name]: {
                ...formValue[parent_name],
                [name]: value ? value : null
            }
        }));
    };

    const handleCheckboxChange = (section, key) => (event) => {
        setFormValue((formValue) => ({
            ...formValue,
            [section]: {
                ...formValue[section],
                [key]: event.target.checked ? 1 : 0
            }
        }));
    };

    const checkboxHandlerNestedObject = (first, second, third) => (event) => {
        setFormValue((formValue) => ({
            ...formValue,
            [first]: {
                ...formValue[first],
                [second]: {
                    ...formValue[first][second],
                    [third]: event.target.checked ? 1 : 0,
                }
            }
        }));
    };

    useEffect(() => {
        setFormValue(prevFormValue => ({
            ...prevFormValue,
            permission_at_invoice_number: {
                ...prevFormValue.permission_at_invoice_number,
                min: minMaxFields.invoiceNo.min ? Number(minMaxFields.invoiceNo.min) : 8,
                max: minMaxFields.invoiceNo.max ? Number(minMaxFields.invoiceNo.max) : 12,
                input_length: minMaxFields.invoiceNo.max ? Number(minMaxFields.invoiceNo.max) : 0,
                field_type: selectedValues.invoiceNo ? Number(selectedValues.invoiceNo?.id) : 1,
            },
            permission_at_field2: {
                ...prevFormValue.permission_at_field2,
                min: minMaxFields.fieldTwo.min ? Number(minMaxFields.fieldTwo.min) : 8,
                max: minMaxFields.fieldTwo.max ? Number(minMaxFields.fieldTwo.max) : 12,
                input_length: minMaxFields.fieldTwo.max ? Number(minMaxFields.fieldTwo.max) : 0,
                field_type: selectedValues.fieldTwo ? Number(selectedValues.fieldTwo?.id) : 1,
            },
            permission_at_field3: {
                ...prevFormValue.permission_at_field3,
                min: minMaxFields.fieldThree.min ? Number(minMaxFields.fieldThree.min) : 8,
                max: minMaxFields.fieldThree.max ? Number(minMaxFields.fieldThree.max) : 12,
                input_length: minMaxFields.fieldThree.max ? Number(minMaxFields.fieldThree.max) : 0,
                field_type: selectedValues.fieldThree ? Number(selectedValues.fieldThree?.id) : 1,
            },
            permission_at_field4: {
                ...prevFormValue.permission_at_field4,
                min: minMaxFields.fieldFour.min ? Number(minMaxFields.fieldFour.min) : 8,
                max: minMaxFields.fieldFour.max ? Number(minMaxFields.fieldFour.max) : 12,
                input_length: minMaxFields.fieldFour.max ? Number(minMaxFields.fieldFour.max) : 0,
                field_type: selectedValues.fieldFour ? Number(selectedValues.fieldFour?.id) : 1,
            },
            permission_at_field5: {
                ...prevFormValue.permission_at_field5,
                min: minMaxFields.fieldFive.min ? Number(minMaxFields.fieldFive.min) : 8,
                max: minMaxFields.fieldFive.max ? Number(minMaxFields.fieldFive.max) : 12,
                input_length: minMaxFields.fieldFive.max ? Number(minMaxFields.fieldFive.max) : 0,
                field_type: selectedValues.fieldFive ? Number(selectedValues.fieldFive?.id) : 1,
            },
        }));
    }, [minMaxFields, selectedValues]);

    const submitSettings = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await updateConsignorSetting(formValue);
            if (res?.responseCode == 200) {
                toast.success(`Settings Update`);
                setLoading(false);
            } else {
                toast.error('Error , Settings Not Updated');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            toast.error('Error , Settings Not Updated');
            // throw error;
        }
    }

    console.log("formValue :-", formValue)

    const getDataConsignorDetails = async (id_1, id_2) => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                consignor_id: id_1,
                company_id: id_2,
            }
            const res = await fetchConsignorDetailsData(object);
            if (res?.responseCode == 200) {
                let data = res?.data?.consignor;
                console.log("Data coming from consigner Api :-", data)
                setFormValue((s) => ({
                    ...s,
                    permission_at_dispatch: data.permission_at_dispatch,
                    permission_at_lr: data.permission_at_lr,
                    permission_at_invoice_number: data.permission_at_invoice_number,
                    permission_at_field2: data.permission_at_field2,
                    permission_at_field3: data.permission_at_field3,
                    permission_at_field4: data.permission_at_field4,
                    permission_at_field5: data.permission_at_field5,
                    permission_at_item: data.permission_at_item,
                    permission_at_pod: data.permission_at_pod,
                }))
                setMinMaxFields({
                    invoiceNo: {
                        min: Number(data.permission_at_invoice_number.min),
                        max: Number(data.permission_at_invoice_number.max),
                    },
                    fieldTwo: {
                        min: Number(data.permission_at_field2.min),
                        max: Number(data.permission_at_field2.max),
                    },
                    fieldThree: {
                        min: Number(data.permission_at_field3.min),
                        max: Number(data.permission_at_field3.max),
                    },
                    fieldFour: {
                        min: Number(data.permission_at_field4.min),
                        max: Number(data.permission_at_field4.max),
                    },
                    fieldFive: {
                        min: Number(data.permission_at_field5.min),
                        max: Number(data.permission_at_field5.max),
                    },
                });

                setSelectedValues({
                    invoiceNo: options.find(option => option.id === Number(data.permission_at_invoice_number.field_type)) || options[0],
                    fieldTwo: options.find(option => option.id === Number(data.permission_at_field2.field_type)) || options[0],
                    fieldThree: options.find(option => option.id === Number(data.permission_at_field3.field_type)) || options[0],
                    fieldFour: options.find(option => option.id === Number(data.permission_at_field4.field_type)) || options[0],
                    fieldFive: options.find(option => option.id === Number(data.permission_at_field5.field_type)) || options[0],
                });
            }
        } catch (error) {
            throw error;
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    useEffect(() => {
        if (id && StorageService.getCompanyId()) {
            getDataConsignorDetails(id, StorageService.getCompanyId());
        }
    }, [id])

    return (
        <>
            <div>
                <LoadingBar
                    color="#ffa800"
                    ref={loadingBarRef}
                    height={4}
                    shadow={true}
                    loaderSpeed={1000}
                />
                {/* <ButtonHeading title="Parle Transportation" buttonTitle="Edit" /> */}

                {/* <NavTabs id={id} /> */}

                <div>

                    <div className="_addContainer _shrtY_height">
                        <div className='_allUrs_bx'>

                            <div className="_mainRl_table">
                                <div className='_addRl_headCheck'>
                                    <p className='anvLabel'>Permissions</p>
                                </div>

                                <div className="_addRl_checkList">
                                    <div className='_permisionBx_ls'>
                                        <div className='_headPermission'>At Dispatch</div>
                                        <div className='_brxFlex'>
                                            <div className='_dLass'>
                                                <div>
                                                    <CommonCheckbox
                                                        type="checkbox"
                                                        name="Add LR Mandatory "
                                                        id='lr_mandatory'
                                                        isChecked={formValue?.permission_at_dispatch?.lr_mandatory === 1}
                                                        handleClick={handleCheckboxChange('permission_at_dispatch', 'lr_mandatory')}
                                                    />
                                                </div>
                                            </div>

                                            <div className='_dLass'>
                                                <CommonCheckbox
                                                    type="checkbox"
                                                    name="Add Eway Bill Mandatory"
                                                    id="eway_bill_mandatory_dispatch"
                                                    isChecked={formValue?.permission_at_dispatch?.eway_bill_mandatory === 1}
                                                    handleClick={handleCheckboxChange('permission_at_dispatch', 'eway_bill_mandatory')}
                                                />
                                            </div>

                                            <div className='_dLass'>
                                                <CommonCheckbox
                                                    type="checkbox"
                                                    name="Add Freight Mandatory"
                                                    id="freight_mandatory"
                                                    isChecked={formValue?.permission_at_dispatch?.freight_mandatory === 1}
                                                    handleClick={handleCheckboxChange('permission_at_dispatch', 'freight_mandatory')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='_permisionBx_ls'>
                                        <div className='_headPermission'>Permissions at LR</div>
                                        <div className='_brxFlex'>

                                            <div className='_dLass'>
                                                <CommonCheckbox
                                                    type="checkbox"
                                                    name="Add Unit Mandatory"
                                                    id="unit_mandatory"
                                                    isChecked={formValue?.permission_at_lr?.unit_mandatory === 1}
                                                    handleClick={handleCheckboxChange('permission_at_lr', 'unit_mandatory')}
                                                />
                                            </div>

                                            <div className='_dLass'>
                                                <CommonCheckbox
                                                    type="checkbox"
                                                    name="Add Consignee Mandatory"
                                                    id="consignee_mandatory"
                                                    isChecked={formValue?.permission_at_lr?.consignee_mandatory === 1}
                                                    handleClick={handleCheckboxChange('permission_at_lr', 'consignee_mandatory')}
                                                />
                                            </div>

                                            <div className='_dLass'>
                                                <CommonCheckbox
                                                    type="checkbox"
                                                    name='Add Eway Bill Mandatory'
                                                    id='eway_bill_mandatory'
                                                    isChecked={formValue?.permission_at_lr?.eway_bill_mandatory === 1}
                                                    handleClick={handleCheckboxChange('permission_at_lr', "eway_bill_mandatory")}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='_permisionBx_ls'>
                                        <div className='_headPermission fw-400'>Invoice Number</div>

                                        <div className='_brxFlex gap-x-4'>
                                            <div className='_dLass min-w-auto'>
                                                <CommonCheckbox
                                                    type="checkbox"
                                                    name="View"
                                                    id='invoice_view'
                                                    isChecked={formValue?.permission_at_invoice_number?.view === 1}
                                                    handleClick={handleCheckboxChange('permission_at_invoice_number', 'view')}
                                                />
                                            </div>
                                            <div className='_dLass min-w-auto'>
                                                <CommonCheckbox
                                                    type="checkbox"
                                                    name="Mandatory"
                                                    id="invoice_mandatory"
                                                    isChecked={formValue?.permission_at_invoice_number?.mandatory}
                                                    handleClick={handleCheckboxChange('permission_at_invoice_number', 'mandatory')}
                                                />
                                            </div>

                                        </div>
                                        <div className='_brxFlex'>
                                            {/* <div className='_divFix_wid'>
                                                <CommonInput
                                                    type="number"
                                                    placeholder="Field Size"
                                                    label="Field Size"
                                                    name="input_length"
                                                    value={formValue?.permission_at_invoice_number?.input_length}
                                                    onChange={({ target: { name, value } }) =>
                                                        handleNumberOnChange(name, value, 'permission_at_invoice_number')
                                                    }
                                                />
                                            </div> */}
                                            <div className='_divFix_wid _brxFlex'>
                                                {/* <CommonInput
                                                type="number"
                                                placeholder="Field Size"
                                                label="Field Size"
                                                name="input_length"
                                                value={formValue?.permission_at_invoice_number?.input_length}
                                                onChange={({ target: { name, value } }) =>
                                                    handleNumberOnChange(name, value, 'permission_at_invoice_number')
                                                }
                                            /> */}
                                                <div className='halfDiv'>

                                                    <CommonInput
                                                        type="number"
                                                        placeholder="Minimum Size"
                                                        label="Min Size"
                                                        name="min"
                                                        value={minMaxFields.invoiceNo.min}
                                                        onChange={(e) => handleChangeMinMax('invoiceNo', e)}
                                                    />
                                                </div>
                                                <div className='halfDiv'>

                                                    <CommonInput
                                                        type="number"
                                                        placeholder="Maximum Size"
                                                        label="Max Size"
                                                        name="max"
                                                        value={minMaxFields.invoiceNo.max}
                                                        onChange={(e) => handleChangeMinMax('invoiceNo', e)}
                                                    />
                                                </div>
                                            </div>

                                            <div className='_divFix_wid'>
                                                <CustomSelect
                                                    name="simpleDropdown"
                                                    options={options}
                                                    label='Type'
                                                    value={selectedValues.invoiceNo}
                                                    onSelect={(option) => handleSelectType('invoiceNo', option)}
                                                    placeholder="Select an option"
                                                    disableSearch={true}
                                                />
                                            </div>



                                        </div>
                                    </div>

                                    <div className='_permisionBx_ls'>
                                        <div className='_headPermission fw-400'>Field 2</div>

                                        <div className='_brxFlex gap-x-4'>
                                            <div className='_dLass min-w-auto'>
                                                <div>
                                                    <CommonCheckbox
                                                        type="checkbox"
                                                        name="View"
                                                        id="field2_view"
                                                        isChecked={formValue?.permission_at_field2?.view}
                                                        handleClick={handleCheckboxChange('permission_at_field2', 'view')}
                                                    />
                                                </div>
                                            </div>
                                            <div className='_dLass min-w-auto'>
                                                <div>
                                                    <CommonCheckbox
                                                        type="checkbox"
                                                        name="Mandatory"
                                                        id="field2_mandatory"
                                                        isChecked={formValue?.permission_at_field2?.mandatory}
                                                        handleClick={handleCheckboxChange('permission_at_field2', 'mandatory')}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className='_brxFlex'>

                                            <div className='_divFix_wid'>
                                                <CommonInput
                                                    type="text"
                                                    placeholder="Field Name"
                                                    label="Field Name"
                                                    name="field_name"
                                                    value={formValue?.permission_at_field2?.field_name}
                                                    onChange={({ target: { name, value } }) =>
                                                        handleStringOnChange(name, value, 'permission_at_field2')
                                                    }
                                                />
                                            </div>

                                            <div className='_divFix_wid _brxFlex'>
                                                {/* <CommonInput
                                                type="number"
                                                placeholder="Field Size"
                                                label="Field Size"
                                                name="input_length"
                                                value={formValue?.permission_at_field2?.input_length}
                                                onChange={({ target: { name, value } }) =>
                                                    handleNumberOnChange(name, value, 'permission_at_field2')
                                                }
                                            /> */}
                                                <div className='halfDiv'>

                                                    <CommonInput
                                                        type="number"
                                                        placeholder="Minimum Size"
                                                        label="Min Size"
                                                        name="min"
                                                        value={minMaxFields.fieldTwo.min}
                                                        onChange={(e) => handleChangeMinMax('fieldTwo', e)}
                                                    />
                                                </div>
                                                <div className='halfDiv'>

                                                    <CommonInput
                                                        type="number"
                                                        placeholder="Maximum Size"
                                                        label="Max Size"
                                                        name="max"
                                                        value={minMaxFields.fieldTwo.max}
                                                        onChange={(e) => handleChangeMinMax('fieldTwo', e)}
                                                    />
                                                </div>
                                            </div>

                                            <div className='_divFix_wid'>
                                                <CustomSelect
                                                    name="simpleDropdown"
                                                    options={options}
                                                    label='Type'
                                                    value={selectedValues.fieldTwo}
                                                    onSelect={(option) => handleSelectType('fieldTwo', option)}
                                                    placeholder="Select an option"
                                                    disableSearch={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='_permisionBx_ls'>
                                        <div className='_headPermission fw-400'>Field 3</div>

                                        <div className='_brxFlex gap-x-4'>
                                            <div className='_dLass min-w-auto'>
                                                <div>
                                                    <CommonCheckbox
                                                        type="checkbox"
                                                        name="View"
                                                        id="field3_view"
                                                        isChecked={formValue?.permission_at_field3?.view}
                                                        handleClick={handleCheckboxChange('permission_at_field3', 'view')}
                                                    />
                                                </div>
                                            </div>
                                            <div className='_dLass min-w-auto'>
                                                <div>
                                                    <CommonCheckbox
                                                        type="checkbox"
                                                        name="Mandatory"
                                                        id="field3_mandatory"
                                                        isChecked={formValue?.permission_at_field3?.mandatory}
                                                        handleClick={handleCheckboxChange('permission_at_field3', 'mandatory')}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className='_brxFlex'>

                                            <div className='_divFix_wid'>
                                                <CommonInput
                                                    type="text"
                                                    placeholder="Field Name"
                                                    label="Field Name"
                                                    name="field_name"
                                                    value={formValue?.permission_at_field3?.field_name}
                                                    onChange={({ target: { name, value } }) =>
                                                        handleStringOnChange(name, value, 'permission_at_field3')
                                                    }
                                                />
                                            </div>

                                            <div className='_divFix_wid _brxFlex'>
                                                {/* <CommonInput
                                                type="number"
                                                placeholder="Field Size"
                                                label="Field Size"
                                                name="input_length"
                                                value={formValue?.permission_at_field3?.input_length}
                                                onChange={({ target: { name, value } }) =>
                                                    handleNumberOnChange(name, value, 'permission_at_field3')
                                                }
                                            /> */}
                                                <div className='halfDiv'>

                                                    <CommonInput
                                                        type="number"
                                                        placeholder="Minimum Size"
                                                        label="Min Size"
                                                        name="min"
                                                        value={minMaxFields.fieldThree.min}
                                                        onChange={(e) => handleChangeMinMax('fieldThree', e)}
                                                    />
                                                </div>
                                                <div className='halfDiv'>

                                                    <CommonInput
                                                        type="number"
                                                        placeholder="Maximum Size"
                                                        label="Max Size"
                                                        name="max"
                                                        value={minMaxFields.fieldThree.max}
                                                        onChange={(e) => handleChangeMinMax('fieldThree', e)}
                                                    />
                                                </div>
                                            </div>


                                            <div className='_divFix_wid'>
                                                <CustomSelect
                                                    name="fieldThree"
                                                    options={options}
                                                    label='Type'
                                                    value={selectedValues.fieldThree}
                                                    onSelect={(option) => handleSelectType('fieldThree', option)}
                                                    placeholder="Select an option"
                                                    disableSearch={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='_permisionBx_ls'>
                                        <div className='_headPermission fw-400'>Field 4</div>

                                        <div className='_brxFlex gap-x-4'>
                                            <div className='_dLass min-w-auto'>
                                                <div>
                                                    <CommonCheckbox
                                                        type="checkbox"
                                                        name="View"
                                                        id="field4_view"
                                                        isChecked={formValue?.permission_at_field4?.view}
                                                        handleClick={handleCheckboxChange('permission_at_field4', 'view')}
                                                    />
                                                </div>
                                            </div>
                                            <div className='_dLass min-w-auto'>
                                                <div>
                                                    <CommonCheckbox
                                                        type="checkbox"
                                                        name="Mandatory"
                                                        id="field4_mandatory"
                                                        isChecked={formValue?.permission_at_field4?.mandatory}
                                                        handleClick={handleCheckboxChange('permission_at_field4', 'mandatory')}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className='_brxFlex'>

                                            <div className='_divFix_wid'>
                                                <CommonInput
                                                    type="text"
                                                    placeholder="Field Name"
                                                    label="Field Name"
                                                    name="field_name"
                                                    value={formValue?.permission_at_field4?.field_name}
                                                    onChange={({ target: { name, value } }) =>
                                                        handleStringOnChange(name, value, 'permission_at_field4')
                                                    }
                                                />
                                            </div>


                                            <div className='_divFix_wid _brxFlex'>
                                                {/* <CommonInput
                                                    type="number"
                                                    placeholder="Field Size"
                                                    label="Field Size"
                                                    name="input_length"
                                                    value={formValue?.permission_at_field4?.input_length}
                                                    onChange={({ target: { name, value } }) =>
                                                        handleNumberOnChange(name, value, 'permission_at_field4')
                                                    }
                                                /> */}
                                                <div className='halfDiv'>
                                                    <CommonInput
                                                        type="number"
                                                        placeholder="Minimum Size"
                                                        label="Min Size"
                                                        name="min"
                                                        value={minMaxFields.fieldFour.min}
                                                        onChange={(e) => handleChangeMinMax('fieldFour', e)}
                                                    />

                                                </div>
                                                <div className='halfDiv'>
                                                    <CommonInput
                                                        type="number"
                                                        placeholder="Maximum Size"
                                                        label="Max Size"
                                                        name="max"
                                                        value={minMaxFields.fieldFour.max}
                                                        onChange={(e) => handleChangeMinMax('fieldFour', e)}
                                                    />

                                                </div>
                                            </div>

                                            <div className='_divFix_wid'>
                                                <CustomSelect
                                                    name="fieldFour"
                                                    options={options}
                                                    label='Type'
                                                    value={selectedValues.fieldFour}
                                                    onSelect={(option) => handleSelectType('fieldFour', option)}
                                                    placeholder="Select an option"
                                                    disableSearch={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='_permisionBx_ls'>
                                        <div className='_headPermission fw-400'>Field 5</div>

                                        <div className='_brxFlex gap-x-4'>
                                            <div className='_dLass min-w-auto'>
                                                <div>
                                                    <CommonCheckbox
                                                        type="checkbox"
                                                        name="View"
                                                        id="field5_view"
                                                        isChecked={formValue?.permission_at_field5?.view}
                                                        handleClick={handleCheckboxChange('permission_at_field5', 'view')}
                                                    />
                                                </div>
                                            </div>
                                            <div className='_dLass min-w-auto'>
                                                <div>
                                                    <CommonCheckbox
                                                        type="checkbox"
                                                        name="Mandatory"
                                                        id="field5_mandatory"
                                                        isChecked={formValue?.permission_at_field5?.mandatory}
                                                        handleClick={handleCheckboxChange('permission_at_field5', 'mandatory')}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className='_brxFlex'>
                                            <div className='_divFix_wid'>
                                                <CommonInput
                                                    type="text"
                                                    placeholder="Field Name"
                                                    label="Field Name"
                                                    name="field_name"
                                                    value={formValue?.permission_at_field5?.field_name}
                                                    onChange={({ target: { name, value } }) =>
                                                        handleStringOnChange(name, value, 'permission_at_field5')
                                                    }
                                                />
                                            </div>


                                            <div className='_divFix_wid _brxFlex'>
                                                {/* <CommonInput
                                                    type="number"
                                                    placeholder="Field Size"
                                                    label="Field Size"
                                                    name="input_length"
                                                    value={formValue?.permission_at_field5?.input_length}
                                                    onChange={({ target: { name, value } }) =>
                                                        handleNumberOnChange(name, value, 'permission_at_field5')
                                                    }
                                                /> */}
                                                <div className='halfDiv'>

                                                    <CommonInput
                                                        type="number"
                                                        placeholder="Minimum Size"
                                                        label="Min Size"
                                                        name="min"
                                                        value={minMaxFields.fieldFive.min}
                                                        onChange={(e) => handleChangeMinMax('fieldFive', e)}
                                                    />
                                                </div>
                                                <div className='halfDiv'>

                                                    <CommonInput
                                                        type="number"
                                                        placeholder="Maximum Size"
                                                        label="Max Size"
                                                        name="max"
                                                        value={minMaxFields.fieldFive.max}
                                                        onChange={(e) => handleChangeMinMax('fieldFive', e)}
                                                    />
                                                </div>
                                            </div>

                                            <div className='_divFix_wid'>
                                                <CustomSelect
                                                    name="fieldFive"
                                                    options={options}
                                                    label='Type'
                                                    value={selectedValues.fieldFive}
                                                    onSelect={(option) => handleSelectType('fieldFive', option)}
                                                    placeholder="Select an option"
                                                    disableSearch={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='_permisionBx_ls'>
                                        <div className='_headPermission'>Permissions at Item</div>
                                        <div className='_brxFlex gap-x-4'>
                                            <label className="fix_width"> Item / Group / HSN </label>
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="View"
                                                id="item_group_hsn_view"
                                                isChecked={true}
                                                disabled={true}
                                            // isChecked={formValue?.permission_at_item?.item_group_hsn?.view}
                                            // handleClick={checkboxHandlerNestedObject('permission_at_item', 'item_group_hsn', 'view')}
                                            />
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="Mandatory"
                                                id="item_group_hsn_mandatory"
                                                isChecked={formValue?.permission_at_item?.item_group_hsn?.mandatory}
                                                handleClick={checkboxHandlerNestedObject('permission_at_item', 'item_group_hsn', 'mandatory')}
                                            />
                                        </div>
                                        <div className='_brxFlex gap-x-4'>
                                            <label className="fix_width"> Weight </label>
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="View"
                                                id="weight_view"
                                                isChecked={formValue?.permission_at_item?.weight?.view}
                                                handleClick={checkboxHandlerNestedObject('permission_at_item', 'weight', 'view')}
                                            />
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="Mandatory"
                                                id="weight_mandatory"
                                                isChecked={formValue?.permission_at_item?.weight?.mandatory}
                                                handleClick={checkboxHandlerNestedObject('permission_at_item', 'weight', 'mandatory')}
                                            />
                                        </div>
                                        <div className='_brxFlex gap-x-4'>
                                            <label className="fix_width"> Kot </label>
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="View"
                                                id="kot_view"
                                                isChecked={formValue?.permission_at_item?.kot?.view}
                                                handleClick={checkboxHandlerNestedObject('permission_at_item', 'kot', 'view')}
                                            />
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="Mandatory"
                                                id="kot_mandatory"
                                                isChecked={formValue?.permission_at_item?.kot?.mandatory}
                                                handleClick={checkboxHandlerNestedObject('permission_at_item', 'kot', 'mandatory')}
                                            />
                                        </div>
                                        <div className='_brxFlex gap-x-4'>
                                            <label className="fix_width"> Sales </label>
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="View"
                                                id="sales_view"
                                                isChecked={formValue?.permission_at_item?.sales?.view}
                                                handleClick={checkboxHandlerNestedObject('permission_at_item', 'sales', 'view')}
                                            />
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="Mandatory"
                                                id="sales_mandatory"
                                                isChecked={formValue?.permission_at_item?.sales?.mandatory}
                                                handleClick={checkboxHandlerNestedObject('permission_at_item', 'sales', 'mandatory')}
                                            />
                                        </div>
                                        <div className='_brxFlex gap-x-4'>
                                            <label className="fix_width"> Qty </label>
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="View"
                                                id="qty_view"
                                                isChecked={formValue?.permission_at_item?.qty?.view}
                                                handleClick={checkboxHandlerNestedObject('permission_at_item', 'qty', 'view')}
                                            />
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="Mandatory"
                                                id="qty_mandatory"
                                                isChecked={formValue?.permission_at_item?.qty?.mandatory}
                                                handleClick={checkboxHandlerNestedObject('permission_at_item', 'qty', 'mandatory')}
                                            />
                                        </div>
                                    </div>

                                    <div className='_permisionBx_ls'>
                                        <div className='_headPermission'> POD Submissions </div>
                                        <div className='_brxFlex gap-x-4'>
                                            <label className='fix_width'> Attachment </label>
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="View"
                                                id="attachment_view"
                                                isChecked={formValue?.permission_at_pod?.attachment?.view}
                                                handleClick={checkboxHandlerNestedObject('permission_at_pod', 'attachment', 'view')}
                                            />
                                            <CommonCheckbox
                                                type="checkbox"
                                                name="Mandatory"
                                                id="attachment_mandatory"
                                                isChecked={formValue?.permission_at_pod?.attachment?.mandatory}
                                                handleClick={checkboxHandlerNestedObject('permission_at_pod', 'attachment', 'mandatory')}
                                            />
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="bottomFix">
                        <AddButton onClick={(e) => submitSettings(e)} name={loading ? 'Loading...' : "Save Changes"} type="button" disabled={loading} />
                        <button className="_cancel_btn" onClick={(e) => navigate(-1)}> Cancel </button>
                    </div>
                </div>


            </div >
        </>
    )
}

export default Settings