import React, { useEffect } from 'react';
import { PiWarning } from "react-icons/pi";

const ConfirmModalFor = ({ show, closeModal, onSubmit, isLoadding, disabled, modalMainClass, children, title, submitTitle, icon,modalZIndex }) => {

  if (!show) return null
  return (
    <>
      <div>
        {show && (
          <div className={`modal fade show d-block _dashCommon_Modal  ${modalMainClass}`} tabIndex="-1" role="dialog"  style={{ zIndex: modalZIndex }}  >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">

                  <h5 className="modal-title d-flex align-items-center gap-1" id="exampleModalLabel">
                    {
                      icon ?
                        <img src={icon} alt="" />
                        :
                        <span className='warning_icn'> <PiWarning /> </span>
                    }  <span>{title}</span> </h5>
                </div>

                <div className='modal-body ps-4 pb-4'>
                  {children}
                </div>

                <div className="modal-footer">
                  <button type="button" className="_addComm_btn" onClick={onSubmit} disabled={disabled}>{isLoadding ? "Loading..." : submitTitle}</button>
                  <button type="button" className="_cancel_btn" onClick={closeModal}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {show && <div className="modal-backdrop fade show" onClick={closeModal}></div>}

      </div>
    </>

  );
};

export default ConfirmModalFor