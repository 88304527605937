import {
  getAccountList,
  getConsignorTransactionList,
  handleKeyDown,
} from "_services";
import { CommonInput, CommonModal, CustomSelect } from "components";
import { Images } from "constants";
import images from "constants/images";
import { UserContext } from "context";
import { utils } from "helper";
import {
  AddBankAccount,
  AddClaimForConsignor,
  AddEditLrModal,
  BankListCanvas,
  ConfirmModalFor,
  EditConsignorFreight,
  LrDetailModal,
  OtherExpenseModal,
} from "modals";
import AddChargeModal from "modals/AddChargeModal/AddChargeModal";
import Loading from "pages/Loading";
import React, { useContext, useEffect, useState } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";

const ConsignorFreight = (props) => {
  const { freightId, callApi, tripDetais } = props;

  const { globTripDetails, setBankDetails } = useContext(UserContext);
  const [selectLrOpen, setSelectLrOpen] = useState(false);
  const [paySupplierOpen, setPaySupplierOpen] = useState(false);
  const [consignorTransList, setConsignorTransList] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isOpenCharge, setIsOpenCharges] = useState(false);
  const [isOpenClaim, setIsOpenClaim] = useState(false);
  const [lrDetails, setLrDetails] = useState();
  const [lrId, setLrId] = useState();
  const [freghtSummary, setFreghtSummary] = useState();
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [showAddBank, setShowAddBank] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [isOpenExpense, setIsOpenExpense] = useState({ open: false, type: "" });
  const [editLrAmount, setEditLrAmount] = useState(false);
  const [editData, setEditData] = useState('');
  const [initalEditData, setInitialEditData] = useState('');
  const [showLrDetail, setShowLrDetail] = useState(false);
  const [showAddLR, setShowAddLR] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false)
  const [confirmModalShow, setConfirmModalShow] = useState(false);
const [transationDetails,setTransationDetails] = useState()

  const toggleAddClaimCanvas = () => {
    setSelectLrOpen(!selectLrOpen);
  };

  const togglePayClaimCanvas = () => {
    setPaySupplierOpen(!paySupplierOpen);
  };

  useEffect(() => {
    getConsignorTransActionHandler(freightId);
    getAccountListHandler(globTripDetails?.supplier_id?._id);
  }, [freightId, tripDetais]);

  useEffect(() => {
    const list = consignorTransList?.find((i) => i?._id === lrId);
    setFreghtSummary(list);
  }, [lrId]);

  useEffect(() => {
    if (consignorTransList?.length) {
      if (!lrId) {
        setLrId(consignorTransList[0]._id);
      }
    }
  }, [consignorTransList]);

  useEffect(() => {
    if (transationDetails) {
      setConfirmModalShow(true);
    }
  }, [transationDetails]);

  const arryOptions = [
    { label: "Arjun", value: "01" },
    { label: "Anvit", value: "02" },
  ];

  const handleDelete = (item, index, indexTable) => {

  }

  const getConsignorTransActionHandler = async (freightId) => {
    setFlag(true);
    try {
      const res = await getConsignorTransactionList({
        company_id: StorageService.getCompanyId(),
        trip_id: freightId,
      });

      if (res?.responseCode === 200) {
        setFlag(false);
        setConsignorTransList(res?.data?.lr_list);
        const list = res?.data?.lr_list?.find((i) => i?._id === lrId);
        setFreghtSummary(list);
      } else {
        setFlag(false);
        console.log(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      setFlag(false);
    }
  };

  const getAccountListHandler = async (id) => {
    setAccountLoading(false);
    try {
      const res = await getAccountList({
        company_id: StorageService?.getCompanyId(),
        vendor_type: 1,
        vendor_id: id,
      });

      if (res?.responseCode === 200) {
        setAccountLoading(false);
        const details = res?.data?.bank_account?.map((item) => {
          return {
            ...item,
            label: item?.name,
            value: item?.id,
          };
        });
        setAccountList(details);
      } else {
        setAccountLoading(false);
        console.log(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openChargesHandle = async (data) => {
    await setLrDetails(data);
    setIsOpenCharges(data);
  };

  const openClaimHandle = async (data) => {
    await setLrDetails(data);
    setIsOpenClaim(data);
  };

  const callBackCharge = () => {
    getConsignorTransActionHandler(freightId);
    setIsOpenCharges(false);
    setIsOpenClaim(false);
    callApi();
  };

  const callBackAddAccount = () => {
    setCanvasOpen(true);
    getAccountListHandler(globTripDetails?.supplier_id?._id);
  };

  const closeClaimModal = () => {
    setIsOpenClaim(false);
    setBankDetails("");
  };

  const submitUpdateHandle = () => { };

  const handleDelteSubmit = (i) => {
    setTransationDetails(i);
  };

  const handleDetailsAction = (data) => {
    setShowLrDetail(data);
  };

  const handleNextModal = (data) => {
    setShowLrDetail(!showLrDetail);
    setShowAddLR(data);
  };

  const callBackDataLr = (data) => {
    callApi();
    // if (data?.branch_id) {
    //   addTable();
    //   const newList = [...listOfLr];
    //   newList[indexofTable].list[listOfLr[indexofTable].list.length] = data;
    //   const newSelect = [...selectedLr];
    //   newSelect[indexofTable]?.list.push(data);
    //   setSelectedLr(newSelect);
    //   setListOfLr(newList);
    // }
  };

  let chargeProps = {
    isOpen: isOpenCharge,
    closeModal: (e) => setIsOpenCharges(false),
    headerPart: true,
    footerPart: true,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add Freight",
    submitTitle: "Add Freight",
    cancelButtonTitle: "Send for Approval",
    tripId: freightId,
    lrDetails: lrDetails,
    callBackCharge: callBackCharge,
  };

  let claimProps = {
    isOpen: isOpenClaim,
    closeModal: closeClaimModal,
    headerPart: true,
    footerPart: true,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add Claim",
    tripId: freightId,
    lrDetails: lrDetails,
    callBackCharge: callBackCharge,
    setCanvasOpen: setCanvasOpen,
    canvasOpen: canvasOpen,
    accountList: accountList,
  };

  let bankListProps = {
    setCanvasOpen: setCanvasOpen,
    canvasOpen: canvasOpen,
    setShowAddBank: setShowAddBank,
    accountList: accountList,
    setIsOpenFreight: setIsOpenClaim,
  };

  const accountClose = () => {
    setShowAddBank(false);
    setCanvasOpen(true);
  };

  let firstAddBank = {
    isOpen: showAddBank,
    closeModal: accountClose,
    headerPart: false,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add Bank Account",
    submitTitle: "Continue",
    callBackAddAccount: callBackAddAccount,
  };

  let otherExpenseProps = {
    isOpen: isOpenExpense.open,
    modalType: isOpenExpense.type,
    closeModal: (e) =>
      setIsOpenExpense({ ...isOpenExpense, open: false, type: "" }),
    headerPart: true,
    footerPart: true,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Other",
    submitTitle: "Approval",
    tripId: freightId,
    callBackFreight: callBackCharge,
    // transactionList: transactionList,
  };

  // let propsObject = {
  //   show: editLrAmount,
  //   closeModal: (e) => setEditLrAmount(false),
  //   title: "Update",
  //   submitTitle: "Update",
  //   onSubmit: submitUpdateHandle,
  //   icon: images.EDIT,
  //   // isLoadding:ewayLoading,
  //   disabled: initalAmount == amount,
  // };
  console.log("editData", editData)

  let addTotalProps = {
    isOpen: editLrAmount,
    closeModal: (e) => {
      setEditLrAmount(false)
    },
    headerPart: false,
    footerPart: true,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Edit Freight Amount",
    submitTitle: "Update Amount",
    tripId: freightId,
    data: editData,
    setEditData: setEditData,
    icon: images.EDIT,
  };


   let confirmModalProps = {
    show: confirmModalShow,
    closeModal: (e) => {setConfirmModalShow(false);  
      setTransationDetails("")}
   ,
    title: "Confirm",
    submitTitle: "Delete",
    onSubmit: handleDelete,
    confirmModal: "confirmModal",
    icon: Images.DELETEICON.default,
  };

  return (
    <>
      {consignorTransList?.length > 0 ? (
        <div className="anv_tripTab_otr">
          <div className="row anvRG_gap">
            <div className="col-12">
              <div className="anvFrightUpp_tabBx_tp">
                <div className="anvFrightInden_detailBx_tp">
                  <div className="sprt_dix">
                    <p>Revenue</p>
                    <h2>{`₹ ${(
                      globTripDetails?.consignor_freight?.total_freight ? globTripDetails?.consignor_freight?.total_freight : 0 +
                        globTripDetails?.consignor_freight?.receivable ? globTripDetails?.consignor_freight?.receivable : 0 -
                          globTripDetails?.consignor_freight?.payble ? globTripDetails?.consignor_freight?.payble : 0
                    )?.toFixed(2)}`}</h2>
                  </div>

                  <div className="sprt_dix">
                    <p>Total Balance</p>
                    <h2>{`₹ ${globTripDetails?.consignor_freight?.balance ? globTripDetails?.consignor_freight?.balance : 0?.toFixed(
                      2
                    )}`}</h2>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="anv_freightTable_otr_tpBx _anvfrg_table">
                <div className="anv_freightTable_detail_tpBx">
                  <div className="accordionTable_view">
                    <div className="accordion" id="accordionConFright">
                      {consignorTransList &&
                        consignorTransList.map((item, index) => {
                          const isFirstItem = index === 0;
                          return (
                            <div
                              className="accordion-item"
                              key={`${index}_${item?._id}`}
                            >
                              <h2 className="accordion-header">
                                <div className="PickNdrop_placeBx">
                                  <div className="_cnFr_listsBx">
                                    <div>
                                      <div className="_inrPd">
                                        <h4>{item?.from_location}</h4>
                                        <img
                                          src={Images.ROUTTRUCKICON.default}
                                          alt=""
                                        />
                                        <h4>{item?.to_location}</h4>
                                      </div>
                                      <div className="_pdTransport_dl">
                                        <h4>{item?.consignor_id?.name}</h4>
                                        <span>
                                          {item?.invoice_number
                                            ? `INV- ${item?.invoice_number}`
                                            : ""}
                                        </span>
                                        <span
                                          onClick={(e) =>
                                            item?.lr_number
                                              ? handleDetailsAction(item)
                                              : ""
                                          }
                                        >
                                          {item?.lr_number
                                            ? `#${item?.lr_number}`
                                            : ""}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="_flxRight">
                                      <button
                                        className="_numEdits_btn"
                                        onClick={() => {
                                          setEditData(
                                            consignorTransList[
                                              index
                                            ]?.lr_freight
                                          );
                                          setInitialEditData(
                                            consignorTransList[
                                              index
                                            ]?.lr_freight
                                          );
                                          setEditLrAmount(true);
                                        }}
                                      >
                                        <span>
                                          {consignorTransList[
                                            index
                                          ]?.lr_freight?.total_freight?.toFixed(
                                            2
                                          )}
                                        </span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="17"
                                          height="17"
                                          viewBox="0 0 17 17"
                                          fill="none"
                                        >
                                          <path
                                            d="M3.64706 4.52942H2.76471C2.29668 4.52942 1.84782 4.71534 1.51687 5.04629C1.18592 5.37724 1 5.8261 1 6.29413V14.2353C1 14.7033 1.18592 15.1522 1.51687 15.4831C1.84782 15.8141 2.29668 16 2.76471 16H10.7059C11.1739 16 11.6228 15.8141 11.9537 15.4831C12.2847 15.1522 12.4706 14.7033 12.4706 14.2353V13.353"
                                            stroke="#0049D7"
                                            strokeWidth="1.76469"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M11.5881 2.7648L14.2352 5.41186M15.4572 4.16333C15.8047 3.81582 16 3.34449 16 2.85304C16 2.36158 15.8047 1.89025 15.4572 1.54274C15.1097 1.19523 14.6384 1 14.1469 1C13.6555 1 13.1841 1.19523 12.8366 1.54274L5.41162 8.94128V11.5883H8.05868L15.4572 4.16333Z"
                                            stroke="#0049D7"
                                            strokeWidth="1.76469"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                      <button
                                        className={`accordion-button ${isFirstItem ? '' : 'collapsed'}`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#${index}_${item?._id}`}
                                        aria-expanded={isFirstItem ? "true" : "false"}
                                        aria-controls={`${index}_${item?._id}`}
                                        onClick={() => setLrId(item?._id)}
                                      >
                                        <svg
                                          className="cstCevs"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                        >
                                          <path
                                            d="M4.91862 6.69936C4.87178 6.6502 4.81668 6.61176 4.75647 6.58625C4.69625 6.56074 4.63211 6.54865 4.56769 6.55068C4.50327 6.5527 4.43984 6.5688 4.38102 6.59805C4.3222 6.6273 4.26915 6.66913 4.22488 6.72116C4.18062 6.77319 4.14602 6.83439 4.12305 6.90127C4.10008 6.96815 4.08919 7.0394 4.09102 7.11095C4.09284 7.18251 4.10733 7.25296 4.13367 7.31829C4.16 7.38362 4.19767 7.44255 4.24451 7.49172L8.6601 12.1238C8.75119 12.2194 8.87179 12.2727 8.99715 12.2727C9.12251 12.2727 9.24312 12.2194 9.33421 12.1238L13.7503 7.49172C13.7982 7.44288 13.8368 7.38396 13.864 7.31839C13.8912 7.25282 13.9064 7.1819 13.9087 7.10975C13.911 7.0376 13.9004 6.96566 13.8774 6.89811C13.8545 6.83056 13.8197 6.76875 13.7751 6.71626C13.7305 6.66377 13.6769 6.62165 13.6175 6.59235C13.5581 6.56305 13.494 6.54715 13.4291 6.54558C13.3641 6.54401 13.2995 6.5568 13.239 6.5832C13.1785 6.60959 13.1233 6.64908 13.0767 6.69936L8.99715 10.9783L4.91862 6.69936Z"
                                            fill="#1450EC"
                                          />
                                          <circle
                                            cx="9"
                                            cy="9"
                                            r="8.59091"
                                            transform="rotate(-180 9 9)"
                                            stroke="#0049D7"
                                            strokeWidth="0.818182"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </h2>

                              <div
                                id={`${index}_${item?._id}`}
                                className={`accordion-collapse collapse ${isFirstItem ? 'show' : ''}`}
                                data-bs-parent="#accordionConFright"
                              >
                                <div className="accordion-body">
                                  <table className="ctmTable">
                                    <thead>
                                      <tr className="accordiTr">
                                        <th>Date</th>
                                        <th>Voucher Number</th>
                                        <th>Category</th>
                                        <th>Description</th>
                                        <th>From Account</th>
                                        <th>To Account</th>
                                        <th>Amount</th>
                                        <th></th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {item?.transactions?.length > 0 ? (
                                        item?.transactions?.map((k, indexk) => {
                                          console.log(k)
                                          return (
                                            <tr key={`${indexk}_${k?._id}`}>
                                              <td>
                                                <span>
                                                  {utils?.formatDateMonth(
                                                    k?.date
                                                  )}
                                                </span>
                                                <br />
                                                {/* <span className="sameSpan">
                                                5:30 PM
                                              </span> */}
                                              </td>

                                              <td>
                                                <span className="sameSpan">
                                                  {k?.voucher_no}
                                                </span>
                                              </td>

                                              <td>
                                                <span className="sameSpan fw-500">
                                                  {k?.expense_category?.name}
                                                </span>
                                                <br />
                                                <span className="sameSpan">
                                                  {k?.trip?.trip_number} |{" "}
                                                  {k?.sub_type_name?k?.sub_type_name:""}
                                                </span>
                                              </td>
                                              <td className="sameSpan">
                                                <>
                                                  <div className="fBold">
                                                    {k?.trip?.trip_number}
                                                  </div>

                                                  <div>
                                                    {k?.trip?.route_name}{" "}
                                                    {k?.description
                                                      ? `| ${k?.description
                                                        ?.length > 6
                                                        ? k?.description.slice(
                                                          0,
                                                          6
                                                        ) + "..."
                                                        : k?.description
                                                      }`
                                                      : ""}

                                                  </div>
                                                </>
                                              </td>
                                              <td>
                                                <span className="sameSpan fw-500">
                                                  {k?.account_name}
                                                </span>
                                                <br />

                                              </td>

                                              <td>
                                                <span className="sameSpan fw-500">
                                                  {k?.trip?.supplier_id?.name
                                                    ? k?.trip?.supplier_id?.name
                                                    : k?.trip?.driver_id?.name}
                                                </span>
                                                <br />
                                                {/* <span className="sameSpan">
                                                Cash
                                              </span> */}
                                              </td>

                                              <td>
                                                <span className="sameSpan">
                                                  {k?.credit_or_debit === 2
                                                    ? k?.debit_amount?.toFixed(2)
                                                    : k?.credit_amount?.toFixed(
                                                      2
                                                    )}
                                                </span>
                                              </td>

                                              <td align="right" >
                                                <div className="_rSide_drp">
                                                  <div className="signDropdown">
                                                    <button className="dropbtn11">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="25"
                                                        height="25"
                                                        viewBox="0 0 25 25"
                                                        fill="none"
                                                      >
                                                        <rect
                                                          x="0.390625"
                                                          y="0.390625"
                                                          width="24.2188"
                                                          height="24.2188"
                                                          rx="2.73438"
                                                          stroke="#0049D7"
                                                          strokeWidth="0.78125"
                                                        />
                                                        <path
                                                          d="M12.5 7.8125C13.3629 7.8125 14.0625 7.11295 14.0625 6.25C14.0625 5.38706 13.3629 4.6875 12.5 4.6875C11.6371 4.6875 10.9375 5.38706 10.9375 6.25C10.9375 7.11295 11.6371 7.8125 12.5 7.8125Z"
                                                          fill="#0049D7"
                                                        />
                                                        <path
                                                          d="M12.5 14.0625C13.3629 14.0625 14.0625 13.3629 14.0625 12.5C14.0625 11.6371 13.3629 10.9375 12.5 10.9375C11.6371 10.9375 10.9375 11.6371 10.9375 12.5C10.9375 13.3629 11.6371 14.0625 12.5 14.0625Z"
                                                          fill="#0049D7"
                                                        />
                                                        <path
                                                          d="M12.5 20.3125C13.3629 20.3125 14.0625 19.6129 14.0625 18.75C14.0625 17.8871 13.3629 17.1875 12.5 17.1875C11.6371 17.1875 10.9375 17.8871 10.9375 18.75C10.9375 19.6129 11.6371 20.3125 12.5 20.3125Z"
                                                          fill="#0049D7"
                                                        />
                                                      </svg>
                                                    </button>
                                                    <div className="dropdownContent">
                                                      <Link
                                                        // to={`/setting/user/adduser/${item?.user_id?._id}`}
                                                        className='_avPointer'
                                                        style={{ textDecoration: 'none' }}
                                                      >
                                                        <span className='_samAnchor'>
                                                          <span className='viewIcon_avt'>
                                                            <FiEdit />
                                                          </span> Edit
                                                        </span>
                                                      </Link>

                                                      <div
                                                        onClick={(e) => {
                                                          handleDelteSubmit(
                                                            item
                                                          );
                                                        }}
                                                        className='_avPointer'
                                                      >
                                                        <span className='_samAnchor'>
                                                          <span className='deleteIcon_avt'>
                                                            <FaRegTrashCan color='red' />
                                                          </span> Delete
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>No Transaction</tr>
                                      )}
                                    </tbody>

                                    <tfoot>
                                      <tr>
                                        <td colspan="7">
                                          <div className="_muliAddbtns_bx">
                                            <button
                                              className="addAble_btn addFreight"
                                              onClick={() =>
                                                openChargesHandle(item)
                                              }
                                            >
                                              <div className="_otrIcon_imb">
                                                <img
                                                  src={Images.RUPEE}
                                                  alt=""
                                                />
                                              </div>
                                              <span>Add Freight</span>
                                            </button>

                                            <button
                                              className="addAble_btn addClaim"
                                              onClick={() =>
                                                openClaimHandle(item)
                                              }
                                            >
                                              <div className="_otrIcon_imb">
                                                <img
                                                  src={Images.CLAIM}
                                                  alt=""
                                                />
                                              </div>
                                              <span>Add Claims</span>
                                            </button>

                                            <button
                                              className="addAble_btn addOthrExp"
                                              onClick={() =>
                                                setIsOpenExpense({
                                                  open: item,
                                                  type: "consignor_claim",
                                                })
                                              }
                                            >
                                              <div className="_otrIcon_imb">
                                                <img
                                                  src={Images.EXPLORE}
                                                  alt=""
                                                />
                                              </div>
                                              <span>Add Other Expenses</span>
                                            </button>
                                          </div>
                                        </td>
                                        <td></td>
                                        {/* <td className="_totalAtm _totBold">
                                          Total
                                        </td>
                                        <td className="_totalAtm _totBold">
                                          50000.00
                                        </td> */}
                                        <td></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div className="anvFreight_smry_tpBx">
                  <div className="anvFreight_smrySticky">
                    <h2>
                      Freight Summary{" "}
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="38"
                          height="34"
                          viewBox="0 0 38 34"
                          fill="none"
                        >
                          <rect
                            x="0.386364"
                            y="0.386364"
                            width="37.0909"
                            height="33.2273"
                            rx="2.70455"
                            fill="#0049D7"
                            stroke="#D9E6EF"
                            strokeWidth="0.772727"
                          />
                          <path
                            d="M23 21C23.2449 21 23.4813 21.09 23.6644 21.2527C23.8474 21.4155 23.9643 21.6397 23.993 21.883L24 22V26C24 26.2449 23.91 26.4813 23.7473 26.6644C23.5845 26.8474 23.3603 26.9643 23.117 26.993L23 27H15C14.7551 27 14.5187 26.91 14.3356 26.7473C14.1526 26.5845 14.0357 26.3603 14.007 26.117L14 26V22C14 21.7551 14.09 21.5187 14.2527 21.3356C14.4155 21.1526 14.6397 21.0357 14.883 21.007L15 21H23ZM26 12C26.7956 12 27.5587 12.3161 28.1213 12.8787C28.6839 13.4413 29 14.2044 29 15V22C29 22.5304 28.7893 23.0391 28.4142 23.4142C28.0391 23.7893 27.5304 24 27 24H26V21C26 20.4696 25.7893 19.9609 25.4142 19.5858C25.0391 19.2107 24.5304 19 24 19H14C13.4696 19 12.9609 19.2107 12.5858 19.5858C12.2107 19.9609 12 20.4696 12 21V24H11C10.4696 24 9.96086 23.7893 9.58579 23.4142C9.21071 23.0391 9 22.5304 9 22V15C9 14.2044 9.31607 13.4413 9.87868 12.8787C10.4413 12.3161 11.2044 12 12 12H26ZM24 14H22C21.7451 14.0003 21.5 14.0979 21.3146 14.2728C21.1293 14.4478 21.0178 14.687 21.0028 14.9414C20.9879 15.1958 21.0707 15.4464 21.2343 15.6418C21.3979 15.8373 21.6299 15.9629 21.883 15.993L22 16H24C24.2549 15.9997 24.5 15.9021 24.6854 15.7272C24.8707 15.5522 24.9822 15.313 24.9972 15.0586C25.0121 14.8042 24.9293 14.5536 24.7657 14.3582C24.6021 14.1627 24.3701 14.0371 24.117 14.007L24 14ZM24 7C24.2652 7 24.5196 7.10536 24.7071 7.29289C24.8946 7.48043 25 7.73478 25 8V10H13V8C13 7.73478 13.1054 7.48043 13.2929 7.29289C13.4804 7.10536 13.7348 7 14 7H24Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </h2>

                    <div className="anvFr_sumPara_flex to_inversTab">
                      <p>Total Freight</p>{" "}
                      <p>
                        {freghtSummary?.lr_freight?.total_freight?.toFixed(2)}
                      </p>
                    </div>
                    <div className="anvFr_sumPara_flex">
                      <p>Advance</p>{" "}
                      <p>
                        {freghtSummary?.lr_freight?.advance_freight?.toFixed(2)}
                      </p>
                    </div>
                    <div className="anvFr_sumPara_flex fstInvers_tab">
                      <p>Freight Payment</p>{" "}
                      <p>
                        {freghtSummary?.lr_freight?.balance_freight?.toFixed(2)}
                      </p>
                    </div>
                    <div className="anvFr_sumPara_flex">
                      <p>Claim Paid</p>{" "}
                      <p>{freghtSummary?.lr_freight?.payble?.toFixed(2)}</p>
                    </div>
                    <div className="anvFr_sumPara_flex">
                      <p>Claim Received</p>{" "}
                      <p>{freghtSummary?.lr_freight?.receivable?.toFixed(2)}</p>
                    </div>
                    <div className="anvFr_sumPara_flex to_inversTab">
                      <p>Balance</p>{" "}
                      <p className="_balaCe">
                        {freghtSummary?.lr_freight?.balance?.toFixed(2)}
                      </p>
                    </div>
                    <div className="anvFr_sumPara_flex">
                      <p>Other Trip Expense</p>{" "}
                      <p>{freghtSummary?.lr_freight?.other_expenses?.toFixed(2)}</p>
                    </div>
                    <div className="anvFr_sumPara_flex">
                      <p>Other Trip Receipt</p>{" "}
                      <p>{freghtSummary?.lr_freight?.other_receipt?.toFixed(2)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : flag ? (
        <Loading></Loading>
      ) : (
        <>No Data</>
      )}

      {isOpenCharge && <AddChargeModal {...chargeProps} />}
      {isOpenClaim && <AddClaimForConsignor {...claimProps} />}
      {canvasOpen && <BankListCanvas {...bankListProps} />}
      {showAddBank && <AddBankAccount {...firstAddBank} />}
      {isOpenExpense.open && <OtherExpenseModal {...otherExpenseProps} />}



      {editLrAmount && <EditConsignorFreight {...addTotalProps} />}

      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${showLrDetail ? "show" : ""
          }`}
        tabIndex={-1}
        style={{ visibility: showLrDetail ? "visible" : "hidden" }}
      >
        {showLrDetail && (
          <LrDetailModal
            isOpen={showLrDetail}
            onClose={(e) => setShowLrDetail(false)}
            nextModal={(e) => handleNextModal(e)}
          />
        )}
      </div>
      <div className={showLrDetail ? "offBack_drop" : ""}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${showAddLR ? "show" : ""
          }`}
        tabIndex={-1}
        style={{ visibility: showAddLR ? "visible" : "hidden" }}
      >
        {showAddLR && (
          <AddEditLrModal
            isOpen={showAddLR}
            onClose={() => setShowAddLR(false)}
            callBackDataLr={callBackDataLr}
            type="from_trip"
            callGetData={() => { }}
          />
        )}
      </div>
      <div className={showAddLR ? "offBack_drop" : ""}></div>

      {confirmModalShow && (
            <ConfirmModalFor {...confirmModalProps}>
              <>
                <p className="_confirmPrx">
                  {" "}
                  Do you really want to delete " {"Transaction"} "{" "}
                </p>
              </>
            </ConfirmModalFor>
          )}
    </>
  );
};

export default ConsignorFreight;
