import React, { useState } from "react";
import images from "constants/images";

const BootstrapTable = (props) => {
  const { data, columns, isLoading } = props;

  return (
    <>
      <div>
        <div className="_tab_htLane"> 
          <div className="anv_fr_detailHead">
            <table className="_trpTable_xd">
              <thead className="table_head_style">
                <tr>
                  {columns?.map((column, index) => (
                    <th key={index}>{column?.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? data?.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex}>{row[column?.accessor]}</td>
                    ))}
                  </tr>
                )) :
                  <tr>
                    <td><span className="_tblText">No data</span></td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </>
  );
};

export default BootstrapTable