import React from 'react'

const ContinuePodRecievedModal = (
    {
        isOpen,
        closeModal,
        modalTitle = "",
        submitTitle = "Submit",
        cancleTitle = "",
        children,
        isLoadding = false,
        onSubmit,
        disabled = false,
        modalMainClass,
        headerPart = false,
        footerPart = false,
        modalZIndex,
        cancelButtonTitle = 'Cancel',
        sendFor,
        isDivForth = false,
        continueOnClick,
        saveOnClick,
    }
) => {
    return (
        <div>
            <div id="myModal" className={`modal fade show d-block  ${modalMainClass}`} tabIndex="-1" role="dialog" style={{ zIndex: modalZIndex }}  >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ display: modalZIndex == -1 ? "none" : '' }}>
                        <div className="modal-header">
                            <span>{modalTitle}</span>

                            <button className="_switchBtn" onClick={closeModal}>
                                {modalTitle === "Add Account" ? <svg width="20" height="20" className='switched' viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.75 2.5C10.75 2.30109 10.671 2.11032 10.5304 1.96967C10.3897 1.82902 10.199 1.75 10 1.75C9.80113 1.75 9.61037 1.82902 9.46972 1.96967C9.32906 2.11032 9.25005 2.30109 9.25005 2.5V8.5C9.25005 8.69891 9.32906 8.88968 9.46972 9.03033C9.61037 9.17098 9.80113 9.25 10 9.25C10.199 9.25 10.3897 9.17098 10.5304 9.03033C10.671 8.88968 10.75 8.69891 10.75 8.5V2.5ZM13.743 4C13.6576 3.94914 13.5629 3.91577 13.4645 3.90184C13.366 3.88791 13.2658 3.8937 13.1696 3.91886C13.0734 3.94402 12.9832 3.98806 12.9042 4.04841C12.8251 4.10876 12.7589 4.18422 12.7093 4.27041C12.6597 4.35659 12.6278 4.45177 12.6153 4.55041C12.6028 4.64905 12.6101 4.74919 12.6367 4.845C12.6633 4.94081 12.7087 5.03037 12.7702 5.10849C12.8317 5.1866 12.9081 5.2517 12.995 5.3C14.14 5.95902 15.0354 6.97748 15.5424 8.19737C16.0494 9.41726 16.1396 10.7704 15.7991 12.0468C15.4586 13.3232 14.7063 14.4515 13.659 15.2567C12.6117 16.0619 11.3279 16.499 10.0069 16.5001C8.68583 16.5012 7.40135 16.0663 6.35271 15.2628C5.30408 14.4594 4.54992 13.3323 4.20725 12.0564C3.86458 10.7806 3.95255 9.42735 4.45752 8.20662C4.96249 6.98588 5.85622 5.96593 7.00005 5.305C7.08541 5.25575 7.16023 5.19018 7.22025 5.11202C7.28026 5.03385 7.3243 4.94463 7.34983 4.84945C7.37537 4.75427 7.38191 4.655 7.36907 4.55729C7.35624 4.45958 7.32429 4.36536 7.27505 4.28C7.2258 4.19464 7.16022 4.11982 7.08206 4.0598C7.0039 3.99978 6.91468 3.95575 6.8195 3.93022C6.72432 3.90468 6.62504 3.89814 6.52734 3.91097C6.42963 3.9238 6.33541 3.95575 6.25005 4.005C4.81982 4.83048 3.70201 6.10491 3.07006 7.63056C2.43811 9.15621 2.32735 10.8478 2.75496 12.4428C3.18257 14.0378 4.12465 15.4471 5.43502 16.4521C6.74539 17.457 8.3508 18.0014 10.0022 18.0007C11.6535 18 13.2585 17.4544 14.568 16.4484C15.8776 15.4424 16.8186 14.0324 17.2449 12.437C17.6712 10.8416 17.5591 9.15015 16.9259 7.62501C16.2928 6.09987 15.1739 4.82633 13.743 4.002"
                                        fill="black" />
                                </svg> :

                                    <svg className='cross' xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path d="M12.5321 0.477671C12.4422 0.387555 12.3353 0.316061 12.2177 0.267281C12.1001 0.2185 11.9741 0.193391 11.8468 0.193391C11.7195 0.193391 11.5934 0.2185 11.4758 0.267281C11.3582 0.316061 11.2514 0.387555 11.1615 0.477671L6.40798 5.22143L1.6545 0.46795C1.56451 0.377952 1.45766 0.306563 1.34008 0.257857C1.22249 0.20915 1.09646 0.184082 0.969185 0.184082C0.84191 0.184082 0.715881 0.20915 0.598294 0.257857C0.480707 0.306563 0.373865 0.377952 0.283868 0.46795C0.19387 0.557947 0.122481 0.664789 0.0737746 0.782376C0.0250685 0.899963 -9.48274e-10 1.02599 0 1.15327C9.48275e-10 1.28054 0.0250684 1.40657 0.0737746 1.52416C0.122481 1.64175 0.19387 1.74859 0.283868 1.83858L5.03735 6.59206L0.283868 11.3455C0.19387 11.4355 0.122481 11.5424 0.0737746 11.66C0.0250685 11.7776 0 11.9036 0 12.0309C0 12.1581 0.0250685 12.2842 0.0737746 12.4017C0.122481 12.5193 0.19387 12.6262 0.283868 12.7162C0.373865 12.8062 0.480707 12.8776 0.598294 12.9263C0.715881 12.975 0.84191 13 0.969185 13C1.09646 13 1.22249 12.975 1.34008 12.9263C1.45766 12.8776 1.56451 12.8062 1.6545 12.7162L6.40798 7.9627L11.1615 12.7162C11.2515 12.8062 11.3583 12.8776 11.4759 12.9263C11.5935 12.975 11.7195 13 11.8468 13C11.974 13 12.1001 12.975 12.2177 12.9263C12.3353 12.8776 12.4421 12.8062 12.5321 12.7162C12.6221 12.6262 12.6935 12.5193 12.7422 12.4017C12.7909 12.2842 12.816 12.1581 12.816 12.0309C12.816 11.9036 12.7909 11.7776 12.7422 11.66C12.6935 11.5424 12.6221 11.4355 12.5321 11.3455L7.77861 6.59206L12.5321 1.83858C12.9015 1.46919 12.9015 0.847062 12.5321 0.477671Z" fill="black" />
                                    </svg>}
                            </button>
                        </div>


                        <div className='addItem_body'>
                            Marking POD Received will Change LR Status to Delivered
                        </div>
                        
                        <div className="modal-footer" style={{ zIndex: modalZIndex }}>
                            <button type="button" className="_addComm_btn" onClick={continueOnClick} disabled={disabled}>{isLoadding ? "Loading..." : submitTitle}</button>
                            <button type="button" className="_cancel_btn" onClick={saveOnClick}>{cancelButtonTitle}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='offBack_drop'></div>

        </div>
    )
}

export default ContinuePodRecievedModal