import { createMaterial } from '_services/materialServices/materialServices';
import { CommonInput, CommonModal, CustomSelect } from 'components'
import { UserContext } from 'context';
import { errorSchema, utils } from 'helper';
import React, { useContext, useEffect, useState } from 'react'
import StorageService from 'utils/StorageServices/StorageServices';
import toast, { Toaster } from 'react-hot-toast';
import { getMaterialCategoryListData } from '_services/materialServices/materialCategoryServices';
import { getUnitDropdownListData } from '_services/dropdownServices/dropdownService';

const formObj = {
    name: '',
    material_code: '',
    hsn_code: '',
    material_category_id: {},
    weight: '',
    weight_type: 1,
    kot: '',
    remarks: '',
    qty_type: {},
    company_id: StorageService.getCompanyId(),
}

const AddItemModal = (props) => {
    const { masterData, cities, modalZIndex, setModalZIndex } = useContext(UserContext);
    const { nextModal, closeModal, isCurrentModal, materialCategoryList } = props;
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addItemInLrSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const onhandleSelect = async (item, name) => {
        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addItemInLrSchema);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async () => {
        try {
            let object = {
                ...formValue,
                material_category_id: formValue?.material_category_id?.value,
                qty_type: formValue?.qty_type?.value,
            }
            const res = await createMaterial(object);
            if (!!res) {
                setFormValue({});
                setLoading(false);
                toast.success('Item Created Successfully');
                closeModal(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleClick = async (e) => {
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.addItemInLrSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            apiCallingFunction();
            setFormError("");

        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    }

    let inhanceProps = {
        ...props,
        submitTitle: loading ? 'Loading...' : 'Add Item',
        disabled: loading,
        onSubmit: handleClick,
    }

    const handleAddButtonAction = () => {
        nextModal({ modalValue: true, nextView: true, previousView: true });
        setModalZIndex(-1);
        // isCurrentModal(false);
    }

    useEffect(() => {
        setFormValue((s) => ({
            ...s,
            material_category_id: materialCategoryList?.map((el) => {
                return {
                    label: el?.name,
                    value: el?._id,
                    hsn_code:el?.hsn_code,
                }
            })[0],
            qty_type: masterData?.material_qty_type_list?.map((el) => {
                return {
                    label: el?.name,
                    value: el?.id
                }
            })[0],
            company_id: StorageService.getCompanyId()
        }))

    }, [masterData, materialCategoryList, nextModal?.previousView, StorageService.getCompanyId()])

    useEffect(() => {
        if(formValue?.material_category_id?.hsn_code){
            setFormValue((s) => ({...s, hsn_code:formValue?.material_category_id?.hsn_code}))
        }
    }, [formValue?.material_category_id])

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    return (
        <div>
            <CommonModal {...inhanceProps}>
                <div className='addItem_body'>
                    <div className='colFlx'>
                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                label="Item Name * "
                                placeholder="Item Name * "
                                name='name'
                                value={formValue.name}
                                focus={!!(typeof formError === "object" && formError?.name)}
                                error={!!(typeof formError === "object") ? formError?.name : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>

                        <div className='singleDiv _inSil_flx'>
                            <div className='_inSi_duoBx'>
                                <CommonInput
                                    type="text"
                                    label="Item Code"
                                    placeholder="Item Code"
                                    name='material_code'
                                    value={formValue.material_code}
                                    focus={!!(typeof formError === "object" && formError?.material_code)}
                                    error={!!(typeof formError === "object") ? formError?.material_code : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                                <p className='_btmInput_para'>Leave Blank to Autogenerate</p>
                            </div>

                            <div className='_inSi_duoBx'>
                                <CommonInput
                                    type="text"
                                    label="HSN Code"
                                    placeholder="HSN Code"
                                    name='hsn_code'
                                    maxLength="6"
                                    onInput={maxLengthCheck}
                                    value={formValue.hsn_code}
                                    focus={!!(typeof formError === "object" && formError?.hsn_code)}
                                    error={!!(typeof formError === "object") ? formError?.hsn_code : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                            </div>
                        </div>

                        <div className='singleDiv _inSil_flx'>
                            <div className='_inSi_duoBx'>
                                <CustomSelect
                                    options={materialCategoryList?.map((el) => {
                                        return {
                                            label: el?.name,
                                            value: el?._id,
                                            hsn_code:el?.hsn_code,
                                        }
                                    })}
                                    name="material_category_id"
                                    label="Item Group"
                                    isAddButton={true}
                                    addButtonAction={(e) => handleAddButtonAction(e)}
                                    value={formValue?.material_category_id}
                                    error={formError["material_category_id.label"]}
                                    onSelect={(data) => onhandleSelect(data, 'material_category_id')}
                                    placeholder="Select Item Group"
                                />
                            </div>

                            <div className='_inSi_duoBx'>
                                <CustomSelect
                                    options={masterData?.material_qty_type_list?.map((el) => {
                                        return {
                                            label: el?.name,
                                            value: el?.id
                                        }
                                    })}
                                    name="qty_type"
                                    label="Unit"
                                    value={formValue?.qty_type}
                                    error={formError["qty_type.label"]}
                                    onSelect={(data) => onhandleSelect(data, 'qty_type')}
                                    placeholder="Select Unit"
                                />
                            </div>
                        </div>

                        <div className='singleDiv _relatePos'>
                            <CommonInput
                                type="number"
                                placeholder="Weight"
                                label="Weight"
                                name='weight'
                                value={formValue.weight}
                                focus={!!(typeof formError === "object" && formError?.weight)}
                                error={!!(typeof formError === "object") ? formError?.weight : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                            <span className='_weightSpan' >KG</span>
                        </div>

                        <div className='singleDiv'>
                            <CommonInput
                                type="text"
                                placeholder="KOT Per Unit"
                                label="KOT Per Unit *"
                                name='kot'
                                maxLength="8"
                                onInput={maxLengthCheck}
                                value={formValue.kot}
                                focus={!!(typeof formError === "object" && formError?.kot)}
                                error={!!(typeof formError === "object") ? formError?.kot : ""}
                                onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </CommonModal>
        </div>
    )
}

export default AddItemModal
