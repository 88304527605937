import moment from "moment";
import React, { useEffect } from "react";

const BiltyFormat1 = ({ list }) => {

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
     @page {
    margin: 0px;
    padding: 0px;
}

/* Note: Necessary for the pdf */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');

body {
    margin: 0;
}

/* Invoice 1 */

.invoice-box {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
    color: #222;
    padding: 0px;
}

.height_410 {
    position: relative;
}

table.border_t_b.Extra_p.p_b_0 td {
    padding-bottom: 5px !important;
}

.overly_table tr:last-child td {
    border-bottom: none;
}

.address_outer p {
    height: 30px;
    margin-bottom: 5px !important;
}

.bold_text {
    font-weight: bold;
}

.trip_no_detailsa {
    position: absolute;
    bottom: 0px;
    font-size: 10px;
    margin-bottom: 0;
}

.border_box {
    border: 1px solid #cccc;
}

.termsand_condiotn_relative {
    position: relative;
}

.p_0 {
    padding: 0px !important;
}

.Gst_in {
    font-weight: 900 !important;
}

.Extra_p td {
    padding: 5px 10px !important;
}

.address_outer p {
    margin: 0;
}

.Extra_p {
    border-top: 1px solid #ccc;
    position: relative;
    border-bottom: 0;
}

.Signature_height {
    height: 2.3cm;
}

.invoice-box table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}


.top_heading p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
}



.top_heading .h1 {
    margin: 0;
    font-size: 20px !important;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: bold;
}



.left_table {
    border-right: 1px solid #ccc;
}

.invoice-box table td {
  vertical-align: top;
  font-size: 12px;
}



.text-left {
    text-align: left;
}

.border_table thead td {
    font-weight: bold;
    padding: 2px 5px;
    font-size: 12px;
}

.top_heading {
    padding: 6px 0 4px 15px;
}


.top_heading_P {
    font-size: 20px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-weight: 700 !important;
}

span.number-with-bg {
    line-height: 16px;
    width: 16px;
    height: 16px;
    display: inline-block;
    background-color: #000 !important;
    color: #fff !important;
    text-align: center;
    border-radius: 30px;
    margin-left: 4px;
    margin-top: -30px;
    -webkit-print-color-adjust: exact;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}



.border_table td {
    border: 1px solid #ccc;
    padding: 0px 5px;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}



.Heading_right {
    margin-bottom: 2px !important;
    background: #efefef !important;
    margin: 0;
    padding: 4px 8px;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    -webkit-print-color-adjust: exact;
    border-top: 1px solid #ccc;
    margin-top: -1px;
    border-right: 1px solid #ccc;
}

@media print {
    .Heading_right {
        background: #efefef !important;
        -webkit-print-color-adjust: exact;
        border-top: 1px solid #ccc !important;
    }
}

.tfoot_bg td {
    padding: 2px 10px;
    background: #efefef !important;
    font-size: 12px;
    -webkit-print-color-adjust: exact;
}

@media print {
    .tfoot_bg td {
        padding: 2px 10px;
        background: #efefef !important;
        font-size: 12px;
        -webkit-print-color-adjust: exact;
    }
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}



table.table_right thead td {
    padding: 0px;
}

.table_right td {
    padding: 2px 10px;
}

.termsAndCondiotion {
    font-size: 10px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.b_0 {
    border-bottom: 0 !important;
    border-top: 0 !important;
}

.width_35 {
    width: 19%;
}

.Address {
    height: 26px;
    line-height: 14px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
}

.Address2 span {
    display: block;
    margin-bottom: 2px;
}

table.border_table tr td:nth-child(1) {
    border-left: 0;
    width: 20%;
}

table.border_table tr td:nth-child(2) {
    width: 52%;
}

table.border_table tr td:nth-child(3) {
    width: 11%;
    /*border-right: 0px;*/
}

table.border_table tr td:nth-child(4) {
    width: 11%;
}

span.bold_text.width_100 {
    width: 100%;
    text-align: center;
    float: left;
    position: absolute;
    bottom: 2px;
}

.address_bottom {
    margin: 0;
    height: 38px;
    line-height: 12px;
    padding-top: 0px;
    margin-top: 0px;
    font-size: 10px;
    padding-bottom: 4px;
}

.address_bottom p {
    margin-bottom: 0px;
}

table.border_table .tfoot_bg td:nth-child(3) {
    border-right: 1px solid #ccc;
}

.height_410 {
    height: 328px;
    /*border-right: 1px solid #ccc;*/
    position: relative;
    font-size: 11px;
    overflow: hidden;
}

.height_410 td {
    height: 16.9px;
    line-height: 16.9px;
}

.overly_table {
    /*position: absolute;*/
    width: 100%;
    top: 0px;
}

.fixed_width {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0px;
    text-overflow: ellipsis;
}

.oP_none {
    opacity: 0;
}

.lrCopy_space td {
    padding-top: 4.1px;
    padding-bottom: 5.1px;
}

span.poweredby {
    position: absolute;
    bottom: -20px;
    text-align: center;
    width: 100%;
    right: 0px;
    font-size: 9px;
    display: block;
}

span.orignal_copy {
    text-align: right;
    float: right;
    font-size: 10px;
    line-height: 17px;
}

tr.lrCopy_space.last_tr td {
    padding-bottom: 8px;
}

table.border_table.Table_border_none td {
    border-color: #fff;
}

.fixed_width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
}

td.bold_text.text-right.total_div {
    width: 88.5%;
}

.sheet.padding-2mm {
    width: 210mm;
    margin: 0 auto;
    /* height: 147mm;*/
    /* padding: 2mm; */
    background: white;
    /*  box-shadow: 0 0.5mm 2mm rgba(0,0,0,.3);*/

}

.invoice-box table.border_table tbody td {
    font-size: 11px;
}

/* Invoice 1  */


/* Invoice 2 */

.sheet2.padding-2mm {
    width: 210mm;
    position: relative;
    margin: 0 auto 25px;
    /* height: 147mm;*/
    /*padding: 2mm;*/
    background: white;
    /*  box-shadow: 0 0.5mm 2mm rgba(0,0,0,.3);*/

}

.width_50 {
    width: 50%;
}

.invoice-box2 {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
    color: #222;
    /*padding: 3px;*/
}

.invoice-box2 table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.invoice-box2 table td {
    vertical-align: top;
    line-height: 20px;
    font-size: 12px;
}

.invoice-box2 table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box2 table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box2 table tr.details td {
    padding-bottom: 20px;
}

.invoice-box2 table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

.top_heading2 {
    padding: 10px 0 5px 15px;
}

.top_heading2 p {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 10px;
}

.top_heading2 .h1 {
    margin: 0;
    font-size: 20px !important;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: bold;
}

.invoice-box2 table.table_gray_bg {
    background: #efefef !important;
    margin: 0;
    font-size: 13px;
    border-bottom: 1px solid #ccc;
    -webkit-print-color-adjust: exact;
    border-top: 1px solid #ccc;
    margin-top: -1px;
    border-right: 1px solid #ccc;
}

.new_table td {
    line-height: 16px !important;
}

.table_gray_bg td {
    padding: 5px 15px !important;
}

.invoice-box2 td.text-left.width_75 {
    text-align: right;
}

.invoice-box2 .table_right td {
    padding: 3px 15px;
    font-size: 12px;
}

.invoice-box2 table.table_right thead td {
    padding: 0px;
    font-size: 13px;
}


.height_div {
    position: relative;
}
.invoice-box2 .border_table tr td:nth-child(1) {
    width: 80px;
    border-left: 0;
    padding-left: 15px;
}


.invoice-box2 .border_table.border_none td {
    border-color: #fff !important;
    line-height: 24px;
    border: 0;
}

.gst_invoiceno_table td {
    height: 22px !important;
    border-top: 1px solid #efefef;
    line-height: 23px !important;
}

.graY-bg {
    background: #000 !important;
    border-bottom: 1px solid #ccc;
    -webkit-print-color-adjust: exact;
    color: white;
}

.invoice-box2 .border_table thead td {
    font-weight: 700;
    padding: 6px 5px;
    font-size: 12px;
}

.gst_invoiceno_table .border_table tr td:nth-child(1) {
    width: 75px !important;
}

.gst_invoiceno_table .border_table tr td:nth-child(2) {
    width: 110px !important;
}

.gst_invoiceno_table .border_table tr td:nth-child(3) {
    width: 110px !important;
}
.gst_invoiceno_table .border_table tr td:nth-child(4) {
    width: 110px !important;
}
.gst_invoiceno_table .border_table tr td:nth-child(5) {
    width: 60px !important;
}
.gst_invoiceno_table .border_table tr td:nth-child(6) {
    width: 60px !important;
}
.gst_invoiceno_table .border_table tr td:nth-child(7) {
    width: 60px !important;
}
.gst_invoiceno_table .border_table tr td:nth-child(8) {
    width: 70px !important;
}

.invoice-box2 .border_table td {
    padding: 8px 5px;
}

.invoice-box2 .item_description {
    margin: 2px 0;
}

.invoice-box2 .address_new {
    margin: 0px;
    margin-bottom: 5px;
}

.invoice-box2 .poweredby {
    position: absolute;
    right: 0;
    font-size: 11px;
    margin-top: 3px;
    padding-right: 15px;
}

.footer {
    margin-top: 60px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
}

.footer.margintop {
    margin-top: 60px !important;
}

.footer p {
    margin: 0;
}

/* Invoice 2  */
        `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <>
      <div>
        {list &&
          list?.map((i,index) => {
  
            return (
              <section className="sheet padding-2mm">
                <div className="invoice-box">
                  <div className="border_box">
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      style={{ pageBreakAfter: "always" }}
                    >
                      <tbody>
                        <tr className="top">
                          <td colspan="2">
                            <table cellpadding="0" cellspacing="0">
                              <tbody>
                                <tr>
                                  <td style={{ width: "65%" }}>
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      className="left_table"
                                    >
                                      <thead>
                                        <tr>
                                          <th colspan="2" align="left">
                                            <div className="top_heading">
                                              <span className="h1">
                                                {i?.branch_id?.branch}
                                              </span>
                                              <p className="Address">
                                                <span>
                                                  {i?.branch_id?.address_line1
                                                    ? `${i?.branch_id?.address_line1} ,`
                                                    : ""}
                                                  {i?.branch_id?.address_line2
                                                    ? `${i?.branch_id?.address_line2} ,`
                                                    : ""}
                                                  {i?.branch_id?.city?.label
                                                    ? `${i?.branch_id?.city?.label} ,`
                                                    : ""}
                                                  {i?.branch_id?.state?.label
                                                    ? `${i?.branch_id?.state?.label} ,`
                                                    : ""}
                                                  {i?.branch_id?.pincode
                                                    ? `${i?.branch_id?.pincode} `
                                                    : ""}
                                                </span>
                                                <span>, {i?.branch_id?.contact_no
                                                    ? `${i?.branch_id?.contact_no} `
                                                    : ""}</span>
                                              </p>
                                              <p className="Gst_in">
                                                {" "}
                                                GST NO.{i?.branch_id?.gst_no
                                                    ? `${i?.branch_id?.gst_no} `
                                                    : ""}
                                              </p>
                                            </div>
                                          </th>
                                        </tr>
                                      </thead>
                                    </table>
                                    <div className="height_410">
                                      {/* <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        className="border_table Table_border_none"
                                      >
                                        <thead>
                                          <tr>
                                            <td>Invoice No</td>
                                            <td>Item Description</td>
                                            <td>Type</td>
                                            <td>Qty</td>
                                          </tr>
                                        </thead>
                                        <tbody></tbody>
                                        <tfoot>
                                          <tr>
                                            <td>
                                              <span className="oP_none">
                                                1234567890
                                              </span>
                                            </td>
                                            <td>
                                              <span className="oP_none fixed_width">
                                                3535333
                                              </span>
                                            </td>
                                            <td>
                                              <span className="oP_none">
                                                123456
                                              </span>
                                            </td>
                                            <td>
                                              <span className="oP_none">
                                                567890
                                              </span>
                                            </td>
                                          </tr>
                                        </tfoot>
                                      </table> */}
                                      <div className="overly_table">
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          className="border_table"
                                        >
                                          <thead>
                                          <tr>
                                            <td>Invoice No</td>
                                            <td>Item Description</td>
                                            <td>Type</td>
                                            <td>Qty</td>
                                          </tr>
                                        </thead>
                                          {/* <thead>
                                            <tr>
                                              <td>
                                                <span className="oP_none">
                                                  1234567890
                                                </span>
                                              </td>
                                              <td>
                                                <span className="oP_none fixed_width">
                                                  3535333
                                                </span>
                                              </td>
                                              <td>
                                                <span className="oP_none">
                                                  123456
                                                </span>
                                              </td>
                                              <td>
                                                <span className="oP_none">
                                                  567890
                                                </span>
                                              </td>
                                            </tr>
                                          </thead> */}
                                          <tbody>
                                          {i?.lr_material_list?.map((k) => {
                                            return (
                                              <tr>
                                                
                                                <td>{k?.invoice_number}</td>
                                                <td>{k?.name}</td>
                                                <td>{k?.qty_type}</td>
                                                <td>{k?.qty}</td>
                                              </tr>
                                            );
                                          })}
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td>&nbsp;</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      className="border_table"
                                    >
                                      <tfoot>
                                        <tr className="tfoot_bg">
                                          <td
                                            colspan="3"
                                            className="bold_text text-right total_div"
                                          >
                                            Total
                                          </td>
                                          <td className="bold_text">0 </td>
                                        </tr>
                                        <tr>
                                          <td
                                            colspan="4"
                                            className="b_0"
                                            style={{ width: "100%" }}
                                          >
                                            <div className="termsand_condiotn_relative">
                                              <h5 className="termsAndCondiotion">
                                                Terms &amp; Conditions
                                              </h5>
                                              <div className="address_bottom"></div>
                                              <p className="trip_no_detailsa">
                                                <b>Trip No</b> {i?.trip_id?.trip_number} | 
                                                <b>ETT Value</b> - 0{" "}
                                              </p>
                                              <p className="trip_no_detailsa"></p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </td>
                                  <td style={{ width: "35%" }}>
                                    <table
                                      className="table_right"
                                      cellpadding="0"
                                      cellspacing="0"
                                    >
                                      <thead>
                                        <tr>
                                          <td colspan="4">
                                            <h3
                                              className="Heading_right"
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              L.R.- {i?.lr_number}
                                              <span className="orignal_copy">
                                                {" "}
                                                {i?.type}
                                                <span className="number-with-bg">
                                                  {i?.number}
                                                </span>
                                              </span>
                                            </h3>
                                          </td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className="lrCopy_space last_tr">
                                          <td
                                            className="bold_text width_35"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            Date
                                          </td>
                                          <td
                                            className="text-left width_75"
                                            style={{
                                              whiteSpace: "nowrap",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {moment(
                                                      i?.dispatch_date
                                                    ).format("MM/DD/YYYY")}
                                          </td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr className="lrCopy_space last_tr">
                                          <td
                                            className="bold_text width_35"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            Vehicle No.
                                          </td>
                                          <td
                                            className="text-left width_75"
                                            style={{
                                              whiteSpace: "nowrap",
                                              fontWeight: "bold",
                                            }}
                                          >
                                              {i?.trip_id?.vehicle_id?.vehicle_no}
                                          </td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td colspan="4" className="p_0">
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              className="border_t_b Extra_p p_b_0"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td className="bold_text width_35">
                                                    Consignor
                                                  </td>
                                                  <td
                                                    className="text-left width_75 bold-text"
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  > {i?.consignor_id?.name}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="4" className="p_0">
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              className="border_t_b Extra_p"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td className="bold_text width_35">
                                                    Consignee
                                                  </td>
                                                  <td className="text-left width_75">
                                                    <b> {i?.consignee_id
                                                                ?.name
                                                                ? i
                                                                    ?.consignee_id
                                                                    ?.name
                                                                : ""}</b>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td colspan="2">
                                                    <span className="bold_text">
                                                      Address
                                                    </span>
                                                    <br />
                                                    <div className="address_outer">
                                                      <p className="Sec_address">
                                                      {i?.consignee_id?.address_line1
                                                        ? `${i?.consignee_id?.address_line1} ,`
                                                        : ""}
                                                      {i?.consignee_id?.address_line2
                                                        ? `${i?.consignee_id?.address_line2} ,`
                                                        : ""}
                                                     
                                                      </p>
                                                      <p className="first_address">
                                                      {i?.consignee_id?.city?.label
                                                        ? `${i?.consignee_id?.city?.label} ,`
                                                        : ""}
                                                      {i?.consignee_id?.state?.label
                                                        ? `${i?.consignee_id?.state?.label} ,`
                                                        : ""}
                                                      {i?.consignee_id?.pincode
                                                        ? `${i?.consignee_id?.pincode} `
                                                        : ""}
                                                      </p>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="bold_text width_35">
                                                    Contact{" "}
                                                  </td>
                                                  <td className="text-left bold_text width_75"> {i?.consignee_id?.contact_no
                                                        ? `${i?.consignee_id?.contact_no} `
                                                        : ""}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="4" className="p_0">
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              className="border_t_b Extra_p"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td colspan="2">
                                                    <div className="Signature_height"></div>
                                                    <span className="bold_text width_100">
                                                      Consignee Signature
                                                    </span>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="4" className="p_0">
                                            <table
                                              cellpadding="0"
                                              cellspacing="0"
                                              className="border_t_b Extra_p"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td colspan="2">
                                                    <div className="Signature_height">
                                                      <span className="bold_text width_100">
                                                        Godown Keeper's
                                                        Signature
                                                      </span>
                                                      <span className="poweredby">
                                                        Powered By :
                                                        <strong>
                                                          Transport360.in
                                                        </strong>{" "}
                                                        | Printed on :
                                                        04-07-2024 12:05pm
                                                      </span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            );
          })}
      </div>
    </>
  );
};

export default BiltyFormat1;
