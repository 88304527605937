import { getPayment } from '_services';
import { UserContext } from 'context';
import { utils } from 'helper';
import { AtPartyPointModal } from 'modals';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import StorageService from 'utils/StorageServices/StorageServices';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const RejectedTab = (props) => {

  const {activeTab,userlist,filter,setFilter} = props;
  const { masterData } = useContext(UserContext);
  const [paymentList, setPaymentList] = useState([]);
  const [isLoading,setIsLoading] = useState(false)
  const [activeStatus, setActiveStatus] = useState();
  const [isOpenSmallModal, setIsOpenSmallModal] = useState(false);
  const [textState, setTextState] = useState(0);
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [offSet,setOffset] = useState(1)

  const columns = [
    { header: "Date", accessor: "tADate" },
    { header: "Related", accessor: "TripNumber" },
    { header: "Requested User", accessor: "createAt" },
    { header: "Voucher No", accessor: "voucher_no" },
    { header: "Category", accessor: "category" },
    { header: "From Account", accessor: "fromAcc" },
    { header: "To Account", accessor: "toAcc" },
    { header: "Description", accessor: "description" },
    { header: "Receipt", accessor: "credit_amount" },
    { header: "Payment", accessor: "debit_amount" },
    { header: "Reason", accessor: "Reason" },
    { header: "Attachment", accessor: "" },
    { header: "Action", accessor: "action" },
    
  ];

  const accessorList = (data) => {

    const listArray = data?.map((item, i) => ({
      ...item,
      serial: i + 1,
      tADate: (
        <>
          <div className="_dateNtime"><span className='_tblText'>{utils.formatDateMonth(item?.date)}</span></div>
        </>
      ),
      transactionType:(<>
      
          <div>{item?.sub_type_name} | {item?.type_name}</div>
      </>),
     TripNumber:(<><div className='__locationWid_bx'><Link to={`/trip/addtrip/dispatched/${item?.trip?._id}`}> {`Trip-#${item?.trip?.trip_number}`} </Link></div></>),
      category:(<> <div className='__locationWid_bx'>{item?.sub_type_name} | {item?.type_name}</div></>),
      fromAcc:(<> <div className='__locationWid_bx'>{item?.account_sub_category_name} | {item?.account_name}</div></>),
      toAcc:(<> <div className='__locationWid_bx'>{item?.trip?.supplier_id?.name}</div></>),
      createAt : <><div className='__locationWid_bx'><span>{userlist?.find((i)=>i?.user_id == item?.created_by)?.company_user_name}</span></div></>,
      action: (
        <>
          <div className="dropdown statusDrop_dwn">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="_drpStatus _pending">
                <span className="_dotState"></span>
                {masterData?.payment_approval_status[item?.approval_status]}
              </span>
            </button>
            <ul className="dropdown-menu">
              {masterData?.payment_approval_action_list?.map((i, index) => {
                return (
                  <li
                    onClick={() => {
                      handleButtonClick({i:i,item:item});
                    }}
                  >
                    <a className="dropdown-item" href="#">
                      <span className="_drpStatus _pending">
                        <span className="_dotState"></span> {i?.name}
                      </span>
                    </a>
                  </li>
                );
              })}
             
            </ul>
          </div>

          {/* <div className='_paymentStat_bxs'>
            <button className='_paymentStat _paymentStat_completed'>Complete</button>
            <button className='_paymentStat _paymentStat_hold'>Hold</button>
            <button className='_paymentStat _paymentStat_reject'>Reject</button>
            <button className='_paymentStat _paymentStat_approved'>Approved</button>
          </div> */}
         
        </>
      ),

    }));
    return listArray;
  };

  useEffect(()=>{
handlePaymentAprovel()
  },[activeTab,filter,offSet])

 const handlePaymentAprovel = async() =>{
  try {
    const res = await getPayment({...filter,
      offset:offSet,
      company_id:StorageService?.getCompanyId(),
      approval_status: activeTab,
    });

    if(res?.responseCode===200){
      setPaymentList(res?.data?.list)
      setIsLoading(false)
      setTotalItems(res?.data?.total_record);
    }else{
      setIsLoading(false)
    }
  } catch (error) {
    
  }
 }

 const handleButtonClick = (status) => {

  switch (status?.i?.id) {
    case 1:
      setIsOpenSmallModal(true);
      setTextState(status);
      break;
    case 2:
      setIsOpenSmallModal(true);
      setTextState(status);
      break;
    case 3:
      setIsOpenSmallModal(true);
      setTextState(status);
      break;
    case 4:
      setIsOpenSmallModal(true);
      setTextState(status);
      break;
    case 5:
      setIsOpenSmallModal(true);
      setTextState(status);
      break;
    case 6:
      setIsOpenSmallModal(true);
      setTextState(status);
      break;

    default:
      break;
  }
};


const callBackApiCall = (status) => {
  if (status) {
    handlePaymentAprovel();
  }
};

const handlePageChange = (page) => {
  setOffset( page )
};

const renderPagination = () => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  let pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(
      <li
        key={i}
        className={`page-item ${i ===offSet ? "active" : ""}`}
      >
        <a className="page-link _avPointer" onClick={(e) => handlePageChange(i)}>
          {i}
        </a>
      </li>
    );
  }

  return (
    <ul className="pagination ">
      <li className={`page-item ${offSet === 1 ? "disabled" : ""}`}>
        <a
          className="page-link _avPointer"
          onClick={() => handlePageChange(offSet - 1)}
        >
          <IoChevronBack />
        </a>
      </li>
      {pages}
      <li
        className={`page-item ${offSet === totalPages ? "disabled" : ""
          }`}
      >
        <a
          className="page-link _avPointer"
          onClick={() => handlePageChange(offSet + 1)}
        >
          <IoChevronForward />
        </a>
      </li>
    </ul>
  );
};

let modalProps = {
  isOpen: isOpenSmallModal,
  closeModal: (e) => setIsOpenSmallModal(false),
  lrId: textState,
  companyId: textState?.company_id,
  modalMainClass: "_dashCommon_Modal _smModal",
  modalTitle:textState?.i?.name,
  submitTitle: textState?.i?.name,
  callBackApiCall: callBackApiCall,
  aprroveStatus:true
};


  return (
    <div className="_transactionTable"><CustomeTable
    columns={columns}
    data={accessorList(
      paymentList?.length ? paymentList : []
    )}
    isLoading={isLoading}
    />
    <div className='paginationFt_avt '>
          {renderPagination()}
        </div>
     {isOpenSmallModal && <AtPartyPointModal {...modalProps} />}
    </div>
  )
}

export default RejectedTab


const CustomeTable = ({ columns, data, isLoading }) => {

  return (
    <table className="">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}><div className='_minTh_wid'>{column.header}</div></th>
          ))}

          <th></th>
        </tr>
      </thead>

      <tbody>
        {!isLoading ? (
          <>
            {" "}
            {data.length > 0 ? (
              data?.map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex}>{row[column.accessor]}</td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>No data</td>
              </tr>
            )}
          </>
        ) : (
          <>
            <tr>
              <td>Loadding...</td>
            </tr>
            ;
          </>
        )}
      </tbody>
    </table>
  );
};
