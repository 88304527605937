import React, { useContext, useEffect, useRef, useState } from 'react'
import { getAllConnections, sendConnectionRequest } from '_services'
import { CommonModal } from 'components'
import toast from 'react-hot-toast';
import StorageService from 'utils/StorageServices/StorageServices';
import { UserContext } from 'context';

const formObj = {
    company_id: StorageService.getCompanyId(),
    to_company_id: "",
    list_ids: []
}

const SaveConnectModal = (props) => {
    let { isOpen, nextModal, previousModal, closeModal, isCurrentModal, isDirect, hideShowBtn } = props;
    const { connectionDetailsGlobal } = useContext(UserContext);
    const [formValue, setFormValue] = useState(formObj);
    const [listData, setListData] = useState([]);
    const [isSearch, setIsSearch] = useState('')
    const [loading, setLoading] = useState(false);

    const loadingBarRef = useRef(null);

    const fetchAllConnectionsData = async (value) => {
        setLoading(true);
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                company_id: StorageService.getCompanyId(),
                keyword: value ? value : '',
            }
            const res = await getAllConnections(object);
            if (res?.responseCode === 200) {
                setListData(res?.data?.reverse());
                setLoading(false);
                // setConnectionData(res?.data)
                // toast.success('Error !! ');
            }
        } catch (error) {
            setLoading(false);
            toast.error('Server side api error !! ');
        } finally {
            setLoading(false);
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    useEffect(() => {
        if (StorageService.getCompanyId()) {
            let delay = setTimeout(() => {
                fetchAllConnectionsData(isSearch);
            }, 500);

            return () => clearInterval(delay);
        }
    }, [props, isSearch])

    const addListHandler = () => {
        nextModal(true);
        previousModal(false);
    }

    const handleCheckbox = (id) => {
        let data = [...formValue?.list_ids];
        let values = data?.includes(id) ? data?.filter((item) => item !== id) : [...data, id]
        setFormValue((s) => ({ ...s, list_ids: values }))
    }

    const sendRequestForConnection = async (data) => {
        setLoading(true);
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                ...formValue,
                to_company_id: connectionDetailsGlobal?._id
            }
            const res = await sendConnectionRequest(object);
            if (res?.responseCode === 200) {
                if (isDirect) {
                    isCurrentModal(false);
                } else {
                    closeModal(true);
                }
                hideShowBtn(true);
                setLoading(false);
                toast.success('Connection Request Send Successfully');
            }
        } catch (error) {
            setLoading(false);
            toast.error('Server side api error !! ');
        } finally {
            setLoading(false);
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    const changesProps = {
        ...props,
        onSubmit: (e) => sendRequestForConnection(e),
    }

    useEffect(() => {
        setFormValue((s) => ({ ...s, company_id: StorageService.getCompanyId() }))
    }, [StorageService.getCompanyId()])

    return (
        <>
            <CommonModal {...changesProps}>
                <div className='__saveCnt_body'>
                    <div className='_addSrc_lBx'>
                        <div className='_inpW_search _lrl_inpSrch'>
                            <svg className='srcIcon' xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path opacity="0.5" d="M14 13.2678L10.0893 9.35711C11.0291 8.22892 11.4977 6.78185 11.3977 5.31693C11.2977 3.85202 10.6368 2.48205 9.55246 1.49202C8.46811 0.501988 7.04382 -0.0318806 5.57588 0.00147384C4.10793 0.0348283 2.70936 0.632837 1.6711 1.6711C0.632837 2.70936 0.0348283 4.10793 0.00147384 5.57588C-0.0318806 7.04382 0.501988 8.46811 1.49202 9.55246C2.48205 10.6368 3.85202 11.2977 5.31693 11.3977C6.78185 11.4977 8.22892 11.0291 9.35711 10.0893L13.2678 14L14 13.2678ZM1.05418 5.71468C1.05418 4.79292 1.32752 3.89186 1.83962 3.12545C2.35172 2.35903 3.07959 1.76168 3.93118 1.40894C4.78278 1.0562 5.71985 0.963907 6.62389 1.14373C7.52794 1.32356 8.35836 1.76743 9.01014 2.41921C9.66193 3.07099 10.1058 3.90141 10.2856 4.80546C10.4654 5.70951 10.3732 6.64658 10.0204 7.49817C9.66767 8.34976 9.07032 9.07763 8.30391 9.58974C7.53749 10.1018 6.63644 10.3752 5.71468 10.3752C4.47906 10.3738 3.29444 9.88235 2.42072 9.00863C1.54701 8.13492 1.05555 6.9503 1.05418 5.71468Z" fill="black" />
                            </svg>
                            <input
                                type="text"
                                placeholder='Search List'
                                className='simpleFilter_input'
                                value={isSearch}
                                onChange={(e) => setIsSearch(e.target.value)}
                            />
                        </div>
                        <div className='_addLst_nxs'>
                            <button onClick={(e) => addListHandler(e)}>+ Add New List</button>
                        </div>
                    </div>

                    <div className='_connectList_bx'>
                        {
                            loading ? 'Loading...' : ((listData?.length > 0) ? listData?.map((el, ind) => {
                                return (
                                    <React.Fragment key={ind}>
                                        <div className='flexDiv_connect'>
                                            <div className='innerCi_int'>
                                                <input
                                                    type="checkbox"
                                                    className="_checkTbl"
                                                    id={el?._id}
                                                    checked={formValue?.list_ids?.includes(el?._id)}
                                                    onChange={(e) => handleCheckbox(el?._id)}
                                                />
                                                <label htmlFor={el?._id}> {el?.name} <span className='tin_fonts'> Owner : {el?.name} </span></label>
                                            </div>
                                            <span className='_sepRate_cont'> 23 connections </span>
                                        </div>
                                    </React.Fragment>
                                )
                            }) : 'No Data Found')
                        }
                    </div>
                </div>
            </CommonModal>
            <div className='offBack_drop'></div>
        </>
    )
}

export default SaveConnectModal