import { CommonModal } from 'components'
import React from 'react'

const MakeTruckAvailableModal = (props) => {

    const propsMarkTruck = {
        ...props,
      }


  return (
    <>
    <CommonModal {...propsMarkTruck}>
      <div className='endTrip_bxs pt-2'>

        <div className='_transDetail_bx _mkTrkDetail_bx'>

          <div className='_trkDetail'>
            <h6 className='_trkNum'>MP20 GA 2108</h6>
            <div className='_trkMark'>Market</div>
            <div className='_trkTyre'><span>32Feet XL</span></div>
          </div>

          <div className='_trkStatus'><span className='_maintainance'></span> Maintainance</div>

        </div>

      </div>
    </CommonModal>
  </>
  )
}

export default MakeTruckAvailableModal