import React, { useContext, useEffect, useState , useRef} from 'react'
import StorageService from 'utils/StorageServices/StorageServices';
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context'
import { createInvoiceBillRaise, getInvoiceBillRaiseSummaryData } from '_services/invoiceBillRaiseServices';
import { useLocation } from 'react-router-dom';
import { ConfirmModalFor, ThankyouModal } from 'modals';

const BillSummary = () => {

    let location = useLocation();
    const navigate = useNavigate();
    const [isOpenThankyouModal, setIsOpenThankyouModal] = useState(false)
    const { masterData, states, setIsMaterialIndex, invoiceBillRaiseData, setInvoiceBillRaiseData } = useContext(UserContext)
    let responseData = location?.state;
    const [leaveShowConfirm, setLeaveShowConfirm] = useState(false);
    const shouldReloadRef = useRef(false);
    const callNextSteperCall = async () => {
        try {
            let object = {
                company_id: invoiceBillRaiseData?.company_id,
                consignor_id: invoiceBillRaiseData?.consignor_id?.value,
                invoice_number: invoiceBillRaiseData?.invoice_number,
                invoice_date: invoiceBillRaiseData?.invoice_date,
                due_date: invoiceBillRaiseData?.due_date,
                branch_id: invoiceBillRaiseData?.branch_id?.value,
                charge_gst: invoiceBillRaiseData?.charge_gst,
                gst_percentage: invoiceBillRaiseData?.gst_percentage?.value ? invoiceBillRaiseData?.gst_percentage?.value : '',
                tds: invoiceBillRaiseData?.tds?.value,
                receiving_state: invoiceBillRaiseData?.receiving_state,
                select_lr_ids: invoiceBillRaiseData?.select_lr_ids,
                invoice_summary: invoiceBillRaiseData?.invoice_summary,
            }
            const res = await createInvoiceBillRaise(object);
            if (res?.responseCode === 200) {
                setInvoiceBillRaiseData((s) => ({ ...s, invoice_summary: res?.data?.invoice_summary }));
                setIsOpenThankyouModal(true);
            }
        } catch (error) {
            // throw error;
        }
    }

    const summaryLrSteperHandler = async (e) => {
        e.preventDefault();
        setIsOpenThankyouModal(true);
        callNextSteperCall();
    }

    const callNavigateFunction = async (e) => {
        navigate('/invoicing');
    }

    let thankyouModalProps = {
        isOpen: isOpenThankyouModal,
        closeModal: (e) => setIsOpenThankyouModal(false),
        modalMainClass: "_dashCommon_Modal _smModal",
        callNavigateFunction: callNavigateFunction,
    }

    const zeroValue = 0;

    const discardHandlerFunction = (e) => {
        e.preventDefault();
        setLeaveShowConfirm(true);
    }

    const submitLeaveModalProps = () => {
        navigate('/invoicing')
    }

    let leaveModalProps = {
        show: leaveShowConfirm,
        closeModal: (e) => setLeaveShowConfirm(!leaveShowConfirm),
        title: "Leave This Page",
        submitTitle: "Confirm",
        onSubmit: submitLeaveModalProps,
        confirmModal: "confirmModal",
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (!shouldReloadRef.current) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <>
            <div>
                <div className='contentHead_new'>
                    <h4> Create Bill Raise </h4>

                    <div className='_stepedBxs'>
                        <div className='_seperateCheckes activated'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <circle cx="10.4999" cy="10.5" r="10.5" fill="#20A154" />
                                <path d="M5.99988 10.1672L9.16704 13.3343L15.4999 7" stroke="white" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span>Bill Details</span>
                        </div>

                        <div className='_seperateCheckes activated'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                                <path d="M6 10.1672L9.16716 13.3343L15.5 7" stroke="#828282" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span>Select LR</span>
                        </div>

                        <div className='_seperateCheckes activated'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                                <path d="M6 10.1672L9.16716 13.3343L15.5 7" stroke="#828282" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span>Selected LR</span>
                        </div>

                        <div className='_seperateCheckes activated'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <circle cx="10.5" cy="10.5" r="10.5" fill="#D9D9D9" />
                                <path d="M6 10.1672L9.16716 13.3343L15.5 7" stroke="#828282" strokeWidth="1.49289" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span>Summary</span>
                        </div>
                    </div>
                </div>

                <div className='_billRaiseBody'>
                    <div className='billDetial_modBxs'>
                        <div className='billDetail_smModal'>
                            <div className='_invoiceSummary'>
                                <h2>Invoice Summary</h2>
                                <div className='_alInvoiceSumry'>
                                    <div className='_invsSum_fx'>
                                        <p className='_blackText'>Total Freight</p> <p className='_blackText'> {responseData?.invoice_summary?.total_freight ? <> &#x20B9; {(responseData?.invoice_summary?.total_freight).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>} </p>
                                    </div>

                                    <div className='_invsSum_fx'>
                                        <p className='_blackText'>Claims Receivable (+)</p> <p className='_blackText'>
                                            {responseData?.invoice_summary?.claim_receivable ? <> &#x20B9; {(responseData?.invoice_summary?.claim_receivable).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>}
                                            {/* ₹ 1000.00 */}
                                        </p>
                                    </div>

                                    <div className='_invsSum_fx'>
                                        <p className='_blackText'> GST @ 12% (+) </p> <p className='_blackText'> {responseData?.invoice_summary?.gst_amount ? <> &#x20B9; {(responseData?.invoice_summary?.gst_amount).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>} </p>
                                    </div>

                                    <div className='_invsSum_fx'>
                                        <p className='_boldBlack'>Invoice Amount</p> <p className='_boldBlack'> {responseData?.invoice_summary?.total_freight ? <> &#x20B9; {(responseData?.invoice_summary?.total_freight).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>} </p>
                                    </div>

                                    <div className='_invsSum_fx'>
                                        <p className='_blackText'>Freight Received (-)</p> <p className='_blackText'> {responseData?.invoice_summary?.freight_received ? <> &#x20B9; {(responseData?.invoice_summary?.freight_received).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>} </p>
                                    </div>

                                    <div className='_invsSum_fx'>
                                        <p className='_blackText'>Claims Payable (-)</p> <p className='_blackText'> {responseData?.invoice_summary?.claim_payable ? <> &#x20B9; {(responseData?.invoice_summary?.claim_payable).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>} </p>
                                    </div>

                                    <div className='_invsSum_fx'>
                                        <p className='remainBal'>Balance</p> <p className='remainBal'> {responseData?.invoice_summary?.balance ? <> &#x20B9; {(responseData?.invoice_summary?.balance).toFixed(2)} </> : <> &#x20B9; {zeroValue?.toFixed(2)} </>} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='_billRaiseFooter'>
                    <div className='prevDiscard_bx'>
                        <button className='_previousBtn' onClick={(e) => navigate('/invoicing/selectlr')}><span>Previous</span></button>
                        <button className='_discardBtn' onClick={(e) => discardHandlerFunction(e)}><span>Discard</span></button>
                    </div>

                    <div>
                        <button
                            className='_stepSuccessBtn'
                            onClick={(e) => summaryLrSteperHandler(e)}
                        >
                            Next : Select  LR
                        </button>
                    </div>
                </div>
            </div>
            {isOpenThankyouModal
                && (
                    <ThankyouModal {...thankyouModalProps} />
                )
            }

            {leaveShowConfirm && (
                <ConfirmModalFor {...leaveModalProps}>
                    <>
                        <p className="_confirmPrx"> Do you really want leave this Page"</p>
                    </>
                </ConfirmModalFor>
            )}
        </>
    )
}

export default BillSummary