import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Modal, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import getCroppedImg from './croperHalper';

const CropModal = ({ show, onHide, image,handleCrop ,imageLoading}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0)
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null)
  
  // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleSave = () => {
    // Here you can implement the save functionality
  
    onHide();
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedArea,
        rotation
      )
      setCroppedImage(croppedImage)
      handleCrop(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedArea, rotation])
 

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ position: 'relative', width: '100%', height: 400 , }}>
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
          />
        </div>
        <div className="slider-container mt-3">
        <Form.Range  value={zoom}
            onChange={(e) => setZoom(e.target.value)}
            min={1}
            max={3}
            step={0.1}/>
          
        </div>
        <div className="slider-container mt-3">
        <Form.Range  value={rotation}
            onChange={(e) => setRotation(e.target.value)}
            min={0}
            max={100}
            step={1}/>
          
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" disabled={imageLoading} onClick={showCroppedImage}>
        {imageLoading?"Cropping":"Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CropModal;
