import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, axiosInstance } from 'lib';

export const addRoute = createAsyncThunk(
    'add Route',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ADD_ROUTE, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addTrip = createAsyncThunk(
    'add Trip',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ADD_TRIP, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addTotalFrieght = createAsyncThunk(
    'add Total',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.TOTAL_FREIGHT, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addBankAccount = createAsyncThunk(
    'add Bank Account',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ADD_BANK_ACCOUNT, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addTransationFreight = createAsyncThunk(
    'add Advance Freight',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ADD_ADV_FREIGHT, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addTransationConsignor = createAsyncThunk(
    'add Customer Claim',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ADD_CONSIGNOR_CLAIM, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addFuelTransaction = createAsyncThunk(
    'add Fuel Transaction',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ADD_FUEL_TRAN, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const endTrip = createAsyncThunk(
    'end Trip ',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.END_TRIP, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const addDriverTransaction = createAsyncThunk(
    'add Driver Transaction',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.ADD_DRIVER_TRANSACTION, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const addEwayWill = createAsyncThunk(
    'add Eway will',
    async (data = {}, { rejectWithValue }) => {
      
        try {
            const response = await axiosInstance.post(API.ADD_EWAY_WILL, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const editTotalFrieght = createAsyncThunk(
    'edit Total',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.EDIT_TOTAL_FREIGHT, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


// export const contractRateApi = createAsyncThunk(
//     'edit Total',
//     async (data = {}, { rejectWithValue }) => {
//         try {
//             const response = await axiosInstance.post(API.CONTRACT_RATE, data);
//             return response.data;
//         } catch (err) {
//             return rejectWithValue(err.response.data);
//         }
//     }
// );

export const contractRateApi = (data = {}) => {
    return axiosInstance
        .post(
            API.CONTRACT_RATE, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            if (error.name === 'TypeError') {
                
                console.error('Network error:', error.message);
            } else {
                       console.error('Error:', error.message);
            }
        });
};