import { createInvoiceBillTemplate } from '_services/invoiceBillRaiseServices';
import { CommonInput, CommonModal } from 'components';
import { UserContext } from 'context'
import { errorSchema, utils } from 'helper';
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import StorageService from 'utils/StorageServices/StorageServices';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const formObj = {
    company_id: StorageService.getCompanyId(),
    name: '',
    lr_list_columns: []
}

const AddInvoiceTemplateModal = (props) => {
    const { closeModal, columns, filterableColumns, data, setColumns } = props;
    const { masterData } = useContext(UserContext);
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);

    const [allColumns, setAllColumns] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState([]);

    useEffect(() => {
        if (masterData?.billraise_lr_list_columns) {
            setFormValue((s) => ({ ...s, company_id: StorageService.getCompanyId(), lr_list_columns: masterData.billraise_lr_list_columns }))
        }
    }, [masterData, StorageService.getCompanyId()]);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addEditZoneSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async () => {
        try {
            let obj = {
                ...formValue,
                lr_list_columns: allColumns,
            }
            const res = await createInvoiceBillTemplate(obj);
            if (!!res) {
                setFormValue({});
                setLoading(false);
                toast.success('Template Created Successfully');
                closeModal(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleClick = async (e) => {
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.addEditZoneSchema);
        if (utils?.isObjectKeyEmpty(validationResult)) {
            setLoading(true);
            apiCallingFunction();
            setFormError("");

        } else {
            setFormError(validationResult);
            setLoading(false);
        }
    };

    let inhanceProps = {
        ...props,
        isLoadding: loading,
        onSubmit: handleClick,
        disabled: loading,
    }

    // 

    useEffect(() => {
        setAllColumns(columns)
        setVisibleColumns(columns.filter(col => col.view == 1).map(col => col.accessor));
        // setAllFilterableColumns(filterableColumns)
    }, [columns]);

    const onDragEnd = (result) => {
        if (!result?.destination) return
        const tempData = [...allColumns];
        const [selectedColumn] = tempData.splice(result.source.index, 1)
        tempData.splice(result.destination.index, 0, selectedColumn);
        setAllColumns(tempData)
    }

    const getColumnId = (column) => {
        if (column?.accessor && column?.accessor != '') return column?.accessor
        return ((column.Header.toLowerCase()).replaceAll(' ', '_'));
    }

    const handleCheckboxChange = (item) => {
        const isColumnVisible = visibleColumns.includes(item.accessor);
        const isVisible = !isColumnVisible;

        const updatedColumns = allColumns.map(col => {
            if (col.accessor == item.accessor) {
                return { ...col, view: isVisible ? 1 : 0 };
            }
            return col;
        });

        setAllColumns(updatedColumns)

        const updatedVisibleColumns = visibleColumns.map(col => {
            if (col.accessor == item.accessor) {
                return { ...col, view: isVisible ? 1 : 0 };
            }
            return col;
        });
        updatedColumns.sort((a, b) => {
            if (a.view == 1 && b.view == 0) return -1; // a should come before b
            if (a.view == 0 && b.view == 1) return 1; // b should come before a
            return 0; // maintain current order
        });

        setAllColumns(updatedColumns)
        // setFormValue((s) => ({ ...s, lr_list_columns: updatedColumns }))
        setVisibleColumns(updatedVisibleColumns)
        setVisibleColumns(prevColumns =>
            prevColumns.includes(item?.accessor) ? prevColumns.filter(col => col !== item?.accessor) : [...prevColumns, item?.accessor]
        );
        // toggleActiveFilter(item);
    }


    return (
        <>
            {formValue?.lr_list_columns.length > 0 && (
                <div>
                    <CommonModal {...inhanceProps}>
                        <div className='pr_procssBx_grid p-3'>
                            <div className='_divDivide_half'>
                                <CommonInput
                                    type="text"
                                    placeholder="Template Name"
                                    label="Template Name *"
                                    name='name'
                                    value={formValue.name}
                                    focus={!!(typeof formError === "object" && formError?.name)}
                                    error={!!(typeof formError === "object") ? formError?.name : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                            </div>
                        </div>

                        <div className='_customCol_body'>
                            <div className={allColumns?.filter((el) => (el?.view != 1) ? el : '')?.length > 0 ? `_cstColDiv1 _cstColDiv_halfRg ${allColumns?.filter((el) => (el?.view != 0) ? el : '')?.length == 0 ? '_cstFull_divHide' : ''}` : '_cstColDiv1'}>
                                <p className='_clstHead'>Selected Columns ({allColumns?.filter((el) => (el?.view != 0) ? el : '')?.length})</p>
                                <div className='_colsListing_bx'>

                                    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                                        <Droppable droppableId='lrSelectedlist'>
                                            {(provided) =>
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    {allColumns.map((item, index) => {
                                                        return item.view == 1 && <Draggable draggableId={getColumnId(item)} index={index} key={getColumnId(item)}>
                                                            {(provided) =>
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}>

                                                                    <div
                                                                        className="column-toggle-dropdown-item text-nowrap extra-padding"
                                                                        draggable={true}
                                                                        {...provided.dragHandleProps}>
                                                                        <svg fill="#656565" version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 32 32" xmlSpace="preserve">
                                                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:none;}\n" }} />
                                                                            <title>draggable</title>
                                                                            <rect x={10} y={6} width={4} height={4} />
                                                                            <rect x={18} y={6} width={4} height={4} />
                                                                            <rect x={10} y={14} width={4} height={4} />
                                                                            <rect x={18} y={14} width={4} height={4} />
                                                                            <rect x={10} y={22} width={4} height={4} />
                                                                            <rect x={18} y={22} width={4} height={4} />
                                                                            <rect id="_Transparent_Rectangle_" className="st0" width={32} height={32} />
                                                                        </svg>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={true}
                                                                            className='_avPointer'
                                                                            onChange={() => handleCheckboxChange(item)}
                                                                        />
                                                                        {item.Header}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Draggable>
                                                    })}
                                                </div>
                                            }
                                        </Droppable>
                                    </DragDropContext>


                                </div>
                            </div>
                            <div className={allColumns?.filter((el) => (el?.view == 0) ? el : '')?.length > 0 ? `_cstColDiv2 _cstColDiv2_visible ${allColumns?.filter((el) => (el?.view != 0) ? el : '')?.length == 0 ? '_cstFull_divShow' : ''}` : '_cstColDiv2'}>
                                <p className='_clstHead'>Unselected Columns ({allColumns?.filter((el) => (el?.view == 0) ? el : '')?.length})</p>
                                <div className='_colsListing_bx'>

                                    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                                        <Droppable droppableId='lrUnselectedlist'>
                                            {(provided) =>
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    {allColumns.map((item, index) => {
                                                        return item.view == 0 && <Draggable draggableId={getColumnId(item)} index={index} key={getColumnId(item)}>
                                                            {(provided) =>
                                                                <div

                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}>

                                                                    <div
                                                                        className="column-toggle-dropdown-item text-nowrap extra-padding"
                                                                        draggable={true}
                                                                        {...provided.dragHandleProps}>
                                                                        <svg fill="#656565" version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 32 32" xmlSpace="preserve">
                                                                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:none;}\n" }} />
                                                                            <title>draggable</title>
                                                                            <rect x={10} y={6} width={4} height={4} />
                                                                            <rect x={18} y={6} width={4} height={4} />
                                                                            <rect x={10} y={14} width={4} height={4} />
                                                                            <rect x={18} y={14} width={4} height={4} />
                                                                            <rect x={10} y={22} width={4} height={4} />
                                                                            <rect x={18} y={22} width={4} height={4} />
                                                                            <rect id="_Transparent_Rectangle_" className="st0" width={32} height={32} />
                                                                        </svg>
                                                                        <input
                                                                            type="checkbox"
                                                                            className='_avPointer'
                                                                            checked={false}
                                                                            onChange={() => handleCheckboxChange(item)}
                                                                        />
                                                                        {item.Header}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Draggable>
                                                    })}
                                                </div>
                                            }
                                        </Droppable>
                                    </DragDropContext>



                                </div>
                            </div>
                        </div>





                        {/* <div className='_customCol_body'>
                            <div className={formValue?.lr_list_columns.filter((el) => el.view != 1)?.length > 0 ? `_cstColDiv1 _cstColDiv_halfRg ${formValue?.lr_list_columns?.filter((el) => el.view != 0)?.length === 0 ? '_cstFull_divHide' : ''}` : '_cstColDiv1'}>
                                <p className='_clstHead'>Selected Columns ({formValue?.lr_list_columns.filter((el) => el.view === 1).length})</p>
                                <div className='_colsListing_bx _colsList_smHeight'>
                                    {formValue?.lr_list_columns.map((column, index) => column.view === 1 && (
                                        <div className='_tempAdd_list ' key={column.accessor || column.Header} >
                                            <input
                                                type="checkbox"
                                                id={`checkbox-${column.Header}`}
                                                onChange={(e) => checkboxHandleOnChange(column, e.target.checked)}
                                                checked={column.view === 1}
                                            />
                                            <label htmlFor={`checkbox-${column.Header}`}>{column.Header}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={formValue?.lr_list_columns.filter((el) => el.view === 0).length > 0 ? `_cstColDiv2 _cstColDiv2_visible ${formValue?.lr_list_columns?.filter((el) => el.view != 0).length === 0 ? '_cstFull_divShow' : ''}` : '_cstColDiv2'}>
                                <p className='_clstHead'>Unselected Columns ({formValue?.lr_list_columns.filter((el) => el.view === 0).length})</p>
                                <div className='_colsListing_bx _colsList_smHeight'>
                                    {formValue?.lr_list_columns.map((column, index) => column.view === 0 && (
                                        <div className='_tempAdd_list ' key={column.accessor || column.Header} >
                                            <input
                                                type="checkbox"
                                                id={`checkbox-${column.Header}`}
                                                onChange={(e) => checkboxHandleOnChange(column, e.target.checked)}
                                            />
                                            <label htmlFor={`checkbox-${column.Header}`}>{column.Header}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div> */}
                    </CommonModal>
                </div>
            )}
        </>
    );
};

export default AddInvoiceTemplateModal;