import { createSlice } from '@reduxjs/toolkit';
import { addRoute,addTrip,addTotalFrieght ,addBankAccount,addEwayWill} from './tripAction';

const initialState = {
    data: null,
    userData: null,
    loading: false,
    error: null,
};

const tripSlice = createSlice({
    name: 'trip',
    initialState,
    reducers: {
        resetError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(addRoute.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addRoute.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(addRoute.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
          
            .addCase(addTrip.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addTrip.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(addTrip.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(addTotalFrieght.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addTotalFrieght.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(addTotalFrieght.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(addBankAccount.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addBankAccount.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(addBankAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(addEwayWill.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addEwayWill.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(addEwayWill.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
           
    },
});

export const { resetError } = tripSlice.actions;

export default tripSlice.reducer;
