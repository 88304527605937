import React from 'react'

const Revenue = () => {
  return (
    <>
      <div className='_revenueBx'>
        <div className="accordion" id="revenuAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div className='_revnHead'>Trip Revenue (+) <span>₹ 0.00</span></div>
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#revenuAccordion">
              <div className="accordion-body">
                <div className='__frtTab_lets'>
                  <span>Consignor Freight (+)</span> <span>₹ 0.00</span>
                </div>
                <div className='__frtTab_lets'>
                  <span>Claim Payable (+)</span> <span>₹ 0.00</span>
                </div>
                <div className='__frtTab_lets'>
                  <span>Claim Receivable (-)</span> <span>₹ 0.00</span>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <div className='_revnHead'>Truck Charges (-) <span>₹ 50000.00</span></div>
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#revenuAccordion">
              <div className="accordion-body">
                <div className='__frtTab_lets'>
                  <span>Truck Freight (-)</span> <span>₹ 50000.00</span>
                </div>
                <div className='__frtTab_lets'>
                  <span>Claim Paid (+)</span> <span>₹ 0.00</span>
                </div>
                <div className='__frtTab_lets'>
                  <span>Claim Received (-)</span> <span>₹ 0.00</span>
                </div>
                <div className='__frtTab_lets'>
                  <span>Truck Expenses (-)</span> <span>₹ 0.00</span>
                </div>
                <div className='__frtTab_lets'>
                  <span>Truck Receipt (+)</span> <span>₹ 0.00</span>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <div className='_revnHead'>Other Expenses (-) <span>₹ 0.00</span></div>
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#revenuAccordion">
              <div className="accordion-body">
                <div className='__frtTab_lets'>
                  <span>Other Expenses (-)</span> <span>₹ 0.00</span>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <div className='_revnHead'>Other Receipt (+) <span>₹ 0.00</span></div>
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#revenuAccordion">
              <div className="accordion-body">
                <div className='__frtTab_lets'>
                  <span>Other Receipt (+)</span> <span>₹ 0.00</span>
                </div>
              </div>
            </div>
          </div>

          <div className='__lst_Tab_lets'>
            <span>Total Profile & Loss</span> <span>₹ -50000.00</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Revenue