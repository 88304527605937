import { getAllRoutes, getAllVehicleNumber } from '_services'
import { fetchConsignorDetailsData } from '_services/accountsServices/consignorServices'
import { createContract, getContractListData, singleContractDetailsData } from '_services/accountsServices/contractServices'
import { getLorryTypeList } from '_services/accountsServices/lrServices'
import { getConsigneeDropdownListData, getConsignorDropdownListData, getContractDropdownListData, getSupplierDropdownListData, getUnitDropdownListData, getZoneDropdownListData } from '_services/dropdownServices/dropdownService'
import { CommonInput, CommonModal, CustomSelect } from 'components'
import { UserContext } from 'context'
import { errorSchema, utils } from 'helper'
import VehicleTypeModal from 'modals/VehicleTypeModal'
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import * as Yup from "yup";
import StorageService from 'utils/StorageServices/StorageServices'
import { createRate } from '_services/accountsServices/rateServices'
import { getVehicleGroupListData } from '_services/accountsServices/vehicleGroupServices'
import { RxCross2 } from "react-icons/rx";

const formObj = {
    consignor_id: {},
    contract_id: {},
    rate_type: {},
    rate: '',
    amount: '',
    vehicle_id: {},
    supplier_id: {},
    vehicle_ownership: {},
    vehicle_group_id: {},
    weight_range: {},

    from: {},
    to: {}
}

const RateDetailsModal = (props) => {

    let { isOpen , closeModal } = props

    let { id } = useParams();
    let company_id = StorageService.getCompanyId();
    const { masterData, cities, modalZIndex, setModalZIndex } = useContext(UserContext)
    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [consignorList, setConsignorList] = useState([]);
    const [contractList, setContractList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [contractDetails, setContractDetails] = useState({});
    const [vehicleNumbList, setVehicleNumbList] = useState([]);
    const [vehicleTypeshow, setVehicleTypeShow] = useState(false);
    const [conditionData, setConditionData] = useState({})
    const [lorryType, setLorryType] = useState([]);
    const [vehicleTypeLabel, setVehicleTypeLabel] = useState({ label: "", value: "" });
    const [consigneeDropdownData, setConsigneeDropdownData] = useState([])
    const [unitDropdownData, setUnitDropdownData] = useState([])
    const [routeList, setRouteList] = useState([]);
    const [vehicleGroupData, setVehicleGroupData] = useState([]);
    const [allZoneList, setAllZoneList] = useState([]);

    const getDropdownDataConsignorList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getConsignorDropdownListData(object);
            if (res?.responseCode == 200) {
                setConsignorList(res?.data?.consignor_list)
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            // setLoading(false);
            // throw error;
        }
    }

    const getDropdownDataSupplierList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getSupplierDropdownListData(object);
            if (res?.responseCode == 200) {
                let list = res?.data?.supplier_list.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                    };
                });
                setSupplierList(list)
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            // setLoading(false);
            // throw error;
        }
    }

    // const getZoneList = async () => {
    //     try {
    //         let object = { company_id: StorageService.getCompanyId() };
    //         const res = await getZoneDropdownListData(object);
    //         if (res) {
    //             const list = res?.data?.zone_list.map((el) => {
    //                 return {
    //                     ...el,
    //                     label: el?.name,
    //                     value: el?._id,
    //                 };
    //             });
    //             setAllZoneList(list);
    //         }
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    const getDropdownDataContractList = async (id_1, id_2) => {
        try {
            let object = {
                consignor_id: id_1,
                company_id: id_2,
                search: '',
                limit: 100,
                offset: 1,
            }
            const res = await getContractDropdownListData(object);
            if (res?.responseCode == 200) {
                let data = res?.data?.contract_list?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                        active: el?.active,
                    }
                })
                setContractList(data)
            }
        } catch (error) {
            // throw error;
        }
    }

    // const getDropdownDataUnitList = async () => {
    //     try {
    //         let object = {
    //             company_id: StorageService.getCompanyId(),
    //             limit: 100,
    //             offset: 1,
    //             search: '',
    //         }
    //         const res = await getUnitDropdownListData(object);
    //         if (res?.responseCode == 200) {
    //             let list = res?.data?.unit_list?.map((el) => {
    //                 return {
    //                     label: el?.name,
    //                     value: el?._id,
    //                 }
    //             })
    //             setUnitDropdownData(list)
    //         }
    //     } catch (error) {
    //         // throw error;
    //     }
    // }

    const getDropdownDataConsigneeList = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getConsigneeDropdownListData(object);
            if (res?.responseCode == 200) {
                let list = res?.data?.consignee_list?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?._id,
                    }
                })
                setConsigneeDropdownData(list)
            }
        } catch (error) {
            // throw error;
        }
    }

    // const getRouteList = async () => {
    //     const res = await getAllRoutes({
    //         company_id: StorageService.getCompanyId(),
    //     });
    //     if (res?.responseCode === 200) {
    //         const list = res?.data?.route?.map((i) => {
    //             return {
    //                 ...i,
    //                 label: i.route_name,
    //                 value: i._id,
    //             };
    //         });
    //         setRouteList(list);
    //     } else {
    //         toast.error(res?.responseMessage);
    //     }
    // };

    const getDataContractDetails = async (comp_id, consign_id , cont_id) => {
        try {
            let object = {
                company_id: comp_id,
                consignor_id: consign_id,
                contract_id: cont_id,
            }
            const res = await singleContractDetailsData(object);
            if (res?.responseCode == 200) {
                setContractDetails(res?.data?.contract_detail)
                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            // setLoading(false);
            // throw error;
        }
    }

    // useEffect(() => {
    //     if (consignorList?.length > 0) {
    //         setFormValue((s) => ({
    //             ...s,
    //             consignor_id: consignorList?.filter((el) => (el?._id === id) ? el : '')?.map((element) => {
    //                 return {
    //                     label: element?.name,
    //                     value: element?._id,
    //                 }
    //             })[0],
    //         }))
    //     }
    // }, [consignorList, id])

    // useEffect(() => {
    //     if (contractList?.length > 0) {
    //         setFormValue((s) => ({
    //             ...s,
    //             contract_id: contractList?.find((el) => (el?.active === 1) ? el : ''),
    //         }))
    //     }
    // }, [contractList])

    const getVehicleList = async () => {
        const res = await getAllVehicleNumber({
            company_id: StorageService.getCompanyId(),
        });
        if (res?.responseCode === 200) {
            setVehicleNumbList(
                res?.data?.vehicle_list?.map((i) => {
                    return {
                        ...i,
                        label: i.vehicle_no,
                        value: i._id,
                    };
                })
            );
        } else {
            toast.error(res?.responseMessage);
        }
    };

    const getDataLorryTypeList = async () => {
        try {
            const res = await getLorryTypeList();
            if (res?.responseCode == 200) {
                setLorryType(res?.data?.lorry_type);
            }
        } catch (error) {
            throw error;
        }
    };

    const vehicleHandleChange = (data) => {
        setFormValue({
            ...formValue,
            vehicle_id: data,
        });

        // setVehicleTypeLabel({
        //     label: `${data?.lorry_type_id?.lorry_type} > ${data?.vehicle_type_id_1?.name}`,
        //     value: "",
        // });
    };

    const callBackVehicleType = (value) => {
        setFormValue({
            ...formValue,
            lorry_type_id: value?.activeTab?._id,
            vehicle_type_id: value?.tyre?.value,
        });
        // vehicleHandleChange(value);
        setVehicleTypeLabel({
            label: `${value?.activeTab?.lorry_type} > ${value?.tyre?.label}`,
            value: value?.tyre?.value,
        });
        setVehicleTypeShow(false);
    };

    let vehicleTypeProps = {
        isOpen: vehicleTypeshow,
        closeModal: (e) => (setVehicleTypeShow(false), setModalZIndex('')),
        headerPart: true,
        footerPart: true,
        modalMainClass: "_dashCommon_Modal _smModal",
        modalTitle: "Vehicle Type",
        submitTitle: "Save",
        isLoadding: loading,
        disabled: loading ? true : false,
        lorryType: lorryType,
        callBackVehicleType: callBackVehicleType,
    };

    // const getDataVehicleGroupList = async () => {
    //     try {
    //         let object = {
    //             company_id: StorageService.getCompanyId(),
    //         }
    //         const res = await getVehicleGroupListData(object);
    //         if (res?.responseCode == 200) {
    //             setVehicleGroupData(res?.data?.vehicle_group_list);
    //         }
    //     } catch (error) {
    //         console.log('errr', error)
    //         //   throw error;
    //     }
    // }

    useEffect(() => {
        // getZoneList();
        // getRouteList();
        getVehicleList();
        getDropdownDataConsignorList();
        getDropdownDataSupplierList();
        // getDropdownDataUnitList();
        getDropdownDataConsigneeList();
        getDataLorryTypeList();
        // getDataVehicleGroupList();
    }, []);
    
    useEffect(() => {
        if(isOpen?._id){
            getDropdownDataContractList(isOpen?.consignor_id?._id, isOpen?.company_id);
            getDataContractDetails(isOpen?.company_id, isOpen?.consignor_id?._id, isOpen?.contract_id?._id);
            setFormValue(isOpen)
        }
    }, [isOpen?._id])

    useEffect(() => {
        if (contractDetails?.variable_rates?.length > 0) {
            let newObj = {}
            if (contractDetails?.matrix_allowed === 5) {
                newObj['isRouteWise'] = contractDetails?.matrix_allowed === 5 ? true : false
            }
            contractDetails?.variable_rates?.forEach(element => {
                newObj[(element?.name).replace(/\s+/g, '').replace('(KG)', '')] = element?.id
            });
            setConditionData(newObj);
        }
    }, [contractDetails?.variable_rates])

    console.log('isOpen', isOpen)

    return (
        <div>
            <CommonModal {...props}>
                <div className='addCont_body'>

                    <div className='_rtInn_bx'>
                        <div className='_cngDetail'>
                            <div className='_dualDivs'>
                                <CommonInput
                                    type="text"
                                    label="Consignor Name *"
                                    placeholder="Consignor Name *"
                                    name="name"
                                    value={formValue?.consignor_id?.name ? formValue?.consignor_id?.name : ''}
                                    disabled
                                />
                            </div>

                            <div className='_dualDivs'>
                                <CommonInput
                                    type="text"
                                    label="Select Contract *"
                                    placeholder="Select Contract *"
                                    name="name"
                                    value={formValue?.contract_id?.name ? formValue?.contract_id?.name : ''}
                                    disabled
                                />
                            </div>

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'City-City' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="From City "
                                            placeholder="From City "
                                            name="name"
                                            value={formValue?.from?.label ? formValue?.from?.label : formValue?.from}
                                            disabled
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="To City"
                                            placeholder="To City"
                                            name="name"
                                            value={formValue?.to?.label ? formValue?.to?.label : formValue?.to}
                                            disabled
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'Zone-Zone' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="From Zone "
                                            placeholder="From Zone "
                                            name="name"
                                            value={formValue?.from?.label ? formValue?.from?.label : formValue?.from}
                                            disabled
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="To Zone"
                                            placeholder="To Zone"
                                            name="name"
                                            value={formValue?.to?.label ? formValue?.to?.label : formValue?.to}
                                            disabled
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'Unit-Consignee' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="From Unit"
                                            placeholder="From Unit"
                                            name="name"
                                            value={formValue?.from?.label ? formValue?.from?.label : formValue?.from}
                                            disabled
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="To Consignee"
                                            placeholder="To Consignee"
                                            name="name"
                                            value={formValue?.to?.label ? formValue?.to?.label : formValue?.to}
                                            disabled
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'City-Zone' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="From Unit"
                                            placeholder="From Unit"
                                            name="name"
                                            value={formValue?.from?.label ? formValue?.from?.label : formValue?.from}
                                            disabled
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="To Zone"
                                            placeholder="To Zone"
                                            name="name"
                                            value={formValue?.to?.label ? formValue?.to?.label : formValue?.to}
                                            disabled
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'Route wise' && (
                                <>
                                    <div className='singleDiv'>
                                        <CommonInput
                                            type="text"
                                            label="Route wise"
                                            placeholder="Route wise"
                                            name="name"
                                            value={formValue?.route_wise?.label ? formValue?.route_wise?.label : ''}
                                            disabled
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'Unit-City' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="From Unit"
                                            placeholder="From Unit"
                                            name="name"
                                            value={formValue?.from?.label ? formValue?.from?.label : formValue?.from}
                                            disabled
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="To City"
                                            placeholder="To City"
                                            name="name"
                                            value={formValue?.to?.label ? formValue?.to?.label : formValue?.to}
                                            disabled
                                        />
                                    </div>
                                </>
                            )}

                            {masterData?.contract_matrix_allowed[contractDetails?.matrix_allowed] === 'Pin-Pin' && (
                                <>
                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="From Pin"
                                            placeholder="From Pin"
                                            name="name"
                                            value={formValue?.from?.label ? formValue?.from?.label : formValue?.from}
                                            disabled
                                        />
                                    </div>

                                    <div className='_dualDivs'>
                                        <CommonInput
                                            type="text"
                                            label="To Pin"
                                            placeholder="To Pin"
                                            name="name"
                                            value={formValue?.to?.label ? formValue?.to?.label : formValue?.to}
                                            disabled
                                        />
                                    </div>
                                </>
                            )}

                            <div className='_dualDivs'>
                                <CommonInput
                                    type="text"
                                    label="Rate Type"
                                    placeholder="Rate Type"
                                    name="name"
                                    value={contractDetails?.rate_type?.map((el) => {
                                        return {
                                            label: el?.name,
                                            value: el?.id,
                                        }
                                    })?.find((el) => (el?.value === formValue?.rate_type) ? el : '')?.label}
                                    disabled
                                />
                            </div>

                            {formValue?.rate_type?.value !== 6 && <div className='_dualDivs'>
                                <CommonInput
                                    type="text"
                                    label="Rate"
                                    placeholder="Rate"
                                    name="name"
                                    value={formValue?.rate}
                                    disabled
                                />
                            </div>}

                            {formValue?.rate_type?.value === 6 && <div className='_dualDivs'>
                                <CommonInput
                                    type="text"
                                    label="Amount"
                                    placeholder="Amount"
                                    name="name"
                                    value={formValue?.amount ? formValue?.amount : ''}
                                    disabled
                                />
                            </div>}

                            <div className='_dualDivs'>
                                <CommonInput
                                    label="Transit KMs"
                                    placeholder="Transit KMs"
                                    type="number"
                                    name='transit_kms'
                                    value={formValue.transit_kms}
                                    disabled
                                />
                            </div>

                            <div className='_dualDivs'>
                                <CommonInput
                                    label="Transit Days"
                                    placeholder="Transit Days"
                                    type="number"
                                    name='transit_days'
                                    value={formValue.transit_days}
                                    disabled
                                />
                            </div>
                        </div>
                        {
                            contractDetails?.variable_rates?.length > 0 ? (
                                <div>
                                    <h5 className='_cngH5_head'>Variable Rate on Fields</h5>
                                    <div className='_cngDetail'>
                                        {conditionData?.VehicleNo === 3 &&
                                            <div className='_dualDivs'>
                                                <CommonInput
                                                    label="Vehicle Number"
                                                    placeholder="Vehicle Number"
                                                    type="text"
                                                    name='transit_days'
                                                    value={formValue.vehicle_no ? formValue.vehicle_no : 'All'}
                                                    disabled
                                                />
                                            </div>
                                        }
                                        {conditionData?.VehicleType === 1 &&
                                            <div className='_dualDivs'>
                                                <CommonInput
                                                    label="Vehicle Type"
                                                    placeholder="Vehicle Type"
                                                    value={formValue.vehicle_type_name ? formValue.vehicle_type_name : 'All'}
                                                    disabled
                                                />
                                            </div>
                                        }
                                        {conditionData?.VehicleOwnership === 2 &&
                                            <div className='_dualDivs'>
                                                <CommonInput
                                                    label="Vehicle Ownership"
                                                    placeholder="Vehicle Ownership"
                                                    value={masterData?.vehicle_ownership[formValue.vehicle_ownership] ? masterData?.vehicle_ownership[formValue.vehicle_ownership] : 'All'}
                                                    disabled
                                                />
                                            </div>
                                        }
                                        {conditionData?.Supplier === 4 &&
                                            <div className='_dualDivs'>
                                                <CommonInput
                                                    label="Supplier"
                                                    placeholder="Supplier"
                                                    value={formValue.supplier_name ? formValue.supplier_name : 'All'}
                                                    disabled
                                                />
                                            </div>
                                        }
                                        {conditionData?.WeightRange === 5 &&
                                            <div className='_dualDivs'>
                                                <CommonInput
                                                    label="Weight Range(KG)"
                                                    placeholder="Weight Range(KG)"
                                                    value={formValue.weight_range ? formValue.weight_range : 'All'}
                                                    disabled
                                                />
                                            </div>
                                        }
                                        {conditionData?.VehicleGroup === 6 &&
                                            <div className='_dualDivs'>
                                                <CommonInput
                                                    label="Vehicle Group"
                                                    placeholder="Vehicle Group"
                                                    value={formValue.vehicle_group_id ? formValue.vehicle_group_id : 'All'}
                                                    disabled
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            ) : <></>
                        }
                    </div>

                </div>
            </CommonModal >
        </div >
    )
}

export default RateDetailsModal
