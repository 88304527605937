import { API, axiosInstance } from "lib";

export const getConsignorGroupListData = (data={}) => {
  return axiosInstance
    .post(API.GET_CONSIGNOR_GROUP_LIST , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const createConsignorGroup = (data = {}) => {
  return axiosInstance
    .post(API.CREATE_CONSIGNOR_GROUP , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getSingleConsignorGroupData = (data={}) => {
  return axiosInstance
    .post(API.GET_SINGLE_CONSIGNOR_GROUP , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteConsignorGroupData = (data={}) => {
  return axiosInstance
    .post(API.DELETE_CONSIGNOR_GROUP , data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};