import { handleKeyDown } from "_services";
import { CommonInput, CommonModal, CustomSelect } from "components";
import { UserContext } from "context";
import { addBankAccount } from "features";
import { errorSchema, utils } from "helper";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import StorageService from "utils/StorageServices/StorageServices";

const AddBankAccount = (props) => {
  const initial = {
    company_id: StorageService?.getCompanyId(),
    account_type: 1,
    vendor_type: '',
    vendor_id: "",
    bank_name: "",
    account_name: "",
    account_number: "",
    confirm_account_number: "",
    ifsc_code: "",
    upi_id: "",
  };
  const dispatch = useDispatch()
  const { masterData, globTripDetails,setBankDetails } = useContext(UserContext);
  const [form, setForm] = useState(initial);
  const [formError, setFormError] = useState("");
  const [accountListList, setAccountTypeList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [isLoadding,setIsLoadding] = useState(false)


  useEffect(() => {
    if (masterData) {
      const lsit = masterData?.bank_account_type_list?.map((i) => {
        return {
          ...i,
          label: i?.name,
          value: i?.id,
        };
      });
      const bList = masterData?.bank_list?.map((i) => {
        return {
          ...i,
          label: i?.name,
          value: i?.name,
        };
      });
      setBankList(bList);
      setAccountTypeList(lsit);

    }
  }, [masterData]);

  useEffect(()=>{
if(form.account_type==1){
    setForm({...form, account_type: 1,
        vendor_type: "",
        vendor_id: "",
        bank_name: "",
        account_name: "",
        account_number: "",
        confirm_account_number: "",
        ifsc_code: "",
        upi_id: "",})
}else{
    setForm({...form, account_type: 2,
        vendor_type: "",
        vendor_id: "",
        bank_name: "",
        account_name: "",
        account_number: "",
        confirm_account_number: "",
        ifsc_code: "",
        upi_id: "",})
}
  },[form.account_type])

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...form,
      [name]: value,
    };
    setForm(stateObj);

    if (!!formError) {
      const validationResult = await utils.checkFormError(
        { ...form },
        errorSchema.addAccountSchema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const accountSubmit = async () => {
    const validationResult = await utils.checkFormError(
      { ...form },
      errorSchema.addAccountSchema
    );
    
    const payload = {
        company_id: StorageService?.getCompanyId(),
        account_type: form?.account_type,
        vendor_type:  globTripDetails?.vehicle_ownership == 2 ? 1 : 3,
        vendor_id:  globTripDetails?.vehicle_ownership == 2 ? globTripDetails?.supplier_id?._id:globTripDetails?.driver_id?._id,
        bank_name: form?.bank_name,
        account_name: form?.account_name,
        account_number: form?.account_number,
        ifsc_code: form?.ifsc_code,
        upi_id: form?.upi_id,
    };
    
    if(validationResult === true){
      
        setIsLoadding(true)
        try{
            const res = await dispatch(addBankAccount(payload))
           
            if(res?.payload?.responseCode===200){
                props?.closeModal()
                props?.callBackAddAccount()
                setIsLoadding(true)
                setBankDetails(res?.payload?.data?.bank_account)
            }

        }catch(error){

        }
    }else{
      setFormError(validationResult);
    }

 
  };

  let propsAddAccount = {
    ...props,
    isLoadding:isLoadding,
    onSubmit: accountSubmit,
  };

 
  return (
    <>
      <CommonModal {...propsAddAccount}>
        <div className="addBankAcc_body">
          <div className="_allDflx">
            <div className="checkBx_lv">
              {accountListList?.map((i) => {
                return (
                  <div className="_dualCheckr_bx">
                    <input
                      className="form-check-input"
                      id={i?.value}
                      type="radio"
                      name="account_type"
                      checked={i.value == form.account_type}
                      value={i.value}
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, parseInt(value))
                      }
                    />
                    <label htmlFor={i?.value} className="form-check-label">
                      {i.label}
                    </label>
                  </div>
                );
              })}
            </div>

            {form.account_type == 2?<div>
                <CommonInput
                  label="UPI"
                  type="text"
                  name="upi_id"
                  value={form.upi_id}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  focus={!!formError?.upi_id}
                  error={formError.upi_id}
                />
              </div>:
            <>
              <div>
                <CommonInput
                  label="Account Number"
                  type="text"
                  name="account_number"
                  value={form.account_number}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  onKeyDown={handleKeyDown}
                  focus={!!formError?.account_number}
                  error={formError.account_number}
                />
              </div>

              <div>
                <CommonInput
                  label="Confirm Account Number"
                  type="text"
                  name="confirm_account_number"
                  value={form.confirm_account_number}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  onKeyDown={handleKeyDown}
                  focus={!!formError?.confirm_account_number}
                  error={formError.confirm_account_number}
                />
              </div>

              <div>
                <CommonInput
                  label="IFSC Code"
                  type="text"
                  name="ifsc_code"
                  value={form.ifsc_code}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  focus={!!formError?.ifsc_code}
                  error={formError.ifsc_code}
                />
              </div>

              <div>
                <CommonInput
                  label="Account Holder Name"
                  type="text"
                  name="account_name"
                  value={form.account_name}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                  focus={!!formError?.account_name}
                  error={formError.account_name}
                />
              </div>

              <div>
                <CustomSelect
                  label="Select Bank"
                  isDropdownTop={true}
                  options={bankList}
                  value={bankList?.find((i) => i?.name === form?.bank_name)}
                  onSelect={(data) => {
                    handleOnChange("bank_name", data?.value);
                  }}
                  focus={!!formError?.bank_name}
                  error={formError.bank_name}
                />
              </div>
            </>}
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default AddBankAccount;
